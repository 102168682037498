let checkFiles = [];

const checkFilesReducer = (state = checkFiles, action) => {
  switch (action.type) {
    case "SAVE_CHECK_FILESNAME":
      return [...state, ...[action.payload]];
    case "CLEAR_CHECK_FILESNAME":
      return [...checkFiles];
    default:
      return state;
  }
};

let isCheckFilesCheckbox = false;

const toggleCheckFilesCheckboxReducer = (state = isCheckFilesCheckbox, action) => {
  switch (action.type) {
    case "TOGGLE_CHECKFILES_CHECKBOX":
      return (state = action.payload);
    default:
      return state;
  }
};

const fileCountReducer = (state = 0, action) => {
  switch (action.type) {
    case "CHECK_FILE_COUNT":
      return [state+action.payload]; // Assuming action.payload contains the updated count value
    // Handle other action types if needed
    case "CLEAR_FILE_COUNT":
      return [];
    default:
      return state;
  }
};



export default checkFilesReducer;

export { toggleCheckFilesCheckboxReducer,fileCountReducer };
