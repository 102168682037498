import React, { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { useNavigate } from "react-router";
import {
  extractNumberFromString,
  getChunkName,
  sortChapters,
  sortChunks,
} from "../../../services/config";
// import { getProjectById } from "../../../services/indexed-db/project.indexeddb";
import { useSelector,useDispatch } from "react-redux";
import { sideNavBarStatusReducer } from "../../../redux/actions/util.action";

const ChunkSideNavbar = ({ chapterData, book, type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [chapter, setChapter] = useState([]);
  const [books, setBooks] = useState([]);
  const [chunkData, setChunkData] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [selectedPro, setSelectedPro] = useState({});
  const projectsReducer = useSelector((state) => state.projectsReducer);
  let setNavBar = useSelector((state) => state.sideNavBarStatusReducer);

  useEffect(() => {
    if (localStorage.getItem("selectedProject")) {
      setSelectedPro(JSON.parse(localStorage.getItem("selectedProject")));
    }
    if (type == "book") {
      setSelectedPro(JSON.parse(localStorage.getItem("selectedBook")));
      setChapter(chapterData);
      sortChap();
    } else {
      setBooks(chapterData);
      sortChap();
    }
  }, []);

  const showChunk = () => {
    setIsShow(!isShow);
  };

  const sortChap = async () => {
    if (type == "book") {
      let data = await sortChunks(chapterData.chunks);
      setChunkData(data);
    } else {
      let data = await sortChapters(chapterData.chapters);
      setChunkData(data);
    }
  };

  const redirectToChunkDetails = async (chapter) => {
    dispatch(sideNavBarStatusReducer(setNavBar));
    let { bookName } = book;
    if (
      localStorage.getItem("role") == "Technician" ||
      localStorage.getItem("role") == "Coordinator" ||
      localStorage.getItem("role") == "Translation Manger"
    ) {
      let selectP = JSON.stringify({
        projectName: selectedPro.projectName,
        bookName,
        chapterName: chapter.chapterName,
      });

      localStorage.setItem("selectedProject", selectP);
      navigate("/myProject");
    } else {
      // let project = await getProjectById(chapter.projectId);
      let project= await projectsReducer.filter((item)=>{return item.id==chapter.projectId})[0];
      let headingName = await extractNumberFromString(chapter.chunk[0].verse);
      let chunkName = await getChunkName(
        project.language,
        chapter.chunk[0].bookName,
        chapter.chunk[0].chapterName
      );
      let { bookName } = book;
      if (type == "book") {
        let { bookName } = book;
        let str = JSON.stringify({
          bookName,
          chapterName: chapter.chunk[0].chapterName,
          tab: localStorage.getItem("role") =="MTT" ? "1" : "4" 
        });

        chapter.chunk=chapter.chunk.sort((a, b) => {
          // Extracting file extensions (assuming all files have extensions)
          const extensionA = a.fileName.split('.').pop();
          const extensionB = b.fileName.split('.').pop();        
          // Assigning priorities based on file type
          const priority = { 'mp3': 1, 'txt': 2 };
          // Comparing based on priorities
          return priority[extensionA] - priority[extensionB];
        });        
        localStorage.setItem("selectedBook", str);
        if(localStorage.getItem("role")=="MTT"){
          navigate("/draft-details", {
            state: {
              chunkName: await getChunkName(
                project.language,
                chapter.chunk[0].bookName,
                chapter.chunk[0].chapterName
              ),
              chunk: chapter,
              chapter: chapter.chunk[0].chapterName,
              verse: chapter.chunkName,
              project,
              book: book,
              heading: `${chunkName} ${headingName}`,
              isReadOnly: false,
            },
          });
        }else{
          navigate("/cross-check", {
            state: {
              chunkName: await getChunkName(
                project.language,
                chapter.chunk[0].bookName,
                chapter.chunk[0].chapterName
              ),
              chunk: chapter,
              book: book,
              verse: chapter.chunkName,
              heading: `${chunkName} ${headingName}`,
              chapter: chapter.chunk[0].chapterName,
              project:project,
              // targetType:
              //   activeKey == 2 ? "draft" : activeKey == 3 ? "backTranslate" : "",
            },
          });
        }
      } else {
        let selectP = JSON.stringify({
          projectName: selectedPro.projectName,
          bookName,
          chapterName: chapter.chunk[0].chapterName,
        });

        localStorage.setItem("selectedProject", selectP);
        navigate("/myProject",{
          state:{
            chapterName: chapter.chunk[0].chapterName
          }
        });
      }
    }
  };

  return (
    <div>
      <p onClick={(e) => showChunk()}>
        <span className="cursor-pointer text-md">
          {" "}
          {type == "book" ? chapter.chapterName : books.bookName}{" "}
        </span>
        <span>
          <FiChevronDown />
        </span>
      </p>
      {isShow &&
        chunkData &&
        chunkData.map((chunk, i) => (
          <>
            <p
              className="cursor-pointer"
              key={`${chunk._id} ${i}`}
              onClick={(e) => {
                redirectToChunkDetails(
                  type == "book"
                    ? chunk
                    : localStorage.getItem("role") == "CIT" ||
                      localStorage.getItem("role") == "Consultant"
                    ? chunk.chunks[i]
                    : chunk
                );
              }}
            >
              {type == "book" ? chunk.chunkName : chunk.chapterName}
            </p>
          </>

        ))}
    </div>
  );
};

export default ChunkSideNavbar;
