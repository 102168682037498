import React, { useEffect, useState } from "react";
import "./cross-check.scss";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { notification, Modal, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toggleCheckFilesCheckbox } from "../../../redux/actions/check-files.action";
// import { crossCheckAndSync } from "../../../services/sync-methods/cross-check.sync";
import { postCheckTypeToServer,citConCheckTypeToServer } from "../../../services/api-calls/check_file.api"
import { useLocation } from "react-router";

const { confirm } = Modal;

const CrossCheckModal = ({ }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isRecordingStatus = useSelector(
    (state) => state.recordingStatusReducer
  );
  const checkFilesReducer = useSelector((state) => state.checkFilesReducer);
  const toggleCheckFilesCheckboxReducer = useSelector(
    (state) => state.toggleCheckFilesCheckboxReducer
  );
  const fileCountReducer = useSelector((state) => state.fileCountReducer)

  const [isChecked, setisChecked] = useState(false);
  const [text, setText] = useState("");

  const [api, contextHolder] = notification.useNotification();
  const showNotification = (type, message) => {
    notification.destroy();
    api[type]({
      message,
      placement: "top",
    });
  };
  let role = localStorage.getItem("role");
  useEffect(() => {
    if (role == "MTT") {
      setText("Cross Check");
    } else if (role == "Facilitator") {
      setText("Community Check");
    }else if(role == "CIT"){
      setText("CIT Check");
    }else if(role == "Consultant"){
      setText("Consultant Check");
    }
   
    //  else if (role == "Consultant") {
    //   setText("Consultant Review")
    // } else if (role == "CIT") {
    //   setText("CIT Review")
    // }
    setisChecked(toggleCheckFilesCheckboxReducer || fileCountReducer.length>0);
    return () => {
      // dispatch(toggleCheckFilesCheckbox(false));
    };
  },[location.state]);

  useEffect(() => {
    setisChecked(toggleCheckFilesCheckboxReducer || fileCountReducer.length>0);
  }, [toggleCheckFilesCheckboxReducer]);

  const showDeleteConfirm = () => {
    confirm({
      title: (
        <p className="">
          Are you sure you want to <span className="lowercase">{text}</span> this file?
        </p>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: "Confirm",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        try {
          if (localStorage.getItem("checkPageMsg")) {
            showNotification(
              "success",
              `${localStorage.getItem("checkPageMsg")}  
              ${role == "Facilitator" ? " Community check" : 
              role == "MTT" ?  " Cross Check" :
              role == "CIT" ?  " CIT Check" : 
              role == "Consultant" ?  " Consultant Check" : null
              }`
            );
          } else {
            let data;
            //  await crossCheckAndSync({
            //   userId: localStorage.getItem("id"),
            //   checkType:
            //     role == "Facilitator"
            //       ? "COMMUNITY_CHECK"
            //       : "CROSS_CHECK",
            //   fileId: checkFilesReducer,
            // });

            if (window.navigator.onLine == true) {
              if(role == "CIT" || role == "Consultant"){
                data= await citConCheckTypeToServer({
                  projectId:location.state.project.id,
                  bookName:location.state.book.bookName,
                  chapterName:location.state.chapter,
                  sourceVerseName:location.state.chunk.chunkName,
                  role
                })
              }else{
                data = await postCheckTypeToServer({
                  userId: localStorage.getItem("id"),
                  checkType:
                    role == "Facilitator"
                      ? "COMMUNITY_CHECK"
                      : "CROSS_CHECK",
                  fileId: checkFilesReducer,
                });
              }
            }

            if (data == "COMMUNITY_CHECK completed successfully") {
              showNotification("success", "Community check done successfully");
            } else if (data == "CROSS_CHECK completed successfully") {
              showNotification("success", "Cross Check done successfully");
            } else if (data == "CIT_CHECK completed successfully") {
              showNotification("success", "CIT Check done successfully");
            } else if (data == "Consultant_CHECK completed successfully") {
              showNotification("success", "Consultant Check done successfully");
            }
            setisChecked(true);
          }
        } catch (error) {
          showNotification("error", error.response.data.msg);
          console.log(error);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const toggleModal = () => {
    if (isRecordingStatus == true) {
      showNotification("error", "Please stop recording");
    } else {
      showDeleteConfirm();
    }
  };

  return (
    <>
      <Checkbox
        onClick={() => toggleModal()}
        checked={isChecked}
        className={`${isChecked == true ? "crossCheck" : ""}`}
        disabled={isChecked || checkFilesReducer.length == 0 }
      >
        <span className="text-lg">{text}</span>
      </Checkbox>
      {contextHolder}
    </>
  );
};

export default CrossCheckModal;
