export const storeProjects = (payload) => {
    return {
      type: "STORE_PROJECTS",
      payload,
    };
  };

  export const getAllProjects = () => {
    return {
      type: "GET_PROJECTS",
    };
  };
  
  
  
  export const clearProjects = () => {
    return { type: "CLEAR_PROJECTS" };
  };
  