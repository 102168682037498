import React, { useState, useEffect, useRef } from "react";
import { Card, Row, Col, notification } from "antd";
import "../dashboard/dashboard.scss";
import Project from "../../assets/images/resized/project.jpeg";
import Team_Connect from "../../assets/images/resized/Team Connect.jpg";
import Progress from "../../assets/images/resized/progress.jpg";
import Draft from "../../assets/images/resized/Draft.png";
import support from "../../assets/images/Support.png";
import ToDo from "../../assets/images/resized/ToDO.png";
import RecentUpdatesImages from "../../assets/images/resized/Recent Updates.jpg";
import Communit_Check from "../../assets/images/resized/Community Check.jpg";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { base_url, desktop } from "../../services/apis";
import getHeader from "../../services/config";
import MainLayout from "../../components/layout/main.layout";
import { storeBooks } from "../../redux/actions/books.action";
import { storeProjects } from "../../redux/actions/project.action";
import { useDispatch,useSelector } from "react-redux";
import SpinnerComponent from "../../components/spinner/spinner.component";
import { saveBooksAndProjects } from "../../services/BooksAndProjects";

const { Meta } = Card;

const DashboardPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(true);
  const [page, setPage] = useState("dashboard");
  const [role, setRole] = useState("");
  const projectsReducer = useSelector((state) => state.projectsReducer);
  const booksReducer = useSelector((state) => state.booksReducer);
  // const interval = useRef();
  // const timeout = useRef();
  // const timeout2 = useRef();
  const location = useLocation();
  // let draftFileStatus = undefined;

  const [notificationApi, NotificationJSX] = notification.useNotification();
  const showNotification = (type, message) => {
    notification.destroy();
    notificationApi[type]({
      message,
    });
  };
  useEffect(() => {
    localStorage.removeItem("selectedBook");
    localStorage.removeItem("selectedProject");
    localStorage.removeItem("statusChecked");
    setRole(localStorage.getItem("role"));
    booksNProjects();
  }, [location]);

  const redirectToPage = (pageName) => {
    setPage(pageName);
    localStorage.setItem("appName", JSON.stringify(pageName));
    navigate(`/${pageName}`);
  };

  const addSpinner = async () => {
    setIsLoader(true);
  };

  const booksNProjects = async () => {
    let data = await saveBooksAndProjects(navigate);
    if((projectsReducer.length<=0 || booksReducer.length<=0) && data.length>0 ){
      data.projectsData.length >0 ? data.projectsData.forEach(async (item)=>{
        await dispatch(storeProjects([item]));
      }) : await dispatch(storeProjects([data.projectsData]));
  
      data.booksData.length>0 ? data.booksData.forEach(async(item)=>{
        // console.log("booksData",item);
        await dispatch(storeBooks([item]));
      }):await dispatch(storeBooks([data.booksData]));
      // await dispatch(storeProjects(data.projectsData));
      // await dispatch(storeBooks(data.booksData));
      setIsLoader(false);
    }else{
      setIsLoader(false);
    }
    
  };

  return (
    <>
    {isLoader ? (
        <SpinnerComponent />
      ) : (
        <MainLayout>
          <div className="site-layout-background">
            <div className="site-layout-background" style={{ minHeight: 360 }}>
              <div className="site-card-border-less-wrapper">
                <Card bordered={false} className="dashCard">
                  <Row >
                    <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={7.5}>
                      {role == "MTT" || role == "Facilitator" ? (
                        <Card
                          className="itemCard flex flex-column items-center h-100"
                          onClick={(e) => {
                            redirectToPage("draft");
                          }}
                          style={{
                            width: "auto",
                            textAlign: "center",
                            border: "0",
                          }}
                          cover={
                            <div className="coverStyle  card-image">
                              <img
                                className="dashImg h-75 w-75"
                                alt="example"
                                src={role == "MTT" ? Draft : Communit_Check}
                              />
                            </div>
                          }
                        >
                          <Meta
                            title={
                              role == "MTT" ? (
                                <span className="  ">Draft</span>
                              ) : (
                                <span className="  ">Community Check</span>
                              )
                            }
                            style={{ justifyContent: "center" }}
                            description=""
                          />
                        </Card>
                      ) : (
                        <Card
                          className="itemCard flex flex-column items-center h-100 w-100"
                          onClick={(e) => {
                            redirectToPage("myProject");
                          }}
                          style={{
                            width: "auto",
                            textAlign: "center",
                            border: "0",
                            // height: "100%",
                          }}
                          cover={
                            <div className="coverStyle  w-100">
                              <img
                                className="dashImg h-75 w-75"
                                alt="example"
                                src={Project}
                                style={{ width: "246px", height: "246px" }}
                              />
                            </div>
                          }
                        >
                          <Meta
                            title={<span className="  ">Projects</span>}
                            style={{ justifyContent: "center" }}
                            description=""
                          />
                        </Card>
                      )}
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={7.5}>
                      <Card
                        onClick={(e) => {
                          redirectToPage("my-progress");
                        }}
                        className="itemCard flex flex-column items-center h-100"
                        style={{
                          width: "auto",
                          textAlign: "center",
                          border: "0",
                        }}
                        cover={
                          <div className="coverStyle ">
                            <img
                              className="dashImg h-75 w-75"
                              alt="example"
                              src={Progress}
                              style={{ width: "246px", height: "246px" }}
                            />
                          </div>
                        }
                      >
                        <Meta
                          title={<span className="  ">Progress</span>}
                          style={{ justifyContent: "center" }}
                          description=""
                        />
                      </Card>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={7.5}>
                      <Card
                        // hoverable
                        onClick={(e) => {
                          redirectToPage("team-connect");
                        }}
                        className="itemCard flex flex-column items-center h-100"
                        style={{
                          width: "auto",
                          textAlign: "center",
                          border: "0",
                        }}
                        cover={
                          <div className="coverStyle ">
                            <img
                              className="dashImg h-75 w-75"
                              alt="example"
                              src={Team_Connect}
                            />
                          </div>
                        }
                      >
                        <Meta
                          title={<span className="  ">Team Connect</span>}
                          style={{ justifyContent: "center" }}
                          description=""
                        />
                      </Card>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={7.5}>
                      <Card
                        onClick={(e) => {
                          redirectToPage("to-do");
                        }}
                        className="itemCard flex flex-column items-center h-100"
                        style={{
                          width: "auto",
                          textAlign: "center",
                          border: "0",
                        }}
                        cover={
                          <div className="coverStyle ">
                            <img
                              className="dashImg h-75 w-75"
                              alt="example"
                              src={ToDo}
                            />
                          </div>
                        }
                      >
                        <Meta
                          title={<span className="  ">To Do</span>}
                          style={{ justifyContent: "center" }}
                          description=""
                        />
                      </Card>
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={7.5}>
                      <Card
                        className="itemCard flex flex-column items-center h-100"
                        onClick={(e) => {
                          redirectToPage("support");
                        }}
                        style={{
                          width: "auto",
                          textAlign: "center",
                          border: "0",
                        }}
                        cover={
                          <div className="coverStyle">
                            <img
                              className="dashImg h-75 w-75"
                              alt="example"
                              src={support}
                            />
                          </div>
                        }
                      >
                        <Meta
                          title={<span className="  ">Support</span>}
                          style={{ justifyContent: "center" }}
                          description=""
                        />
                      </Card>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={7.5}>
                      <Card
                        className="itemCard flex flex-column items-center h-100"
                        onClick={(e) => {
                          redirectToPage("recent-updates");
                        }}
                        style={{
                          width: "auto",
                          textAlign: "center",
                          border: "0",
                        }}
                        cover={
                          <div className="coverStyle">
                            <img
                              className="dashImg h-75 w-75 "
                              alt="example"
                              src={RecentUpdatesImages}
                            />
                          </div>
                        }
                      >
                        <Meta
                          title={<span className="  ">What's new</span>}
                          style={{ justifyContent: "center" }}
                          description=""
                        />
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </div>
            </div>
          </div>
          {NotificationJSX}
        </MainLayout>
      )}
    </>
  );
};

export default DashboardPage;