import { Button, Checkbox, Drawer, Form, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getChunkName,extractNumberFromString } from "../../../services/config";
// import { getProjectById } from "../../../services/indexed-db/project.indexeddb";

const SelectChunk = ({
  isBackTranslation,
  updateState,
  chunks,
  chapters,
  currentBookName,
  currentChapterName,
  book,
  type,
  fileType,
  project,
  reload,
}) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(true);
  const [availableChunks, setAvailableChunks] = useState([]);
  const [availableChapters, setAvailableChapters] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  // const projectsReducer = useSelector((state) => state.projectsReducer);

  const showNotification = (type, message) => {
    notification.destroy();
    api[type]({
      message,
      placement: "top",
    });
  };

  const state = {
    button: 1,
  };

  useEffect(() => {
    type != "chapter"
      ? setAvailableChunks(chunks)
      : setAvailableChapters(chapters);
  }, [chunks, currentBookName, currentChapterName]);



  const onClose = () => {
    setVisible(false);
    updateState(false);
  };

  const onFinish = async (values) => {
    let filtered = await Object.keys(values).filter((key) => values[key] === true);
    book = book[0] || book
    // let project = await getProjectById(book.projectId);
    // let project = await projectsReducer[0]
    if (type != "chapter") {
      if (isBackTranslation != true) {
        if (filtered.length == 0) {
          showNotification("error", "Please select chunks");
          return;
        } else {
          if (project.projectType.signLanguage == false) {
            if (fileType == "view") {
              navigate("/script-view", {
                state: {
                  book: book,
                  chapter: currentChapterName,
                  type: "PLAY-SELECTED",
                  project: project,
                  chunk: filtered,
                  fileType: fileType
                },
              });
            }
            else {
              navigate("/audio-playlist", {
                state: {
                  book: book,
                  chapter: currentChapterName,
                  type: "PLAY-SELECTED",
                  project: project,
                  chunk: filtered,
                  fileType: fileType
                },
              });
            }

          } else {
            navigate("/video-player", {
              state: {
                book: book,
                chapter: currentChapterName,
                type: "PLAY-SELECTED",
                chunk: filtered,
                project: project,
                fileType
              },
            });
          }
        }
      } else {
        if (filtered.length > 0) {
          let filterChunkData=[];
          for(let i=0;i<filtered.length;i++){
            for(let j=0;j<chunks.length;j++){
              if(filtered[i]==chunks[j].chunkName){
                filterChunkData.push(chunks[j])
              }
            }
          }
          let headingName = await extractNumberFromString(filtered[0]);
          let chunkName = await getChunkName(
            project.language,
            book.bookName,
            currentChapterName
          );

          if (state.button === 1) {
            localStorage.setItem("selectedChunks", JSON.stringify(filtered));
            navigate("/cit-con-check", {
              state: {
                chunkName: chunkName,
                chunk: filterChunkData,
                book: book,
                verse: filtered[0],
                heading: `${chunkName} ${headingName}`,
                chapter: currentChapterName,
                project,
              },
            });

          } else {
            if (filtered.length == 0) {
              showNotification("error", "Please select chunks");
              return;
            }
            if (project.projectType.signLanguage == false) {
              navigate("/audio-playlist", {
                state: {
                  book: book,
                  chapter: currentChapterName,
                  type: "PLAY-SELECTED",
                  chunk: filtered,
                  project,
                  fileType
                },
              });
            } else {
              navigate("/video-player", {
                state: {
                  book: book,
                  chapter: currentChapterName,
                  type: "PLAY-SELECTED",
                  chunk: filtered,
                  project,
                  fileType
                },
              });
            }
          }
        } else {
          showNotification("error", "Please select Chunk");
        }
      }
    } else {
      localStorage.setItem("aduioPlay", "chapter");
      if (filtered.length == 0) {
        showNotification("error", "Please select Chapter");
        return;
      } else {
        if (project.projectType.signLanguage == false) {

          if (fileType == "view") {
            navigate("/script-view", {
              state: {
                book: book,
                chapter: filtered,
                type: "PLAY-SELECTED",
                fileType,
                project
              },
            });
          }
          else {
            navigate("/audio-playlist", {
              state: {
                book: book,
                chapter: filtered,
                type: "PLAY-SELECTED",
                fileType,
                project
              },
            });
          }
        } else {
          navigate("/video-player", {
            state: {
              book: book,
              chapter: filtered,
              type: "PLAY-SELECTED",
              fileType
            },
          });
        }
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      {isBackTranslation === true ? (
        <div>
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="flex items-center">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-btn mx-2"
                  disabled={availableChunks.length == 0}
                  style={{ background: "#DAA520", color: "white", borderColor: "#DAA520" }}
                  onClick={() => (state.button = 1)}
                  onMouseOver={(e) => { e.target.style.color = "white"; }}
                >
                  View
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-btn"
                  disabled={availableChunks.length == 0}
                  style={{ background: "#031832", color: "white" }}
                  onClick={() => (state.button = 2)}
                  onMouseOver={(e) => { e.target.style.color = "white"; }}
                >
                  Play Selected
                </Button>
              </Form.Item>
            </div>
            <div className="mx-2 capitalize ">
              <div className="d-flex flex-wrap">
                {(availableChunks && availableChunks.length) > 0 ? (
                  <>
                    {availableChunks.map((chunk) => (
                      <Form.Item
                        name={chunk.chunkName}
                        valuePropName="checked"
                        key={chunk.chunkName}
                      >
                        <Checkbox
                          className=" py-2  px-1 rounded hover:bg-primary hover-bg-gray"
                          style={{
                            border:
                              chunk.chunksDataProgress == "completed"
                                ? "#3CB043"
                                : chunk.chunksDataProgress == "pending"
                                  ? "#C8102E"
                                  : "#FFBF00",
                            color:
                              chunk.chunksDataProgress == "completed"
                                ? "#3CB043"
                                : chunk.chunksDataProgress == "pending"
                                  ? "#C8102E"
                                  : "#FFBF00",
                          }}
                        >
                          {chunk.chunkName}
                        </Checkbox>
                      </Form.Item>
                    ))}
                  </>
                ) : (
                  <h5>Please select chapter </h5>
                )}
              </div>
            </div>
          </Form>
        </div>
      ) : (
        <Drawer
          title={
            <span className="select-none">
              {type != "chapter" ? "Select Chunks" : "Select Chapters"}{" "}
            </span>
          }
          placement="right"
          closable={false}
          onClose={onClose}
          visible={visible}
          maskClosable={false}
          mask={false}
        >
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="flex items-center">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-btn"
                  disabled={
                    type != "chapter"
                      ? availableChunks.length == 0
                        ? true
                        : false
                      : availableChapters.length == 0
                        ? true
                        : false
                  }
                  style={{ background: "#031832", color: "white", borderColor: "#031832" }}
                  onMouseOver={(e) => { e.target.style.color = "white"; }}
                >
                  {fileType == "view" ? "View Selected" : "Play Selected"}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="button"
                  className="submit-btn mx-2"
                  style={{ background: "#F9002B", color: "white", borderColor: "#F9002B" }}
                  onClick={onClose}
                  onMouseOver={(e) => { e.target.style.color = "white"; }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </div>

            {type != "chapter" ? (
              <>
                {(availableChunks && availableChunks.length) > 0 ? (
                  <>
                    {availableChunks.map((chunk) => (
                      <Form.Item
                        name={chunk.chunkName}
                        valuePropName="checked"
                        key={chunk.chunkName}
                      >
                        <Checkbox
                          className="border py-2 w-full px-1 rounded hover:bg-primary hover-bg-gray"
                          style={{
                            border:
                              chunk.chunksDataProgress == "completed"
                                ? "#3CB043"
                                : chunk.chunksDataProgress == "pending"
                                  ? "#C8102E"
                                  : "FFD700",
                            color:
                              chunk.chunksDataProgress == "completed"
                                ? "#3CB043"
                                : chunk.chunksDataProgress == "pending"
                                  ? "#C8102E"
                                  : "FFD700",
                          }}
                        >
                          {chunk.chunkName}
                        </Checkbox>
                      </Form.Item>
                    ))}
                  </>
                ) : (
                  <h5>Please select chapter </h5>
                )}
              </>
            ) : (
              <>
                {(availableChapters && availableChapters.length) > 0 ? (
                  <>
                    {availableChapters.map((chapter) => (
                      <Form.Item
                        name={chapter.chapterName}
                        valuePropName="checked"
                        key={chapter.chapterName}
                      >
                        <Checkbox className="border py-2 w-full px-1 rounded hover:bg-primary hover-bg-gray">
                          {chapter.chapterName}
                        </Checkbox>
                      </Form.Item>
                    ))}
                  </>
                ) : (
                  <h5>Please select Book </h5>
                )}
              </>
            )}
          </Form>
        </Drawer>
      )}
      {contextHolder}
    </div>
  );
};
export default SelectChunk;
