const textToSpeech = (text) => {
  let speech = speechSynthesis;

  if (speech.speaking) {
    speech.pause();
    speech.cancel();
  }

  //   let voice = speech.getVoices().filter((item) => item.name == "Lekha");
  let utterence = new SpeechSynthesisUtterance(text);
  for (let voice of speech.getVoices()) {
    if (voice.name == "Lekha") {
      utterence.voice = voice;
    }
  }
  utterence.pitch = 1;
  utterence.rate = 0.9;
  let status = speech.speak(utterence);

 

  return utterence; 
};

const stopTexttoSpeech = () => {
  let speech = speechSynthesis;

  if (speech.speaking) {
    speech.pause();
    speech.cancel();
  }
};

export { textToSpeech, stopTexttoSpeech };
