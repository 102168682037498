import { Alert, Empty, Progress } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CgPlayBackwards, CgPlayForwards } from "react-icons/cg";
import { RiPauseMiniFill, RiPlayMiniFill } from "react-icons/ri";

import {isTablet,isSafari,isMobile} from 'react-device-detect';
import MainLayout from "../../components/layout/main.layout";
import TitleBar from "../../components/ui/title-bar/title-bar.component";
import Waveform from "../../components/waveform/waveform.component";
import Moment from "moment";
import { List } from "antd";
import VirtualList from "rc-virtual-list";
import StaticWaveform from "../../components/waveform/static.waveform";
import { useLocation } from "react-router";
import SpinnerComponent from "../../components/spinner/spinner.component";
import { s3_files_url } from "../../services/apis";
import { waitForAWhile } from "../../services/config";
import CommentModal from "../../components/modal/comment-modal";
import FilenameComponent from "../../components/ui/filename.component";
import { getDraftedFilesByBookNameOnline } from "../../services/api-calls/draft-files.api"

const AudioStaticPlaylist = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [audios, setAudios] = useState([]);
  const [currentPlaying, setCurrentPlaying] = useState({});
  const [audioTime, setAudioTime] = useState(0);
  const [secs, setSecs] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [audioPlayingTime, setAudioPlayingTime] = useState(0);
  const [alertMessage, setAlertMessage] = useState({
    type: "",
    message: "",
  });

  const isPlaying = useRef(false);
  const isPaused = useRef(false);
  const isStopAll = useRef(false);
  const currentAudioIndex = useRef(0);
  const countSeconds = useRef(0);
  const audioPlayingTimeRef = useRef(0);
  const audioRef = useRef();
  const setTimeotRef = useRef();
  const lastPlayedAt = useRef(Number(0));
  const lastAudioBarTime = useRef(Number(0));

  useEffect(() => {
    loadAudios();
    currentAudioIndex.current = 0;
    window.addEventListener("hashchange", () => {
      if (isPlaying.current == true) {
        stopAudio();
      }
    });

    const listener = ({ location, action }) => {
      if (action === "PUSH") {
        if (isPlaying.current == true) {
          stopAudio();
        }
      }
    };
    // const unlisten = navigator.listen(listener);
    // return unlisten;
  }, []);

  const loadAudios = () => {
    return new Promise(async (resolve, reject) => {
        let result = [];

        let draftData = {
          projectId: location.state.book.projectId,
          bookName: location.state.book.bookName,
        };
        if(location.state.chapter){
          draftData["chapterName"]= await location.state.chapter[0].chapterName;
        }
  
        let draftedFile = await getDraftedFilesByBookNameOnline(draftData);

      let targetType;
      if (
        localStorage.getItem("role") == "Facilitator" ||
        localStorage.getItem("role") == "CIT" ||
        localStorage.getItem("role") == "Consultant"
      ) {
        targetType = "backTranslate";
      } else if (
        localStorage.getItem("role") == "MTT" ||
        localStorage.getItem("role") == "Technician"
      ) {
        targetType = "draft";
      }

      let filteredAudio = [];
      if (location.state.type == "PLAY-SELECTED") {
          if (localStorage.getItem("aduioPlay") == "chapter") {
            let chunks = location.state.chapter;
            for (let i = 0; i < chunks.length; i++) {
              let data = await draftedFile.filter(
                (item) =>
                  item.fileType == "audio" &&
                  item.targetType == targetType &&
                  item.chapterName == chunks[i]
              );

              filteredAudio = [...filteredAudio, ...data];
            }
          } else {
            let chunks = location.state.chunk;
            for (let i = 0; i < chunks.length; i++) {
              let data = await draftedFile.filter(
                (item) =>
                  item.fileType == "audio" &&
                  (item.chapterName == location.state.chapter ||
                    item.chapter == location.state.chapter) &&
                  item.targetType == targetType &&
                  item.sourceVerseName == chunks[i]
              );
              filteredAudio = [...filteredAudio, ...data];
            }
          }
        // }
        let sortedAudios = await sortFiles(filteredAudio);
        setAudios(sortedAudios);
        setIsLoading(false);
        resolve(filteredAudio);
      } else {
        let role = localStorage.getItem("role");
        if (role == "CIT" || role == "Consultant" || role == "Technician") {
            let chunks = location.state.chapter;
            for (let i = 0; i < chunks.length; i++) {
              let data = await draftedFile.filter(
                (item) =>
                  item.fileType == "audio" &&
                  item.targetType == targetType &&
                  item.chapterName == chunks[i].chapterName
              );
              filteredAudio = [...filteredAudio, ...data];
            }
        } else {
          filteredAudio = await draftedFile.filter(
            (item) =>
              item.fileType == "audio" &&
              (item.chapterName == location.state.chapter ||
                item.chapter == location.state.chapter) &&
              item.targetType == targetType
          );
        }

        let sortedAudios = await sortFiles(filteredAudio);
        setAudios(sortedAudios);
        setIsLoading(false);
        resolve(filteredAudio);
      }
    });
  };

  const playAllAudio = async () => {
    if (audios.length > 0) {
      await playAudio(
        audios[currentAudioIndex.current],
        currentAudioIndex.current
      );
    }
  };

  const playAudio = async (data, index, playNext) => {
    return new Promise(async (resolve, reject) => {
      try {
        await stopAudio();
        isPlaying.current = true;
        setCurrentPlaying(data);
        currentAudioIndex.current = index;

        // let content = await getFileChunks(data.fileName);
        // let localData = await window.api.readFile("readFile", {
        //   fileId: data.fileId,
        //   fileName: data.fileName,
        // });
        // let content = await unitArrayToBlob(localData, data.fileType);
        // if (content.length > 0) {
        //   audioRef.current.src = content;
        //   // audioRef.current = new Audio(url);
        // } else {
        //   let url = `${s3_files_url}/${data.fileId}/${data.fileName}`;
        //   audioRef.current.src = url;
        // }

        let url = `${s3_files_url}/${data.fileId}/${data.fileName}`;
        audioRef.current.src = url;

        await audioRef.current.play();
        let timePlay = await audioRef.current.duration;
        timePlay = Math.ceil(timePlay);
        setAudioTime(timePlay);
        setSecs(lastPlayedAt.current);

        if (isPaused.current == true) {
          // setAudioPlayingTime(lastPlayedAt.current);
          audioRef.current.currentTime = lastPlayedAt.current;
          countSeconds.current = setInterval(() => {
            setSecs((secs) => secs + 1);
            lastPlayedAt.current = Number(lastPlayedAt.current) + Number(1);
          }, 1000);

          audioPlayingTimeRef.current = setInterval(() => {
            lastAudioBarTime.current = audioPlayingTime + 10 / timePlay;
            setAudioPlayingTime((time) => time + 10 / timePlay);
          }, 100);
        } else {
          countSeconds.current = setInterval(() => {
            setSecs((secs) => secs + 1);
            lastPlayedAt.current = Number(lastPlayedAt.current) + Number(1);
          }, 1000);

          audioPlayingTimeRef.current = setInterval(() => {
            lastAudioBarTime.current = audioPlayingTime + 10 / timePlay;
            setAudioPlayingTime((time) => time + 10 / timePlay);
          }, 100);
        }

        setTimeotRef.current = setTimeout(() => {
          clearTimeout(setTimeotRef.current);
          if (isPlaying.current) stopAudio();
        }, (timePlay - lastPlayedAt.current > 0 ? timePlay - lastPlayedAt.current : 0) * 1000);
      } catch (error) {
        console.log(error);
        showAlertMessage("error", "Contact to admin , file might be deleted");
        let pauseStats = await stopAudio();
      }
    });
  };

  const stopAudio = () => {
    return new Promise(async (resolve, reject) => {

      if (isPlaying.current == true) {
        isPlaying.current = false;
        await audioRef.current?.pause();
        // await updateLastPlayedSecs(currentPlaying.name, 0);
        await waitForAWhile(400);
        audioRef.current.currentTime = 0;
        clearInterval(audioPlayingTimeRef.current);
        clearTimeout(setTimeotRef.current);
        clearInterval(countSeconds.current);
        setAudioPlayingTime(0);
        setAudioTime(0);
        setSecs(0);
        lastPlayedAt.current = 0;
        lastAudioBarTime.current = 0;
        setCurrentPlaying({});
        audioRef.current.src = null;
        isStopAll.current = false;
        resolve("Playing is paused");
      } else {
        resolve("Player is not playing audio");
      }
    });
  };

  const playNext = () => {
    currentAudioIndex.current = currentAudioIndex.current + 1;
    if (currentAudioIndex.current == audios.length) {
      currentAudioIndex.current = 0;
    }
    if (audios.length >= 0 && audios.length > currentAudioIndex.current) {
      playAudio(
        audios[currentAudioIndex.current],
        currentAudioIndex.current,
        true
      );
    }
  };

  const playPrev = () => {
    currentAudioIndex.current = currentAudioIndex.current - 1;
    if (currentAudioIndex.current == audios.length) {
      currentAudioIndex.current = 0;
    }
    if (audios.length >= 0 && audios.length > currentAudioIndex.current) {
      playAudio(
        audios[currentAudioIndex.current],
        currentAudioIndex.current,
        true
      );
    }
  };

  const showAlertMessage = async (type, message) => {
    setAlertMessage({
      ...alertMessage,
      type,
      message,
    });
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 1500);
  };

  const sortFiles = async (files) => {
    return new Promise(async (resolve, reject) => {
      let data = files.sort((a, b) => (a.isSelected < b.isSelected ? 1 : -1));
      resolve(data);
    });
  };

  const audioPaused = () => {
    return new Promise(async (resolve, reject) => {
      if (isPlaying.current == true) {
        isPlaying.current = false;
        isPaused.current = true;
        await audioRef.current?.pause();

        // audioRef.current.currentTime = 0;
        clearInterval(audioPlayingTimeRef.current);
        clearTimeout(setTimeotRef.current);
        clearInterval(countSeconds.current);

        if (
          Number(lastPlayedAt.current) == Math.floor(audioRef.current.duration)
        ) {
          setAudioPlayingTime(0);
          setAudioTime(0);
          setSecs(0);
          // await updateLastPlayedSecs(currentPlaying.name, 0);
        }
        // await updateLastPlayedSecs(currentPlaying.name, secs);
        setCurrentPlaying({});
        // audioRef.current.src = null;
        isStopAll.current = false;
        resolve("Playing is paused");
      } else {
        resolve("Player is not playing audio");
      }
    });
  };

  return (
    <div>
      <MainLayout>
        <div className="draft-details ">
          <div className="draft-details-wrapper h-screen">
            <TitleBar title={"Audio Player"} />

            <audio id="audio_player" ref={audioRef} />
            {showAlert && (
              <Alert
                message={alertMessage.message}
                type={alertMessage.type}
                className="my-2 p-1"
                showIcon
                closable
              />
            )}

            {isLoading ? (
              <SpinnerComponent />
            ) : (
              <div className="grid grid-cols-2 pt-4">
                <div className="mx-2">
                  <h2 className="text-center py-2 flex items-center justify-center">
                    {(
                      <>
                        {Object.keys(currentPlaying).length > 0 ? (
                          <span className="mr-2">
                          <FilenameComponent file = {currentPlaying} />
                          </span>
                        ) : (
                          "Please start playing"
                        )}
                      </>
                    ) || "Please start playing"}
                  </h2>
                  <div className="w-50 mx-2 py-4">
                    {isPlaying.current ? (
                      <Waveform isPlaying={isPlaying.current} lines={isTablet ? 50 : isMobile ? 10 : 140} />
                    ) : (
                      <StaticWaveform lines={isTablet ? 50 : isMobile ? 10 : 140} />
                    )}
                  </div>
                  <div class="duration">
                    <div className="flex items-center justify-between">
                      <span> {Moment.utc(secs * 1000).format("mm:ss")} </span>
                      <span>
                        {Moment.utc(audioTime * 1000).format("mm:ss")}
                      </span>
                    </div>
                    <Progress percent={audioPlayingTime} showInfo={false} />
                  </div>
                  <div className="audio-controls flex items-center justify-center py-1">
                    <button
                      className="remove-btn-css"
                      disabled={currentAudioIndex.current == 0 ? true : false}
                      onClick={playPrev}
                    >
                      <CgPlayBackwards
                        size={50}
                        color={`${
                          currentAudioIndex.current == 0 ? "gray" : "black"
                        }`}
                      />
                    </button>
                    <span>
                      {isPlaying.current ? (
                        <RiPauseMiniFill
                          size={50}
                          onClick={(e) => {
                            isStopAll.current = true;
                            audioPaused();
                          }}
                        />
                      ) : (
                        <RiPlayMiniFill
                          size={50}
                          onClick={(e) => {
                            playAllAudio();
                          }}
                        />
                      )}
                    </span>
                    <button className="remove-btn-css" onClick={playNext}>
                      <CgPlayForwards size={50} />
                    </button>
                  </div>
                </div>

                <div className="ml-4 shadow-lg p-4">
                  <h5>Audio</h5>

                  <List>
                    {audios.length > 0 ? (
                      <VirtualList data={audios} height={370} itemKey="email">
                        {(item, i) => (
                          <List.Item key={item._id}>
                            <span className="flex">                              
                              {Object.keys(currentPlaying).length > 0 &&
                              item.fileName == currentPlaying.fileName ? (
                                <RiPauseMiniFill
                                  size={50}
                                  className="text-theme"
                                  onClick={stopAudio}
                                />
                              ) : (
                                <RiPlayMiniFill
                                  size={50}
                                  className="text-theme"
                                  onClick={(e) => {
                                    playAudio(item, i, false);
                                  }}
                                />
                              )}
                              <span className="text-lg flex items-center">
                                {/* {item.fileName || item.name} */}
                                <span className="mr-2">
                                 <FilenameComponent file = {item} />
                                 </span>
                                <span className="mx-4">
                                  {item.isSelected == true && (
                                    <>
                                      <CommentModal draftFile={item} />
                                    </>
                                  )}
                                </span>
                              </span>
                              <span></span>
                            </span>
                          </List.Item>
                        )}
                      </VirtualList>
                    ) : (
                      <Empty />
                    )}
                  </List>
                </div>
              </div>
            )}
          </div>
        </div>
      </MainLayout>
    </div>
  );
};

export default AudioStaticPlaylist;
