import { Tooltip, notification } from "antd";
import { Comment } from '@ant-design/compatible';
import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import AudioPlayer from "../audio-player/audio-player.component";
import VideoPlayer from "../video/video-player";
import EditComment from "./edit-comment";
import { MdEdit } from "react-icons/md";
import { useSelector } from "react-redux";

import ReactReadMoreReadLess from "react-read-more-read-less";
import { useLocation } from "react-router";

const ViewComment = ({ item, deleteComment, chunk, reload, file }) => {
  const [isEditing, setIsEditing] = useState(false);
  const location = useLocation()
  const [notificationApi, NotificationJSX] = notification.useNotification();
  const showNotification = (type, message) => {
    notification.destroy();
    notificationApi[type]({
      message,
    });
  };

  const isRecording = useSelector((state) => state.recordingStatusReducer);
  const isPlayingStatus = useSelector((state) => state.playingStatusReducer);

  useEffect(() => {
  }, [item]);

  const toggleEditing = () => {
    if (localStorage.getItem("isRecording") == "true") {
      showNotification("error", "Please stop recording");
    } else {
      setIsEditing(!isEditing);
    }
  };

  return (
    <>
      <Comment
        author={
          <div className={`${location.pathname != "/to-do" ? "flex" : "grid grid-cols-2"}`}>
            <div className="flex ml-2">
              <div className="text-lg  text-black">{item.user}</div>
              {item.userId == localStorage.getItem("id") && location.pathname != "/to-do" && (
                <div className="">
                  {item.fileType == "text" && (
                    <>
                      <Tooltip placement="topLeft" title="Edit">
                        <button
                          className="remove-btn-css"
                          disabled={isRecording || isPlayingStatus}
                          onClick={toggleEditing}
                        >
                          <MdEdit
                            size={19}
                            color={
                              (isRecording || isPlayingStatus) == true
                                ? "gray"
                                : "black"
                            }
                          />
                        </button>
                      </Tooltip>
                    </>
                  )}
                  <Tooltip placement="topLeft" title="Delete">
                    <button
                      className=" mx-1 text-lg remove-btn-css"
                      disabled={isRecording || isPlayingStatus}
                      onClick={(e) => {
                        deleteComment(item);
                      }}
                    >
                      <MdDelete
                        color={
                          (isRecording || isPlayingStatus) == true
                            ? "gray"
                            : "black"
                        }
                        size={20}
                      />
                    </button>
                  </Tooltip>
                </div>
              )}
            </div>
            <div className="flex items-center ml-2">
              {
                location.pathname == "/to-do" ? (
                  <div className="">{new Date(Number(item.fileId)).toLocaleDateString('en-GB')}</div>
                ) : (null)
              }
            </div>
          </div>
        }
        content={
          <>
            {item.fileType == "video" ? (
              <>
                <VideoPlayer data={item} fileType={"COMMENTFILE"} />
              </>
            ) : null}
            {item.fileType == "audio" ? (
              <div className="w-50">
                <AudioPlayer
                  audioType={"COMMENTFILE"}
                  data={item}
                  fileName={item.fileName}
                  isReverse={false}
                />
              </div>
            ) : null}
            {item.fileType == "text" ? (
              <>
                {isEditing == true ? (
                  <EditComment
                    cancel={setIsEditing}
                    data={item}
                    fileId={chunk?.fileId}
                    fileName={chunk?.fileName}
                    reload={reload}
                    file={file}
                  />
                ) : (
                  <div className="ml-2 commentText">
                    <ReactReadMoreReadLess
                      charLimit={200}
                      readMoreText={"Read more ▼"}
                      readLessText={"Read less ▲"}
                      readMoreStyle={{
                        color: "red",
                        cursor: "pointer",
                        whiteSpace: "break-spaces",
                      }}
                      readLessStyle={{ color: "red", cursor: "pointer" }}
                    >
                      {item.comments}
                    </ReactReadMoreReadLess>
                  </div>
                )}
              </>
            ) : null}
          </>
        }
      />

      {NotificationJSX}
    </>
  );
};

export default ViewComment;