// FOR CROSS CHECK ,MASTER RECORD AND COMMUNITY CHECK FILES
export const saveCheckFilesName = (payload) => {
  return { type: "SAVE_CHECK_FILESNAME", payload };
};

export const clearCheckFilesName = () => {
  return { type: "CLEAR_CHECK_FILESNAME" };
};

export const toggleCheckFilesCheckbox = (payload) => {
  return { type: "TOGGLE_CHECKFILES_CHECKBOX", payload };
};
 
export const checkFileCount = (count) => ({
  type: "CHECK_FILE_COUNT",
  payload: count,
});

export const clearFileCount = (count) =>({
  type: "CLEAR_FILE_COUNT",
  payload: count,
});