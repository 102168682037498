import React, { useEffect, useRef } from 'react'

const TestPage = () => {

  const recoRef = useRef()
  var listening = false;

  useEffect(() => {
    loadSpeech()
  }, [])

  const loadSpeech = () => {
    recoRef.current = new window.webkitSpeechRecognition();
    recoRef.current.continuous = false;
    recoRef.current.interimResults = true;
    recoRef.current.lang = "en-US";

    recoRef.current.onresult = function (event) {
      var interim_transcript = '';
      var final_transcript = '';

      for (var i = event.resultIndex; i < event.results.length; ++i) {
        // if (event.results[i].isFinal) {
          final_transcript += event.results[i][0].transcript;
          document.getElementById('transcript').value = final_transcript;
        //   toggle();
        // } else {
        //   interim_transcript += event.results[i][0].transcript;
        //   document.getElementById('transcript').value = interim_transcript;
        // }
      }
      console.log(interim_transcript, final_transcript);

    };

    recoRef.current.onend = () => {
      // Start a new recognition session when the current one ends
      recoRef.current.start();
    };


  }

  function toggle() {
    if (listening) {
      recoRef.current.stop();
      listening = false;
      document.getElementById('action').value = "Start";
    }
    else {
      recoRef.current.start();
      listening = true;
      document.getElementById('action').value = "Stop";
    }
  }
  return (
    <div >
      <textarea type="text" name="q" id="transcript" placeholder="Speak" class="keyboardInput"></textarea>

      <input id="action" type="button" onClick={toggle} value="Start" />

    </div>
  )
}

export default TestPage