import axios from "axios";
import { checkType, citConCheckType } from "../apis";

const postCheckTypeToServer = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await axios.post(`${checkType}`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      resolve(res.data.msg);
    } catch (error) {
      reject(error);
    }
  });
};

const citConCheckTypeToServer = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await axios.post(`${citConCheckType}`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      resolve(res.data.msg);
    } catch (error) {
      reject(error);
    }
  });
};

export { postCheckTypeToServer, citConCheckTypeToServer };
