import React, { useEffect, useRef } from "react";
import { IoIosArrowBack } from "react-icons/io";
import MainLayout from "../../components/layout/main.layout";
import { BiArrowBack } from "react-icons/bi";
import { IoChatbubblesOutline } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { base_url, support } from "../../services/apis";
import { RiPauseMiniFill, RiPlayMiniFill } from "react-icons/ri";
import {
  Layout,
  Button,
  Form,
  Input,
  Radio,
  Space,
  Table,
  Popconfirm,
  Upload,
  notification,
  message,
} from "antd";
import {
  UserOutlined,
  UploadOutlined,
  PhoneOutlined,
  MailOutlined,
  WechatOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router";
import TitleBar from "../../components/ui/title-bar/title-bar.component";
import NotificationComponent from "../../components/notification/notification.component";
import { useState } from "react";
import axios from "axios";
import FormItem from "antd/es/form/FormItem";
// import { url } from "inspector";

const { TextArea } = Input;
const SupportPage = () => {
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState();
  const [isNotification, setIsNotification] = useState();
  const [notifications, setNotifications] = useState({
    type: "",
    content: "",
  });
  const [file, setFile] = useState([]);
  const [value, setValue] = useState("");
  const [fileType, setFileType] = useState([]);
  const [descriptionData, setDescriptionData] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const [btndisabled, setbtndisabled] = useState(true);

  // const [images, setImages] = useState([])

  const [allUrls, setAllUrls] = useState([]);
  const audioRef = useRef();


  const onChange = (e) => {
    setValue(e.target.value);
  };
  const description = (e) => {
    setDescriptionData(e.target.value);
  };
  const [form] = Form.useForm();



  // onValueChanges in form:

  const onValuesChange = (changedValues, allValues) => {
    if (
      allValues.name != undefined &&
      allValues.LastName != undefined &&
      allValues.email != undefined &&
      allValues.name != "" &&
      allValues.LastName != "" &&
      allValues.email != "" &&
      allValues.Question !== undefined &&
      allValues.description !== undefined &&
      allValues.description !== "" &&
      allValues.description !== ""
    ) {
      setbtndisabled(false);
    } else {
      setbtndisabled(true);
    }
  };

  // handle Delete:

  const handleDelete = (key) => {
    let removeFile = allUrls.filter((item) => item[0].key == key);
    removeFile = removeFile[0];
    let newFiles = file.filter((file) => file.name !== removeFile[0].name);

    const newData = allUrls.filter((item) => item[0].key !== key);
    setFile(newFiles);
    setAllUrls(newData);
  };

  // Display files:

  const displayFiles = (file, type, key) => {
    let url = URL.createObjectURL(file);

    switch (type) {
      case "audio":
        let audio = [];
        audio.push({ url: url, name: file.name, type: "audio", key });
        // allUrls.push(audio);
        return audio;
        break;
      case "image":
        // let image = imageUrls;
        let image = [];
        image.push({ url: url, name: file.name, type: "image", key });
        // allUrls.push(image);
        return image;
        break;
      case "video":
        // let video = videoUrls;
        let video = [];
        video.push({ url: url, name: file.name, type: "video", key });
        // allUrls.push(video);
        return video;
        // setVideoUrl(video);
        break;
    }
  };

  // Colounm for selected data display :

  const columns = [
    {
      title: "File Name",
      dataIndex: "name",
      key: "1",
      render: (item, record) => {
        record = record[0];
        if (record.type == "audio") {
          return (
            <div className="d-flex flex-row gap-3">
              <div className="playPauseButton">
                <span>
                  {isPlaying ? (
                    <RiPauseMiniFill
                      size={50}
                      onClick={(e) => {
                        pauseAudio();
                      }}
                    />
                  ) : (
                    <RiPlayMiniFill
                      size={50}
                      onClick={() => {
                        playAudio(record.url);
                      }}
                    />
                  )}
                </span>
              </div>

              <div className="songTitle mt-3">{record.name}</div>
            </div>
          );
        }
        if (record.type == "image") {
          return (
            <div className="d-flex flex-row gap-3">
              <img src={record.url} width="100" height="100" />
              <div className="songTitle mt-3">{record.name}</div>
            </div>
          );
        }
        if (record.type == "video") {
          return (
            <div className="d-flex flex-row gap-3">
              <video
                width="150"
                height="150"
                controls
                id="id"
                src={record.url}
              />
              <div className="songTitle mt-3">{record.name}</div>
            </div>
          );
        }
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (item, record) => {
        return <span className="capitalize">{record[0].type}</span>;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) =>
        allUrls.length >= 1 ? (
          <Popconfirm
            className="ant-btn-foucs"
            arrowPointAtCenter
            placement="topLeft"
            title="Are you sure you want to delete this file?"
            onConfirm={() => handleDelete(record[0].key)}
          >
            <a className="ant-btn-foucs">
              <MdDelete size={20} />
            </a>
          </Popconfirm>
        ) : null,
    },
  ];

  async function postImage({ supportFile, userData }) {
    let url = `${support.createRequest}`;
    const formData = new FormData();
    for (let i = 0; i < supportFile.length; i++) {
      formData.append("supportFile", supportFile[i]);
    }
    // formData.append("supportFile", supportFile)
    formData.append("userData", userData);

    const result = await axios.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return { data: result.data, status: result.status };
  }

  const submit = async (values) => {
    // event.preventDefault()
    setIsLoader(true);
    const { name, LastName, email } = values;
    const data = {
      firstName: name,
      lastName: LastName,
      email: email,
      typeOfSsupport: value,
      description: descriptionData,
      status: "To Do",
    };

    const result = await postImage({
      supportFile: file,
      userData: JSON.stringify(data),
    });

    if (result.status == 200) {
      form.resetFields();
      setFile([]);
      setValue("");
      setAllUrls([]);
      setDescriptionData("");
      setIsLoader(false);
      openNotification(result.data.message);
    } else {
      displayNotification("error", "Please Check the Data");
    }
  };

  const openNotification = (message) => {
    notification.success({
      message: "Success",
      duration: 2,
      description: message,
    });
  };

  const fileSelected = (event) => {
    let files = event.target.files;
    files = file.length == 0 ? [...files] : [...file, ...files];
    setFile(files);
    let data = [];
    if (files.length > 0) {
      let count = 0;
      for (const i of files) {
        count = count + 1;
        let type = i.type;
        type = type.split("/")[0];
        fileType.push(type);
        let res = displayFiles(i, type, count);
        data.push(res);
      }
      data = [...data];
      setAllUrls(data);
    }
  };

  // display Nofication

  const displayNotification = (type, content) => {
    setNotifications({
      type,
      content,
    });
    setIsNotification(true);
    setTimeout(() => {
      setIsNotification(false);
      setNotifications({
        type: "",
        content: "",
      });
    }, 500);
  };

  // audio play:

  const playAudio = async (fileUrl) => {
    setIsPlaying(true);
    audioRef.current = new Audio(fileUrl);
    await audioRef.current.play();
  };

  // Stop audioPlay:

  const pauseAudio = () => {
    setIsPlaying(false);
    audioRef.current.pause();
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <MainLayout>
        <div className="draft-details ">
          <div className="draft-details-wrapper">
            <TitleBar title={"Support"} page={"draft"} />
            <Layout.Content>
              <div className=" m-3">
                <Form
                  form={form}
                  name="support"
                  encType="multipart/form-data"
                  onFinish={submit}
                  autoComplete="off"
                  onValuesChange={onValuesChange}
                >
                  {isNotification && (
                    <NotificationComponent
                      type={notifications.type}
                      content={notifications.content}
                    />
                  )}

                  <div className="d-flex flex-row w-100 m-2">
                    <Form.Item
                      name="name"
                      className="w-50 ps-2"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your FirstName!",
                        },
                        {
                          min: 3,
                          message: "Please enter minimum 3 characters",
                        },
                        {
                          max: 20,
                          message: "maximum 20",
                        },
                      ]}
                    >
                      <Input placeholder="FirstName" />
                    </Form.Item>

                    <Form.Item
                      name="LastName"
                      className="w-50 ps-2"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your LastName!",
                        },
                        {
                          min: 3,
                          message: "Please enter minimum 3 characters",
                        },
                        {
                          max: 20,
                          message: "maximum 20",
                        },
                      ]}
                    >
                      <Input placeholder="LastName" />
                    </Form.Item>
                  </div>
                  <div className="d-flex flex-row w-100 m-2 ">
                    <Form.Item
                      name="email"
                      className="w-50 ps-2"
                      rules={[
                        {
                          required: true,
                          message: "Please input your email!",
                        },
                        {
                          type: "email",
                          warningOnly: true,
                          message: "Please enter valid email",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Email"
                        prefix={
                          <UserOutlined className="site-form-item-icon" />
                        }
                      />
                    </Form.Item>
                    {value == "Bug Report" ? (
                      <Form.Item className="ps-2 w-50">

                        <div className="w-100">
                          <Input
                            onChange={fileSelected}
                            type="file"
                            // key={file.length}
                            accept="image/*, video/*, audio/*"
                            multiple
                          // allowClear
                          ></Input>
                        </div>
                      </Form.Item>
                    ) : null}
                  </div>

                  <div className="d-flex flex-row w-100 m-2 ">
                    <div className="w-50">
                      <h6 className="  ">
                        What can we help you today?
                      </h6>
                      <Form.Item
                        name="Question"
                      >
                        <div className="d-flex flex-row m-2">
                          <Radio.Group onChange={onChange} value={value}>
                            <Space direction="vertical">
                              <Radio value={"General Question"}>
                                General Question
                              </Radio>
                              <Radio value={"Feature Request"}>
                                Feature Request
                              </Radio>
                              <Radio value={"Bug Report"}>Bug Report</Radio>
                              <Radio value={"My Account"}>My Account</Radio>
                            </Space>
                          </Radio.Group>
                        </div>
                      </Form.Item>
                    </div>
                    <div className="w-50">
                      <h6 className="  ">Description</h6>
                      <Form.Item
                        name="description"
                        rules={[
                          {
                            required:
                              value === "General Question" ? true : false,
                            message: "Please enter your description!",
                          },
                        ]}
                      >
                        <div className="d-flex flex-row m-2">
                          <TextArea
                            style={{ color: "black" }}
                            rows={5}
                            value={descriptionData}
                            onChange={description}
                          />
                        </div>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="w-100">
                    {file.length > 0 && value == "Bug Report" ? (
                      <Table
                        columns={columns}
                        dataSource={allUrls}
                        pagination={{
                          pageSize: 5,
                        }}
                      />
                    ) : null}
                  </div>

                  <Form.Item
                    wrapperCol={{
                      offset: 11,
                      span: 13,
                    }}
                  >
                    <Button
                      htmlType="submit"
                      className="submit-btn"
                      style={{
                        background: btndisabled ? "gray" : "#0c1d35",
                      }}
                      loading={isLoader}
                      disabled={btndisabled}
                    >
                      <span style={{ color: btndisabled ? "" : "whitesmoke" }}>
                        {" "}
                        Submit
                      </span>
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Layout.Content>
            <Layout.Footer
              style={{
                position: "sticky",
                bottom: "0",
                backgroundColor: "#fff",
              }}
            >
              <div
                className="d-flex flex-row justify-between text-center mt-5 "
                style={{ position: "sticky", bottom: "0" }}
              >
                <div className="call d-flex flex-column  ps-2">
                  <PhoneOutlined
                    style={{ fontSize: "18px", color: "#bfbfbf" }}
                  />
                  <h6 style={{ color: "#7b7575" }}>Call us</h6>
                  <span style={{ justifyContent: "center", color: "#7b7575" }}>
                    We’re just a ring away
                  </span>
                  <a href="" style={{ textAlign: "center" }}>
                    ViewNumber
                  </a>
                </div>
                <div
                  className="call d-flex flex-column ps-2"
                >
                  <MailOutlined
                    style={{ fontSize: "18px", color: "#434141" }}
                  />
                  {/* <h6 className="  ">Feedback</h6> */}
                  <h6>
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSfhtXLB0UQzdgCGlSbMpXS5byDCnkQI9eOlvF4VV1eHdCn1sg/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer">
                    Feedback
                  </a>
                  </h6>
                  <span style={{ justifyContent: "center", color: "#434141" }}>
                    Have a compliment or complaint.
                  </span>

                  {/* <a href="" style={{ textAlign: "center" }}>
                  
                  ViewNumber
                </a> */}
                </div>
                <div className="call d-flex flex-column ps-2">
                  <WechatOutlined
                    style={{ fontSize: "18px", color: "#bfbfbf" }}
                  />
                  <h6 style={{ color: "#7b7575" }}>Chat with us</h6>
                  <span style={{ justifyContent: "center", color: "#7b7575" }}>
                    Questions? We are here to help.
                  </span>
                  {/* <a href="" style={{ textAlign: "center" }}>
                  
                  ViewNumber
                </a> */}
                </div>
              </div>
            </Layout.Footer>
          </div>
        </div>
      </MainLayout>
    </div>
  );
};

export default SupportPage;
