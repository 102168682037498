import React, { useEffect, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Table,
  Modal,
  notification,
  Tooltip,
  ConfigProvider,
  Empty,
} from "antd";
import { MdDelete, MdRecordVoiceOver } from "react-icons/md";
import { FaComment } from "react-icons/fa";
import { useLocation } from "react-router";
import VideoPlayer from "../../video/video-player";
import DeleteModalComponent from "../../modal/delete-modal/delete-modal.component";
import VideoScreen from "../../video/video-recorder";
import CommentsComponent from "../../comments/comments.component";
import {
  extractNumberFromString,
  sortItemBasedOnCreation,
} from "../../../services/config";
// import {
//   getDraftedFilesByChunks,
// } from "../../../services/indexed-db/draftfiles.indexeddb";
// import { getProjectByRoleAndId } from "../../../services/indexed-db/project.indexeddb";
import SmallSpinnerComponent from "../../spinner/small-spinner.component";
import BackTranslation from "../back-translation";
// import { updateFileSelection } from "../../../services/sync-methods/draft-files.sync";
// import { updateBooks } from "../../../services/indexed-db/books.indexeddb";
import FilenameComponent from "../../ui/filename.component";
import { selectDraftedFileOnServer,getDraftedFilesByChunksOnline } from "../../../services/api-calls/draft-files.api";
import { useDispatch, useSelector } from "react-redux";
const { confirm } = Modal;

const DraftVideoComponent = ({
  title,
  targetType,
  relatedDraft,
  isDisableRecord,
  isBackTranslationDisplay,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const booksReducer = useSelector((state) => state.booksReducer);
  const [list, setList] = useState([]);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [userProject, setUserProject] = useState({});
  const [isCommentShow, setIsCommentShow] = useState(false);
  const [currentChunk, setCurrentChunk] = useState({});
  const [currentSelected, setCurrentSelected] = useState("");
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [isShowMasterRecording, setIsShowMasterRecording] = useState(false);
  const [resultantNumber, setResultantNumber] = useState("");
  const [currentFileName, setCurrentFileName] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const showNotification = (type, message) => {
    notification.destroy();
    api[type]({
      message,
    });
  };

  useEffect(() => {
    fetchVideoFromDB();
  }, [location]);

  const columns = [
    {
      title: "File Name",
      dataIndex: "name",
      render: (value, record) => (
        <>
          {/* <p>
            {location.state?.chunkName} {resultantNumber}
          </p>
          <p className="text-italic text-xs">
            {new Date(Number(record.fileId)).toLocaleString()}
          </p> */}
           <FilenameComponent file={record} />
        </>
      ),
    },
    {
      title: (
        <span className={`flex items-center  justify-end mr-4-5`}>Action</span>
      ),
      dataIndex: "",
      key: "x",
      render: (item, record) => (
        <div
          className={`flex items-center justify-end ${
            record.key == selectedKeys ? "pointers-compuslory" : ""
          }`}
        >
          <span
            className={`pointers-compuslory  ${
              localStorage.getItem("id") != record.userId ? "" : "mx-2"
            }`}
          >
            <VideoPlayer
              data={record}
              isDisabled={isVideoLoading}
              fileType="DRAFTFILE"
            />
          </span>

          {localStorage.getItem("id") == record.userId && (
            <span className={`pointers-compuslory  `}>
              <DeleteModalComponent
                name={<MdDelete size={20} />}
                data={record}
                reload={fetchVideoFromDB}
                removeSelected={removeSelected}
                isDisabled={isVideoLoading}
              />
            </span>
          )}
          {currentSelected == record.key && (
            <>
              <Tooltip title="Comment">
                <Button
                  className="ml-2 hover"
                  onClick={(e) => {
                    setIsCommentShow(!isCommentShow);
                  }}
                  disabled={isVideoLoading}
                >
                  <FaComment size={16} />
                </Button>
              </Tooltip>

              {(localStorage.getItem("role") == "Facilitator" ||
                localStorage.getItem("role") == "CIT" ||
                localStorage.getItem("role") == "Consultant") && (
                <>
                  {isBackTranslationDisplay == true ? (
                    <button
                      className="ml-2 remove-btn-css"
                      disabled={isVideoLoading}
                    >
                      <BackTranslation
                        chunk={currentChunk}
                        draftFileName={record.fileName}
                        isButtonDisabled={isVideoLoading}
                        targetType={"draft"}
                        fileType="audio"
                        fileData={currentChunk}
                      />
                    </button>
                  ) : null}
                </>
              )}
            </>
          )}
        </div>
      ),
    },
  ];

  const getDraftedFiles = (targetType) => {
    return new Promise(async (resolve, reject) => {
      try {
        let files;
        let draftData = {
          projectId: location.state?.project.id,
          sourceVerseName: location.state?.verse,
          bookName: location.state?.book.bookName,
          chapterName: location.state?.chapter,
          fileType: "video",
          targetType,
          relatedDraft
        };
        files = await getDraftedFilesByChunksOnline(draftData);
        // files = await [...resp.data];
        if (files.length) {
          for (let i = 0; i < files.length; i++) {
            let fileName = await files[i].fileName.split(".")[0];
            files[i].name = fileName;
            files[i].formattedName = `${location.state?.heading}`;
            files[i].projectType = location.state?.project.projectType;
            files[i].key = files[i].fileId;
          }
        }
        resolve(files);
      } catch (error) {
        console.log("ERROR", error);
      }
    });
  };

  const fetchVideoFromDB = async (msg) => {
    if (msg) {
      showNotification("success", msg);
    }
    setIsVideoLoading(true);
    try {
      let resultantNumberData = await extractNumberFromString(
        `${location.state?.verse}`
      );
      setResultantNumber(resultantNumberData);

      let name;
      if (targetType == "masterRecord" || targetType == "backTranslate") {
        name = relatedDraft;
      } else {
        name = `${location.state?.heading}`;
      }
      // let draftFilesData = await getDraftedFilesByChunks(
      //   `${name}`,
      //   "video",
      //   targetType
      // );

      let draftFilesData = await getDraftedFiles(targetType);

      let selectedFiles = draftFilesData.filter(
        (item) => item.isSelected == true
      );
      let unSelectedFiles = await draftFilesData.filter(
        (item) => item.isSelected == false
      );
      unSelectedFiles = await sortItemBasedOnCreation(unSelectedFiles);
      if (localStorage.getItem("role") == "Facilitator") {
        if (targetType == "backTranslate") {
          setList([...selectedFiles, ...unSelectedFiles]);
        } else {
          let backTranslateEnabledFile = selectedFiles.filter(
            (item) =>
              item.translatorStatusCheck == true &&
              item.communityStatusCheck == true
          );
          setList([...backTranslateEnabledFile]);
        }
      } else if (localStorage.getItem("role") == "MTT") {
        setList(
          [...selectedFiles, ...unSelectedFiles].filter(
            (item) => item.userId == localStorage.getItem("id")
          )
        );
      }
      if (
        localStorage.getItem("role") == "CIT" ||
        localStorage.getItem("role") == "Consultant"
      ) {
        setList([...selectedFiles]);
      }

      if (selectedFiles.length) {
        setCurrentSelected(selectedFiles[0].key);
        setSelectedKeys([selectedFiles[0].key]);
        setCurrentChunk(selectedFiles[0]);
        setCurrentFileName(selectedFiles[0].fileName);
      } else {
        setCurrentSelected("");
        setSelectedKeys([]);
        setCurrentChunk({});
      }
      // let data = await getProjectByRoleAndId(
      //   localStorage.getItem("role"),
      //   localStorage.getItem("id")
      // );
      // if (data.length) {
      //   setUserProject(data[0]);
      // }
      setIsCommentShow(false);
      setIsShowMasterRecording(false);
    } catch (error) {
      console.log("VIDEO RECORDING COMPONENT ERROR", error);
    }

    setIsVideoLoading(false);
  };

  const removeSelected = () => {
    setCurrentSelected("");
    setSelectedKeys([]);
    setCurrentChunk({});
  };

  const updateChunkData = async (book) => {
    let chunk = location.state.chunk;
    chunk.chunksDataProgress = "completed";
    let books = booksReducer.map((item) => {
      if (item.bookName == book.bookName) {
        let bookChapter = book.bookProgress.filter((item) => {
          item.pending -= 1;
          item.completed += 1;
        });
        let ChapterProgress = book.chaptersProgress.filter((item) => {
          if (item.chapters == location.state.chapter) {
            item.pending -= 1;
            item.completed += 1;
          }
        });
        let chapter = book.chapters.filter((item) => {
          if (item.chapterName == location.state.chapter) {
            item.chunks.map((element) => {
              if (element.chunkName === chunk.chunkName) {
                element = chunk;
              }
            });
          }
        });
        // await updateBooks(book.book_id, book);
      }
    });
  };

  const SelectFileModal = (selectedRowKeys, selectedRows) => {
    confirm({
      title: <span className="  ">Are you sure you want to select this file?</span>,
      icon: <ExclamationCircleOutlined />,
      okText: "Confirm",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        try {
          setCurrentChunk(selectedRows[0]);
          setSelectedKeys(selectedRowKeys);
          let chunk = selectedRows[0].fileName;
          if (window.navigator.onLine == true) {
            await selectDraftedFileOnServer(`${selectedRows[0].fileId}`);
          }
          // await updateFileSelection(selectedRows[0]);
          setCurrentFileName(`${selectedRows[0].fileName}`);
          // await updateChunkData(location.state.book);
          await fetchVideoFromDB();
          showNotification("success", "File selected successfully");
        } catch (error) {
          showNotification(
            "error",
            "File is not selected, Please try after some time"
          );
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const myRowSelection = {
    type: "radio",
    selectedRowKeys: [currentSelected.key],
    onChange: async (selectedRowKeys, selectedRows) => {
      SelectFileModal(selectedRowKeys.toString(), selectedRows);
    },
  };

  return (
    <div>
      <div className="flex">
        <span className="span-heading   ">{title}</span>
        {isVideoLoading ? (
          <span className="mx-2">
            <SmallSpinnerComponent />
          </span>
        ) : (
          <>
            <>
              {isDisableRecord == true ? (
                ""
              ) : (
                <VideoScreen
                  reload={fetchVideoFromDB}
                  chunk={location.state?.chunk}
                  project={location.state?.project}
                  setIsLoading={setIsVideoLoading}
                  chunkNumber={resultantNumber}
                  isDisabled={isVideoLoading}
                  targetType={targetType}
                  draftFileName={relatedDraft}
                  name={title}
                />
              )}
            </>
          </>
        )}
      </div>

      <div>
        <ConfigProvider
          renderEmpty={() => (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                localStorage.getItem("role") == "Facilitator" &&
                targetType == "draft"
                  ? "To make back translation, Cross check and Communtiy check should be completed"
                  : "No Data"
              }
            />
          )}
        >
          <Table
            loading={{
              spinning: isVideoLoading,
              indicator: (
                <span>
                  <SmallSpinnerComponent />
                </span>
              ),
            }}
            rowSelection={{
              type: "radio",
              ...myRowSelection,
            }}
            pagination={{
              pageSize: 3,
            }}
            columns={columns}
            dataSource={list}
            rowClassName={(record, index) => {
              if (currentSelected && currentSelected !== record.key) {
                return "disabled-row";
              }
              return "pointers-compuslory";
            }}
          />
        </ConfigProvider>
      </div>

      <div className="pb-2">
        {isCommentShow && <CommentsComponent chunk={currentChunk} />}
      </div>

      {contextHolder}
    </div>
  );
};

export default DraftVideoComponent;
