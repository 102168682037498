import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainLayout from "../../components/layout/main.layout";
import NoInternetConnection from "../../components/no-internet";
import TitleBar from "../../components/ui/title-bar/title-bar.component";
const RecentUpdatesPage = () => {
  const [isInternet, setIsInternet] = useState(false);
  const dispatch = useDispatch();
  const audioRef = useSelector((state) => state.audioReducer);
  let isFirstLoad = useSelector((state) => state.firstLoadReducer);
  useEffect(() => {
    let connection = navigator.onLine;
    setIsInternet(connection);
  }, [navigator.onLine]);

  return (
    <div>
      <MainLayout>
        <div className="draft-details">
          <div className="draft-details-wrapper h-screen">
            <TitleBar title={"What's new"} page={"draft"} />
            {isInternet ? <></> : <NoInternetConnection />}
          </div>
        </div>
      </MainLayout>
    </div>
  );
};

export default RecentUpdatesPage;
