import React from "react";

const ChatBotSelectionJSX = ({ chatbots, selectChatbot }) => {
  console.log("Chatbots", chatbots);
  return (
    <div>
      <div className="text-black message-content clearfix">
        <div className="message bot-message float-left">
          <div className="d-block w-full">
            <div>
              {chatbots.length > 0 ? (
                <>
                  <p> Please select Chatbot</p>
                  <div style={{ display: "flex" }}>
                    {chatbots.map((item) => (
                      <div className="mt-2 mb-4 sec-marginLeft-1">
                        <span
                          onClick={(e) => selectChatbot(item.verse)}
                          className="border px-2 border-dark rounded py-1 cursor-pointer capitalize"
                        >
                          {item.verse}
                        </span>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <p>Resource not availabe</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBotSelectionJSX;
