import { combineReducers } from "redux";
import audioReducer from "./audio.reducer";
import projectsReducer from "./project.reducer"
import booksReducer from "./books.reducer";
import firstLoadReducer from "./util.reducer";
import { sideNavBarStatusReducer } from "./util.reducer";
import checkFilesReducer, {
  toggleCheckFilesCheckboxReducer,fileCountReducer
} from "./checkFiles.reducer";
import {
  recordingStatusReducer,
  playingStatusReducer,
  selctedChunkPlaylistReducer,
  homeLoadingReducer,
  textSpeechStatusReducer,
  voiceRecognitionReducer,
  syncStatusReducer,
  tranScriptedText
} from "./util.reducer";

const rootReducer = combineReducers({
  audioReducer,
  booksReducer,
  firstLoadReducer,
  recordingStatusReducer,
  playingStatusReducer,
  selctedChunkPlaylistReducer,
  homeLoadingReducer,
  checkFilesReducer,
  homeLoadingReducer,
  textSpeechStatusReducer,
  toggleCheckFilesCheckboxReducer,
  voiceRecognitionReducer,
  syncStatusReducer,
  projectsReducer,
  sideNavBarStatusReducer,
  tranScriptedText,
  fileCountReducer,
});

export default rootReducer;
