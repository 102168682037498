import { Button, Modal, Tooltip, notification } from "antd";
import React, { useEffect, useState } from "react";
import DraftScriptComponent from "../draft-script/index";
import DraftAudioComponent from "../draft-audio/index";
import { MdRecordVoiceOver } from "react-icons/md";
import DraftVideoComponent from "../draft-video/draft-video.component";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import FilenameComponent from "../../ui/filename.component";

const MasterRecroding = ({
  fileType,
  chunk,
  draftFileName,
  isButtonDisabled,
  project,
}) => {
  const [isShow, setIsShow] = useState(false);
  const location = useLocation();
  const [userProject, setUserProject] = useState({});

  const isPlayingStatus = useSelector((state) => state.playingStatusReducer);
  const isRecordingStatus = useSelector(
    (state) => state.recordingStatusReducer
  );

  const [notificationApi, NotificationJSX] = notification.useNotification();
  const showNotification = (type, message) => {
    notification.destroy();
    notificationApi[type]({
      message,
    });
  };

  const openModal = () => {
    if (isRecordingStatus == true) {
      showNotification("error", "Please stop recording");
    } else if (isPlayingStatus == true) {
      showNotification("error", "Please stop playing");
    } else {
      setIsShow(true);
    }
  };
  useEffect(() => {
    console.log("location?.state?.project", location?.state?.project);
    setUserProject(location?.state?.project);
  }, [project, chunk]);

  const handleCancel = () => {
    if (isRecordingStatus == true) {
      showNotification("error", "Please stop recording");
    } else if (isPlayingStatus == true) {
      showNotification("error", "Please stop playing");
    } else {
      setIsShow(false);
    }
  };

  return (
    <>
      <Tooltip title="Master Recording">
        <Button disabled={isButtonDisabled} onClick={openModal}>
          <MdRecordVoiceOver size={20} />
        </Button>
      </Tooltip>
      <Modal
        title={
          <>
            <FilenameComponent file={chunk} />
          </>
        }
        visible={isShow}
        width={1300}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
      >
        {userProject?.projectType?.signLanguage == true ? (
          <DraftVideoComponent
            chunk={chunk}
            title={"Master Recording"}
            relatedDraft={draftFileName}
            targetType="masterRecord"
            isDisableRecord={localStorage.getItem("role") == "Facilitator"}
          />
        ) : null}

        {userProject?.projectType?.audio == true && fileType=='audio' ? (
          <DraftAudioComponent
            title={"Master Recording"}
            // fileType="audio"
            fileType={fileType}
            targetType="masterRecord"
            chunk={chunk}
            relatedDraft={draftFileName}
            isDisableRecord={localStorage.getItem("role") == "Facilitator"}
            // record_type={"masterRecord_audio"}
            record_type={draftFileName}
          />
        ) : null}

        {userProject?.projectType?.jesusFilm == true ? (
          <DraftAudioComponent
            title={"Master Recording"}
            // fileType="audio"
            fileType={fileType}
            targetType="masterRecord"
            chunk={chunk}
            relatedDraft={draftFileName}
            isDisableRecord={localStorage.getItem("role") == "Facilitator"}
            // record_type={"masterRecord_audio"}
            record_type={draftFileName}
          />
        ) : null}

        {userProject?.projectType?.text == true && fileType=='script' ? (
          <DraftScriptComponent
            title={"Master Recording"}
            targetType="masterRecord"
            fileType={fileType}
            chunk={chunk}
            relatedDraft={draftFileName}
            isTextAreaDisplayed={localStorage.getItem("role") == "MTT"}
            // record_type={"masterRecord_script"}
            record_type={draftFileName}
          />
        ) : console.log("wertyuiop",userProject?.projectType?.text)}

        {/* {Object.keys(userProject).length ? (
          <>
            {userProject.projectType.text == true ? (
              <DraftScriptComponent
                title={"Script Draft"}
                targetType="draft"
                chunk={location.state?.chunk}
                isTextAreaDisplayed={
                  localStorage.getItem("role") == "MTT"
                }
                isBackTranslationDisplay={true}
                relatedDraft=""
              />
            ) : null}
          </>
        ) : null} */}
      </Modal>

      {NotificationJSX}
    </>
  );
};
export default MasterRecroding;
