import { Alert, Button, notification, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { BsCameraVideoFill, BsFillCameraVideoOffFill } from "react-icons/bs";
import { bufferToBase64 } from "../../../services/conversion.service";
import Moment from "moment";
import { useLocation } from "react-router";
import {
  FaMicrophone,
  FaMicrophoneSlash,
  FaVideo,
  FaVideoSlash,
} from "react-icons/fa";
import { getCurrentTimeStamp, randomHEXID } from "../../../services/config";
// import { saveDraftFileAndSync } from "../../../services/sync-methods/draft-files.sync";
// import { saveCommentAndSync } from "../../../services/sync-methods/comments.sync";
import { toggleSyncStatus } from "../../../redux/actions/util.action";
import { useDispatch } from "react-redux";
import {uploadDraftFileToServer} from "../../../services/api-calls/draft-files.api"
import {postCommentToServer} from "../../../services/api-calls/comments.api"
import { saveDraftFileToServer } from "../../../services/api-calls/draft-files.api"

const VideoRecorder = ({
  chunk,
  project,
  type,
  reload,
  name,
  setIsLoading,
  chunkNumber,
  isDisabled,
  targetType,
  draftFileName,
}) => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [secs, setSecs] = useState(0);
  const [chunkData, setChunkData] = useState({});
  const [userProject, setUserProject] = useState({});
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [videoDevices, setVideoDevices] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    type: "",
    message: "",
  });

  const [notificationApi, NotificationJSX] = notification.useNotification();
  const showNotification = (type, message) => {
    notification.destroy();
    notificationApi[type]({
      message,
    });
  };
  const streamRef = useRef();
  const countRef = useRef();
  const mediaRecorder = useRef();
  const location = useLocation();
  let videoChunk = [];

  useEffect(() => {
    fetchUserProject();
    fetchAllCamera();
  }, [type]);

  const fetchUserProject = async () => {
    setChunkData(chunk);
    setUserProject(project);
  };
  const initiateRecord = async () => {
    await fetchAllCamera();
    if (localStorage.getItem("isPlaying") == "true") {
      showAlertMessage("error", "Please stop current playing");
    } else {
      if (videoDevices.length == 1) {
        startRecording();
      } else {
        showNotification("error", "Please connect a Device");
      }
    }
  };

  const startRecording = async (deviceId) => {
    setIsShow(true);
    setIsRecording(true);
    countRef.current = setInterval(() => {
      setSecs((secs) => secs + 1);
    }, 1000);
    navigator.getUserMedia =
      (await navigator.getUserMedia) ||
      (await navigator.webkitGetUserMedia) ||
      (await navigator.mozGetUserMedia);

    if (navigator.getUserMedia) {
      await navigator.getUserMedia(
        {
          audio: {
            mandatory: {
              echoCancellation: true,
            },
          },
          video: {
            width: 1280,
            height: 900,
            echoCancellation: true,
            deviceId: { exact: deviceId ? deviceId : videoDevices[0].deviceId },
          },
        },
        async (stream) => {
          const video = document.getElementById(targetType);
          video.srcObject = await stream;
          showNotification("success", "Video recording started successfully");
          video.muted = true;
          mediaRecorder.current = new MediaRecorder(stream, {
            mimeType: "video/webm",
          });
          mediaRecorder.current.ondataavailable = async function (e) {
            await videoChunk.push(e.data);
          };
          await mediaRecorder.current.start(10000);

          video.onloadedmetadata = async (e) => {
            await video.play();
          };
          streamRef.current = await stream;

          mediaRecorder.current.onstop = async function () {
            setIsLoading(true);
            const blob = await new Blob(videoChunk, {
              type: "video/mp4",
            });

            showNotification("success", "Video recording stopped successfully");
            setIsLoading(true);
            if (targetType == "comment") {
              await saveVideoCommentFile(blob);
            } else {
              await saveDraftFile(blob);
            }
            videoChunk = [];
          };
        },
        (err) => {
          console.error(`The following error occurred: ${err}`);
          showNotification("error", `${err}`);
          closeModal();
        }
      );
    } else {
      console.log("getUserMedia not supported");
    }
  };

  const fetchAllCamera = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();

      // Filter the devices to get only the video devices.
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      setVideoDevices(videoDevices);
    } catch (error) {
      console.log(error);
    }
  };

  const saveDraftFile = async (blob) => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
        let uniqueKey = await getCurrentTimeStamp();
        let videoName = `${location.state?.chunkName} ${chunkNumber} ${uniqueKey}`;
        let videoObj = {
          key: uniqueKey,
          name: videoName,
          chunk: chunk,
          time: secs,
          formattedName: `${location.state?.heading}`,
          blob: blob,
          bookName: location.state?.chunk.chunk[0].bookName,
          comments: [],
          fileId: uniqueKey,
          chapter: location.state?.chunk.chunk[0].chapterName,
          language: userProject.language,
          majorLanguage: userProject.majorLanguage1,
          projectId: userProject._id,
          sourceVerseName: location.state?.verse,
          verse: location.state?.verse,
          userId: localStorage.getItem("id"),
          targetType: targetType,
          projectType: userProject.projectType,
          fileName: `${videoName}.mp4`,
          isSelected: false,
          fileType: "video",
          isSynced: false,
          relatedDraft: draftFileName,
          createdAt: new Date().toISOString(),
        };

        let base64 = await bufferToBase64(blob);

        await saveDraftFileToServer(videoObj)

        // await saveDraftFileAndSync(videoObj);

        // window.api.saveFile("saveFile", {
        //   base64: base64,
        //   fileType: "video",
        //   fileName: `${videoName}.mp4`,
        //   fileId: uniqueKey,
        // });

        await reload("Video saved successfully");
        dispatch(toggleSyncStatus(true));
        setTimeout(() => {
          dispatch(toggleSyncStatus(false));
        }, 5000);
        const video = await document.querySelector("video");
        if (video) {
          video.srcObject = null;
        }
        setIsLoading(false);
        resolve();
      } catch (error) {
        console.warn("draft file saving ", error);
      }
      resolve();
    });
  };

  // const saveDraftFileToServer = (body) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       let fileObj = body;
  //       let blob = body.blob;

  //       delete body.blob;
  //       if (window.navigator.onLine == true) {
  //         if (body.fileType == "audio" || body.fileType == "video") {
  //           let base64 = await bufferToBase64(blob);
  //           body = { ...body, ...{ base64 } };
  //         } else {
  //           body = { ...body, ...{ base64: blob } };
  //         }
  //         let res = await uploadDraftFileToServer(body);
  //         resolve();
  //       }
  //     } catch (error) {
  //       console.log("ERROR", error);
  //     }
  //   })
  // }

  const saveVideoCommentFile = async (blob) => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
        showNotification("success", "Comment saved Successfully");
        let base64Data = await bufferToBase64(blob);
        let uniqueKey = await getCurrentTimeStamp();
        let commentFileName = `${await chunkData.fileName}____${uniqueKey}`;

        let commentObj = {
          fileName: commentFileName,
          role: localStorage.getItem("role"),
          userId: localStorage.getItem("id"),
          user: `${localStorage.getItem("firstName")}  ${localStorage.getItem(
            "lastName"
          )} (${localStorage.getItem("role")})`,
          fileType: "video",
          fileId: uniqueKey,
          comments: "",
          createdAt: new Date().toISOString(),
        };

        // await window.api.saveFile("saveFile", {
        //   base64: base64Data,
        //   fileType: "audio",
        //   fileName: `${commentFileName}`,
        //   fileId: uniqueKey,
        // });

        if (navigator.onLine == true) {
          let commentRes = await postCommentToServer(chunkData?.fileId, {
            ...commentObj,
            base64: base64Data,
          });
          // await saveCommentToIndexedDb(fileId, commentRes?.result);
          // resolve(true);
        }

        // await saveCommentAndSync(chunkData?.fileName, chunkData?.fileId, {
        //   ...commentObj,
        //   base64: base64Data,
        // });

        const video = document.querySelector("video");

        if (video) {
          video.srcObject = null;
        }
        await reload("Comment saved Successfully");
        dispatch(toggleSyncStatus(true));
        setTimeout(() => {
          dispatch(toggleSyncStatus(false));
        }, 5000);
        showAlertMessage("error", "Comment submitted successfully");
        showNotification("success", "Comment submitted successfully");
        setIsLoading(false);
        resolve(true);
      } catch (error) {
        console.log("SAVE VIDEO COMMENT FILE ", error);
        setIsLoading(false);
      }
    });
  };

  const closeModal = async (type) => {
    if (isRecording == true) {
      showNotification("error", "Please stop recording");
    } else {
      setIsShow(false);
      setIsRecording(false);
      clearInterval(countRef.current);
      setSecs(0);
    }
  };

  const stopRecording = async () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(function (track) {
        track.stop();
      });
    }
    if (mediaRecorder.current) {
      await mediaRecorder.current.stop();
    }
  };

  const stopVideoOnly = async () => {
    await streamRef.current.getTracks().forEach(async (track) => {
      if (
        isVideoEnabled == true &&
        track.readyState == "live" &&
        track.kind === "video"
      ) {
        track.enabled = false;
        setIsVideoEnabled(false);
      } else if (
        isVideoEnabled == false &&
        track.readyState == "live" &&
        track.kind === "video"
      ) {
        track.enabled = true;
        setIsVideoEnabled(true);
      }
    });
  };

  const stopAudioOnly = async () => {
    await streamRef.current.getTracks().forEach(function (track, i) {
      if (
        isAudioEnabled == true &&
        track.readyState == "live" &&
        track.kind === "audio"
      ) {
        track.enabled = false;
        setIsAudioEnabled(false);
      } else if (
        isAudioEnabled == false &&
        track.readyState == "live" &&
        track.kind === "audio"
      ) {
        track.enabled = true;
        setIsAudioEnabled(true);
      }
    });
  };

  const showAlertMessage = async (type, message) => {
    setAlertMessage({
      ...alertMessage,
      type,
      message,
    });
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 1500);
  };

  return (
    <>
      <button
        className="remove-btn-css"
        disabled={isDisabled}
        onClick={initiateRecord}
      >
        <>
          <button className="remove-btn-css" disabled={isDisabled}>
            <BsCameraVideoFill
              size={35}
              className="mx-2"
              color={isDisabled ? "gray" : "black"}
            />
          </button>
          {name && (
            <Button className="hover" disabled={isDisabled}>
              <span>Start Recording</span>
            </Button>
          )}
        </>
      </button>
      {showAlert && (
        <Alert
          message={alertMessage.message}
          type={alertMessage.type}
          showIcon
          className="w-86 mx-2"
          closable
        />
      )}
      <Modal
        title="Video Recorder"
        visible={isShow}
        width={900}
        onCancel={(e) => {
          closeModal("close");
        }}
        footer={null}
        maskClosable={false}
      >
        {videoDevices.length > 1 && isRecording == false ? (
          <>
            <h2 className="mx-2 pb-4">
              Select on camera name to proceed video recording
            </h2>
            {videoDevices.map((item) => (
              <Button
                className="mx-2"
                onClick={(e) => {
                  startRecording(item.deviceId);
                }}
              >
                {" "}
                {item.label}{" "}
              </Button>
            ))}
          </>
        ) : null}

        {isRecording == true ? (
          <>
            <>
              <BsFillCameraVideoOffFill size={35} className="mx-2" />
              <Button className="hover" onClick={stopRecording}>
                <span>Stop Recording</span>
              </Button>
              <span className="px-2 text-lg">
                {Moment.utc(secs * 1000).format("mm:ss")}{" "}
              </span>
            </>
            <video className="w-100" id={targetType} />
            <div className="flex items-center justify-center py-2">
              {isAudioEnabled ? (
                <Button className="hover">
                  <FaMicrophone
                    size={25}
                    color={"green"}
                    onClick={(e) => {
                      stopAudioOnly();
                    }}
                  />
                </Button>
              ) : (
                <Button className="hover">
                  <FaMicrophoneSlash
                    size={25}
                    color={"red"}
                    onClick={(e) => {
                      stopAudioOnly();
                    }}
                  />
                </Button>
              )}
              {isVideoEnabled ? (
                <Button className="hover mx-2" onClick={stopVideoOnly}>
                  <FaVideo size={25} color={"green"} />
                </Button>
              ) : (
                <Button className="hover mx-2" onClick={stopVideoOnly}>
                  <FaVideoSlash size={25} color={"red"} />
                </Button>
              )}
            </div>
          </>
        ) : null}
      </Modal>

      {NotificationJSX}
    </>
  );
};
export default VideoRecorder;
