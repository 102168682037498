import { togglePlaying } from "../redux/actions/util.action";

let storedValue;

const getHeader = () => {
    return {
      headers: {
        "Content-Type": "application/json",
      },
    };
  };
  
  export default getHeader;
  
  // extract numbers from string
  // const extractNumberFromString = (str) => {
  //   return new Promise(async (resolve, reject) => {
  //     if (str) {
  //       var numb = str.match(/\d/g);
  //       if (numb == null) {
  //         resolve("0000");
  //         return;
  //       }
  //       numb = numb.join("");
  //       if (typeof str[str.length - 1] == "string") {
  //         console.log("IS STRING");
  //         let numberLength = Number(3 - numb.length);
  //         for (let i = 0; i < numberLength; i++) {
  //           numb = `0${numb}`;
  //         }
  //         numb = `${numb}${str[str.length - 1]}`;
  //       } else {
  //         let numberLength = Number(4 - numb.length);
  //         for (let i = 0; i < numberLength; i++) {
  //           numb = `0${numb}`;
  //         }
  //       }
  //       resolve(numb);
  //     } else {
  //       resolve("0000");
  //     }
  //   });
  // };
  
  const extractNumberFromString = (str) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (str == null || str == undefined) {
          resolve("0000");
          return "0000";
        }
  
        let splittedStr = str.split(" ");
        if (splittedStr.length > 0) {
          let lastStr = splittedStr[splittedStr.length - 1].trim();
          let numberLength = Number(4 - lastStr.length);
  
          for (let i = 0; i < numberLength; i++) {
            lastStr = `${lastStr}`;
          }
  
          if (lastStr.length > 4) {
            lastStr = lastStr.substring(1, lastStr.length);
          }
          resolve(lastStr);
        } else {
          resolve("0000");
        }
      } catch (error) {
        resolve("0000");
      }
    });
  };
  // Check Internet status
  const checkInternetConnection = async () => {
    return new Promise(async (resolve, reject) => {
      resolve(navigator.onLine);
    });
  };
  
  // it will return combination of Source language , book name and chapter name
  const getChunkName = (sourceLanguage, bookName, chapterName) => {
    return new Promise((resolve, reject) => {
      let sourceName =
        sourceLanguage?.substring(0, 2).toUpperCase() +
        " " +
        bookName?.replace(/\s/g, "").substring(0, 3).toUpperCase() +
        " " +
        "00" +
        chapterName?.split(" ")[1];
      resolve(sourceName);
    });
  };
  
  // It will hold the process or execution for given time
  const waitForAWhile = (time) => {
    return new Promise((resolve, reject) => {
      let timeOut = setTimeout(() => {
        resolve(true);
        clearTimeout(timeOut);
      }, time);
    });
  };
  
  // sort chunk :
  
  const sortChunks = async (chunk) => {
    return new Promise(async (resolve, reject) => {
      if (chunk.length) {
        let data = await chunk.sort((a, b) =>
          a.chunkName
            .toString()
            .localeCompare(b.chunkName.toString(), "en", { numeric: true })
        );
        resolve(data);
      } else {
        resolve([]);
      }
    });
    // return a.toString().localeCompare(b.toString(), 'en', { numeric: true })
  };
  
  // sort chapters in ascending orders
  const sortChapters = async (chapter) => {
    return new Promise(async (resolve, reject) => {
      if (chapter.length) {
        let data = await chapter.sort((a, b) =>
          a.chapterName
            .toString()
            .localeCompare(b.chapterName.toString(), "en", { numeric: true })
        );
        resolve(data);
      } else {
        resolve([]);
      }
    });
  };
  
  const sortBooks = async (book) => {
    return new Promise(async (resolve, reject) => {
      if (book.length) {
        let data = await book.sort((a, b) =>
          a.bookName > b.bookName ? 1 : b.bookName > a.bookName ? -1 : 0
        );
        resolve(data);
      } else {
        resolve([]);
      }
    });
  };
  
  // it will return 3 digit number
  const getCurrentTimeStamp = async (value) => {
    return new Promise(async (resolve, reject) => {
      var result = new Date().getTime();
      resolve(result.toString());
      // var characters = "0123456789";
      // var charactersLength = characters.length;
      // resolve(new Date().getTime());
      // for (var i = 0; i < 3; i++) {
      //   result += characters.charAt(Math.floor(Math.random() * charactersLength));
      // }
      // if (value == result) {
      //   // getRandom3Digit(result);
      // } else {
      //   // resolve(result);
      //   resolve(new Date().getTime());
      // }
      // let currentYear = String(await new Date().getFullYear());
      // let yearLastDigit = currentYear.split("20")[1];
      // resolve(
      //   `${new Date().getDate()}:${
      //     new Date().getMonth() + 1
      //   }:${yearLastDigit}  ${new Date().getHours()}:${new Date().getMinutes()}`
      // );
    });
  };
  
  // it will hex string
  const randomHEXID = async () => {
    return new Promise(async (resolve, reject) => {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < 16; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      resolve(result);
    });
  };
  
  // get Last three char from the string
  const last3DigitFromFileName = async (str) => {
    return new Promise(async (resolve, reject) => {
      try {
        let fileName = str.split(".")[0];
        let fileNameLength = fileName.length;
        let lastDigit = `${fileName[fileNameLength - 3]}${
          fileName[fileNameLength - 2]
        }${fileName[fileNameLength - 1]}`;
  
        resolve(lastDigit);
      } catch (error) {}
    });
  };
  
  // sort item on creation time
  const sortItemBasedOnCreation = async (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        let sortedData = await data.sort(function (a, b) {
          return b.createdAt < a.createdAt
            ? -1
            : b.createdAt > a.createdAt
            ? 1
            : 0;
        });
        resolve(sortedData);
      } catch (error) {}
    });
  };
  
  //stop all audio playing
  const stopAllAudioPlaying = async () => {
    var player = await document.getElementById("audio_player");
    await player.pause();
    player.currentTime = 0;
  };
  
  // popup toast notification message
  const showToastNotification = async (msg, time) => {
    let toastBar = await document.getElementById("toast-bar");
    toastBar.className = "display";
    toastBar.innerHTML = msg;
  
    setTimeout(() => {
      toastBar.classList.remove("display");
      toastBar.innerHTML = "";
    }, time * 1000);
  };

  const sourcePause= async (audio)=>{
    return new Promise(async (resolve, reject) => {
      try {
        storedValue = audio
        resolve(audio)
        // console.log("AUDIO",audio);
        // audioPause.current = await audio.current
        // dispatch(setAudioRef(audio))
      } catch (error) {
       console.error(error); 
      }
    })
    
  }
  const storeValue = (value) => {
    console.log("Recived value: ", value)
    storedValue = value;
  };
  
  // Function to retrieve the stored value
  const getStoredValue = () => {
    return storedValue;
  };
  
  
  export {
    extractNumberFromString,
    checkInternetConnection,
    getChunkName,
    sortBooks,
    waitForAWhile,
    sortChapters,
    getCurrentTimeStamp,
    randomHEXID,
    sortChunks,
    last3DigitFromFileName,
    sortItemBasedOnCreation,
    stopAllAudioPlaying,
    showToastNotification,
    sourcePause,
    storeValue,
    getStoredValue,
  };
  