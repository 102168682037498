import {  notification } from "antd";
import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { clearCheckFilesName, clearFileCount, toggleCheckFilesCheckbox } from "../../../redux/actions/check-files.action";
import { clearTranScriptedText } from "../../../redux/actions/util.action";

const TitleBar = ({ title, address, page, checkModal }) => {
  const [isShow, setIsShow] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [role, setRole] = useState(localStorage.getItem("role"));
  const isPlayingStatus = useSelector((state) => state.playingStatusReducer);
  const isRecordingStatus = useSelector(
    (state) => state.recordingStatusReducer
  );
  const [api, contextHolder] = notification.useNotification();
  const showNotification = (type, message) => {
    notification.destroy();
    api[type]({
      message,
    });
  };
  const toggleCheckFilesCheckboxReducer = useSelector(
    (state) => state.toggleCheckFilesCheckboxReducer
  );

  useEffect(() => {
    const checkWindowSize = () => {
      if (window.innerWidth <= 768) { // Adjust the screen width as needed
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    // Initial check when the component mounts
    checkWindowSize();
    // Listen for window resize events and update the state
    window.addEventListener('resize', checkWindowSize);
    // Clean up the event listener when the component unmounts
    return () => {
      setIsShow(false);
      window.removeEventListener('resize', checkWindowSize);
    };
  }, [location]);

  const goBack = async() => {
    setIsShow(false);
    await dispatch(clearCheckFilesName());
    if (role == "MTT" || role == "Facilitator") {
      await dispatch(clearTranScriptedText());
    }
    // await dispatch(clearTranScriptedText());
    if (isPlayingStatus == true) {
      showNotification("error", "Please stop playing");
    } else if (isRecordingStatus == true) {
      showNotification("error", "Please stop recording");
    } else {
      if (address) {
        navigate(address);
      } else if (page == "draft-details") {
        navigate("/draft");
        // localStorage.setItem("selectedBook", JSON.stringify(""));
      } else if (page == "draft") {
        navigate("/dashboard");
        localStorage.setItem("selectedBook", JSON.stringify(""));
      } else {
        navigate(-1);
      }
    }
  };

  return (
    <>
      <div className="grid grid-cols-3 border-bottom   ">
        <div
          className="back-arrow"
          onContextMenu={(e) => {
            e.preventDefault();
            setIsShow(!isShow);
          }}
        >
          <IoIosArrowBack size={isMobile ? 20 : 26} onClick={goBack} />
        </div>
        <div className="flex items-center justify-center chunkname">
          <h4 className="fsize">{title}</h4>
        </div>

        <div className="flex items-center justify-end">{checkModal}</div>
      </div>

      {isShow == true && (
        <div
          className="go-back-dropdown"
          onMouseEnter={(e) => {
            setIsShow("true");
          }}
          onMouseLeave={(e) => {
            setIsShow("false");
          }}
        >
          <div
            className="border-bottom px-3 py-2 cursor-pointer"
            onClick={goBack}
          >
            <span>Go back</span>
          </div>
          <div
            className="border-bottom px-3 py-2 cursor-pointer"
            onClick={(e) => {
              navigate("/dashboard");
              setIsShow(false);
            }}
          >
            <span>Go to home</span>
          </div>
        </div>
      )}

      {contextHolder}
    </>
  );
};

export default TitleBar;
