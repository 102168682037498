import {
  Button,
  Table,
  Tooltip,
  Modal,
  notification,
  Alert,
  Empty,
  ConfigProvider,
} from "antd";
import React, { useEffect, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FaComment } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { IoMdMicOff } from "react-icons/io";
import { BsFillFileEarmarkPersonFill } from "react-icons/bs";
import { useLocation } from "react-router";
import {
  extractNumberFromString,
  sortItemBasedOnCreation,
  waitForAWhile,
} from "../../../services/config";
// import { getDraftedFilesByChunks } from "../../../services/indexed-db/draftfiles.indexeddb";
// import { getProjectByRoleAndId } from "../../../services/indexed-db/project.indexeddb";
import DeleteModalComponent from "../../modal/delete-modal/delete-modal.component";
import AudioPlayer from "../../audio-player/audio-player.component";
import AudioRecorder from "../../audio-recorder";
import SmallSpinnerComponent from "../../spinner/small-spinner.component";
import CommentsComponent from "../../comments/comments.component";
import BackTranslation from "../back-translation";
import { useDispatch, useSelector } from "react-redux";
import { draftFile } from "../../../services/apis";
import axios from "axios";
import getHeader from "../../../services/config";
// import { updateFileSelection } from "../../../services/sync-methods/draft-files.sync";
// import { updateBooks } from "../../../services/indexed-db/books.indexeddb";
import FilenameComponent from "../../ui/filename.component";
import { selectDraftedFileOnServer } from "../../../services/api-calls/draft-files.api";
import { getDraftedFilesByChunksOnline } from "../../../services/api-calls/draft-files.api";
import { saveCheckFilesName,toggleCheckFilesCheckbox,checkFileCount } from "../../../redux/actions/check-files.action";
import { createToDoComment } from "../../../services/api-calls/comments.api";
import {isTablet,isSafari} from 'react-device-detect';
const { confirm } = Modal;

const DraftAudioComponent = ({
  title,
  fileType,
  chunk,
  relatedDraft,
  isDisableRecord,
  targetType,
  isBackTranslationDisplay,
  record_type
}) => {
  const [list, setList] = useState([]);
  const location = useLocation();
  const stateData = location.state;
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [currentSelected, setCurrentSelected] = useState("");
  const [showComments, setShowComments] = useState(false);
  const [showBtAudio, setShowBtAudio] = useState(false);
  const [showMasterRecording, setShowMasterRecording] = useState(false);
  const [resultantNumber, setResultantNumber] = useState("");
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [currentChunk, setCurrentChunk] = useState({});
  const [userProject, setUserProject] = useState([]);
  const [showCommentIcon, setShowCommentIcon] = useState(false);
  const [currentFileName, setCurrentFileName] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isAudioPresent, setIsAudioPresent] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const booksReducer = useSelector((state) => state.booksReducer);
  const isPlayingStatus = useSelector((state) => state.playingStatusReducer);
  const isTranScriptedText = useSelector((state) => state.tranScriptedText);
  const isRecordingStatus = useSelector((state) => state.recordingStatusReducer);
  const [showAlert, setShowAlert] = useState(false);
  const [draftFileData,setDraftFileData]=useState()
  const [alertMessage, setAlertMessage] = useState({
    type: "",
    message: "",
  });

  const [api, contextHolder] = notification.useNotification();
  const showNotification = (type, message) => {
    notification.destroy();
    api[type]({
      message,
    });
  };
  let role = localStorage.getItem("role");
  useEffect(() => {
    loadFiles();
    setIsButtonDisabled(false);
  }, [targetType,location,relatedDraft]);

  useEffect(()=>{
    if(localStorage.getItem("role")== "Facilitator" && targetType == "backTranslate" && record_type?.split("_")[1] == isTranScriptedText.targetfileType){
      loadFiles();
    }
    else if(localStorage.getItem("role")== "MTT" && (targetType == "draft" || targetType == "masterRecord")){
      loadFiles();
    }
      },[isTranScriptedText])

  const getDraftedFiles = (targetType) => {
    return new Promise(async (resolve, reject) => {
      try {
        let files;
        let draftData = {
          projectId: location.state?.project.id,
          sourceVerseName: location.state?.verse,
          bookName: location.state?.book.bookName,
          chapterName: location.state?.chapter,
          fileType: "audio",
          targetType,
        };
        
        if(targetType!="draft"){
          draftData['relatedDraft']=relatedDraft
        }
        
        files = await getDraftedFilesByChunksOnline(draftData);
        // files = await [...resp.data];
        if (files.length) {
          for (let i = 0; i < files.length; i++) {
            let fileName = await files[i].fileName.split(".")[0];
            files[i].name = fileName;
            files[i].formattedName = `${location.state?.heading}`;
            files[i].projectType = location.state?.project.projectType;
            files[i].key = files[i].fileId;
          }
        }
        resolve(files);
      } catch (error) {
        console.log("ERROR", error);
      }
    });
  };

  // const loadFiles = async () => {
  //   setIsTableLoading(true);
  //   setShowCommentIcon(false);
  //   setIsLoading(true)
  //   setShowMasterRecording(false);
  //   setIsAudioPresent(false);
  //   setList([])
  //   try {
  //     let resultantNumberData = await extractNumberFromString(
  //       `${location.state?.verse}`
  //     );
  //     setResultantNumber(resultantNumberData);
  //     let name;
  //     if (targetType == "masterRecord" || targetType == "backTranslate") {
  //       name = relatedDraft;
  //     } else {
  //       name = `${location.state?.chunkName} ${resultantNumberData}`;
  //     }

  //     let files = await getDraftedFiles(targetType);
  //     let selectedFiles = await files.filter((item) => item.isSelected == true);
  //     let unSelectedFiles = await files.filter((item) => item.isSelected == false);
  //     unSelectedFiles = await sortItemBasedOnCreation(unSelectedFiles);
  //     if (localStorage.getItem("role") == "Facilitator") {
  //       if (targetType == "backTranslate") {
  //         setList([...selectedFiles, ...unSelectedFiles]);
  //         setIsAudioPresent(true)
  //       } else {
  //         let backTranslateEnabledFile = selectedFiles.filter(
  //           (item) =>
  //             item.translatorStatusCheck == true &&
  //             item.communityStatusCheck == true
  //         );
  //         setList([...backTranslateEnabledFile]);
  //       }
  //     } else if (localStorage.getItem("role") == "MTT") {
  //       setList(
  //         [...selectedFiles, ...unSelectedFiles].filter(
  //           (item) => item.userId == localStorage.getItem("id")
  //         )
  //       );
  //     }
  //     if (
  //       localStorage.getItem("role") == "CIT" ||
  //       localStorage.getItem("role") == "Consultant"
  //     ) {
  //       setList([...selectedFiles]);
  //       setIsAudioPresent(true);
  //     }

  //     if (selectedFiles.length) {
  //       setCurrentSelected(selectedFiles[0]);
  //       setSelectedKeys([selectedFiles[0]].key);
  //       setCurrentChunk(selectedFiles[0]);
  //       setCurrentFileName(selectedFiles[0].fileName);
  //     } else {
  //       setCurrentSelected("");
  //       setSelectedKeys([]);
  //       setCurrentChunk({});
  //     }
  //     // let data = await getProjectByRoleAndId(
  //     //   localStorage.getItem("role"),
  //     //   localStorage.getItem("id")
  //     // );
  //     // if (location.state.length) {
  //     //   setUserProject(location.state.project);
  //     // }

  //     // if (data.length) {
  //     //   setUserProject(data[0]);
  //     // }
  //     setIsTableLoading(false);
  //     setShowComments(false);
  //     setShowMasterRecording(false);
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   setIsLoading(false)
  //   setIsTableLoading(false);
  //   setIsButtonDisabled(false);
  // };

  const loadFiles = async () => {
    // setIsShowComment(false);
    // setIsTableLoading(true);
    // setIsLoading(true)
    // setIsAudioPresent(false);
    // setList([]);
    // setShowComments(false);
        setIsTableLoading(true);
    setShowCommentIcon(false);
    setIsLoading(true)
    setShowMasterRecording(false);
    setIsAudioPresent(false);
    setShowComments(false);
    setList([]);
    try {
      (targetType == "draft" && (role!="MTT" && role!="Facilitator")) ? setIsAudioPresent(true): setIsAudioPresent(false);
      let currentUserId = localStorage.getItem("id");
      let resultantNumberData = await extractNumberFromString(
        `${stateData?.verse}`
      );
      setResultantNumber(resultantNumberData);
      let name;
      if (targetType == "masterRecord" || targetType == "backTranslate") {
        name = relatedDraft;
      } else {
        name = `${stateData?.chunkName} ${resultantNumberData}`;
      }
      let allFiles;
      // files = await getDraftedFilesByChunks(name, "audio", targetType);      
      allFiles = await getDraftedFiles(targetType);
      let selectedFiles = await allFiles.filter((item) => item.isSelected == true);
      let files = await allFiles.filter(
        (item) => item.userId === currentUserId
      );
      setDraftFileData(files);

      let currentUserSelected = await files.filter((item) => item.isSelected == true);
      let unSelectedFiles = await files.filter((item) => item.isSelected === false);

      unSelectedFiles = await sortItemBasedOnCreation(unSelectedFiles);

      if (
        localStorage.getItem("role") == "Facilitator" ||
        localStorage.getItem("role") == "Consultant" ||
        localStorage.getItem("role") == "CIT"
      ) {

        if (targetType == "backTranslate" && localStorage.getItem("role") == "Facilitator") {
          if (files && files.length > 0) {
            // files created by login user
            setList([...currentUserSelected, ...unSelectedFiles]);
            setIsAudioPresent(true)
            let selectedBackTransaltion = await files.filter(
              (item) => item.isSelected == true
            );
            if (selectedBackTransaltion.length) {
              setSelectedKeys([selectedBackTransaltion[0].key]);
              setCurrentSelected(selectedBackTransaltion[0].key);

              setCurrentChunk(selectedBackTransaltion[0]);
              setCurrentFileName(selectedBackTransaltion[0].fileName);
            }
            else{
              setCurrentSelected("")
            }
          } else if (files && files.length == 0 && allFiles && allFiles.length > 0) {
            // File has not been created by this user, But some other user created file
            setIsAudioPresent(true);
           
            setList([]);
          } else if (allFiles && allFiles.length == 0) {
            // File has not been created by any user
            setIsAudioPresent(true);
                    }
        }
        else if (targetType == "backTranslate" && (localStorage.getItem("role") == "Consultant" ||
          localStorage.getItem("role") == "CIT")) {
          let backTranslateFiles = await allFiles.filter(item => item.targetType == "backTranslate" && item.isSelected == true);
          setList(backTranslateFiles);
          setIsAudioPresent(true)
          if (backTranslateFiles && backTranslateFiles.length > 0) {
            if (
              (backTranslateFiles[0].citStatusCheck == true &&
              localStorage.getItem("role") == "CIT") || 
              (backTranslateFiles[0].consultantStatusCheck==true &&
              localStorage.getItem("role") == "Consultant")
            ) {
              dispatch(toggleCheckFilesCheckbox(true));
              dispatch(checkFileCount(1));
            } else {
              dispatch(toggleCheckFilesCheckbox(false));
              dispatch(saveCheckFilesName(backTranslateFiles[0].fileId));
            }

            setSelectedKeys([backTranslateFiles[0].key]);
            setCurrentSelected(backTranslateFiles[0].key);
            setCurrentChunk(backTranslateFiles[0]);
            setCurrentFileName(backTranslateFiles[0].fileName);
          }else {
            // localStorage.setItem(
            //   "checkPageMsg",
            //   "OBT Draft is required to complete"
            // );
            // await dispatch(clearCheckFilesName());
            dispatch(toggleCheckFilesCheckbox(false));
          }
        }
        //NEED TO Test and deleted this section
        else {
          let backTranslateEnabledFile = await allFiles.filter(
            (item) =>
              item.translatorStatusCheck == true &&
              item.communityStatusCheck == true &&
              item.isSelected == true
          );
          setList([...backTranslateEnabledFile]);
          if (backTranslateEnabledFile && backTranslateEnabledFile.length > 0) {

            setSelectedKeys([backTranslateEnabledFile[0].key]);
            setCurrentSelected(backTranslateEnabledFile[0].key);
            setCurrentChunk(backTranslateEnabledFile[0]);
            setCurrentFileName(backTranslateEnabledFile[0].fileName);
            setShowBtAudio(true);
            setIsAudioPresent(true);
          }else{
            setIsAudioPresent(false);
                        localStorage.getItem("role") == "Facilitator" ? setShowBtAudio(false):setShowBtAudio(true);
            dispatch(toggleCheckFilesCheckbox(false));
          }
        }
      }
      // MTT USER, while drafting
      else if (localStorage.getItem("role") == "MTT") {

        if (files && files.length > 0 ) {
          // files created by login user
          setList([...currentUserSelected, ...unSelectedFiles]);
          setIsAudioPresent(true);
          if (selectedFiles.length) {
            setSelectedKeys([selectedFiles[0].key]);
            setCurrentSelected(selectedFiles[0].key);

            setCurrentChunk(selectedFiles[0]);
            setCurrentFileName(selectedFiles[0].fileName);
          }
        } else if (files && files.length == 0 && allFiles && allFiles.length > 0) {
          // File has not been created by this user, But some other user created file
          setIsAudioPresent(true);
          setList([]);
        } else if (allFiles && allFiles.length == 0) {
          // File has not been created by any user
        }
      
      if (selectedFiles.length ) {
        setCurrentSelected(selectedFiles[0].key);
        setSelectedKeys([selectedFiles[0].key]);
        setCurrentChunk(selectedFiles[0]); 
        setCurrentFileName(selectedFiles[0].fileName);
        setIsAudioPresent(true)
      } else {
        // setCurrentSelected("");
        setSelectedKeys([]);
        setCurrentChunk({});
        setCurrentSelected("")
      }
    }
      // let data = await getProjectByRoleAndId(
      //   localStorage.getItem("role"),
      //   localStorage.getItem("id")
      // );
      // if (data.length) {
      //   setUserProject(data[0]);
      // }
      setIsTableLoading(false);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
    setIsTableLoading(false);
    setIsButtonDisabled(false);
  };

  const toggleComments = () => {
    if (isRecordingStatus == true) {
      showNotification("error", "Please stop recording");
    } else {
      setShowComments(!showComments);
    }
  };

  const toggleBackTranslate = (currentFileName,) => {
    if (isRecordingStatus == true) {
      showNotification("error", "Please stop recording");
    } else {
      setShowBtAudio(!showBtAudio);
      // localStorage.setItem("isBackTranslation","true")
    }
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "name",
      render: (value, record) => (
        <>
          <FilenameComponent file={record} />
        </>
      ),
    },
    {
      title: (
        <span className={`flex items-center  justify-end mr-4-5 w-full`}>Action</span>
      ),
      dataIndex: "",
      key: "x",
      render: (item, record) => (
        <div
          className={`flex items-center justify-end ${record.key == currentSelected ? "pointers-compuslory" : ""
            }`}
        >
          <span
            className={`pointers-compuslory ${!isTablet && !isSafari ?"w-full":""}   ${localStorage.getItem("id") != record.userId ? "" : "mx-2"
              }`}
          >
            <AudioPlayer
              revokeOtherFunctions={setIsButtonDisabled}
              data={record}
              audioType={fileType}
              isDisabled={isTableLoading}
              isReverse={false}
              reload={loadFiles}
            />
          </span>
          {localStorage.getItem("id") == record.userId && (record.isReviewed == true || record.comments.length<=0 )? (
            <span
              className={`pointers-compuslory  ${localStorage.getItem("id") != record.userId ? "" : ""
                }`}
            >
              <DeleteModalComponent
                isDisabled={
                  isTableLoading ||
                  isButtonDisabled ||
                  isPlayingStatus ||
                  isRecordingStatus
                }
                name={<MdDelete size={20} />}
                data={record}
                reload={loadFiles}
                selected={currentSelected ? true : false}
              />
            </span>
          ) : null}
          {currentSelected == record.key && (
            <>
            {
                (localStorage.getItem("role") !== "CIT" && localStorage.getItem("role") !== "Consultant") || (record.targetType !== "draft")  ?
               <Tooltip placement="topLeft" title="Comments">
                <Button
                  className={`ml-2 hover  ${showComments ? "ant-btn-foucs" : ""
                    }`}
                  disabled={
                    isTableLoading ||
                    isButtonDisabled ||
                    isPlayingStatus ||
                    isRecordingStatus
                  }
                  onClick={(e) => {
                    toggleComments();
                  }}
                >
                  <FaComment size={16} />
                </Button>
              </Tooltip> : null
            }
              

              {/* {(localStorage.getItem("role") == "Facilitator" ||
                localStorage.getItem("role") == "CIT" ||
                localStorage.getItem("role") == "Consultant") && (
                  <>
                    {isBackTranslationDisplay == true ? (
                      <>
                        <Tooltip placement="topLeft" title="Back Translation">
                          <Button
                            className={`ml-2 hover  ${showBtAudio ? "ant-btn-foucs" : ""
                              }`}
                            disabled={
                              isTableLoading ||
                              isButtonDisabled ||
                              isPlayingStatus ||
                              isRecordingStatus
                            }
                            onClick={(e) => {
                              toggleBackTranslate();
                            }}
                          >
                            <BsFillFileEarmarkPersonFill size={19} fill="true" />
                          </Button>
                        </Tooltip>
                      </>) : null}
                  </>
                )} */}

              {/* {(localStorage.getItem("role") == "Facilitator" ||
                localStorage.getItem("role") == "CIT" ||
                localStorage.getItem("role") == "Consultant") && (
                  <>
                    {isBackTranslationDisplay == true ? (
                      <button
                        className="ml-2 remove-btn-css"
                        disabled={
                          isTableLoading ||
                          isButtonDisabled ||
                          isPlayingStatus ||
                          isRecordingStatus
                        }
                      >
                        <BackTranslation
                          chunk={currentChunk}
                          draftFileName={currentFileName}
                          isButtonDisabled={isTableLoading || isButtonDisabled}
                          targetType={"draft"}
                          fileType="audio"
                          fileData={currentChunk}
                        />
                      </button>
                    ) : null}
                  </>
                )} */}
            </>
          )}
        </div>
      ),
    },
  ];

  const SelectFileModal = (selectedRowKeys, selectedRows) => {
    confirm({
      title: (
        <span className="  ">Are you sure you want to select this file?</span>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: "Confirm",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        try {
          setShowCommentIcon(true);
          setCurrentChunk(selectedRows[0]);
          setCurrentSelected(selectedRowKeys);
          setSelectedKeys(selectedRowKeys);
          // await updateFileSelection(selectedRows[0]);
          if (window.navigator.onLine == true) {
            // await selectDraftFileOnServer(`${file.fileName}`);
            //select drafted file
            await selectDraftedFileOnServer(`${selectedRows[0].fileId}`);
            // let data= await draftFileData.filter((item)=>selectedRows[0].fileId==item.fileId);
            // let filter={
            //   draftId:data[0].id,
            //   fileId:data[0].fileId,
            //   userId:data[0].userId,
            //   fileName:data[0].fileName,
            //   fileType:data[0].fileType,
            //   targetType:data[0].targetType,
            //   bookName:data[0].bookName,
            //   chapterName:data[0].chapterName,
            //   sourceVerseName:data[0].sourceVerseName,
            //   comments:[],
            //   isSelected:true,
            //   isReviewed:false,
            //   projectId:data[0].projectId,
            //   projectName:location.state?.project.projectName

            // }
            // await createToDoComment(filter)
          }
          // await updateChunkData(location.state.book);
          setCurrentFileName(`${selectedRows[0].fileName}`);
          await loadFiles();
          showNotification("success", "File selected successfully");
        } catch (error) {
          showNotification(
            "error",
            "File is not selected, Please try after some time"
          );
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const updateChunkData = async (book) => {
    let chunk = location.state.chunk;
    chunk.chunksDataProgress = "completed";
    let books = booksReducer.map((item) => {
      if (item.bookName == book.bookName) {
        let bookChapter = book.bookProgress.filter((item) => {
          item.pending -= 1;
          item.completed += 1;
        });
        let ChapterProgress = book.chaptersProgress.filter((item) => {
          if (item.chapters == location.state.chapter) {
            item.pending -= 1;
            item.completed += 1;
          }
        });
        let chapter = book.chapters.filter((item) => {
          if (item.chapterName == location.state.chapter) {
            item.chunks.map((element) => {
              if (element.chunkName === chunk.chunkName) {
                element = chunk;
              }
            });
          }
        });
        // await updateBooks(book.book_id, book);
      }
    });
  };

  const showAlertMessage = async (type, message) => {
    setAlertMessage({
      ...alertMessage,
      type,
      message,
    });
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 1500);
  };

  const myRowSelection = {
    type: "radio",
    selectedRowKeys: [currentSelected],
    onChange: async (selectedRowKeys, selectedRows) => {
      SelectFileModal(selectedRowKeys.toString(), selectedRows);
    },
  };

  return (
    <>
      <div className="flex items-center w-100 pb-1">
        <h4 className="mb-0 fsize" style={{ width: targetType == "draft"? "120px": targetType == "draft"?"176px":"200px" }}>{title}</h4>
        <span className="ml-2"><IoMdMicOff size={isTablet?16:26} /></span>
        {contextHolder}
        <>
          {isDisableRecord == true ? (
            ""
          ) : (
            <>
              <AudioRecorder
                isDisabled={isTableLoading}
                showMessage={showAlertMessage}
                recordingType={fileType}
                chunk={chunk}
                record_type={record_type}
                // project={userProject}
                project={location.state.project}
                relatedDraft={relatedDraft}
                reload={loadFiles}
                resultantNumber={resultantNumber}
                setParentLoading={setIsTableLoading}
                targetType={targetType}
              />
            </>
          )}
        </>
      </div>

      <div>
        {showAlert && (
          <Alert
            message={alertMessage.message}
            type={alertMessage.type}
            showIcon
            className="w-86 my-1"
            closable
          />
        )}
      </div>

      {isLoading == true ? (
        <div className="text-center my-2 py-4  flex items-center justify-center">
          <SmallSpinnerComponent />
          <div className="loaderText mx-2">Loading</div>
        </div>
      ) : (
        <>
          {list.length >= 0 && isAudioPresent == true ? (
            <div className="source">
              <ConfigProvider
                renderEmpty={() => (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      localStorage.getItem("isBackTranslation") == "true"
                        ? "No Data" // Display "No Data" when targetType is "backTranslate" and isShowComment is true
                        : "File has been created by other user" // Display "File has been created by other user" for other cases
                    }
                  />
                )}
              >

                <Table
                  loading={{
                    spinning: isTableLoading,
                    indicator: (
                      <span>
                        <SmallSpinnerComponent />

                      </span>
                    ),
                  }}
                  pagination={ role=="CIT" || role=="Consultant" ? false:{
                    pageSize: 3,
                    disabled:isPlayingStatus == true || isRecordingStatus == true ? true:false,
                  }}
                  columns={columns}
                  dataSource={list}
                  rowSelection={{
                    type: "radio",
                    ...myRowSelection,
                  }}
                  rowClassName={(record, index) => {
                    if (currentSelected && currentSelected !== record.key) {
                      return "disabled-row";
                    }
                    return "pointers-compuslory ";
                  }}
                />
              </ConfigProvider>
            </div>
          ) : (
            <>
              <div className="source mx-3">
                {((localStorage.getItem("role") == "Facilitator" && targetType !== "backTranslate") ||
                  localStorage.getItem("role") == "MTT" ||
                  ((localStorage.getItem("role") == "Consultant" || localStorage.getItem("role") == "CIT") && targetType !== "backTranslate")) ? (
                  <>

                    <ConfigProvider
                      renderEmpty={() => (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            (localStorage.getItem("role") == "Facilitator") &&
                              targetType == "draft"
                              ? "To make back translation, Cross check and Communtiy check should be completed"
                              : "No Data"
                          }
                        />
                      )}
                    >
                      <Table
                        loading={{
                          spinning: isTableLoading,
                          indicator: (
                            <span>

                              <SmallSpinnerComponent />
                            </span>
                          ),
                        }}
                        pagination={ role=="CIT" || role=="Consultant" ? false:{
                          pageSize: 3,
                          disabled:isPlayingStatus == true || isRecordingStatus == true ? true:false,
                        }}
                        columns={columns}
                        dataSource={list}
                        rowSelection={{
                          type: "radio",
                          ...myRowSelection,
                        }}
                        rowClassName={(record, index) => {
                          if (currentSelected && currentSelected !== record.key) {
                            return "disabled-row";
                          }
                          return "pointers-compuslory ant-table-row-selected";
                        }}
                      />


                    </ConfigProvider>
                  </>
                ) : null}
              </div>

            </>
          )}
        </>)}

      <div className="pb-2">
        {showComments && <CommentsComponent chunk={currentChunk} />}
      </div>

      <div className="pb-2">
        {showBtAudio &&
          <>
            <BackTranslation
              chunk={currentChunk}
              draftFileName={currentFileName}
              isButtonDisabled={isTableLoading || isButtonDisabled}
              targetType={"draft"}
              fileType="audio"
              fileData={currentChunk}
              record_type="backtranslate_audio"
              showType={currentChunk.fileType}
            />
          </>
        }
      </div>
    </>
  );
};

export default DraftAudioComponent;
