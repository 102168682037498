import { Button, Modal, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { AiFillPlayCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import { s3_video_url } from "../../../services/apis";
import { showToastNotification } from "../../../services/config";
import { unitArrayToBlob } from "../../../services/conversion.service";

const VideoPlayer = ({ url, data, isDisabled, fileType }) => {
  const [isShow, setIsShow] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const isPlayingStatus = useSelector((state) => state.playingStatusReducer);
  const isRecordingStatus = useSelector(
    (state) => state.recordingStatusReducer
  );
  useEffect(() => {
    if (data) {
      playVideo();
    } else {
      setVideoUrl(url);
    }
  }, []);

  const closeModal = () => {
    setIsShow(false);
    let videoPlayer = document.getElementById("video-player-modal");
    videoPlayer.pause();
    videoPlayer.currentTime = 0;
  };

  const playVideo = async () => {
    return new Promise(async (resolve, reject) => {
     
      if (window.navigator.onLine == true) {
        let awsUrl = `${s3_video_url}/${data.fileId}/${data.fileName}`;
          setVideoUrl(awsUrl);
          resolve();
     
    }else{
      let localData = await window.api.readFile("readFile", data);
      let content = await unitArrayToBlob(localData, data.fileType);
      setVideoUrl(content);
      resolve();
    }
       
      
    });
  };

  const openModal = () => {
    if (localStorage.getItem("isPlaying") == "true") {
      showToastNotification("Please pause current playing audio ", 2);
    } else {
      setIsShow(true);
    }
  };
  return (
    <>
      <Tooltip title="Play">
        <Button
          onClick={openModal}
          disabled={isDisabled || isRecordingStatus || isPlayingStatus}
        >
          <AiFillPlayCircle size={20} />
        </Button>
      </Tooltip>
      <Modal
        title="Video Player"
        visible={isShow}
        width={900}
        onCancel={closeModal}
        footer={null}
      >
        <video
          className="w-100"
          id="video-player-modal"
          src={videoUrl}
          controls
          disablePictureInPicture
        />
      </Modal>
    </>
  );
};
export default VideoPlayer;
