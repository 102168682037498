import React, { useEffect, useState } from "react";
import ChunkSideNavbar from "./chunk.sidenavbar";
import { sortBooks, sortChapters } from "../../../services/config";

const ChapterSidenavbar = ({ showData, data, book, type }) => {
  const [chapterData, setChapterData] = useState([]);
  const [bookData, setBookData] = useState([]);
  useEffect(() => {
    sortChap();
  }, []);

  const sortChap = async () => {
    if (type == "book") {
      let datas = await sortChapters(data)
      
      setChapterData(datas);
    } else {
      let datas = await sortBooks(data);
      setBookData(datas);
    }
  };
  return (
    <div>
      {showData && type == "book" && (
        <div className="px-2  capitalize">
          {chapterData &&
            chapterData.map((chp) => (
              <div>
                <ChunkSideNavbar chapterData={chp} book={book} type={type} />
              </div>
            ))}
        </div>
      )}
      {showData && type == "project" && (
        <div className="px-2  capitalize">
          {bookData &&
            bookData.map((book) => (
              <div key={`${book}_${type}`}>
                {/* Chapter List */}
                <ChunkSideNavbar chapterData={book} book={book} type={type} />
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default ChapterSidenavbar;
