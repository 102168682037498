
import React from "react";


import CitConsultant from "./citcon.page";
import TechCord from "./techCor.page";

const ProjectPage = () => {

  return (
    <>
    {
    localStorage.getItem('role')=="CIT" || localStorage.getItem('role') == "Consultant" ? <CitConsultant /> :<TechCord />
    }
    </>
    
  );
};

export default ProjectPage;
