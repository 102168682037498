import React, { useEffect, useRef } from "react";
import { IoMdMic } from "react-icons/io";
import { bufferToBase64 } from "../../services/conversion.service";
import { speech_api } from "../../services/apis";
import axios from "axios";
import { randomHEXID } from "../../services/config";
import { useDispatch } from "react-redux";
import { toggleVoiceRecognition } from "../../redux/actions/util.action";

const SpeechRecognition = ({
  chunks,
  selectChunk,
  setIsRecognising,
  setMessages,
  setIsBotTyping,
  showNotification,
  scrollToBottom,
  parentChunk,
}) => {
  let audioChunks = [];
  const dispatch = useDispatch();
  const mediaRecorder = useRef();
  const streamRef = useRef();

  useEffect(() => {
    dispatch(toggleVoiceRecognition(true));
    startListening();
    setTimeout(() => {
      stopRecording();
    }, 4 * 1000);
    return () => {
      dispatch(toggleVoiceRecognition(false));
    };
  }, []);

  const startListening = async () => {
    navigator.getUserMedia =
      (await navigator.getUserMedia) ||
      (await navigator.webkitGetUserMedia) ||
      (await navigator.mozGetUserMedia);

    await navigator.getUserMedia(
      {
        audio: true,
      },
      async (stream) => {
        const audio = document.getElementById("audio-file");
        audio.srcObject = await stream;
        mediaRecorder.current = new MediaRecorder(stream);
        mediaRecorder.current.ondataavailable = async function (e) {
          await audioChunks.push(e.data);
        };
        await mediaRecorder.current.start(10000);

        audio.onloadedmetadata = async (e) => {
          await audio.play();
        };
        streamRef.current = await stream;

        mediaRecorder.current.onstop = async function () {
          const blob = await new Blob(audioChunks, {
            type: "audio/mp3",
          });
          await fetchChunk(blob);
          audioChunks = [];
        };
      },
      (err) => {
        showNotification("error", "Device not found");
        console.error(`The following error occurred: ${err}`);
        setIsRecognising(false);
        setIsBotTyping(false);
        scrollToBottom();
      }
    );
  };

  const fetchChunk = (blob) => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsBotTyping(true);

        let base64Data = await bufferToBase64(blob);
        let uniquer = await randomHEXID();

        let res = await axios.post(`${speech_api.index}`, {
          fileName: `${uniquer}.mp3`,
          base64: base64Data,
        });

        if (res.data.text) {
          setMessages((prev) => [
            ...prev,
            ...[
              { sender: "USER", content: res.data.text, type: "USER_MESSAGE" },
            ],
          ]);

          let chunkList;
          let chunkData;
          // user has spoke language

          if (
            localStorage.getItem("role") == "MTT" ||
            localStorage.getItem("role") == "Facilitaor"
          ) {
            chunkList = chunks.filter(
              (item) =>
                item.language.toLowerCase() == res.data?.text?.toLowerCase() ||
                item.language == res.data?.text
            );

            chunkData = await chunks.filter(
              (item) =>
                item?.verse.toLowerCase() == res.data?.text.toLowerCase() ||
                item?.verse == res.data?.text
            );
          } else {
            chunkList = parentChunk.filter(
              (item) =>
                item.language.toLowerCase() == res.data?.text.toLowerCase() ||
                item.language == res.data?.text ||
                item.language == res.data?.text.trim()
            );

            chunkData = await parentChunk.filter(
              (item) =>
                item?.verse.toLowerCase() == res.data?.text.toLowerCase() ||
                item?.verse == res.data?.text ||
                item?.verse == res.data?.text.trim()
            );
          }
          if (chunkList.length > 0) {
            setMessages((prev) => [
              ...prev,
              ...[
                { sender: "BOT", content: chunkList, type: "CHATBOT_CHUNKS" },
              ],
            ]);
          }

          if (chunkData.length > 0) {
            selectChunk(chunkData[0], false);
          }

          if (chunkList.length == 0 && chunkData.length == 0) {
            setMessages((prev) => [
              ...prev,
              ...[
                {
                  sender: "BOT",
                  content: "Shalom! What can I do?",
                  type: "BOT_MESSAGE",
                },
              ],
            ]);
          }
        } else {
          setMessages((prev) => [
            ...prev,
            ...[
              {
                sender: "BOT",
                content: "Speech not recognized",
                type: "BOT_MESSAGE",
              },
            ],
          ]);
        }
        scrollToBottom();
        setIsBotTyping(false);
      } catch (error) {
        scrollToBottom();
        console.log("ERROR ", error);
      }
    });
  };

  const stopRecording = async (e) => {
    setIsRecognising(false);
    setIsBotTyping(false);
    dispatch(toggleVoiceRecognition(false));

    if (streamRef.current) {
      streamRef.current.getTracks().forEach(function (track) {
        track.stop();
      });
    }
    if (mediaRecorder.current) {
      await mediaRecorder.current.stop();
    }
  };

  return (
    <>
      <div
        className="flex items-center justify-center text-center pt-4 h-100"
        style={{ height: "100%" }}
      >
        <div class="speech-reco">
          <IoMdMic className="z-50" color="black" fontSize={"8rem"} />
        </div>
      </div>
      <div className=" text-center text-black pt-4">
        <span>Speak, We are listening</span>
        <p></p>
      </div>

      <audio controls id="audio-file" hidden="true" muted />
    </>
  );
};

export default SpeechRecognition;
