import axios from "axios";
import { commentApi } from "../apis"; 
import getHeader from "../config";

const postCommentToServer = async (id, comments) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await axios.post(
        `${commentApi.savedComment}/${id}`,
        { comments },
        getHeader()
      );
      resolve(res.data); 
    } catch (error) {
      reject(error);
    }
  });
};

const deleteCommentFromServer = async (fileId, comment) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await axios.post(
        `${commentApi.deletedComment}/${fileId} `,
        comment,
        getHeader()
      );
      resolve(res.data); 
    } catch (error) {
      reject(error);
    }
  });
};

const updateCommentToServer = async (fileId, comments) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await axios.post(
        `${commentApi.updatedComment}/${fileId}`,
        { comments} ,
        getHeader()
      );
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

const updateToDoCommentToServer= async(comment)=>{
  return new Promise(async (resolve,reject)=>{
    try {
      let res = await axios.post(
        `${commentApi.updateToDoCommentToServer}`,
        { comment },
        getHeader()
      );
    } catch (error) {
      console.log("error",error);
      reject(error);
    }
  })
}

const createToDoComment= async(comment)=>{
  return new Promise(async (resolve,reject)=>{
    try {
      let res = await axios.post(
        `${commentApi.createToDoComment}`,
        comment,
        getHeader()
      );
    } catch (error) {
      console.log("error",error);
      reject(error);
    }
  })
}

const getToDoCommentFromServer = async(userId)=>{
  return new Promise(async (resolve,reject)=>{
    try {
      let res = await axios.post(
        `${commentApi.getToDoCommentFromServer}`,
        {userId:userId},
        getHeader()
      );
      resolve(res.data)
    } catch (error) {
      console.log("error",error);
      reject(error);
    }
  })
}

const checkToDoReviewComment = async (data)=>{
  return new Promise(async (resolve,reject)=>{
    try {
      let res = await axios.post(
        `${commentApi.checkToDoReviewComment}`,
        data,
        getHeader()
      );
      resolve(res.data)
    } catch (error) {
      console.log("error",error);
      reject(error);
    }
  })
}

export { postCommentToServer, deleteCommentFromServer, updateCommentToServer, updateToDoCommentToServer, createToDoComment,getToDoCommentFromServer,checkToDoReviewComment };
