import React, { useEffect, useState } from "react";

const StaticWaveform = ({ lines }) => {
  const [numBars, setNumBars] = useState(100);

  const updateNumBars = () => {
    const availableWidth = window.innerWidth - 40; // Adjust as needed
    const maxBars = Math.floor(availableWidth / 10); // Adjust as needed
    setNumBars(maxBars);
  };

  useEffect(() => {
    updateNumBars();
    window.addEventListener("resize", updateNumBars);
    return () => {
      window.removeEventListener("resize", updateNumBars);
    };
  }, []);
  return (
    <div>
      <div className="waveform mx-2">
        <div className="sound-wave"> 
          {[...Array(lines)].map((x, i) => (
            <div className="static-bar" key={i}></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StaticWaveform;