import React, { useEffect, useRef, useState } from "react";
import { Button, notification, Input } from "antd";
// import {
//   getCommentById,
//   updateIndexedDBComment,
// } from "../../services/indexed-db/comments.indexeddb";
import { updateCommentToServer } from "../../services/api-calls/comments.api";
import SmallSpinnerComponent from "../spinner/small-spinner.component";
import "../draft-page-component/draft-script/script.scss"

// import { updateCommentToSync } from "../../services/indexed-db/sync.indexeddb";
// import {  getDraftFileByFileID } from "../../services/indexed-db/draftfiles.indexeddb";
const { TextArea } = Input;

const EditComment = ({ data, cancel, fileId, fileName, reload, file }) => {
  const [isLoader, setIsLoader] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [pageLoader, setPageLoader] = useState(true);

  const rowsCount = useRef(5);

  useEffect(() => {
    loadCommentText();
  }, [data, fileId, fileName]);

  const loadCommentText = () => {
    return new Promise(async (resolve, reject) => {
      try {
        setPageLoader(true);
        let filteredData = data.comments.split("\n");
        rowsCount.current = filteredData.length;
        setCommentText(data.comments);
        setPageLoader(false);
        resolve();
      } catch (error) {
        setPageLoader(false);
        resolve();
      }
    });
  };

  const [notificationApi, contentJSX] = notification.useNotification();
  const showNotification = (type, message) => {
    notification.destroy();
    notificationApi[type]({
      message,
    });
  };

  const submitHandler = async (e) => {
    try {
      e.preventDefault();
      setIsLoader(true);
      if (commentText.trim().length <= 0) {
        showNotification("error", "Please enter text");
      } else {
        // first update comment in local db
        // await updateIndexedDBComment(fileId, data.fileId, commentText);

        // fetch latested comment after update
        // let current_comment = await getCommentById(file.fileId, data.fileId);
        await updateCommentToServer(fileId, {
          _id: data._id,
          fileId:data.fileId,
          comments: commentText,
        });
        showNotification("success", "Comment updated successfully");        
        await reload("Comment updated successfully");
        cancel(false);
        // checking the internet connection, if online sent requets to database
        // if (window.navigator.onLine == true) {
        //   console.log("commentText_+)_)",commentText);
        //   await updateCommentToServer(fileId, {
        //     _id: data._id,
        //     comments: commentText,
        //   });
        // } else {
          // let current_file = await getDraftFileByFileName(file.name);

          // //Get Draft File
          // let current_file = await getDraftFileByFileID(file.fileId);

          // // file is already is synced with database or saved in main db , then update that comment
          // if (current_file._id && current_comment._id) {
          //   await updateCommentToSync(`${fileName}`,`${fileId}`, {
          //     type: "COMMENT_UPDATE",
          //     comment: { ...data, ...{ comments: commentText } },
          //   });
          // } else {
          //   // if comment is not present or saved in main db
          //   await updateCommentToSync(`${fileName}`,`${fileId}`, {
          //     type: "COMMENT_SAVE",
          //     comment: { ...data, ...{ comments: commentText } },
          //   });
          // }
          // // update sync collection
        // }
      }
    } catch (error) {
      showNotification("error", "Something went wrong");
      reload();
    }
    setIsLoader(false);
  };

  return (
    <div className="pt-2">
      {contentJSX}
      {pageLoader == true ? (
        <SmallSpinnerComponent />
      ) : (
        <form onSubmit={submitHandler}>
          <TextArea
            value={commentText}
            onChange={(e) => {
              setCommentText(e.target.value);
            }}
            className="  "
            rows={rowsCount.current}
            style={{ minHeight: "30px",color:"black" }}
          />
          <div className="pt-1 script">
            <Button
              htmlType="button"
              className={`submit-btn ${isLoader == true ? "bg-secondary" : ""}`}
              style={{ background: "#F9002B", color: "white" }}
              disabled={isLoader}
              onClick={(e) => {
                cancel(false);
              }}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              className="submit-btn mx-2"
              style={{ background: "#031832", color: "white" }}
              loading={isLoader}
            >
              Update
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditComment;
