import React from "react";

const SelectLanguageJSX = ({ selectLanguage, languages }) => {
  return (
    <>
      <div className="text-black message-content clearfix">
        <div className="message bot-message float-left">
          <div className="d-block w-full">
            <div>
              {languages.length > 0 ? (
                <>
                  <p> Please select language</p>
                  {languages.map((item) => (
                    <div className="mt-2 mb-4">
                      <span
                        onClick={(e) => selectLanguage(item)}
                        className="border px-2 border-dark rounded py-1 cursor-pointer capitalize"
                      >
                        {item}
                      </span>
                    </div>
                  ))}
                </>
              ) : (
                <p>Resource not availabe</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectLanguageJSX;
