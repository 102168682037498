import React from "react";
import { MdSignalWifiStatusbarConnectedNoInternet4 } from "react-icons/md";

const NoInternetConnection = () => {
  return (
    <>
      <span className="flex items-center justify-center">
        <MdSignalWifiStatusbarConnectedNoInternet4 size={400} />
      </span>
      <span className="flex items-center justify-center text-lg">
        Internet connection not available
      </span>
    </>
  );
};

export default NoInternetConnection;
