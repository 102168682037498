import React, { useState } from "react";
import { Alert } from "antd";

const NotificationComponent = ({ content, type }) => {
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
  };
  return (
    <div className="py-2">
      <Alert
        message={content}
        type={type}
        showIcon
        closable
        afterClose={handleClose}
      />
    </div>
  );
};

export default NotificationComponent;
