import axios from "axios";
import { Buffer } from "buffer";

const bufferToBase64 = async (data) => {
  return new Promise(async (resolve, reject) => {
    var reader = new window.FileReader();
    reader.readAsDataURL(data);
    reader.onloadend = function () {
      resolve(reader.result);
    };
  });
};

const bufferFromURL = async (url) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(url, {
        decompress: false,
        responseType: "arraybuffer",
      });
      resolve(response.data);
    } catch (error) {
      console.error("bufferFromURl", error);
    }
  });
};

const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

const b64toBlob = (b64Data, contentType, sliceSize = 512) => {
  return new Promise(async (resolve, reject) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = await new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = await new Blob(byteArrays, { type: contentType });
    resolve(blob);
  });
};

const convertsecsToTimeFormat = (secs) => {
  return new Promise(async (resolve, reject) => {
    var sec_num = parseInt(secs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;
    let time = [hours, minutes, seconds]
      .map((v) => (v < 10 ? "0" + v : v))
      .filter((v, i) => v !== "00" || i > 0)
      .join(":");
    resolve(time);
  });
};

const unitArrayToBlob = (array, fileType) => {
  return new Promise(async (resolve, reject) => {
    try {
      const uint8Array = new Uint8Array(array);
      const buffer = Buffer.from(uint8Array);
      const blob = new Blob([buffer], {
        type: fileType == "audio" ? "audio/mp3" : "video/mp4",
      });
      const url = URL.createObjectURL(blob);
      resolve(url);
    } catch (error) {
      resolve("");
      console.log(error);
    }
  });
};

const convertBlobToBase64 = (blob) => new Promise((resolve, reject) => {
  try {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  } catch (error) {
    console.log(error);
  }
});

export {
  bufferFromURL,
  bufferToBase64,
  getBase64FromUrl,
  b64toBlob,
  convertsecsToTimeFormat,
  unitArrayToBlob,
  convertBlobToBase64
};
