import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { extractNumberFromString, getChunkName } from "../../services/config";
import SmallSpinnerComponent from "../spinner/small-spinner.component";

const FilenameComponent = ({ file }) => {
  const [fileName, setFileName] = useState("");
  const [fileId, setFileId] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadFileName();
  }, [file]);

  const loadFileName = async () => {
    try {
      setIsLoading(true);
      let data = await getChunkName(
        file.languageName || file.language,
        file.bookName,
        file.chapterName || file.chapter
      );
      let headingName = await extractNumberFromString(file.sourceVerseName);
      setFileName(data + " " + headingName);
      setFileId(file.fileId);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading == true ? (
        <SmallSpinnerComponent />
      ) : (
        <>
          <div className="">
            {fileName}
            <div className="text-italic text-xs">
              {new Date(Number(fileId)).toLocaleString()}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FilenameComponent;
