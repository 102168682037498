import React, { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import ChapterSidenavbar from "./chapter.sidenavbar";

const HoverNavSidebar = ({ data,type }) => {
 

  const [dataShown,setDataShown] = useState({name:""})
  const [showChapter, setShowChapter] = useState(false);
  const [showBook, setShowBook] = useState(false);

  useEffect(() => {
    if(type=="book"){
      setDataShown({name:data.bookName})
    }
    else if(type=="project"){
      setDataShown({name:data.projectName})
    }
  }, []);
  const toggle = () =>{
    if(type=="book"){
      localStorage.setItem("selectedBook",JSON.stringify({bookName:dataShown.name }))
      setShowChapter(!showChapter)
    }
    else{
      localStorage.setItem("selectedProject",JSON.stringify({projectName:dataShown.name }))
      setShowBook(true)
    }
  }
  
  return (
    <div className="text-md capitalize " key={data.book_id}>
      <span
        onClick={(e) => toggle()}
        className="cursor-pointer"
      >
        {dataShown.name} <FiChevronDown />
      </span>
      {showChapter && (
        <ChapterSidenavbar
          showData={showChapter}
          data={data.chapters}
          book={data}
          type={"book"}
        />
      )}
      {showBook && (
        <ChapterSidenavbar
        showData={showBook}
        data={data.bookData}
        book = {data.bookData}
        type={"project"}
      />
      )}
      <hr />
    </div>
  );
};

export default HoverNavSidebar;
