import { Alert, Button, Popover, Select, Table, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { BsPlayCircle } from "react-icons/bs";
import { RiArrowRightSFill, RiDraftLine } from "react-icons/ri";
import { GoChecklist } from "react-icons/go";
import { HiOutlineUserGroup } from "react-icons/hi";
import { BsTranslate, BsPersonCheck } from "react-icons/bs";
import { IoRecordingOutline } from "react-icons/io5";
import {
  MdPictureAsPdf,
  MdOutlineFactCheck,
  MdOutlinePublish,
} from "react-icons/md";

import { useNavigate } from "react-router";
import MainLayout from "../../components/layout/main.layout";
import SpinnerComponent from "../../components/spinner/spinner.component";
import TitleBar from "../../components/ui/title-bar/title-bar.component";
import {
  extractNumberFromString,
  getChunkName,
  sortChapters,
  sortChunks,
} from "../../services/config";

// import { getBooksByUserRole } from "../../services/indexed-db/books.indexeddb";
// import {
//   getProjectById,
//   getProjectByRoleAndId,
// } from "../../services/indexed-db/project.indexeddb";
import ProgressPopOver from "../../components/modal/progress-modal/index";
import SelectChunk from "../../components/draft-page-component/select-chunk";
import { generatePdf } from "../../services/generate-pdf";
import { base_url, projects, desktop } from "../../services/apis";
import getHeader from "../../services/config";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { saveBooksAndProjects } from "../../services/BooksAndProjects";
import { storeBooks } from "../../redux/actions/books.action";
import { storeProjects } from "../../redux/actions/project.action";
import GoogleTranslation from "../../components/modal/google-translation/google-translation";

const DraftPage = () => {
  const dispatch = useDispatch();

  const projectsReducer = useSelector((state) => state.projectsReducer);
  const booksReducer = useSelector((state) => state.booksReducer);

  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(true);
  const [project, setProject] = useState();
  const [books, setBooks] = useState([]);
  const [currentBook, setCurrentBook] = useState({});
  const [currentChapter, setCurrentChapter] = useState([]);
  const [currentBookName, setCurrentBookName] = useState("");
  const [currentChapterName, setCurrentChapterName] = useState("");
  const [currentChunks, setCurrentChunks] = useState([]);
  const [selectedOption, setSelectedOption] = useState("BOOKS");
  const [placeHolder, setPlaceHolder] = useState("Please Select a Book");
  const [role, setRole] = useState(localStorage.getItem("role"));
  const prevSelectedBook = JSON.parse(localStorage.getItem("selectedBook"));
  const [showNotification, setShowNotification] = useState(false);
  const [oldTestamentBooks, setOldTestamentBooks] = useState([]);
  const [newTestamentBooks, setNewTestamentBooks] = useState([]);
  const [activeKey, setActiveKey] = useState("1");
  const [showDraftPlayingOption, setShowDraftPlayingOption] = useState(false);
  const [isChunkSelection, setIsChunkSelection] = useState(false);
  const [type, setType] = useState();

  useEffect(() => {
    if (role == "MTT") {
      setActiveKey("1");
    } else if (role == "Facilitator") {
      setActiveKey("3");
    }
    fetchUserData();
  }, []);

  // const saveBooksAndProjects = async () => {
  //   return new Promise(async (resolve, reject) => {
  //     // try {
  //       const res = await loadUserBookAndProject();
  //       console.log("loadUserBookAndProject",res);

  //       await dispatch(storeProjects([res.data[0].project]));
  //       await dispatch(storeBooks(res.data[0].books));

  //       await fetchUserData();
  //     //   const userBooks = [];
  //     //   const userProjects = [];
  //     //   const role = localStorage.getItem("role");

  //     //   for (let i = 0; i < res.data.length; i++) {
  //     //     await saveProjectsToIndexedDB(res.data[i].project);
  //     //     userProjects.push(res.data[i].project);

  //     //     const books =
  //     //       role === "Technician" ||
  //     //       role === "Coordinator" ||
  //     //       role === "Translation Manager"
  //     //         ? res.data[i].book
  //     //         : res.data[i].books;

  //     //     for (let j = 0; j < books.length; j++) {
  //     //       books[
  //     //         j
  //     //       ].book_id = `${books[j].bookName} ${books[j].targetLanguage}`;
  //     //       await saveBooksData(books[j]);
  //     //     }

  //     //     dispatch(storeBooks(books));
  //     //     userBooks.push(...books);
  //     //   }
  //     //   resolve("Book Saved");
  //     //   if (navigator.onLine) {
  //     //     saveSourceFilesToDB(userBooks);
  //     //     const draftFileStatus = await loadSavedDraftedFiles(
  //     //       userProjects,
  //     //       userBooks
  //     //     );

  //     //     if (draftFileStatus) {
  //     //       loadCommentAndSaveOffline();
  //     //     }
  //     //   }

  //     //   return "Books Saved";
  //     // } catch (error) {
  //     //   console.log(error);
  //     //   localStorage.setItem("loggedIn", "true");
  //     //   dispatch(firstLoadExecuted(true));
  //     //   clearTimeout(timeout2.current);
  //     //   setIsLoader(false);
  //     //   clearInterval(interval.current);
  //     //   throw error;
  //     // }
  //   });
  // };

  // const loadUserBookAndProject = () => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       let url =
  //         localStorage.getItem("role") == "Technician" ||
  //         localStorage.getItem("role") == "Coordinator" ||
  //         localStorage.getItem("role") == "Translation Manager"
  //           ? `${base_url}${desktop.dt}${desktop.getTecnicianBookList}`
  //           : `${base_url}${desktop.dt}${desktop.getBooksDetails}`;

  //       let body = {
  //         role: localStorage.getItem("role"),
  //         userID: {
  //           id: localStorage.getItem("id"),
  //         },
  //       };
  //       let res = await axios.post(url, body, getHeader());
  //       resolve(res);
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // };

  const getBooksByUserRole = (books) => {
    return new Promise(async (resolve, reject) => {
      let booksData = [];
      if (
        role == "technician" ||
        role == "coordinator" ||
        role == "translation manager"
      ) {
        if (books.result && books.result.length > 0) {
          for (let i = 0; i < books.result.length; i++) {
            booksData.push(books.result[i]);
          }
        }
      } else {
        if (books.result && books.result.length > 0) {
          for (let i = 0; i < books.result.length; i++) {
            for (let j = 0; j < books.result[i][role].length; j++) {
              if (books.result[i][role][j]._id == localStorage.getItem("id")) {
                booksData.push(books.result[i]);
              }
            }
          }
        }
      }

      let userData = [...new Set(booksData)];
      resolve(userData);
    });
  };

  const addBookProgress = async(bookData, progressData) => {
    // console.log("BookProceess: ",bookData, progressData)
    for (const progress of progressData) {

      const matchingBook = bookData.find((book) => book.bookName == progress.bookName);
      if (matchingBook) {
        matchingBook.bookProgress = [{
          total: progress.total,
          completed: progress.completed,
          review: progress.review,
          pending: progress.pending,
        }];
      }
    }
    return bookData;
  }

  const addChunks = async(chapters, chapterProgress) => {
    // console.log("BookProceess: ",bookData, progressData)
    for (const progress of chapterProgress) {
      console.log(progress)
      const matchingBook = chapters.find((chapter) => chapter.chapterName == progress.chapterName);
      if (matchingBook) {
        matchingBook.chaptersProgress = [{
          total: progress.chaptersProgress[0].total,
          completed: progress.chaptersProgress[0].completed,
          review: progress.chaptersProgress[0].review,
          pending: progress.chaptersProgress[0].pending,
        }];
      }
    }
    return chapters;
  }

  const fetchUserData = async () => {
    setisLoading(true);
    try {
      let userId = localStorage.getItem("id");
      let books = booksReducer;
      setProject(projectsReducer[0]);
      // if (books.length <= 0 || projectsReducer.length <= 0) {
      //   let data = await saveBooksAndProjects();
      //   setProject(data.projectsData[0]);
      //   books = data.booksData;
      //   await dispatch(storeProjects(data.projectsData));
      //   await dispatch(storeBooks(data.booksData));

      //   // const obj={role:localStorage.getItem("role"),id:userId};
      //   // const projectUrl= `${base_url}${projects.project}${projects.projectsByUserId}`
      //   // let resp = await axios.post(projectUrl,obj,getHeader());
      //   // console.log("resp",resp);
      //   // setProject(resp.data[0])

      //   // const bookUrl= `${base_url}${desktop.dt}${desktop.getBooksDetails}`;
      //   // let res = await axios.post(bookUrl,{role:localStorage.getItem("role"),userID: {
      //   //   id: userId,
      //   // },},getHeader());
      //   // books=res.data[0].books
      // }
      let bookProgress;
      let data = await saveBooksAndProjects();
      if (projectsReducer.length <= 0 || booksReducer.length <= 0) {
        setProject(data.projectsData[0]);
        // bookProgress = await axios.post(`${base_url}${desktop.dt}${desktop.getAllBookProgressWeb}`,{project:data.projectsData[0],role:role})
        data.projectsData.length > 0
          ? data.projectsData.forEach(async (item) => {
              await dispatch(storeProjects([item]));
            })
          : await dispatch(storeProjects([data.projectsData]));
        books = data.booksData;
        data.booksData.length > 0
          ? data.booksData.forEach(async (item) => {
              await dispatch(storeBooks([item]));
            })
          : await dispatch(storeBooks([data.booksData]));
      }
    
  //  let chapterProgress = await axios.post(`${base_url}${desktop.dt}${desktop.getAllProgressNew}`,)
      // let userRole = role == "MTT" ? "MTT" : role.toLocaleLowerCase();
      let oldTestament = await books.filter(
        (item) => item.testamentType == "old"
      );
      setOldTestamentBooks(oldTestament);
      let newTestament = await books.filter(
        (item) => item.testamentType == "new"
      );
      setNewTestamentBooks(newTestament);
      bookProgress = await axios.post(`${base_url}${desktop.dt}${desktop.getAllBookProgressWeb}`,{project:data.projectsData[0],role:role})
      books= await addBookProgress(books,bookProgress.data.books)
      await setBooks(books);
      if (books != 0) {
        if (
          prevSelectedBook == null ||
          prevSelectedBook == undefined ||
          !Object.keys(prevSelectedBook).length
        ) {
          setCurrentBookName(books[0].bookName);
          books[0].chapters = await sortChapters(books[0].chapters);
          setCurrentChunks(books[0]?.chapters[0]?.chunks[0]);
          setCurrentBook(books[0]);
          setisLoading(false);
        } else {
          let lastSelectedBook = books.filter(
            (item) => item.bookName == prevSelectedBook.bookName
          );
          let chapter = lastSelectedBook[0].chapters;
          chapter = chapter.filter(
            (item) => item.chapterName == prevSelectedBook.chapterName
          );

          let chunkData = await sortChunks(chapter[0].chunks);
          if (lastSelectedBook.length >= 1) {
            lastSelectedBook[0].chapters = await sortChapters(
              lastSelectedBook[0].chapters
            );
            setCurrentBook(lastSelectedBook[0]);
            setCurrentBookName(
              lastSelectedBook[0].bookName +
                " " +
                prevSelectedBook.chapterName.split("chapter")[1]
            );
            setCurrentChapter(prevSelectedBook.chapterName);
            setCurrentChapterName(prevSelectedBook.chapterName);
            // setCurrentChunks(chunkData);
            await selectChunk(chapter[0],data.projectsData[0],lastSelectedBook[0])
            // setSelectedOption("CHUNKS");
            setActiveKey(prevSelectedBook.tab);
            // setisLoading(false);
            // setCurrentChunks(lastSelectedBook[0]?.chapters[0]?.chunks[0]);
          } else {
            books[0].chapters = await sortChapters(books[0].chapters);
            setCurrentBook(books[0]);
            setCurrentBookName(books[0].bookName);
            setCurrentChunks(books[0]?.chapters[0]?.chunks[0]);
            setisLoading(false);
          }
        }
      }
    } catch (error) {
      console.log("Fetch Data ", error);
      setisLoading(false);
    }
    console.log("isload stopped");
    // setisLoading(false);
  };

  // const getbookProgress = async () => {
  //   // let fileType = getFileType
  // };

  // change current book
  const changeCurrentBook = async (value) => {
    setisLoading(true)
    setCurrentChapter([]);
    setCurrentChapterName("");
    let compareAbleValue = typeof value == "string" ? value : value.bookName;
    let availableBook = books.filter(
      (book) => book.bookName == compareAbleValue
    );
    if (availableBook.length) {
      // availableBook[0].chapters = await sortChapters(availableBook[0].chapters);

      // setCurrentBook(availableBook[0]);
      setCurrentBookName(availableBook[0].bookName);
      const chapterNames = availableBook[0].chapters.map((chapter) => chapter.chapterName);
      let body = {
        bookName:availableBook[0].bookName,
        project:project,
        chapters:chapterNames,
        role
      }
      let chapterProgress = await axios.post(`${base_url}${desktop.dt}${desktop.getAllProgressWeb}`,body)
      
      availableBook[0].chapters = await addChunks(availableBook[0].chapters,chapterProgress.data)
      // availableBook[0].chapters = chapterProgress.data;
      availableBook[0].chapters = await sortChapters(availableBook[0].chapters);
      setCurrentBook(availableBook[0]);
      setSelectedOption("CHAPTERS");
      setisLoading(false)
      
    }
    setCurrentChunks([]);
  };

  const toggleChunkSelections = (type) => {
    if (currentChapter.length <= 0) {
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 1500);
    } else {
      changeSelectedOption("CHUNKS");
      type && setType(type);
      setShowDraftPlayingOption(false);
      setIsChunkSelection(!isChunkSelection);
    }
  };

  // Books JSX, grouping in testament type namely OLD , New and NO testament
  const BooksDataJSX = () => {
    return (
      <>
        <div className="mx-2 pt-3">
          {books.length ? (
            <>
              {selectedOption == "BOOKS" && books.length ? (
                <>
                  <Tabs
                    defaultActiveKey="1"
                    type="card"
                    className="pt-2"
                    moreIcon={<RiArrowRightSFill />}
                    size="middle"
                  >
                    <Tabs.TabPane
                      key="Old Testament"
                      tab={<span>Old Testament</span>}
                    >
                      <div className="mt-1">
                        {oldTestamentBooks.length ? (
                          oldTestamentBooks.map((book) => {
                            return (
                              <>
                                {book.testamentType == "old" && (
                                  <ProgressPopOver
                                  role={role}
                                    name={book.bookName}
                                    bookName={book.bookName}
                                    type={"book"}
                                    data={book}
                                    progress={book.bookProgress}
                                    changeCurrentBook={changeCurrentBook}
                                    key={`${book.bookName}-${book.testamentType}`}
                                  />
                                )}
                              </>
                            );
                          })
                        ) : (
                          <span className="mx-2 px-2">
                            Books not assigned to this Testament
                          </span>
                        )}
                      </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      key="New Testament"
                      tab={<span>New Testament</span>}
                    >
                      <div className="mt-1">
                        {newTestamentBooks.length ? (
                          newTestamentBooks.map((book) => {
                            return (
                              <>
                                {book.testamentType == "new" && (
                                  <ProgressPopOver
                                  role={role}
                                    name={book.bookName}
                                    bookName={book.bookName}
                                    type={"book"}
                                    data={book}
                                    progress={book.bookProgress}
                                    changeCurrentBook={changeCurrentBook}
                                    key={`${book.bookName}-${book.testamentType}`}
                                  />
                                )}
                              </>
                            );
                          })
                        ) : (
                          <span className="mx-2 px-2">
                            Books not assigned to this Testament
                          </span>
                        )}
                      </div>
                    </Tabs.TabPane>
                  </Tabs>
                </>
              ) : (
                <h6 className="flex items-center justify-center py-32"> </h6>
              )}
              {selectedOption == "CHAPTERS" && (
                <div className="">
                  <div className="row">
                    <div className="col-md-12">
                      {Object.keys(currentBook).length ? (
                        currentBook.chapters.map((chap) => (
                          <>
                            <ProgressPopOver
                            role={role}
                              name={chap.chapterName}
                              bookName={currentBook.bookName}
                              type={"chapter"}
                              data={chap}
                              progress={chap.chaptersProgress[0]}
                              selectChunk={selectChunk}
                            />
                            <MdPictureAsPdf
                              size="20"
                              onClick={async (e) => {
                                await generatePdf({
                                  ...{ currentChapter: chap.chapterName },
                                  project: projectsReducer[0],
                                  ...currentBook,
                                });
                              }}
                            />
                            {localStorage.getItem("role") == "Facilitator"  && activeKey == 4  ? (
                              <GoogleTranslation
                                files={{
                                  ...{ currentChapter: chap.chapterName },
                                  project: projectsReducer[0],
                                  ...currentBook,
                                }}
                              />
                            ) : null}
                          </>
                        ))
                      ) : (
                        <>
                          <h6 className="flex items-center justify-center mt-6   ">
                            Please select book
                          </h6>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {selectedOption == "CHUNKS" && (
                <div className="mx-2 capitalize">
                  {currentChunks.length > 0 ? (
                    <>
                      <form>
                        {currentChunks.map((chunk) => {
                          return (
                            <>
                              <Button
                                className="mr-2 my-2 mr-2"
                                shape="round"
                                onClick={(e) => {
                                  redirectToDraftDetails(chunk);
                                }}
                                style={{
                                  borderColor:
                                    chunk.chunksDataProgress == "completed"
                                      ? "#3CB043"
                                      : chunk.chunksDataProgress == "pending"
                                      ? "#C8102E"
                                      : "#FFBF00",
                                  color:
                                    chunk.chunksDataProgress == "completed"
                                      ? "#3CB043"
                                      : chunk.chunksDataProgress == "pending"
                                      ? "#C8102E"
                                      : "#FFBF00",
                                }}
                              >
                                {chunk.chunkName}
                              </Button>
                            </>
                          );
                        })}
                      </form>
                    </>
                  ) : (
                    <>
                      <h6 className="flex items-center justify-center mt-6   ">
                        Please select chapter
                      </h6>
                    </>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              <h6 className="flex items-center justify-center mt-6   ">
                Please add book
              </h6>
            </>
          )}
        </div>
      </>
    );
  };

  //Book selection JSX
  let BookSelectJSX = () => {
    return (
      <Select
        style={{
          width: "100%",
        }}
        value={currentBookName ? currentBookName : "Please add book"}
        placeholder={placeHolder}
        onChange={changeCurrentBook}
      >
        {books.map((book) => (
          <Select.Option
            value={book.bookName}
            key={book.bookName}
            className="capitalize"
          >
            {book.bookName}
          </Select.Option>
        ))}
      </Select>
    );
  };

  let SelectBookTableJSX = () => {
    return (
      <>
        {Object.keys(currentBook).length !== 0 ? (
          <Table
            columns={[
              {
                title: "S.No. ",
                dataIndex: "sno",
              },
              {
                title: "Chapter Name",
                dataIndex: "name",
              },
            ]}
            dataSource={[
              { value: "chapter1" },
              { value: "chapter2" },
              { value: "chapter3" },
              { value: "chapter4" },
              { value: "chapter5" },
              { value: "chapter6" },
            ]}
            bordered
            pagination={false}
            title={() => currentBook?.bookName}
          />
        ) : null}
      </>
    );
  };

  let changeSelectedOption = (value) => {
    setSelectedOption(value);
  };

  let selectChunk = async (data,projectData,books) => {
    setisLoading(true)
    let bookName = currentBookName;
    setCurrentBookName(bookName + data.chapterName.split("chapter")[1]);
    let str = JSON.stringify({
      bookName,
      chapterName: data.chapterName,
      tab: activeKey,
    });

    let body = {
      bookName:currentBook.bookName || books.bookName,
      project:project||projectData,
      chapterName:data.chapterName,
      role
    }

    localStorage.setItem("selectedBook", str);
    let chunkProgress = await axios.post(`${base_url}${desktop.dt}${desktop.getAllChunkProgressWeb}`,body)
    data.chunks = chunkProgress.data
    let chunkData = await sortChunks(data.chunks);

    setCurrentChapter(data.chapterName);
    setCurrentChapterName(data.chapterName);
    setCurrentChunks(chunkData);
    setSelectedOption("CHUNKS");
    setisLoading(false)
   
  };

  let redirectToDraftDetails = async (data) => {
    setisLoading(true);
    let bookName = JSON.parse(localStorage.getItem("selectedBook"));
    let str = JSON.stringify({
      bookName: bookName.bookName,
      chapterName: bookName.chapterName,
      tab: activeKey,
    });
    localStorage.setItem("selectedBook", str);

    let headingName = await extractNumberFromString(data.chunkName);
    let chunkName = await getChunkName(
      project.language,
      data.chunk[0].bookName,
      data.chunk[0].chapterName
    );

    if (activeKey == 2 || activeKey == 3) {
      navigate("/cross-check", {
        state: {
          chunkName: chunkName,
          chunk: data,
          book: currentBook,
          verse: data.chunkName,
          heading: `${chunkName} ${headingName}`,
          chapter: data.chunk[0].chapterName,
          project: project,
          targetType:
            activeKey == 2 ? "draft" : activeKey == 3 ? "backTranslate" : "",
        },
      });
    } else if (activeKey == 7) {
      navigate("/master-recording", {
        state: {
          chunkName: chunkName,
          chunk: data,
          book: currentBook,
          verse: data.chunkName,
          heading: `${chunkName} ${headingName}`,
          project: project,
          chapter: data.chunk[0].chapterName,
          targetType:
            activeKey == 2 ? "draft" : activeKey == 3 ? "backTranslate" : "",
        },
      });
    } else {
      navigate("/draft-details", {
        state: {
          chunkName: chunkName,
          chunk: data,
          book: currentBook,
          verse: data.chunkName,
          heading: `${chunkName} ${headingName}`,
          chapter: data.chunk[0].chapterName,
          project: project,
        },
      });
    }
  };

  let redirectToAudioPage = async (type) => {
    if (currentChapter.length <= 0) {
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 1500);
    } else {
      reload();
      // let project = await getProjectById(currentBook.projectId);
      if (project.projectType.signLanguage == true) {
        navigate("/video-player", {
          state: {
            book: currentBook,
            chapter: currentChapter,
            project,
          },
        });
      } else if (type == "view") {
        navigate("/script-view", {
          state: {
            book: currentBook,
            chapter: currentChapterName,
            type: "PLAY-ALL",
            project,
            fileType: type,
          },
        });
      } else {
        navigate("/audio-playlist", {
          state: {
            book: currentBook,
            chapter: currentChapterName,
            type: "PLAY-ALL",
            project,
            fileType: type,
          },
        });
      }
    }
  };

  const BookChapterChunkTabsPane = () => {
    return (
      <>
        <div className="flex items-center justify-evenly ">
          <span
            className={`cursor-pointer option  ${
              selectedOption == "BOOKS" ? "border-b-2" : ""
            }`}
            onClick={(e) => {
              changeSelectedOption("BOOKS");
            }}
          >
            Books
          </span>
          <span
            className={`cursor-pointer option  ${
              selectedOption == "CHAPTERS" ? "border-b-2" : ""
            }`}
            onClick={(e) => {
              changeSelectedOption("CHAPTERS");
            }}
          >
            Chapters
          </span>
          <p className="flex items-center">
            <span
              className={`cursor-pointer option flex mx-2 ${
                selectedOption == "CHUNKS" ? "border-b-2" : ""
              }`}
              onClick={(e) => {
                changeSelectedOption("CHUNKS");
              }}
            >
              Chunks
            </span>
            {project?.projectType?.audio == true ||
            project?.projectType?.signLanguage == true ? (
              <>
                {activeKey == 1 || activeKey == 4 ? (
                  <>
                    <>
                      <Popover
                        content={
                          <>
                            <div
                              className="border-bottom py-2 cursor-pointer"
                              onClick={() => redirectToAudioPage("audio")}
                            >
                              <span>Play all</span>
                            </div>
                            <div
                              className="border-bottom py-2 cursor-pointer"
                              onClick={() => toggleChunkSelections("audio")}
                            >
                              <span>Select to play</span>
                            </div>
                          </>
                        }
                        trigger="hover"
                        placement="bottom"
                      >
                        <BsPlayCircle />
                      </Popover>
                      {project.projectType.text == true && (
                        <Popover
                          content={
                            <>
                              <div
                                className="border-bottom px-2 py-2 cursor-pointer"
                                onClick={() => redirectToAudioPage("view")}
                              >
                                <span>View all</span>
                              </div>
                              <div
                                className="border-bottom py-2 cursor-pointer"
                                onClick={() => toggleChunkSelections("view")}
                              >
                                <span>Select to view</span>
                              </div>
                            </>
                          }
                          trigger="hover"
                          placement="bottom"
                        >
                          <FaEye className="ml-2" />
                        </Popover>
                      )}
                    </>
                  </>
                ) : null}
              </>
            ) : (
              <>
                <Popover
                  content={
                    <>
                      <div
                        className="border-bottom py-2 cursor-pointer"
                        // onClick={() => redirectToAudioPage("view")}
                      >
                        <span>View all</span>
                      </div>
                      <div
                        className="border-bottom py-2 cursor-pointer"
                        onClick={() => toggleChunkSelections("view")}
                      >
                        <span>Select to view</span>
                      </div>
                    </>
                  }
                  trigger="hover"
                  placement="bottom"
                >
                  <FaEye />
                </Popover>
              </>
            )}
          </p>
        </div>
        {showNotification && (
          <Alert
            message="Please select chapter"
            type="error"
            closable={true}
            className="my-2"
          />
        )}
      </>
    );
  };

  const changeActiveKey = (e) => {
    setActiveKey(e);
  };

  const reload = () => {
    setIsChunkSelection(false);
    setisLoading(true);
  };

  return (
    <MainLayout>
      {isLoading ? (
        <SpinnerComponent />
      ) : (
        <div className="draft-details">
          <div className="draft-details-wrapper min-height h-85">
            <TitleBar title={"Draft Files"} page={"draft"} />

            <div className="pt-1">
              <BookSelectJSX />
              <div className="tabpanel">
                <Tabs
                  type="card"
                  className="pt-2"
                  moreIcon={<RiArrowRightSFill />}
                  size="middle"
                  activeKey={activeKey}
                  onChange={changeActiveKey}
                >
                  {/* MTT */}
                  <>
                    <>
                      <Tabs.TabPane
                        disabled={role == "MTT" ? false : true}
                        key="1"
                        tab={
                          <span>
                            <RiDraftLine
                              style={{ margin: "0px 5px", fontSize: "large" }}
                            />
                            Draft
                          </span>
                        }
                      >
                        <BookChapterChunkTabsPane />
                        <BooksDataJSX />
                      </Tabs.TabPane>
                    </>
                    <Tabs.TabPane
                      tab={
                        <span>
                          <GoChecklist
                            style={{ margin: "0px 5px", fontSize: "large" }}
                          />
                          Cross Check
                        </span>
                      }
                      key="2"
                      disabled={role == "MTT" ? false : true}
                    >
                      <BookChapterChunkTabsPane />
                      <BooksDataJSX />
                    </Tabs.TabPane>
                  </>
                  {/* Facilitator */}
                  <>
                    <Tabs.TabPane
                      tab={
                        <span>
                          <HiOutlineUserGroup
                            style={{ margin: "0px 5px", fontSize: "large" }}
                          />
                          Community Check
                        </span>
                      }
                      key="3"
                      disabled={role == "Facilitator" ? false : true}
                    >
                      <div className="bookSelection w-100 mt-2 mb-2 p-2">
                        <BookChapterChunkTabsPane />
                        <BooksDataJSX />
                      </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={
                        <span>
                          <BsTranslate
                            style={{ margin: "0px 5px", fontSize: "large" }}
                          />
                          Back Translation
                        </span>
                      }
                      key="4"
                      disabled={role == "Facilitator" ? false : true}
                    >
                      <div className="seacrh w-100 mt-2 mb-1 p-2">
                        <BookChapterChunkTabsPane />
                      </div>
                      <BooksDataJSX />
                    </Tabs.TabPane>
                  </>
                  {/* CIT */}
                  <>
                    <Tabs.TabPane
                      tab={
                        <span>
                          <MdOutlineFactCheck
                            style={{ margin: "0px 5px", fontSize: "large" }}
                          />
                          CIT Check
                        </span>
                      }
                      key="5"
                      disabled={role == "CIT" ? false : true}
                    >
                      <div className="seacrh w-100 mt-2 mb-1 p-2">
                        <BookSelectJSX />
                      </div>
                      <div className="bookSelection w-100 mt-2 mb-2 p-2">
                        <SelectBookTableJSX />
                      </div>
                    </Tabs.TabPane>
                  </>

                  {/* Consultant Check */}
                  <>
                    <Tabs.TabPane
                      tab={
                        <span>
                          <BsPersonCheck
                            style={{ margin: "0px 5px", fontSize: "large" }}
                          />
                          Consultant Check
                        </span>
                      }
                      key="6"
                      disabled={role == "CONSULATANT" ? false : true}
                    >
                      <div className="seacrh w-100 mt-2 mb-1 p-2">
                        <BookSelectJSX />
                      </div>
                      <div className="bookSelection w-100 mt-2 mb-2 p-2">
                        <SelectBookTableJSX />
                      </div>
                    </Tabs.TabPane>
                  </>
                  {/* TECHNICIAN */}
                  <>
                    <Tabs.TabPane
                      tab={
                        <span>
                          <IoRecordingOutline
                            style={{ margin: "0px 5px", fontSize: "large" }}
                          />
                          Master Recording
                        </span>
                      }
                      key="7"
                      disabled={role == "MTT" ? false : true}
                    >
                      <BookChapterChunkTabsPane />
                      <BooksDataJSX />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={
                        <span>
                          <MdOutlinePublish
                            style={{ margin: "0px 5px", fontSize: "large" }}
                          />
                          Publish
                        </span>
                      }
                      key="8"
                      disabled={role == "TECHNICIAN" ? false : true}
                    >
                      <div className="seacrh w-100 mt-2 mb-1 p-2">
                        <BookSelectJSX />
                      </div>
                      <div className="bookSelection w-100 mt-2 mb-2 p-2">
                        <SelectBookTableJSX />
                      </div>
                    </Tabs.TabPane>
                  </>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      )}

      {isChunkSelection == true ? (
        <SelectChunk
          updateState={setIsChunkSelection}
          chunks={currentChunks}
          fileType={type}
          currentBookName={currentBookName}
          currentChapterName={currentChapterName}
          book={currentBook}
          reload={reload}
          project={project}
        />
      ) : null}
    </MainLayout>
  );
};

export default DraftPage;
