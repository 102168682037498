import React from "react";
import "./sync-bar.scss";

const SyncBar = () => {
  return (
    <>
      <div className="sync-bar">
        <span className="bar--progress py-2 text-white"></span>
        <div id="sync-text"></div>
      </div>
    </>
  );
};

export default SyncBar;
