import { Button, Empty, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { FaComment, FaCommentSlash } from "react-icons/fa";
import { MdDescription } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import CommentsComponent from "../../components/comments/comments.component";
import DraftScriptModal from "../../components/modal/draft-scirpt-modal";
import SmallSpinnerComponent from "../../components/spinner/small-spinner.component";
import {
  clearCheckFilesName,
  saveCheckFilesName,
  toggleCheckFilesCheckbox,
} from "../../redux/actions/check-files.action";
// import { extractNumberFromString } from "../../services/config";
// import {
//   getDraftFileByBookName,
// } from "../../services/indexed-db/draftfiles.indexeddb";
import {getDraftedFilesByBookNameOnline} from "../../services/api-calls/draft-files.api"
import FilenameComponent from "../../components/ui/filename.component";

const CrossCheckScript = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [commentData, setCommentData] = useState({});
  const [commnetObjId, setCommnetObjId] = useState("");
  const [resultantNumber, setResultantNumber] = useState("");
  const isPlayingStatus = useSelector((state) => state.playingStatusReducer);
  const isRecordingStatus = useSelector((state) => state.recordingStatusReducer);

  useEffect(() => {
    loadFiles();

    return () => {
      dispatch(clearCheckFilesName());
      dispatch(toggleCheckFilesCheckbox(false));
      localStorage.removeItem("checkPageMsg");
    };
  }, [location]);

  const loadFiles = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
        // let resultantNumberData = await extractNumberFromString(
        //   `${location.state?.verse}`
        // );
        // setResultantNumber(resultantNumberData);
        let name = await `${location.state?.verse}`;
        // let allFiles = await getDraftFileByBookName(
        //   location.state.book.bookName
        // );

        let draftData =  {
          projectId:location.state?.book.projectId,
          bookName: location.state.book.bookName,
          chapterName: location.state.chapter,
          sourceVerseName : name,
        };

        let allFiles = await getDraftedFilesByBookNameOnline(draftData);
        // let allFiles = await [...draftData.data];

        if (localStorage.getItem("role") == "Facilitator") {
          let crossCheckedFiles = allFiles.filter(
            (item) =>
              item.isSelected == true &&
              item.sourceVerseName==name &&
              item.chapterName== location.state.chapter &&
              // item.formattedName == name &&
              item.targetType == "draft" &&
              item.userId !== localStorage.getItem("id") &&
              item.fileType == "script" &&
              item.translatorStatusCheck == true
          );

          setList(crossCheckedFiles);

          if (crossCheckedFiles.length > 0) {
            if (
              crossCheckedFiles[0].communityStatusCheck == true &&
              localStorage.getItem("role") == "Facilitator"
            ) {
              dispatch(toggleCheckFilesCheckbox(true));
            } else {
              toggleCheckFilesCheckbox(false)
              dispatch(saveCheckFilesName(crossCheckedFiles[0].fileId));
            }
          } else {
            localStorage.setItem(
              "checkPageMsg",
              "Script Draft is required to complete"
            );
          }
        } else {
          let selectedFiles = await allFiles.filter(
            (item) =>
              item.isSelected == true &&
              item.sourceVerseName==name &&
              item.chapterName== location.state.chapter &&
              // item.formattedName == name &&
              item.targetType == "draft" &&
              item.userId !== localStorage.getItem("id") &&
              item.fileType == "script"
          );

          setList(selectedFiles);

          if (selectedFiles.length > 0) {
            if (
              selectedFiles[0].translatorStatusCheck == true &&
              localStorage.getItem("role") == "MTT"
            ) {
              dispatch(toggleCheckFilesCheckbox(true));
            } else {
              toggleCheckFilesCheckbox(false)
              dispatch(saveCheckFilesName(selectedFiles[0].fileId));
            }
          } else {
            localStorage.setItem(
              "checkPageMsg",
              "Script Draft is required to complete"
            );
          }
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
      setIsLoading(false);
    });
  };

  const toggleComments = (data, id) => {
    // setShowComments(!showComments);
    // setCommentData(data);
    // setCommnetObjId(id);

    if (showComments == true && commnetObjId == id) {
      setShowComments(false);
    } else if (showComments == true) {
      setCommentData(data);
      setCommnetObjId(id);
    } else {
      setShowComments(true);
      setCommentData(data);
      setCommnetObjId(id);
    }
  };

  return (
    <>
      <div className="">
        <h4 className="px-2">
          Script Draft
          <span className="px-2">
            <MdDescription size={26} />
          </span>
        </h4>

        <Table
          className="pb-2"
          pagination={false}
          dataSource={list}
          locale={{
            emptyText: (
              <span>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    <p>
                      {localStorage.getItem("role") == "MTT"
                        ? "File is not created"
                        : "Either, File is not created or not cross checked"}
                    </p>
                  }
                />
              </span>
            ),
          }}
          loading={{
            spinning: isLoading,
            indicator: (
              <span>
                <SmallSpinnerComponent />
              </span>
            ),
          }}
          columns={[
            {
              title: "File Name",
              dataIndex: "name",
              render: (value, record) => (
                <>
                  {/* <p>
                    {location.state?.chunkName} {resultantNumber}
                  </p>
                  <p className="text-italic text-xs">
                    {new Date(Number(record.fileId)).toLocaleString()}
                  </p> */}
                  <p className="flex items-center">
                   <FilenameComponent file = {record} />
                  </p>
                </>
              ),
            },
            {
              title: (
                <span className={`flex items-center  justify-end mr-4-5`}>
                  Action
                </span>
              ),
              dataIndex: "",
              key: "x",
              render: (item, record) => (
                <div className={`flex items-center justify-end mr-10`}>
                  <DraftScriptModal
                    data={record}
                    isDisabled={
                      isLoading || isRecordingStatus || isPlayingStatus
                    }
                  />
                  <>
                    <Tooltip placement="topLeft" title="Comment">
                      <Button
                        className="ml-2"
                        disabled={isPlayingStatus}
                        onClick={(e) => {
                          toggleComments(record, record.fileName);
                        }}
                      >
                        {showComments == true &&
                        commnetObjId == record.fileName ? (
                          <FaCommentSlash size={16} />
                        ) : (
                          <FaComment size={16} />
                        )}
                      </Button>
                    </Tooltip>
                  </>
                </div>
              ),
            },
          ]}
        />
      </div>

      <div className="pb-2">
        {showComments && <CommentsComponent chunk={commentData} />}
      </div>
    </>
  );
};

export default CrossCheckScript;
