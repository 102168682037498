import React, { useEffect, useState } from "react";
import MainLayout from "../../components/layout/main.layout";
import NoInternetConnection from "../../components/no-internet";
import SpinnerComponent from "../../components/spinner/spinner.component";
import TitleBar from "../../components/ui/title-bar/title-bar.component";
import { Button, Checkbox, Modal, Table, notification } from "antd";
import CommentsComponent from "../../components/comments/comments.component";
import { useDispatch, useSelector } from "react-redux";
import { storeProjects } from "../../redux/actions/project.action";
import { storeBooks } from "../../redux/actions/books.action";
import { saveBooksAndProjects } from "../../services/BooksAndProjects";
import { getDraftedFilesByChunksOnline } from "../../services/api-calls/draft-files.api";
import { checkToDoReviewComment, getToDoCommentFromServer } from "../../services/api-calls/comments.api";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "../to-do/to-do.scss"
const { confirm } = Modal;

// import FilenameComponent from "../../components/ui/filename.component";
// import { resolve } from "path";
// import { saveBooksAndProjects } from "../../services/BooksAndProjects";
const ToDoPage = () => {
  const [isInternet, setIsInternet] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chunk, setChunk] = useState([]);
  const [chunkDetails, setChunkDetails] = useState();
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const projectsReducer = useSelector((state) => state.projectsReducer);
  const booksReducer = useSelector((state) => state.booksReducer);
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const showNotification = (type, message) => {
    notification.destroy();
    api[type]({
      message,
      placement: "top",
    });
  };
 
  useEffect(() => {
    fetchData();
  }, [navigator.onLine]);

  const showModal = (record) => {
    setIsModalOpen(true);
    setChunkDetails(record)
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const fetchData = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        await getToDoComment();
        if (projectsReducer.length <= 0 || booksReducer.length <= 0) {
          let data = await saveBooksAndProjects();
          data.projectsData.length > 0 ? data.projectsData.forEach(async (item) => {
            await dispatch(storeProjects([item]));
          }) : await dispatch(storeProjects([data.projectsData]));

          data.booksData.length > 0 ? data.booksData.forEach(async (item) => {
            // console.log("booksData",item);
            await dispatch(storeBooks([item]));
          }) : await dispatch(storeBooks([data.booksData]));
          // setIsLoader(false);
        } else {
          // setIsLoader(false);
        }
      } catch (error) {
        console.log("THE ERROR", error);
      }
    })
  }

  const getToDoComment = async () => {
    let response = await getToDoCommentFromServer(localStorage.getItem('id'));
    let data = await response.sort((x, y) => y.fileId - x.fileId)
    console.log("Data",data);
    setChunk(data);
    setIsLoading(false)
  }

  // const getDraftedFiles = (projectId) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       let files;
  //       let draftData = {
  //         projectId:projectId,
  //         isSelected:true,
  //         userId:localStorage.getItem('id'),
  //         fileType:"audio"
  //       };
  //       files = await getDraftedFilesByChunksOnline(draftData);
  //       console.log("files===",files);
  //       // files = await [...resp.data];

  //       // if (files.length) {
  //       //   for (let i = 0; i < files.length; i++) {
  //       //     let fileName = await files[i].fileName.split(".")[0];
  //       //     files[i].name = fileName;
  //       //     files[i].formattedName = `${location.state?.heading}`;
  //       //     files[i].projectType = location.state?.project.projectType;
  //       //     files[i].key = files[i].fileId;
  //       //   }
  //       // }
  //       resolve(files);
  //     } catch (error) {
  //       console.log("ERROR", error);
  //     }
  //   });
  // };

  // const checkToDoReview = async (record) => {
  //   let filter = {
  //     _id: record._id,
  //     draftId: record.draftId
  //   }
  //   // let data = await checkToDoReviewComment(filter);
  // }

  const showDeleteConfirm = (record) => {
    confirm({
      title: (
        <p className="">
          Are you sure you want to review this file?
        </p>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: "Confirm",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        try {
          let filter = {
            _id: record._id,
            draftId: record.draftId
          }
          setIsLoading(true)
          let data = await checkToDoReviewComment(filter);
          if (data.msg == "Comment updated successfully") {
            let index = await chunk.findIndex((item) => item._id == record._id);
            chunk[index].isReviewed = true
            setIsLoading(false)
            showNotification("success", "Comment updated successfully");
          } else {
            setIsLoading(false)
            showNotification("error", "something went wrong")
          }
        } catch (error) {
          showNotification("error", error.response.data.msg);
          console.log(error);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const columns = [

    {
      title: "Project Name",
      dataIndex: "projectName",
    },
    {
      title: "Book Name",
      dataIndex: "bookName",
    },
    {
      title: "Chapter Name",
      dataIndex: "chapterName",
    },
    {
      title: "Chunk Name",
      dataIndex: "sourceVerseName",
    },
    {
      title: "File Type",
      dataIndex: "fileType",
      render: (value, record) => (
        <>
          {record.targetType=="backTranslate" || record.targetType=="masterRecord" ? `${record.fileType}${record.relatedDraft.split('.')[1]=="txt" ? "(SPT)" :"(DFT)"}`: record.fileType}
        </>
      ),
    },
    {
      title: "Target Type",
      dataIndex: "targetType",
      render: (value, record) => (
        <>
          {record.targetType=="draft" ? "DFT" : record.targetType=="backTranslate" ? "BT": "MasterRecord"}
        </>
      ),
    },
    {
      title: "Created At",
      dataIndex: "",
      render: (value, record) => (
        <>
          {new Date(Number(record.fileId)).toLocaleDateString('en-GB')}
        </>
      ),
    },
    {
      title: "View Comment",
      dataIndex: "",
      render: (value, record) => (
        <>
          <Button
            style={{ background: "#031832", color: "white" }}
            className="submit-btn  mx-2"
            onMouseOver={(e) => { e.target.style.color = "white"; }}
            onClick={() => showModal(record)}
          >view
          </Button>
        </>
      ),
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   render: (value, record) => (
    //     <>
    //       <Button
    //         style={{ background: "#031832", color: "white" }}
    //         className="submit-btn  mx-2"
    //         onMouseOver={(e) => { e.target.style.color = "white"; }}
    //       >Action
    //       </Button>
    //     </>
    //   ),
    // },
    {
      title: "Review",
      dataIndex: "",
      render: (value, record) => (
        <>
          <Checkbox
            checked={record.isReviewed}
            disabled={record.isReviewed}
            className={`${record.isReviewed == true ? "crossCheck" : ""}`}
            onClick={() => showDeleteConfirm(record)}>
          </Checkbox>
        </>
      ),
    }
  ]

  return (
    <div className="todopagecss">{contextHolder}
      <MainLayout>

        <div className="draft-details ">
          <div className="draft-details-wrapper h-screen">
            <TitleBar title={"To Do"} page={""} />
            {isLoading == true ? (
              <SpinnerComponent />
            ) : (
              <>
                <Table
                  columns={columns}
                  dataSource={chunk}
                  className="capitalize"
                  bordered
                  pagination={{
                    simple:true,
                    pageSize: 5,
                    current: currentPage, // Set the current page
                    onChange: (page) => setCurrentPage(page),
                  }}
                />

                <Modal
                  // onOk={handleOk}
                  onCancel={handleCancel}
                  visible={isModalOpen}
                  width={900}
                  footer={null}
                >
                  <CommentsComponent chunk={chunkDetails} />
                </Modal>
              </>
            )}
          </div>
        </div>

      </MainLayout>
    </div>
  );
};

export default ToDoPage;
