import React, { Component, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router';
import bannerImage from "../../assets/images/Image.png";
import headerImage from "../../assets/images/rhema.png"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Home = () => {
    const [setuplink, setSetUpLink] = useState('')
    const [currentOS, setCurrentOS] = useState('')
    const [privacyPolicyWeb, setPrivacyPolicyWeb] = useState('https://docs.google.com/document/d/1GoUv5OnIwo6xle3GHCq9e89ge37reywingDIhALKick/edit?usp=sharing')
    const navigate = useNavigate();

    useEffect(() => {
        getOsandSetuplink();
    }, []);

    const getOSType = () => {
        const userAgent = navigator.userAgent;
        if (userAgent.includes('Windows')) {
            return 'Windows';
        } else if (userAgent.includes('Macintosh')) {
            return 'MacOS';
        } else if (userAgent.includes('Linux')) {
            return 'Linux';
        } else if (userAgent.includes('Android')) {
            return 'Android';
        } else if (userAgent.includes('iOS')) {
            return 'iOS';
        } else {
            return 'Unknown';
        }
    };

    const getOsandSetuplink = () => {
        const currentOS = getOSType();
        setCurrentOS(currentOS === 'MacOS' || currentOS === 'Windows' ? currentOS : null);
        let macSetuplink = 'https://rhema-installer.s3.us-east-2.amazonaws.com/win/RhemaApp.msi';
        let winSetuplink = 'https://rhema-installer.s3.us-east-2.amazonaws.com/win/RhemaApp.msi';
        console.log('currentOS 1', currentOS);
        setSetUpLink(currentOS === 'MacOS' ? macSetuplink : winSetuplink);
    };

    const handleDownload = () => {
        const tempLink = document.createElement('a');
        tempLink.href = setuplink;
        tempLink.setAttribute('download', '');
        tempLink.click();
    };

    const sendsignin = () => {
        navigate('/signin')
        // toast("Page is under Development!", { position: "top-right" });
    }

    return (
        <div style={{ height: '90vh' }}>
            <div onClick={() => sendsignin()} className='flex items-end justify-end mar-60 cursor-pointer p-0 mt-[-20px]'>
                <img className='signinlogo ' src={require('../../assets/images/rhema_logo.png')} />
                <p style={{ fontSize: '20px' }} className='flex justify-center items-center mt-4'>Sign In</p>
            </div>

            <div className='wrapper' >

                <div className='content'>

                    <div className='container-fluid'>

                        <div className='middlecontainer'>
                            <div >
                                <div className='applogocontainer' >
                                    <img style={{ width: '100%' }} src={headerImage} />
                                </div>
                                <div className='text-center mobtabbtn'>
                                    <button className='downloadbtn' onClick={handleDownload}>{`Download ${currentOS ? currentOS : 'the'} App`}</button>
                                </div>
                            </div>
                            <div className='sysimgcontainer'>
                                <img className='syslogo' src={bannerImage} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='copyrighttext'>
                    <p style={{ color: '#0a1a44' }}>{`©Copyright ${new Date().getFullYear()}. Marisree LLC. All Rights Reserved`}</p>
                </div>
                <div className="footer">

                    <div className='flex item'>
                        <div className='flex cursor-pointer text-decoration-underline'>
                            <a href={privacyPolicyWeb} target="_blank" rel="noopener noreferrer" style={{ color: '#0a1a44' }}>
                                Privacy Policy
                            </a>
                        </div>
                    </div>
                    <div className='mobcopyrighttext'>
                        <p style={{ color: '#0a1a44' }}>{`©Copyright ${new Date().getFullYear()}. Marisree LLC. All Rights Reserved`}</p>
                    </div>
                    <div className='item'>
                        <div className='flex mobcenter'>
                            <div className='flex'>
                                <div>
                                    <FontAwesomeIcon className='iconsize' icon={faApple} />
                                </div>
                                <div className='mal-2 mobdis'>
                                    <p>Get it on the </p>
                                    <h5>App Store</h5>
                                </div>
                            </div>
                            <div className='flex ml-4'>
                                <div >

                                    <img className='footimgsize' src={require('../../assets/images/playstore.png')} />
                                </div>
                                <div className='mal-2 mobdis'>
                                    <p>Get it on the </p>
                                    <h5>Google Play</h5>
                                </div>
                            </div>
                            <div className='flex ml-4'>
                                <div >
                                    <img className='footimgsize' src={require('../../assets/images/microsoft.png')} />
                                </div>
                                <div className='mal-2 mobdis'>
                                    <p>Get it on the </p>
                                    <h5>Microsoft Store</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;