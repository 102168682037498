import React, { useRef, useState } from "react";
import MainLayout from "../../../components/layout/main.layout";
import { useLocation, useNavigate } from "react-router-dom";
import "../draftDetails/draft.details.scss";
import { useEffect } from "react";
import SpinnerComponent from "../../../components/spinner/spinner.component";
import TitleBar from "../../../components/ui/title-bar/title-bar.component";
import { getDraftedFilesFromServer } from "../../../services/api-calls/draft-files.api";
// import { saveDraftFileToIndexedDb } from "../../../services/indexed-db/draftfiles.indexeddb";
import SourceLanguage from "../../../components/draft-page-component/source-language/index";
import DraftScriptComponent from "../../../components/draft-page-component/draft-script";
import DraftAudioComponent from "../../../components/draft-page-component/draft-audio";
import DraftVideoComponent from "../../../components/draft-page-component/draft-video/draft-video.component";
import Chatbot from "../../../components/chatbot";
import { IoIosArrowForward } from "react-icons/io";
import { extractNumberFromString, getStoredValue, storeValue } from "../../../services/config";
import { notification } from "antd";
import DownDownSelectChunk from "../../../components/draft-page-component/dropdown-select-chunk";
import { useSelector,useDispatch } from "react-redux";
import {saveBooksAndProjects} from "../../../services/BooksAndProjects"
import { storeBooks } from "../../../redux/actions/books.action";
import {storeProjects} from "../../../redux/actions/project.action"
import { clearCheckFilesName, clearFileCount } from "../../../redux/actions/check-files.action";
import { clearTranScriptedText, togglePlaying } from "../../../redux/actions/util.action";
import { isSafari,isTablet } from "react-device-detect";


const DraftDetails = () => {
  const dispatch = useDispatch();
  const projectsReducer = useSelector((state) => state.projectsReducer);
  const booksReducer = useSelector((state) => state.booksReducer);
  const navigate = useNavigate();
  const location = useLocation();
  const [chunk, setChunk] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const [chapterChunks, setChapterChunks] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [project, setProject] = useState({});
  const [currentChunkIndex, setCurrentChunkIndex] = useState("");
  const [isForwardDisabled, setIsForwardDisabled] = useState(false);
  const audioRef = useSelector((state) => state.audioReducer);
  const audioPause = useRef(null);
  const [api, contextHolder] = notification.useNotification();
  const showNotification = (type, message) => {
    notification.destroy();
    api[type]({
      message,
    });
  };

  useEffect(() => {
    loadDetails();
    localStorage.setItem("isBackTranslation","false")
    const checkWindowSize = () => {
      if (window.innerWidth <= 768) { // Adjust the screen width as needed
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    // Initial check when the component mounts
    checkWindowSize();
    // Listen for window resize events and update the state
    window.addEventListener('resize', checkWindowSize);
    // Clean up the event listener when the component unmounts
    return () => {
      setIsForwardDisabled(false);
      window.removeEventListener('resize', checkWindowSize);
    };
  }, [setChunk, location]);

  const loadDetails = () => {
    return new Promise(async (resolve, reject) => {
      try {
      if(projectsReducer.length<=0 || booksReducer.length<=0){
        let data= await saveBooksAndProjects();

        data.projectsData.length >0 ? data.projectsData.forEach(async (item)=>{
          await dispatch(storeProjects([item]));
        }) : await dispatch(storeProjects([data.projectsData]));
    
        data.booksData.length>0 ? data.booksData.forEach(async(item)=>{
          await dispatch(storeBooks([item]));
        }): await dispatch(storeBooks([data.booksData]));
      }
        setIsLoader(true);
        setChunk(location.state?.chunk);
        setProject(location.state?.project);
        loadSavedDraftFiles();
        checkCurrentChunkIndex();
        setIsLoader(false);
      } catch (error) {
        console.log(error);
        resolve();
        setIsLoader(false);
      }
    });
  };
  
  const loadSavedDraftFiles = async () => {
    return new Promise(async (resolve, reject) => {
      setIsLoader(true);
      setProject(location.state?.project);
      try {
        // let files = await getAllFileFromSyncDB();
        // if (files.length > 0) {
        let data = await getDraftedFilesFromServer({
          projectId: `${location.state?.chunk?.projectId}`,
          verse: `${location.state?.verse}`,
          chapter: `${location.state?.chunk?.chunk[0].chapterName}`,
          bookName: `${location.state?.book?.bookName}`,
          majorLanguage: `${location.state?.project?.majorLanguage1}`,
          language: `${location.state?.project?.language}`,
          targetType: "draft",
        });
        if (data.length) {
          for (let i = 0; i < data.length; i++) {
            let fileName = await data[i].fileName.split(".")[0];
            data[i].name = fileName;
            data[i].formattedName = `${location.state?.heading}`;
            data[i].projectType = location.state?.project.projectType;
            data[i].key = data[i].fileId;
            // let awsBase64 = await readFilesFromAWS(data[i]);
            // data[i].base64 = awsBase64;
            // await saveDraftFileToIndexedDb(data[i]);
          }

          resolve(true);
          setIsLoader(false);
        } else {
          resolve(true);
          setIsLoader(false);
        }
        // } else {
        //   resolve(true);
        //   setIsLoader(false);
        // }
      } catch (error) {
        console.log(error);
        resolve();
        setIsLoader(false);
      }

      setIsLoader(false);
      await loadMasterRecord();
      await loadBackTranslate();
    });
  };

  const checkCurrentChunkIndex = async () => {
    try {
      let currentChapter = location.state?.book?.chapters.filter(
        (item) => item.chapterName == location?.state?.chapter
      );

      if (currentChapter.length > 0) {
        setChapterChunks(currentChapter[0].chunks);
        let index = await currentChapter[0].chunks.findIndex(
          (item) => item.chunkName == location?.state?.verse
        );
        setCurrentChunkIndex(index);

        if (currentChapter[0].chunks.length - 1 == index) {
          setIsForwardDisabled(true);
        }
      } else {
      }
    } catch (error) {}
  };

  const loadMasterRecord = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let data = await getDraftedFilesFromServer({
          projectId: `${location.state?.chunk?.projectId}`,
          verse: `${location.state?.verse}`,
          chapter: `${location.state?.chunk?.chunk[0].chapterName}`,
          bookName: `${location.state?.book?.bookName}`,
          majorLanguage: `${location.state?.project?.majorLanguage1}`,
          language: `${location.state?.project?.language}`,
          targetType: "masterRecord",
        });

        if (data.length) {
          for (let i = 0; i < data.length; i++) {
            let fileName = await data[i].fileName.split(".")[0];
            data[i].name = fileName;
            data[i].formattedName = `${location.state?.heading}`;
            data[i].projectType = project.projectType;
            data[i].key = fileName;
            // let something = await saveDraftFileToIndexedDb(data[i]);
          }
        }

        resolve();
      } catch (error) {
        console.log(error);
        resolve();
      }
    });
  };

  const loadBackTranslate = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let data = await getDraftedFilesFromServer({
          projectId: `${location.state?.chunk?.projectId}`,
          verse: `${location.state?.verse}`,
          chapter: `${location.state?.chunk?.chunk[0].chapterName}`,
          bookName: `${location.state?.book?.bookName}`,
          majorLanguage: `${location.state?.project?.majorLanguage1}`,
          language: `${location.state?.project?.language}`,
          targetType: "backTranslate",
        });

        if (data.length) {
          for (let i = 0; i < data.length; i++) {
            let fileName = await data[i].fileName.split(".")[0];
            data[i].name = fileName;
            data[i].formattedName = `${location.state?.heading}`;
            data[i].projectType = project.projectType;
            data[i].key = fileName;
            // let something = await saveDraftFileToIndexedDb(data[i]);
          }
        }

        resolve();
      } catch (error) {
        console.log(error);
        resolve();
      }
    });
  };

  const nextChunk = async () => {
    if (isForwardDisabled == true) {
      // showNotification("info", "This is last chunk");
    } else if (chapterChunks.length - 1 !== currentChunkIndex) {
      setIsLoader(true);
      if(isSafari || isTablet){
        let audio =  getStoredValue();
        audio != undefined && audio.current?.pause();
        dispatch(togglePlaying(false));
        storeValue(undefined)
       }
      let nextChunk = chapterChunks[currentChunkIndex + 1];

      let headingName = await extractNumberFromString(nextChunk.chunkName);
      await dispatch(togglePlaying(false))
      await dispatch(clearCheckFilesName());
      await dispatch(clearFileCount())
      await dispatch(clearTranScriptedText());
      
      nextChunk.chunk=nextChunk.chunk.sort((a, b) => {
        // Extracting file extensions (assuming all files have extensions)
        const extensionA = a.fileName.split('.').pop();
        const extensionB = b.fileName.split('.').pop();        
        // Assigning priorities based on file type
        const priority = { 'mp3': 1, 'txt': 2 };
        // Comparing based on priorities
        return priority[extensionA] - priority[extensionB];
      });  

      console.log("nextChunk",nextChunk);

      navigate("/draft-details", {
        state: {
          book: location.state?.book,
          chapter: location.state?.chapter,
          chunkName: location.state?.chunkName,
          project: location.state?.project,
          isReadOnly: location.state?.isReadOnly,
          verse: nextChunk.chunkName,
          chunk: nextChunk,
          heading: `${location.state?.chunkName} ${headingName}`,
        },
      });
      setIsLoader(false);
    }
  };

  return (
    <MainLayout>
      {isLoader ? (
        <SpinnerComponent />
      ) : (
        <div className="draft-details">
          <div className="draft-details-wrapper">
            <TitleBar
              title={
                <>
                  <div className="flex items-center justify-center fsize">
                    {location.state?.heading}{" "}
                    <DownDownSelectChunk
                      url={"/draft-details"}
                      className="pt-1"
                      isloader = {setIsLoader}
                    />
                  </div>
                </>
              }
              page="draft-details"
              checkModal={
                <button
                  className="back-arrow remove-btn-css"
                  onClick={nextChunk}
                  disabled={isLoader}
                >
                  <IoIosArrowForward
                    size={isMobile ? 20 : 26}
                    color={isForwardDisabled == true ? "gray" : "black"}
                  />
                </button>
              }
            />

            <div className="pt-2 fsize">
              <SourceLanguage chunk={location.state?.chunk} title={"Source"} key={Math.floor(Math.random() * 1e4)}/>
            </div>

            <div className="pt-4">
              {Object.keys(project).length ? (
                <>
                  {project.projectType.signLanguage == true && (
                    <DraftVideoComponent
                      chunk={location.state?.chunk}
                      project={project}
                      title={"Draft Sign"}
                      targetType="draft"
                      isDisableRecord={
                        localStorage.getItem("role") == "Facilitator"
                      }
                      isBackTranslationDisplay={true}
                    />
                  )}
                </>
              ) : null}

              {Object.keys(project).length ? (
                <>
                  {project.projectType.audio == true ? (
                    <DraftAudioComponent
                      chunk={location.state?.chunk}
                      fileType={"audio"}
                      title={"OBT Draft"}
                      targetType="draft"
                      isDisableRecord={
                        localStorage.getItem("role") == "Facilitator" ||
                        localStorage.getItem("role") == "CIT" ||
                        localStorage.getItem("role") == "Consultant"
                      }
                      isBackTranslationDisplay={true}
                      relatedDraft=""
                    />
                  ) : null}
                </>
              ) : null}
              {Object.keys(project).length ? (
                <>
                  {project.projectType.jesusFilm == true ? (
                    <DraftAudioComponent
                      chunk={location.state?.chunk}
                      fileType={"audio"}
                      title={"OBT Draft"}
                      targetType="draft"
                      isDisableRecord={
                        localStorage.getItem("role") == "Facilitator" ||
                        localStorage.getItem("role") == "CIT" ||
                        localStorage.getItem("role") == "Consultant"
                      }
                      isBackTranslationDisplay={true}
                      relatedDraft=""
                    />
                  ) : null}
                </>
              ) : null}

              {Object.keys(project).length ? (
                <>
                  {project.projectType.text == true ? (
                    <DraftScriptComponent
                      title={"Script Draft"}
                      targetType="draft"
                      chunk={location.state?.chunk}
                      isTextAreaDisplayed={
                        localStorage.getItem("role") == "MTT"
                      }
                      isBackTranslationDisplay={true}
                      relatedDraft=""
                    />
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </div>
      )}
      {isLoader == false ? <Chatbot /> : null}
    </MainLayout>
  );
};

export default DraftDetails;
