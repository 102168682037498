import React from "react";
import "./SingleWaveform.css"; // Import your CSS file

const SingleWaveform = () => {
  const numBars = 100; // Adjust the number of bars as needed
  const bars = Array.from({ length: numBars }).map((_, index) => ({
    index,
    animationDelay: `${0.15 * index}s`, // Adjust the animation delay as needed
  }));

  return (
    <div className="waveform-container">
      <div className="waveform">
        {bars.map((bar) => (
          <div key={bar.index} className="bar" style={{ animationDelay: bar.animationDelay,width:"3px" }}></div>
        ))}
      </div>
    </div>
  );
};

export default SingleWaveform;
