let initial_state = "";

const audioReducer = (state = initial_state, action) => {
  switch (action.type) {
    case "STOP_AUDIO":
      return state;

    case "SET_AUDIO_REF":
      return { ...initial_state, ...action.payload };
    default:
      return state;
  }
};

export default audioReducer;
