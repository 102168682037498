import React, { useEffect } from "react";
import MainLayout from "../../components/layout/main.layout";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router";
import TitleBar from "../../components/ui/title-bar/title-bar.component";
import { Link } from "react-router-dom";
import { Button } from "antd";
const TeamConnectPage = () => {



  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <MainLayout>
        <div className="draft-details ">
          <div className="draft-details-wrapper h-screen">
            <TitleBar title={"Team Connect"} page={"draft"} />

            {/*
             * * Start Your Code From Here
             ***/}
            {/* <Link to={"/test"}>
              <Button> Test Pgae</Button>
            </Link> */}
          </div>
        </div>
      </MainLayout>
    </div>
  );
};

export default TeamConnectPage;
