import React, { useState } from "react";
import { Button, Select, Modal, notification } from "antd";
import { IoLanguage } from "react-icons/io5";
import { generateTranslatedPdf } from "../../../services/generate-pdf";
import languages from "../../../assets/languages.json";

const GoogleTranslation = ({ files }) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const [api, contextHolder] = notification.useNotification();
  const showNotification = (type, message) => {
    notification.destroy();
    api[type]({
      message,
      placement: "top",
    });
  };

  const showModal = () => {
    setOpen(true);
  };

  const onChange = (value) => {
    setSelectedLanguage(value);
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleOk = async () => {
    try {
      if (selectedLanguage == null || !selectedLanguage) {
        showNotification("warning", "Please select language");
        return;
      }
      setConfirmLoading(true);
      let res = await generateTranslatedPdf(files, selectedLanguage);
      setOpen(false);
      setConfirmLoading(false);
    } catch (error) {}
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  return (
    <>
      <IoLanguage
        size="20"
        className="mx-2 cursor-pointer"
        onClick={showModal}
      />
      <Modal
        title="Please select a language to convert files"
        open={open}
        onCancel={handleCancel}
        closeIcon={handleCancel}
        maskClosable={false}
        confirmLoading={confirmLoading}
        footer={[
          <div style={{ marginTop: "4px" }}>
            <Button
              danger
              type="primary"
              onClick={handleCancel}
              disabled={confirmLoading}
            >
              Cancel
            </Button>
            <Button
              onClick={handleOk}
              loading={confirmLoading}
              className="submit-btn"
              style={{
                background: "#031832",
                color: "white",
                margin: "0 4px",
              }}
            >
              Submit
            </Button>
          </div>,
        ]}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
              paddingTop: "8px",
            }}
          >
            <Select
              showSearch
              placeholder="Select a language"
              style={{ width: "100%", display: "flex", alignItems: "center" }}
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={languages}
            />
          </div>
        </div>
      </Modal>

      {contextHolder}
    </>
  );
};

export default GoogleTranslation;
