import React, { useState, useEffect, useRef } from "react";
import { GiOpenBook } from "react-icons/gi";
import { SiProgress, SiTodoist } from "react-icons/si";
import { RiDraftLine, RiTeamLine } from "react-icons/ri";
import { GoProject } from "react-icons/go";
import { MdOutlineSupportAgent } from "react-icons/md";
import { VscPreview } from "react-icons/vsc";
import { useNavigate } from "react-router";
import { HiOutlineUserGroup } from "react-icons/hi";
import "./sidenavbar.scss";
import HoverNavSidebar from "./hovernav.sidebar";
import SpinnerComponent from "../../spinner/spinner.component";
// import { createBooksSchema } from "../../../services/indexed-db/books.indexeddb";
import { useDispatch, useSelector } from "react-redux";
// import { getProjectByRoleAndId } from "../../../services/indexed-db/project.indexeddb";
// import { getBooksByUserRole } from "../../../services/indexed-db/books.indexeddb";
import { sideNavBarStatusReducer } from "../../../redux/actions/util.action";
import { notification } from "antd";

const SideNavbar = () => {
  const projectsReducer = useSelector((state) => state.projectsReducer);
  const booksReducer = useSelector((state) => state.booksReducer);
  const dispatch=useDispatch();

  const [books, setBooks] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isBooksNav, setIsBooksNav] = useState(false);
  const [isProjectNav, setIsProjectNav] = useState(false);
  const [isBookLoading, setIsBookLoading] = useState(true);
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [userId, setUserId] = useState(localStorage.getItem("id"));
  const navigate = useNavigate();
  const isBook = useRef(false);
  const isProject = useRef(false);
  const booksRef = useRef([]);
  const [api, contextHolder] = notification.useNotification();
  const showNotification = (type, message) => {
    notification.destroy();
    api[type]({
      message,
    });
  };

  let currentBooks = useSelector((state) => state.booksReducer);
  let isFirstLoad = useSelector((state) => state.firstLoadReducer);
  const isPlayingStatus = useSelector((state) => state.playingStatusReducer);
  let setNavBar = useSelector((state) => state.sideNavBarStatusReducer);
  const isRecordingStatus = useSelector(
    (state) => state.recordingStatusReducer
  );
  const iconSize = 26;
  const miconSize = 28

  const redirectToDest = (path) => {
    if (isRecordingStatus == true) {
      showNotification("error", "Please stop recording");
    } else if (isPlayingStatus == true) {
      showNotification("error", "Please stop playing");
    } else {
      navigate("/" + path);
    }
  };

  useEffect(() => {
    // console.log("SIDE NAV",booksReducer);
    isBook.current =
      (role === "MTT" || role === "Facilitator") == true ? true : false;

    isProject.current =
      (role == "CIT" ||
        role == "Consultant" ||
        role == "Technician" ||
        role == "Coordinator" ||
        role == "Translation Manager") == true
        ? true
        : false;
    loadData();
  }, [isFirstLoad, setBooks]);

  const loadData = () => {
    return new Promise(async (resolve, reject) => {
      try {
        if (isFirstLoad == true) {
          if (isBook.current == true) {
            let userRole = "";
            if (role == "Facilitator") {
              userRole = role.toLocaleLowerCase();
            } else if (role == "MTT") {
              userRole = "MTT";
            }
            // let userBooks = await getBooksByUserRole(userRole);
            let userBooks = await booksReducer;

            setIsBookLoading(false);
            setBooks(userBooks);
            booksRef.current = userBooks;
            resolve();
          } else if (isProject.current == true) {
            // let projectsData = await getProjectByRoleAndId(role, userId);
            let projectsData = await projectsReducer;
            // let booksData = await getBooksByUserRole(role.toLocaleLowerCase());
            let booksData = await booksReducer;
            for (const project of projectsData) {
              project.bookData = [];
              for (const books of booksData) {
                if (project.id == books.projectId) {
                  project.bookData.push(books);
                }
              }
            }
            setProjects(projectsData);
            setIsBookLoading(false);
          }
        } else {
          if (isProject.current == true) {
            let userRole = "";
            if (role == "Facilitator") {
              userRole = role.toLocaleLowerCase();
            } else if (role == "MTT") {
              userRole = "MTT";
            }
            // let projectsData = await getProjectByRoleAndId(role, userId);
            let projectsData = await projectsReducer
            // let booksData = await getBooksByUserRole(role.toLocaleLowerCase());
            let booksData = await booksReducer;
            for (const project of projectsData) {
              project.bookData = [];
              for (const books of booksData) {
                if (project.id == books.projectId) {
                  project.bookData.push(books);
                }
              }
            }
            setProjects(projectsData);
            setIsBookLoading(false);
            resolve();
          } else {
            setBooks(currentBooks);
            booksRef.current = currentBooks;
            setIsBookLoading(false);
            resolve();
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  const mouseEnter = (type) => {
    if (type == "enter") {
      setIsBooksNav(isBook.current == true && true);
      setIsProjectNav(isBook.current == false && true);
    } else if (type == "move") {
      setIsBooksNav(isBook.current == true && false);
      setIsProjectNav(isBook.current == false && false);
    }
  };

  return (
    <>
      <div className="bg-sidebar sidebar select-none overflow-y h-100">
        {/* {isFirstLoad == false ? (
          <> */}
            <div className="py-4 flex justify-center ">
              <nav className="">
               
                {isBook.current && (
                  <>
                    <div
                      className="flex show-me nav-option"
                      onMouseEnter={(e) => {
                        mouseEnter("enter");
                      }}
                      onMouseLeave={(e) => {
                        mouseEnter("move");
                      }}
                    >
                      <span className="nav-svg">
                        <GiOpenBook className="icon-right" size={miconSize} />
                        <br className="brdnone" /> Book
                      </span>
                    </div>
                  </>
                )}
                {isProject.current ? (
                  <div
                    className="nav-option"
                    onMouseEnter={(e) => {
                      mouseEnter("enter");
                    }}
                    onMouseLeave={(e) => {
                      mouseEnter("move");
                    }}
                  >
                    <span className="nav-svg">
                      <GoProject className="icon-right" size={iconSize} />
                      <br className="brdnone" />
                       Projects
                    </span>
                  </div>
                ) : role == "MTT" ? (
                  <div className="nav-option">
                    <span
                      className="nav-svg"
                      onClick={(e) => {
                        dispatch(sideNavBarStatusReducer(setNavBar));
                        redirectToDest("draft");
                      }}
                    >
                      <RiDraftLine className="icon-right" size={iconSize} />
                      <br className="brdnone" /> Draft
                    </span>
                  </div>
                ) : (
                  <div className="nav-option">
                    <span
                      className="nav-svg"
                      onClick={(e) => {
                        dispatch(sideNavBarStatusReducer(setNavBar));
                        redirectToDest("draft");
                      }}
                    >
                      <HiOutlineUserGroup className="icon-right" size={iconSize} />
                      <br className="brdnone" /> Community Check
                    </span>
                  </div>
                )}

                <div className="nav-option">
                  <span
                    className="nav-svg"
                    onClick={(e) => {
                      dispatch(sideNavBarStatusReducer(setNavBar));
                      redirectToDest("my-progress");
                    }}
                  >
                    <SiProgress className="icon-right" size={iconSize} />
                    <br className="brdnone" />
                    Progress
                  </span>
                </div>
                <div className="nav-option">
                  <span
                    className="nav-svg"
                    onClick={(e) => {
                      dispatch(sideNavBarStatusReducer(setNavBar));
                      redirectToDest("team-connect");
                    }}
                  >
                    <RiTeamLine className="icon-right" size={iconSize} /> <br className="brdnone" /> Team Connect
                  </span>
                </div>
                <div className="nav-option">
                  <span
                    className="nav-svg"
                    onClick={(e) => {
                      dispatch(sideNavBarStatusReducer(setNavBar));
                      redirectToDest("to-do");
                    }}
                  >
                    <SiTodoist className="icon-right" size={iconSize} />
                    <br className="brdnone" /> To Do
                  </span>
                </div>
                <div className="nav-option">
                  <span
                    className="nav-svg"
                    onClick={(e) => {
                      dispatch(sideNavBarStatusReducer(setNavBar));
                      redirectToDest("support");
                    }}
                  >
                    <MdOutlineSupportAgent className="icon-right" size={iconSize} />
                    <br className="brdnone" />
                    Support
                  </span>
                </div>
                <div
                  className="nav-option"
                  onClick={(e) => {
                    dispatch(sideNavBarStatusReducer(setNavBar));
                    redirectToDest("recent-updates");
                  }}
                >
                  <span className="nav-svg">
                    <VscPreview className="icon-right" size={iconSize} />
                    <br className="brdnone" />
                    What’s new
                  </span>
                </div>
              </nav>
            </div>
          {/* </>
        ) : null} */}
      </div>
      {isPlayingStatus == false && isRecordingStatus == false && isBooksNav && (
        <div
          className=""
          onMouseEnter={(e) => {
            mouseEnter("enter");
          }}
          onMouseLeave={(e) => {
            mouseEnter("move");
          }}
        >
          <div className="submenu-hover-nav">
            {isBookLoading ? (
              <SpinnerComponent />
            ) : (
              <div>
                {booksRef.current.length ? (
                  booksRef.current.map((book) => (
                    <div key={book.book_id}>
                      <HoverNavSidebar
                        data={book}
                        type={"book"}
                        className="nav-svg cursor-pointer"
                      />
                    </div>
                  ))
                ) : (
                  <span>Please add book</span>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {isProjectNav && (
        <div
          className=""
          onMouseEnter={(e) => {
            mouseEnter("enter");
          }}
          onMouseLeave={(e) => {
            mouseEnter("move");
          }}
        >
          <div className="submenu-hover-nav">
            {isBookLoading ? (
              <SpinnerComponent />
            ) : (
              <div>
                {projects.length ? (
                  projects.map((project) => (
                    <div>
                      <HoverNavSidebar
                        data={project}
                        type={"project"}
                        className="nav-svg cursor-pointer"
                      />
                    </div>
                  ))
                ) : (
                  <span>No Project Available</span>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {contextHolder}
    </>
  );
};

export default SideNavbar;
