import React, { useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import headpic from "../../../assets/images/login.jpg";
import "../signup/signup.scss";
import { Button, Form, Input } from "antd";
import NotificationComponent from "../../../components/notification/notification.component";
import { auth_route } from "../../../services/apis";
import getHeader from "../../../services/config";

const SignUpPage = () => {
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notification, setNotification] = useState({
    type: "",
    content: "",
  });
  const timeoutRef = useRef();

  const [form] = Form.useForm();
  const onFinish = async (values) => {
    setIsLoader(true);
    if (navigator.onLine) {
      if (
        !values.fullName ||
        !values.email ||
        !values.organization ||
        !values.contactNumber ||
        !values.country
      ) {
        
      } else {
        let data = {
          email: values.email,
          userName: values.fullName,
          organization: values.organization,
          contactNumber: values.contactNumber,
          country: values.country,
        };
        try {
          let res = await axios.post(
            `${auth_route.sign_up}`,
            data,
            getHeader()
          );
          displayNotification("success", "Admin will connect you soon");
          setTimeout(() => {
            form.resetFields();
            setIsLoader(false);
            navigate("/signin");
          }, 1500);
        } catch (error) {
          console.log(error);
          setIsLoader(false);
          displayNotification("error", error.response?.data?.msg);
        }
      }
    } else {
      displayNotification("error", "Please check your internet connection");
      setIsLoader(false);
    }
  };

  const displayNotification = async (type, content) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setNotification({
      type,
      content,
    });
    setIsNotification(true);
    let timeout = setTimeout(() => {
      setIsNotification(false);
      setNotification({
        type: "",
        content: "",
      });
    }, 2000);
    timeoutRef.current = timeout;
  };
  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
    if (errorInfo.outOfDate == false) {
      // displayNotification("error", "All field required");
    } else {
      displayNotification("error", "Please try after sometime");
    }
  };

  return (
    <div className="signupPage">
      <div className="container  ">
        <div className=" col-sm-10 signupForm mb-auto mt-auto offset-1">
          <div className="headline">
            <div className="header">
              <span className="pageName">Reach Rhema</span>
            </div>
          </div>
          <div className="signform card">
            <div className="m">
              <img
                src={headpic}
                style={{ width: "100%", height: "100px" }}
                alt="head pic"
              />
            </div>
            <div className="signupformfield col-sm-10 ">
              <Form
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                form={form}
              >
                {isNotification && (
                  <NotificationComponent
                    type={notification.type}
                    content={notification.content}
                  />
                )}
                <Form.Item
                  name="fullName"
                  rules={[
                    {
                      pattern: new RegExp(
                        /^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i
                      ),
                      message: "Please enter text only",
                    },
                    {
                      required: true,
                      message: "Please input your full name!",
                    },
                  ]}
                >
                  <Input placeholder="Full Name" />
                </Form.Item>

                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                    {
                      type: "email",
                      warningOnly: true,
                      message: "Please enter valid email",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>

                <Form.Item
                  name="organization"
                  rules={[
                    {
                      pattern: new RegExp(
                        /^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i
                      ),
                      message: "Please enter text only",
                    },
                    {
                      required: true,
                      message: "Please input your organization",
                    },
                  ]}
                >
                  <Input placeholder="Organization" type="text" />
                </Form.Item>
                <Form.Item
                  name="contactNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your contact number",
                      pattern: new RegExp(/^[0-9]+$/),
                    },
                    {
                      min: 10,
                      message: "Number should of 10 digit",
                    },
                    {
                      pattern: /^[\d]{0,10}$/,
                      message: "Number should be equal to  10 character",
                    },
                  ]}
                >
                  <Input placeholder="Contact Number" minLength={10} />
                </Form.Item>
                <Form.Item
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: "Please input your country!",
                    },
                    {
                      pattern: new RegExp(
                        /^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]+$/i
                      ),
                      message: "Please enter text only",
                    },
                  ]}
                >
                  <Input placeholder="Country" />
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="submit-btn"
                    style={{ background: "#031832", color: "white" }}
                    loading={isLoader}
                  >
                    Send
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>

          <div className="linkToLogin offset-1">
            <span className="link-text-bg-black">
              <Link exact to="/signin" style={{ color: "white" }}>
                Login &gt;
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
