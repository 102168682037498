import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import MasterRecroding from "../../components/draft-page-component/master-record/master-recording";
import MainLayout from "../../components/layout/main.layout";
import SpinnerComponent from "../../components/spinner/spinner.component";
import TitleBar from "../../components/ui/title-bar/title-bar.component";
// import { getAllDraftFiles } from "../../services/indexed-db/draftfiles.indexeddb";
import { Table } from "antd";
import SmallSpinnerComponent from "../../components/spinner/small-spinner.component";
import AudioPlayer from "../../components/audio-player/audio-player.component";
import DraftScriptModal from "../../components/modal/draft-scirpt-modal";
import VideoPlayer from "../../components/video/video-player";
import FilenameComponent from "../../components/ui/filename.component";
import { IoIosArrowForward } from "react-icons/io";
import { extractNumberFromString } from "../../services/config";
import DownDownSelectChunk from "../../components/draft-page-component/dropdown-select-chunk";
import {getDraftedFilesByChunksOnline} from "../../services/api-calls/draft-files.api"
import { clearTranScriptedText } from "../../redux/actions/util.action";
import { clearCheckFilesName, clearFileCount } from "../../redux/actions/check-files.action";
import { useDispatch } from "react-redux";

const MasterRecordingPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState(false);
  const [project, setProject] = useState({});
  const [currentChunkIndex, setCurrentChunkIndex] = useState("");
  const [isForwardDisabled, setIsForwardDisabled] = useState(false);
  const [chapterChunks, setChapterChunks] = useState([]);

  useEffect(() => {
    loadFiles();
    checkCurrentChunkIndex();
  }, [location]);

  const checkCurrentChunkIndex = async () => {
    try {
      let currentChapter = location.state?.book?.chapters.filter(
        (item) => item.chapterName == location?.state?.chapter
      );

      if (currentChapter.length > 0) {
        setChapterChunks(currentChapter[0].chunks);
        let index = await currentChapter[0].chunks.findIndex(
          (item) => item.chunkName == location?.state?.verse
        );
        setCurrentChunkIndex(index);

        if (currentChapter[0].chunks.length - 1 == index) {
          setIsForwardDisabled(true);
        }
      } else {
      }
    } catch (error) {}
  };

  const getAllDraftFiles=()=>{
    return new Promise(async (resolve, reject) => {
      try {
        // let bookData = book.length > 0 ? book[0]:book 
        // let projectData= await projectsReducer.filter((item)=>{return item.id==bookData.projectId})[0];
        let data={
          projectId:location.state.project.id,
          bookName:location.state.book.bookName,
          chapterName:location.state.chapter,
          sourceVerseName:location.state.verse,
          targetType:"draft"
        }
        let files= await getDraftedFilesByChunksOnline(data);
        resolve(files)
      } catch (error) {
        console.log("ERROR",error);
      }
    })
  }    

  const loadFiles = () => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
        setProject(location.state.project);
        let files = await getAllDraftFiles();
        let data = await files.filter(
          (item) => item.consultantStatusCheck == true || item.citStatusCheck == true);
          console.log("files=",files);
        setList(data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "name",
      render: (value, record) => (
        <>
           <FilenameComponent file={record} />
        </>
      ),
    },
    {
      title: (
        <span className={`flex items-center  justify-end mr-4-5`}>Action</span>
      ),
      dataIndex: "",
      key: "x",
      render: (item, record) => (
        <div className="flex items-center justify-end ">
          {record.fileType == "audio" ? (
            <AudioPlayer
              revokeOtherFunctions={isLoading}
              data={record}
              isDisabled={isLoading}
              isReverse={false}
            />
          ) : null}
          {record.fileType == "video" ? (
            <VideoPlayer
              data={record}
              isDisabled={isLoading}
              fileType="DRAFTFILE"
              isReverse={false}
            />
          ) : null}
          {record.fileType == "script" ? (
            <DraftScriptModal data={record} isDisabled={isLoading} />
          ) : null}
          <div className="mx-2"></div>
          <MasterRecroding
            chunk={record}
            fileType={record.fileType}
            draftFileName={record.fileName}
            isButtonDisabled={isLoading}
            project={project}
          />
        </div>
      ),
    },
  ];

  const nextChunk = async () => {
    if (isForwardDisabled == true) {
      // showNotification("info", "This is last chunk");
    } else if (chapterChunks.length - 1 !== currentChunkIndex) {
      setIsLoading(true);
      let nextChunk = await chapterChunks[currentChunkIndex + 1];

      let headingName = await extractNumberFromString(nextChunk.chunkName);
      await dispatch(clearCheckFilesName());
      await dispatch(clearFileCount());
      await dispatch(clearTranScriptedText());

      navigate("/master-recording", {
        state: {
          book: location.state?.book,
          chapter: location.state?.chapter,
          chunkName: location.state?.chunkName,
          project: location.state?.project,
          isReadOnly: location.state?.isReadOnly,
          verse: nextChunk.chunkName,
          chunk: nextChunk,
          targetType: location.state?.targetType,
          heading: `${location.state?.chunkName} ${headingName}`,
        },
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <MainLayout>
        <div className="draft-details">
          <div className="draft-details-wrapper">
            <TitleBar
              // title={location.state?.heading}
              title={
                <>
                  <div className="flex items-center justify-center">
                    {location.state?.heading}{" "}
                    <DownDownSelectChunk
                      url={"/master-recording"}
                      className="pt-1"
                      isloader = {setIsLoading}
                    />
                  </div>
                </>
              }
              page={"draft-details"}
              checkModal={
                <button
                  className="back-arrow remove-btn-css"
                  onClick={nextChunk}
                  disabled={isLoading}
                >
                  <IoIosArrowForward
                    size={26}
                    color={isForwardDisabled == true ? "gray" : "black"}
                  />
                </button>
              }
            />

            {isLoading == true ? (
              <SpinnerComponent />
            ) : (
              <>
                {list.length > 0 ? (
                  <Table
                    loading={{
                      spinning: isLoading,
                      indicator: (
                        <span>
                          <SmallSpinnerComponent />
                        </span>
                      ),
                    }}
                    pagination={{
                      pageSize: 5,
                      hideOnSinglePage: true,
                    }}
                    dataSource={list}
                    columns={columns}
                  />
                ) : (
                  <div className="flex items-center justify-center h-screen">
                    <h4>File is not approved by CIT or Consultant</h4>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default MasterRecordingPage;
