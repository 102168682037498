import { Button, List, Modal, Table, Tabs, Tooltip, notification } from "antd";
import { Comment } from '@ant-design/compatible';
import axios from "axios";
import React, { useState } from "react";
import { base_url, bible, draftFile } from "../../../services/apis";
import { useEffect } from "react";
import SourceLanguage from "../../draft-page-component/source-language/index";
import SmallSpinnerComponent from "../../spinner/small-spinner.component";
import VideoPlayer from "../../video/video-player";
import AudioPlayer from "../../audio-player/audio-player.component";
import DraftScriptModal from "../draft-scirpt-modal/index";
import { RiArrowRightSFill } from "react-icons/ri";
import { useLocation } from "react-router";
import FilenameComponent from "../../ui/filename.component";
import { FaComment, FaCommentSlash } from "react-icons/fa";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { useDispatch, useSelector } from "react-redux";
import { isSafari,isTablet } from "react-device-detect";
import { getStoredValue, storeValue } from "../../../services/config";
import { togglePlaying } from "../../../redux/actions/util.action";

const TechnicianFileModal = ({
  chunkName,
  draftedFiles,
  book,
  chapter,
  userType,
  showModal,
  techModal
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sourceFiles, setSourceFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [draftFiles, setDraftFiles] = useState([]);
  const [backtraslateFiles, setBacktraslateFiles] = useState([]);
  const [masterRecordFiles, setMasterRecordFiles] = useState([]);
  const [isBackTranslateLoading, setIsBackTranslateLoading] = useState(false);
  const [isMasterRecordLoading, setIsMasterRecordLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [isCommentShow, setIsCommentShow] = useState(false);
  const [currentCommentId, setCurrentCommentId] = useState("");
  const isRecording = useSelector((state) => state.recordingStatusReducer);
  const isPlayingStatus = useSelector((state) => state.playingStatusReducer);
  const [notificationApi, NotificationJSX] = notification.useNotification();
  const [isSync,setIsSync] =useState(false)
  const showNotification = (type, message) => {
    notification.destroy();
    notificationApi[type]({
      message,
      placement: "top",
    });
  };
  useEffect(() => {
    loadSourceFiles();
    loadBackTranslateFiles();
    loadMasterRecordFiles();
    
    return () => {
      setMasterRecordFiles([]);
      setBacktraslateFiles([]);
      setDraftFiles([]);
      setIsCommentShow(false);
      setCurrentCommentId("");
      setComments([]);
      setIsLoading(false)
    };
    ;
  }, [showModal,location]);



  const handleOk = () => {
    // setIsModalOpen(false);
    showModal(false)
  };

  const onTabClick = async()=>{
    if(isSafari || isTablet){
      let audio =  getStoredValue();
      
      audio != undefined && audio.current?.pause();
      dispatch(togglePlaying(false));
      storeValue(undefined)
     
     }
  }

  const handleCancel = async () => {
    if(isSafari || isTablet){
      let audio =  getStoredValue();
      audio != undefined && audio.current?.pause();
      dispatch(togglePlaying(false));
      storeValue(undefined)
     }

    !isPlayingStatus && showModal(false);;
  };

  const loadSourceFiles = async () => {
    try {
      let draftFiles = await draftedFiles.filter((item) =>
        item.sourceVerseName == chunkName && item.isSelected == true && item.targetType == "draft");
      setDraftFiles(draftFiles);
      let res = await axios.post(`${base_url}${bible.getFileByVersesName}`, {
        majorLanguage1: draftFiles[0].majorLanguage,
        majorLanguage2: "",
        majorLanguage3: "",
        bookName: draftFiles[0].bookName,
        targetType: "source",
        chapterName: draftFiles[0].chapterName,
        versesName: chunkName,
      });
      setSourceFiles({
        projectId: draftFiles[0].projectId,
        chunk: res.data,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const toggleComments = (data, id) => {
    // setShowComments(!showComments);
    // setCommentData(data);
    // setCommnetObjId(id);

    if (isCommentShow == true && currentCommentId == id) {
      setIsCommentShow(false);
    } else if (isCommentShow == true) {
      setComments(data);
      setCurrentCommentId(id);
    } else {
      setIsCommentShow(true);
      setComments(data);
      setCurrentCommentId(id);
    }
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "name",
      render: (value, record) => (
        <>
          <FilenameComponent file={record} />
        </>
      ),
    },
    {
      title: (
        <span className={`flex items-center  justify-end mr-4-5`}>Action</span>
      ),
      dataIndex: "",
      key: "x",
      render: (item, record) => (
        <div className="flex items-center justify-end ">
          {record.fileType == "audio" ? (
            <AudioPlayer
              revokeOtherFunctions={isLoading}
              data={record}
              isDisabled={isLoading}
              isReverse={false}
            />
          ) : null}
          {record.fileType == "video" ? (
            <VideoPlayer
              data={record}
              isDisabled={isLoading}
              fileType="DRAFTFILE"
              isReverse={false}
            />
          ) : null}
          {record.fileType == "script" ? (
            <DraftScriptModal data={record} isDisabled={isLoading} />
          ) : null}

          <div className="mx-2">
            <Button
              onClick={(e) => {
                toggleComments(record.comments, record.fileName);
              }}
            >
              {isCommentShow == true && currentCommentId == record.fileName ? (
                <FaCommentSlash size={16} />
              ) : (
                <FaComment size={16} />
              )}
            </Button>
          </div>
        </div>
      ),
    },
  ];

  const loadBackTranslateFiles = async () => {
    try {
      setIsBackTranslateLoading(true);
      let draftFiles = await draftedFiles.filter(
        (item) => item.sourceVerseName == chunkName
      );
      let responsefiles = [];
      for (let i = 0; i < draftFiles.length; i++) {
        let res = await axios.get(
          `${draftFile.getAllDraftedFiles}?bookName=${book.bookName
          }&projectId=${book.projectId}&chapterName=${chapter.chapterName
          }&targetType=backTranslate&isSelected=true&relatedDraft=${draftFiles[i].fileName
          }&${userType == "cit"
            ? "citStatusCheck=true"
            : userType == "consultant"
              ? "consultantStatusCheck=true"
              : ""
          }`
        );


        responsefiles = [...responsefiles, ...res.data.draftedFiles];
      }
      setBacktraslateFiles(responsefiles);
      setIsBackTranslateLoading(false);
    } catch (error) {
      console.log(error);
      setIsBackTranslateLoading(true);
    }

  };

  const loadMasterRecordFiles = async () => {

    try {
      setIsMasterRecordLoading(true);
      let draftFiles = await draftedFiles.filter(
        (item) => item.sourceVerseName == chunkName
      );
      let responsefiles = [];
      for (let i = 0; i < draftFiles.length; i++) {
        let res = await axios.get(
          `${draftFile.getAllDraftedFiles}?bookName=${book.bookName}&projectId=${book.projectId}&chapterName=${chapter.chapterName}&targetType=masterRecord&isSelected=true&relatedDraft=${draftFiles[i].fileName}`
        );
        responsefiles = [...responsefiles, ...res.data.draftedFiles];
      }
      setMasterRecordFiles(responsefiles);
      setIsMasterRecordLoading(false);

    } catch (error) {
      console.log(error);
      setIsMasterRecordLoading(true);
    }
  };

  const handlePaginationHover = () => {
    if(isSync==false){
      setIsSync(true);
      if (isPlayingStatus == true) {
        showNotification("error", " Please pause current playing audio");
      }
      else if (isRecording == true) {
        showNotification("error", "Please stop recording");
      }

      setTimeout(() => {
        setIsSync(false)
      }, 5000);
    }
    
  }

  return (
    <>{NotificationJSX}
      {/* <Tooltip placement="topLeft" title={"view Files"}>
        <Button
          shape="round"
          key={chunkName}
          className="mx-2 my-1 capitalize"
          onClick={showModal}
        >
          {chunkName}
        </Button>
      </Tooltip> */}
      <Modal
        title={chunkName}
        open={techModal}
        visible={techModal}
        onOk={handleOk}
        closable={isPlayingStatus == false} 
        onCancel={handleCancel}
        maskClosable={false}
        width={"80%"}
        footer={<></>}
      >
        <div>
          {isLoading == true ? (
            <SmallSpinnerComponent />
          ) : (
            <>
              <div>
                <SourceLanguage
                  chunk={sourceFiles}
                  title={<h5>Source File</h5>}
                />
              </div>
            </>
          )}
        </div>

        <Tabs
          type="card"
          className="pt-2"
          moreIcon={<RiArrowRightSFill />}
          size="middle"
          onChange={(e) => {
            setIsCommentShow(false);
            setComments([]);
            // onTabClick();
          }}
        >
          <Tabs.TabPane disabled={isPlayingStatus} key="1" tab={<span>Draft Files</span>}>
            <Table
              loading={{
                spinning: isLoading,
                indicator: (
                  <span>
                    <SmallSpinnerComponent />
                  </span>
                ),
              }}
              pagination={{
                pageSize: 2,
                hideOnSinglePage: true,
              }}
              dataSource={draftFiles}
              columns={columns}
            />
          </Tabs.TabPane>
          <Tabs.TabPane disabled={isPlayingStatus} key="2" tab={<span>Back Translate</span>}>
            <Table
              loading={{
                spinning: isBackTranslateLoading,
                indicator: (
                  <span>
                    <SmallSpinnerComponent />
                  </span>
                ),
              }}
              scroll={{ y: 180 }}
              pagination={false}
              dataSource={backtraslateFiles}
              columns={columns}
            />
          </Tabs.TabPane>
          <Tabs.TabPane disabled={isPlayingStatus} key="3" tab={<span>Master Record</span>}>
            <Table
              loading={{
                spinning: isMasterRecordLoading,
                indicator: (
                  <span>
                    <SmallSpinnerComponent />
                  </span>
                ),
              }}
              scroll={{ y: 180 }}
              pagination={false}
              dataSource={masterRecordFiles}
              columns={columns}
            />
          </Tabs.TabPane>
        </Tabs>

        {isCommentShow == true ? (
          <div className="mt-3">
            <h3 className="  ">Comment</h3>
            <div className="comments">
              <List
                loading={{
                  spinning: isLoading,
                  indicator: (
                    <span>
                      <SmallSpinnerComponent />
                    </span>
                  ),
                }}
                className="comment-list"
                itemLayout="horizontal"
                // pagination={{
                //   pageSize: 2,
                // }}

                pagination={{
                  pageSize: 2,
                  // disabled: isPlayingStatus && isRecording,
                  disabled: isPlayingStatus == true || isRecording == true ? true : false,
                  itemRender: (page, type, originalElement) => {
                    if (type === 'page') {
                      // Conditionally render based on the isDisabled variable
                      if (isPlayingStatus && isRecording) {
                        return (
                          <div style={{ display: 'inline-block', color: 'gray' }}>
                            {originalElement}
                          </div>
                        );
                      } else {
                        return (
                          <div
                            onMouseEnter={handlePaginationHover}
                            style={{ display: 'inline-block' }}
                          >
                            {originalElement}
                          </div>
                        );
                      }
                    }
                    return originalElement;
                  },
                }}
                dataSource={comments}
                renderItem={(item) => (
                  <Comment
                    author={
                      <div className="flex items-center">
                        <span className="text-lg  text-black">{item.user}</span>
                      </div>
                    }
                    content={
                      <>
                        {item.fileType == "audio" ? (
                          <>
                            <div className="w-50">
                              <span
                                className={`pointers-compuslory w-full  ${localStorage.getItem("id") != item.userId ? "" : "mx-2"
                                  }`}
                              >
                                <AudioPlayer
                                  data={item}
                                  audioType={"COMMENTFILE"}
                                  // audioType={item.fileType}
                                  fileName={item.fileName}
                                  // reload={loadAudioFiles}
                                />
                              </span>
                            </div>
                          </>
                        ) : null}
                        {item.fileType == "video" ? (
                          <>
                            <VideoPlayer data={item} fileType={"COMMENTFILE"} />
                          </>
                        ) : null}
                        {item.fileType == "text" ? (
                          <>
                            <div className="commentText">
                              <ReactReadMoreReadLess
                                charLimit={200}
                                readMoreText={"Read more ▼"}
                                readLessText={"Read less ▲"}
                                readMoreStyle={{
                                  color: "red",
                                  cursor: "pointer",
                                  whiteSpace: "break-spaces",
                                }}
                                readLessStyle={{
                                  color: "red",
                                  cursor: "pointer",
                                }}
                              >
                                {item.comments}
                              </ReactReadMoreReadLess>
                            </div>
                          </>
                        ) : null}
                      </>
                    }
                  ></Comment>
                )}
              />
            </div>
          </div>
        ) : null}


      </Modal>
    </>
  );
};

export default TechnicianFileModal;
