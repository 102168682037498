import { Alert, Button, ConfigProvider, Empty, message, Progress } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CgPlayBackwards, CgPlayForwards } from "react-icons/cg";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import MainLayout from "../../components/layout/main.layout";
import TitleBar from "../../components/ui/title-bar/title-bar.component";
import { List } from "antd";
import VirtualList from "rc-virtual-list";
import { UNSAFE_NavigationContext, useLocation } from "react-router";
import SpinnerComponent from "../../components/spinner/spinner.component";
import axios from "axios";
import getHeader from "../../services/config";
import { s3_files_url,draftFile } from "../../services/apis";
// import { getDraftFileByBookName } from "../../services/indexed-db/draftfiles.indexeddb";
import { FaComment, FaEye } from "react-icons/fa";
import CommentModal from "../../components/modal/comment-modal";
import FilenameComponent from "../../components/ui/filename.component";
import {getDraftedFilesByBookNameOnline} from "../../services/api-calls/draft-files.api"
const ScriptPage = () => {
  const location = useLocation();
  const navigator = useContext(UNSAFE_NavigationContext).navigator;
  const [isLoading, setIsLoading] = useState(true);
  const [currentPlaying, setCurrentPlaying] = useState({});
  const [scripts, setScripts] = useState([]);
  const [viewFile, setViewFile] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    type: "",
    message: "",
  });

  const currentScriptIndex = useRef(0);

  useEffect(() => {
    loadScripts();
    currentScriptIndex.current = 0;
  }, []);

  const loadScripts = () => {
    return new Promise(async (resolve, reject) => {
      let result = [];
      // let draftedFile = await getDraftFileByBookName(
      //   location.state.book.bookName
      // );

      let draftData = {
        projectId:location.state.book.projectId,
        bookName: location.state.book.bookName,
      };
      if(Array.isArray(location.state.chapter)){
        if(location.state.type == 'PLAY-SELECTED'){
         draftData.chapterName=  { $in: location.state.chapter }
        }
        else{
         const chapterNames = location.state.chapter.map(({ chapterName }) => chapterName);
         draftData.chapterName=  { $in: chapterNames }
        }
       
       }
       else{
         draftData["chapterName"]= await location.state.chapter;
       }
      
      let draftedFile = await getDraftedFilesByBookNameOnline(draftData);

      let targetType;
      if (
        localStorage.getItem("role") == "Facilitator" ||
        localStorage.getItem("role") == "CIT" ||
        localStorage.getItem("role") == "Consultant"
      ) {
        targetType = "backTranslate";
      } else if (
        localStorage.getItem("role") == "MTT" ||
        localStorage.getItem("role") == "Technician"
      ) {
        targetType = "draft";
      }

      let filteredScript = [];
      if (location.state.type == "PLAY-SELECTED") {
        if (localStorage.getItem("role") == "Technician") {
          let chunks = location.state.chapter;
          for (let i = 0; i < chunks.length; i++) {
            let data = await draftedFile.filter(
              (item) =>
                (item.fileType == "script" || item.fileType == "text") &&
                item.targetType == targetType &&
                item.chapterName == chunks[i] &&
                (item.citStatusCheck == true ||
                  item.consultantStatusCheck == true)
            );

            filteredScript = [...filteredScript, ...data];
          }
        } else {
          if (localStorage.getItem("aduioPlay") == "chapter") {
            let chunks = location.state.chapter;
            for (let i = 0; i < chunks.length; i++) {
              let data = await draftedFile.filter(
                (item) =>
                  (item.fileType == "script" || item.fileType == "text") &&
                  item.targetType == targetType &&
                  item.chapterName == chunks[i]
              );

              filteredScript = [...filteredScript, ...data];
            }
          } else {
            let chunks = location.state.chunk;
            for (let i = 0; i < chunks.length; i++) {
              let data = await draftedFile.filter(
                (item) =>
                  (item.fileType == "script" || item.fileType == "text") &&
                  (item.chapterName == location.state.chapter ||
                    item.chapter == location.state.chapter) &&
                  item.targetType == targetType &&
                  item.sourceVerseName == chunks[i]
              );
              filteredScript = [...filteredScript, ...data];
            }
          }
        }
        let sortedScripts = await sortFiles(filteredScript);
        if (sortedScripts.length > 0) {
          viewScript(sortedScripts[0],0)
          // setViewFile(sortedScripts[0]);
          setCurrentPlaying(sortedScripts[0]);
        } else {
          setViewFile();
          setCurrentPlaying();
        }
        setScripts(sortedScripts);
        setIsLoading(false);
        resolve(filteredScript);
      } else {
        let role = localStorage.getItem("role");
        if (role == "CIT" || role == "Consultant" || role == "Technician") {
          if (role == "Technician") {
            let chunks = location.state.chapter;
            for (let i = 0; i < chunks.length; i++) {
              let data = await draftedFile.filter(
                (item) =>
                  (item.fileType == "script" || item.fileType == "text") &&
                  item.targetType == targetType &&
                  item.chapterName == chunks[i].chapterName &&
                  (item.citStatusCheck == true ||
                    item.consultantStatusCheck == true)
              );
              filteredScript = [...filteredScript, ...data];
            }
          } else {
            let chunks = location.state.chapter;

            for (let i = 0; i < chunks.length; i++) {
              let data = await draftedFile.filter(
                (item) =>
                  (item.fileType == "script" || item.fileType == "text") &&
                  item.targetType === targetType &&
                  item.chapterName === chunks[i].chapterName
              );
              filteredScript = [...filteredScript, ...data];
            }
          }
        } else {
          filteredScript = await draftedFile.filter(
            (item) =>
              (item.fileType == "script" || item.fileType == "text") &&
              (item.chapterName == location.state.chapter ||
                item.chapter == location.state.chapter) &&
              item.targetType == targetType
          );
        }

        let sortedScripts = await sortFiles(filteredScript);
        if (sortedScripts.length > 0) {
          viewScript(sortedScripts[0],0)
          // setViewFile(sortedScripts[0]);
          setCurrentPlaying(sortedScripts[0]);
        } else {
          setViewFile();
          setCurrentPlaying();
        }
        setScripts(sortedScripts);
        setIsLoading(false);
        resolve(filteredScript);
      }
    });
  };

  const ViewAllScript = async () => {
    if (scripts.length > 0) {
      await viewScript(
        scripts[currentScriptIndex.current],
        currentScriptIndex.current
      );
    }
  };

  const viewScript = async (data, index, playNext) => {
    return new Promise(async (resolve, reject) => {
      try {
        currentScriptIndex.current = index;

        setCurrentPlaying(data);
        if (window.navigator.onLine == false) {
          let scriptContent = await window.api.readFile("readFile", data);
          setViewFile(scriptContent);
        } else {
          let res = await axios.get(
            `${s3_files_url}/${data?.fileId}/${data?.fileName}`
          );
          setViewFile(res.data);
        }
      } catch (error) {
        console.log(error);
        showAlertMessage("error", "Contact to admin , file might be deleted");
      }
    });
  };

  const viewNext = () => {
    currentScriptIndex.current = currentScriptIndex.current + 1;
    if (currentScriptIndex.current == scripts.length) {
      currentScriptIndex.current = 0;
    }
    if (scripts.length >= 0 && scripts.length > currentScriptIndex.current) {
      viewScript(
        scripts[currentScriptIndex.current],
        currentScriptIndex.current,
        true
      );
    }
  };

  const viewPrev = () => {
    currentScriptIndex.current = currentScriptIndex.current - 1;
    if (currentScriptIndex.current == scripts.length) {
      currentScriptIndex.current = 0;
    }
    if (scripts.length >= 0 && scripts.length > currentScriptIndex.current) {
      viewScript(
        scripts[currentScriptIndex.current],
        currentScriptIndex.current,
        true
      );
    }
  };

  const showAlertMessage = async (type, message) => {
    setAlertMessage({
      ...alertMessage,
      type,
      message,
    });
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 1500);
  };

  const sortFiles = async (files) => {
    return new Promise(async (resolve, reject) => {
      let data = files.sort((a, b) => (a.isSelected < b.isSelected ? 1 : -1));
      resolve(data);
    });
  };

  return (
    <div>
      <MainLayout>
        <div className="draft-details ">
          <div className="draft-details-wrapper h-screen">
            <TitleBar title={"Script View"} />
            {showAlert && (
              <Alert
                message={alertMessage.message}
                type={alertMessage.type}
                className="my-2 p-1"
                showIcon
                closable
              />
            )}
            {isLoading ? (
              <SpinnerComponent />
            ) : (
              <div className="grid grid-cols-2 pt-4">
                <div className="mx-2 h-100">
                  <div className="">
                    <h2 className="text-center py-2">
                      <FilenameComponent file={currentPlaying} />
                    </h2>
                  </div>

                  <div
                    className=" mx-2 py-4 overflow-scroll "
                    style={{ height: "250px" }}
                  >
                    {currentPlaying && (
                      <p
                        className="break-spaces "
                        dangerouslySetInnerHTML={{ __html: viewFile }}
                      ></p>
                    )}
                  </div>
                  <div className="script-controls justify-content-between flex items-center  py-1">
                    <div className="float-start">
                      <button
                        className="remove-btn-css "
                        disabled={
                          currentScriptIndex.current == 0 ? true : false
                        }
                        onClick={viewPrev}
                      >
                        <IoIosArrowBack
                          size={26}
                          color={`${
                            currentScriptIndex.current == 0 ? "gray" : "black"
                          }`}
                        />{" "}
                        <span className="fw-bold">Prev</span>
                      </button>
                    </div>
                    <div className="float-end">
                      <button className="remove-btn-css" onClick={viewNext}>
                        <span className="fw-bold">Next</span>{" "}
                        <IoIosArrowForward size={26} />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="ml-4 shadow-lg p-4">
                  <h5>Script</h5>

                  <List>
                    {scripts.length > 0 ? (
                      <VirtualList data={scripts} height={370} itemKey="email">
                        {(item, i) => (
                          <List.Item key={item._id}>
                            <span className="flex">
                              <FaEye
                                size={15}
                                className="mt-1 mr-2 "
                                onClick={(e) => {
                                  viewScript(item, i, false);
                                }}
                              />

                              <span className="text-lg flex mr-4 items-center">
                                {/* {item.fileName || item.name}
                                 */}
                                <span className="mr-2">
                                  <FilenameComponent file={item} />
                                </span>

                                <span className="mx-4">
                                  {item.isSelected == true && (
                                    <>
                                      <CommentModal draftFile={item} />
                                    </>
                                  )}
                                </span>
                              </span>
                              <span></span>
                            </span>
                          </List.Item>
                        )}
                      </VirtualList>
                    ) : (
                      <Empty />
                    )}
                  </List>
                </div>
              </div>
            )}
          </div>
        </div>
      </MainLayout>
    </div>
  );
};

export default ScriptPage;
