import React from "react";
import SmallSpinnerComponent from "./small-spinner.component";

const SpinnerComponent = () => {
  return (
    <div>
      <div className="text-center my-4 py-4 h-screen flex items-center justify-center">
        <div>
          {/* <i className="fas fa-spinner fa-2x fa-spin spinner spinnerTop"></i> */}
          <SmallSpinnerComponent />

          <div className="loaderText mt-2">Loading</div>
        </div>
      </div>
    </div>
  );
};

export default SpinnerComponent;
