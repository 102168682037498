import { Button, Popover } from "antd";
import React from "react";
import { useState, useEffect } from "react";

const ProgressPopOver = ({name, data, bookName, type, selectChunk,progress, changeCurrentBook,selectChapter}) => {
  const [progressData, setProgressData] = useState({
    Total: 0,
    Completed: 0,
    Review: 0,
    Pending: 0,
  });
  const [isLoading,setIsLoading] = useState();

  const getChapterProgress = async (chapterProgress) => {
    let progress = {};
    
  
           progress = {
              Total: chapterProgress.total,
              Completed: chapterProgress.completed,
              Review: chapterProgress.review,
              Pending: chapterProgress.pending,
            };
          
        
    return progress;

  };

  const getProgressData = async () => {
    setIsLoading(true)
    if ((type === "book")||(type === "projectBook")) {
      let progressData = {
        Total: progress[0].total,
        Completed: progress[0].completed,
        Review: progress[0].review,
        Pending: progress[0].pending,
      };
      setProgressData(progressData);
      setIsLoading(false)
    } else if ((type === "chapter")||(type === "projectChapter")) {

      let progressData = await getChapterProgress(progress);
      setProgressData(progressData);
      setIsLoading(false)
    }
    setIsLoading(false);
  };
  
  
  const content = (
    <>
    
    <div className="d-flex flex-column gap-1">
    <Button
      shape="round"
      onMouseOver={(e) => (e.target.style.color = "#FFF")}
      style={{ backgroundColor: "#031832", color: "#FFF" }}
    >
      Total:   {progressData.Total}
    </Button>
    <Button
      shape="round"
      onMouseOver={(e) => (e.target.style.color = "#FFF")}
      style={{ backgroundColor: "#00D100", color: "#FFF" }}
    >
      Completed:  {progressData.Completed}
    </Button>
    {(type == "book" ||type =="chapter" )&&<Button
      shape="round"
      onMouseOver={(e) => (e.target.style.color = "#FFF")}
      style={{ backgroundColor: "#FAD02C", color: "#FFF" }}
    >
      Review:
      {progressData.Review}
    </Button>}
    <Button
      shape="round"
      onMouseOver={(e) => (e.target.style.color = "#FFF")}
      style={{ backgroundColor: "#DE0001", color: "#FFF" }}
    >
      Pending:
      {progressData.Pending}
    </Button>
  </div>
 
    </>
  
  );

  return (
    <>
   
   
  <Popover content={content} title="Progress" placement="topLeft">
  <Button
    shape="round"
    key={name}
    style={{width: type === 'chapter' ? '120px' : 'auto'}}
    className="mx-2 capitalize mt-2"
    onClick={(e) => {
     if( type == "book" ){ 
      changeCurrentBook(data)
    } else if((type == "chapter")||(type == "projectChapter")){
       selectChunk(data)}
     else if(type=="projectBook"){
      selectChapter(data);
     };
    }}
    onMouseOver={getProgressData}
  >
    {name}
  </Button>
</Popover>

    </>
  );
};
export default ProgressPopOver;
