import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, notification, Tooltip } from "antd";
import { IoMdClose } from "react-icons/io";
import { HiOutlineMicrophone } from "react-icons/hi";
import { IoSend } from "react-icons/io5";
import { AiFillCloseCircle } from "react-icons/ai";
import "./chatbot.css";
import { useLocation } from "react-router";
import axios from "axios";
import { base_url, desktop, s3_files_url } from "../../services/apis";
import { renderToStaticMarkup } from "react-dom/server";
import SmallSpinnerComponent from "../spinner/small-spinner.component";
import { FiExternalLink, FiMaximize2 } from "react-icons/fi";
import { waitForAWhile } from "../../services/config";
import {
  getChatbotFilesByChapter,
  getChatbotLanguage,
} from "../../services/api-calls/chatbot.api";
import { stopTexttoSpeech } from "../../services/speech";
import UserMessage from "./user-message";
import SelectLangugaeJSX from "./select-language";
import SelectChunkJSX from "./select-chunk";
import SpeechPlay from "./speech-play";
import { useDispatch, useSelector } from "react-redux";
import { toggleSpeech } from "../../redux/actions/util.action";
import SpeechRecognition from "./speech-recognition";
import chatbot_logo from "../../assets/images/rhema_bot.png";
import RhemaLogo from "../../assets/images/rhema_logo.png";
import ChatBotSelectionJSX from "./chatbot-selection";
import AIMessageJSX from "./ai-message";

const Chatbot = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const messageContainerRef = useRef();

  const [form] = Form.useForm();

  const [isOpen, setIsOpen] = useState(false);
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [chunks, setChunks] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isMaximize, setisMaximize] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [parentChunk, setParentChunk] = useState([]);
  const [isRecognising, setIsRecognising] = useState(false);
  const [role, setRole] = useState("");
  const [currentSelectedBotType, setCurrentSelectedBotType] = useState("");
  const [isChatGPT, setIsChatGPT] = useState(false);
  const [isBard, setIsBard] = useState(false);
  const fileType = useRef("");
  const screens = useRef(1);
  const [notificationApi, NotificationJSX] = notification.useNotification();
  const showNotification = (type, message) => {
    notification.destroy();
    notificationApi[type]({
      message,
    });
  };

  const isVoiceRecognising = useSelector(
    (state) => state.voiceRecognitionReducer
  );

  const scrollToBottom = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let timeout = setTimeout(() => {
          const messageContainer = document.getElementById(
            "messageContainerChat"
          );
          if (messageContainer) {
            messageContainer.lastElementChild.scrollIntoView({
              behavior: "smooth",
            });
            resolve();
            clearTimeout(timeout);
          } else {
            resolve();
            clearTimeout(timeout);
          }
        }, 500);
      } catch (error) {
        resolve();
        console.error("Error scrolling to bottom:", error);
      }
    });
  };

  useEffect(() => {
    // scrollToBottom();
    setIsLoader(true);
    welcomeMsg();
    // loadChatbotResource();

    dispatch(toggleSpeech(false));
    return () => {
      setChunks([]);
      setParentChunk([]);
      setMessages([]);
    };
  }, [location.key]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const welcomeMsg = () => {
    return new Promise(async (resolve, reject) => {
      setMessages((prev) => [
        ...prev,
        ...[
          {
            sender: "BOT",
            content: (
              <span>
                Hi, I’m RhemaBot, your virtual agent.
                <br />
                How can I help you today?
              </span>
            ),
            type: "BOT_MESSAGE",
          },
        ],
      ]);
      chatbotTypes();

      setIsLoader(false);
    });
  };

  const loadChatbotResource = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let userRole = localStorage.getItem("role");

        setRole(userRole);
        if (userRole == "MTT" || userRole == "Facilitator") {
          // load MTT or Facilitator
          fileType.current = "mttresource";
          let chunks = await loadChunkFiles(
            location.state?.project?.majorLanguage1
          );
          setChunks(chunks);
          setMessages((prev) => [
            ...prev,
            ...[{ sender: "BOT", content: chunks, type: "CHATBOT_CHUNKS" }],
          ]);

          if (chunks && chunks.length == 0) chatbotTypes();
          setIsLoader(false);
        } else {
          // load CIT or Consultant
          fileType.current = "citresource";

          fileType.current = "citresource";
          let response = await getChatbotLanguage({
            targetType: "chatbot",
            type: "citresource",
            bookName: location.state?.book?.bookName,
            chapterName: location?.state?.chapter,
          });
          setLanguages(response);

          setMessages((prev) => [
            ...prev,
            ...[{ sender: "BOT", content: "", type: "CHATBOT_LANGUAGE" }],
          ]);
          setIsLoader(false);

          for (let i = 0; i < response.length; i++) {
            let chunks = await loadChunkFiles(response[i]);
            setParentChunk((prev) => [...prev, ...chunks]);
          }
        }
        setIsLoader(false);
      } catch (error) {
        console.log(error);
        setIsLoader(false);
      }
    });
  };

  const loadChunkFiles = async (language) => {
    try {
      let res = await getChatbotFilesByChapter({
        bookName: location.state?.book?.bookName,
        chapterName: location?.state?.chapter,
        language,
        targetType: "chatbot",
        fileType: fileType.current,
      });
      return res;
    } catch (error) {}
  };

  const BotMessageJSX = ({ item, i }) => {
    return (
      <>
        <div className="text-black message-content clearfix">
          {item?.type == "BOT_MESSAGE" ? (
            <div className="float-left mxwidth-800">
              <p className="message bot-message ">{item.content} </p>
              {item?.type == "BOT_MESSAGE" ? (
                <SpeechPlay text={item.content} />
              ) : null}
            </div>
          ) : null}
          {item?.type == "BOT_AI_MESSAGE" ? (
            <div className="float-left mxwidth-800">
              <p className="message bot-message ">
                <span>
                  {" "}
                  <AIMessageJSX verseData={item.content} />
                </span>
                {isMaximize == true ? (
                  <Button
                    className="mx-2 bg-chatbot px-1 py-1 "
                    disabled={isBotTyping}
                  >
                    <Tooltip title="New tab">
                      <FiExternalLink
                        size={15}
                        onClick={(e) => {
                          splitScreen(item.content);
                        }}
                      />
                    </Tooltip>
                  </Button>
                ) : null}
              </p>
              <SpeechPlay text={item.content} />
            </div>
          ) : null}

          {item?.type == "CHATBOT_CHUNKS" ? (
            <SelectChunkJSX
              isMaximized={isMaximize}
              chunks={item.content}
              selectChunk={selectChunk}
              elementId={i}
              splitScreen={splitScreen}
              botTyping={isBotTyping}
            />
          ) : null}

          {item?.type == "CHATBOT_LANGUAGE" ? (
            <SelectLangugaeJSX
              languages={languages}
              selectLanguage={selectLanguage}
            />
          ) : null}
          {item?.type == "CHATBOT_TYPES" ? (
            <ChatBotSelectionJSX
              chatbots={item.content}
              selectChatbot={selectChatbot}
            />
          ) : null}
        </div>
        <span className="message-user-icons mt-1">
          <img src={chatbot_logo} style={{ width: "1.8rem" }} alt="rhema" />
        </span>
      </>
    );
  };

  const BotTypingJSX = () => {
    return (
      <>
        <div className="text-black message-content clearfix">
          <span className="message bot-message float-left">
            <div className="col-3">
              <div className="snippet px-4 py-1" data-title="dot-typing">
                <div className="stage">
                  <div className="dot-typing"></div>
                </div>
              </div>
            </div>
          </span>
        </div>
        <span className="message-user-icons mt-1">
          <img src={RhemaLogo} style={{ width: "2rem" }} alt="rhema" />
        </span>
      </>
    );
  };

  const selectChatbot = async (chatbotName) => {
    try {
      setCurrentSelectedBotType((e) => chatbotName);
      setMessages((prev) => [
        ...prev,
        ...[{ sender: "USER", content: chatbotName, type: "USER_MESSAGE" }],
      ]);
      if (chatbotName == "Rhema Bot") {
        loadChatbotResource();
      } else {
      }
    } catch (error) {}
  };

  const selectChunk = async (item, isMessage) => {
    try {
      stopTexttoSpeech();
      dispatch(toggleSpeech(false));
      setIsBotTyping(true);

      if (isMessage) {
        setMessages((prev) => [
          ...prev,
          ...[{ sender: "USER", content: item.verse, type: "USER_MESSAGE" }],
        ]);
      }
      let res = await axios.get(
        `${s3_files_url}/${item.fileId}/${item.fileName}`
      );
      await waitForAWhile(1000);
      setMessages((prev) => [
        ...prev,
        ...[{ sender: "BOT", content: res.data, type: "BOT_MESSAGE" }],
      ]);

      if (role == "MTT" || role == "Facilitator") {
        // setMessages((prev) => [
        //   ...prev,
        //   ...[{ sender: "BOT", content: chunks, type: "CHATBOT_CHUNKS" }],
        // ]);
      } else {
        // setMessages((prev) => [
        //   ...prev,
        //   ...[{ sender: "BOT", content: "", type: "CHATBOT_LANGUAGE" }],
        // ]);
      }
      setIsBotTyping(false);
      chatbotTypes();
    } catch (error) {
      setIsBotTyping(false);
    }

    // const element = document.getElementById("scroll-down");
    // element.scrollTop = element.scrollHeight;
  };

  const selectLanguage = async (data) => {
    try {
      setMessages((prev) => [
        ...prev,
        ...[{ sender: "USER", content: data, type: "USER_MESSAGE" }],
      ]);
      setIsBotTyping(true);

      let chunkData = parentChunk.filter((item) => item.language == data);

      // setChunks(chunkData);

      await waitForAWhile(1000);

      setIsBotTyping(false);
      // display chunk list
      setMessages((prev) => [
        ...prev,
        ...[{ sender: "BOT", content: chunkData, type: "CHATBOT_CHUNKS" }],
      ]);
    } catch (error) {}
  };

  const maximize = () => {
    try {
      // window.open(
      //   "file:///Users/vipin/DEV/rhema-btdesktop/dist/mac/Rhema.app/Contents/Resources/app.asar/build/js/components/chatbot.js",
      //   "_blank",
      //   "top=500,left=200,frame=true,nodeIntegration=no"
      // );
      let layout = window.document.querySelector(".Layout");
      let layoutOpen = window.document.querySelector(".Layout-open");
      let chatBody = window.document.querySelector("#chat_body");

      if (!isMaximize) {
        layoutOpen.style.maxWidth = "100%";
        layout.style.maxWidth = "100%";
        chatBody.style.height = "90vh";
        chatBody.style.width = "99vw";
        setisMaximize(true);
      } else {
        layoutOpen.style.maxWidth = "300px";
        layout.style.maxWidth = "300px";
        chatBody.style.width = "25rem";
        chatBody.style.height = "400px";
        setisMaximize(false);
      }
    } catch (error) {}
  };

  const splitScreen = async (data) => {
    //fetching data from s3
    setIsBotTyping(true);
    try {
      let readableContent = null;
      if (typeof data == "string") {
        readableContent = data;
      } else {
        let res = await axios.get(
          `${s3_files_url}/${data.fileId}/${data.fileName}`
        );
        readableContent = res.data;
      }
      const div = document.createElement("div");
      let parentScreen = document.getElementById("split-box").children.length;

      if (parentScreen < 5) {
        const chunkMsg = document.createTextNode("");

        div.appendChild(chunkMsg);

        div.classList = "message user-message capitalize mx-2";
        div.id = screens.current;
        let splitBox = document.getElementById("split-box");
        await waitForAWhile(1000);

        splitBox.appendChild(div);

        let currentDiv = document.getElementById(screens.current);
        let closeIcon = renderToStaticMarkup(<AiFillCloseCircle size={25} />);
        let content = "";
        if (readableContent) {
          content = readableContent;
        } else {
          content = "Please try again";
        }

        const lines = content.split("\n");
        currentDiv.innerHTML +=
          "<div class='flex w-full stick-top ' id='" +
          screens.current +
          "'>" +
          "<p class='stick-top mxwidth-400 split-screen-scroll'>" +
          content +
          " </p>" +
          "<p class='cursor-pointer h-fit corner-close' onclick='let elem =document.getElementById(" +
          screens.current +
          "); elem.remove()'>" +
          closeIcon +
          "</p>  </div> ";

        screens.current = screens.current + 1;
      } else {
        showNotification("error", "Please close other tabs");
      }
    } catch (error) {
      setIsBotTyping(false);
    }
    setIsBotTyping(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const chatbotTypes = () => {
    try {
      setMessages((prev) => [
        ...prev,
        ...[
          {
            sender: "BOT",
            content: [
              {
                verse: "BARD",
              },
              {
                verse: "Chat GPT",
              },
              {
                verse: "Rhema Bot",
              },
            ],
            type: "CHATBOT_TYPES", //Keep different name
          },
        ],
      ]);
    } catch (error) {}
  };

  const submitHandler = async (values) => {
    try {
      if (values.send_message == undefined) {
        showNotification("error", "Please Enter text");
      } else {
        setMessages((prev) => [
          ...prev,
          ...[
            {
              sender: "USER",
              content: values.send_message,
              type: "USER_MESSAGE",
            },
          ],
        ]);
        setIsBotTyping(true);
        if (currentSelectedBotType == "Rhema Bot") {
          if (values.send_message && values.send_message.length > 0) {
            let chunkList;
            let chunkData;

            if (fileType.current == "citresource") {
              chunkList = parentChunk.filter(
                (item) =>
                  item.language.toLowerCase() ==
                    values.send_message.toLowerCase() ||
                  item.language == values.send_message ||
                  item.language == values?.send_message.trim()
              );

              chunkData = await parentChunk.filter(
                (item) =>
                  item?.verse.toLowerCase() ==
                    values.send_message.toLowerCase() ||
                  item?.verse == values.send_message ||
                  item?.verse == values?.send_message.trim()
              );
            } else {
              chunkList = chunks.filter(
                (item) =>
                  item.language.toLowerCase() ==
                    values.send_message.toLowerCase() ||
                  item.language == values.send_message ||
                  item.language == values?.send_message.trim()
              );

              chunkData = await chunks.filter(
                (item) =>
                  item?.verse.toLowerCase() ==
                    values.send_message.toLowerCase() ||
                  item?.verse == values.send_message ||
                  item?.verse == values?.send_message.trim()
              );
            }

            if (chunkList.length > 0) {
              setMessages((prev) => [
                ...prev,
                ...[
                  { sender: "BOT", content: chunkList, type: "CHATBOT_CHUNKS" },
                ],
              ]);
            }

            if (chunkData.length > 0) {
              selectChunk(chunkData[0], false);
            }

            if (chunkList.length == 0 && chunkData.length == 0) {
              setMessages((prev) => [
                ...prev,
                ...[
                  {
                    sender: "BOT",
                    content: "Resource not available",
                    type: "BOT_MESSAGE",
                  },
                ],
              ]);
            }
          } else {
            setMessages((prev) => [
              ...prev,
              ...[
                {
                  sender: "BOT",
                  content: "Resource not available",
                  type: "BOT_MESSAGE",
                },
              ],
            ]);
          }
        } else if (currentSelectedBotType == "BARD") {
          let url = `${base_url}${desktop.dt}${desktop.bardApi}`;
          let response = await axios.post(url, {
            botQuestion: values.send_message,
          });
          setMessages((prev) => [
            ...prev,
            {
              sender: "BOT",
              content: response.data[1].content,
              type: "BOT_AI_MESSAGE",
              // from: "backend"
            },
          ]);
        } else if (currentSelectedBotType == "Chat GPT") {
          let url = `${base_url}${desktop.dt}${desktop.chatBotApi}`;
          let response = await axios.post(url, {
            botQuestion: values.send_message,
          });
          setMessages((prev) => [
            ...prev,
            {
              sender: "BOT",
              content: response.data[1].content,
              type: "BOT_AI_MESSAGE",
              // from: "backend"
            },
          ]);
        }
        setIsBotTyping(false);
        setCurrentSelectedBotType(null);
        chatbotTypes();
        form.resetFields();
      }
    } catch (error) {
      setIsBotTyping(false);
      console.log("ERROR ", error);
    }
  };

  return (
    <div className="botIcon">
      {isOpen && (
        <div
          className="Layout Layout-open Layout-expand Layout-right"
          id="chat_body"
        >
          <div className="Messenger_messenger">
            {/* // chatbot header */}
            <div className="Messenger_header items-center justify-between">
              <div>
                <h4 className="Messenger_prompt text-white">Rhema Bot</h4>
              </div>
              <div className="chat_close_icon">
                <button
                  className="btn-icon"
                  onClick={maximize}
                  disabled={isVoiceRecognising}
                >
                  <FiMaximize2
                    size={20}
                    className="mx-2 bg-white text-black rounded-circle p-1 font-weight-bold"
                  />
                </button>
                <button
                  className="rotate-icons remove-btn-css"
                  disabled={isVoiceRecognising}
                  onClick={(e) => {
                    setIsOpen(false);
                    setisMaximize(false);
                    stopTexttoSpeech();
                    dispatch(toggleSpeech(false));
                  }}
                >
                  <IoMdClose
                    size={20}
                    className="bg-white text-black rounded-circle font-weight-bold"
                  />
                </button>
              </div>
            </div>

            {/* // chatbot message content area */}
            <div className="Messenger_content">
              {isRecognising == true ? (
                <SpeechRecognition
                  selectChunk={selectChunk}
                  chunks={parentChunk}
                  setIsRecognising={setIsRecognising}
                  setMessages={setMessages}
                  setIsBotTyping={setIsBotTyping}
                  showNotification={showNotification}
                  scrollToBottom={scrollToBottom}
                  parentChunk={parentChunk}
                />
              ) : (
                <>
                  <div className="Messages">
                    <div className="Messages_list text-black">
                      {isLoader == true ? (
                        <div className="flex items-center justify-center pt-5">
                          <SmallSpinnerComponent />
                        </div>
                      ) : (
                        <>
                          <div className="flex">
                            <div
                              id="messageContainerChat"
                              className="w-full split-screen-scroll"
                            >
                              {messages.length
                                ? messages.map((e, i) => (
                                    <div
                                      key={i}
                                      className="user-bot-chat-message"
                                    >
                                      {e.sender == "USER" ? (
                                        <UserMessage message={e.content} />
                                      ) : (
                                        <BotMessageJSX item={e} i={i} />
                                      )}
                                    </div>
                                  ))
                                : null}
                              {isBotTyping ? <BotTypingJSX /> : null}
                            </div>
                            {isMaximize == true ? (
                              <div>
                                <div
                                  className="flex h-full shrink-2"
                                  id="split-box"
                                ></div>
                              </div>
                            ) : null}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}

              <div
                className="flex w-full"
                id="messenger flex items-center bottom-end"
                style={{ borderTop: "1px solid black" }}
              >
                <Form
                  onFinish={submitHandler}
                  form={form}
                  autoComplete="off"
                  onFinishFailed={onFinishFailed}
                  id="messenger flex items-center"
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <Form.Item
                    name="send_message"
                    style={{
                      width: "100%",
                      marginBottom: "0",
                    }}
                  >
                    <Input
                      className="Input_field w-full"
                      placeholder="Send message.."
                    />
                  </Form.Item>

                  <Form.Item
                    style={{
                      marginBottom: "0",
                    }}
                  >
                    <div className="flex items-center">
                      <button type="submit" className="remove-btn-css">
                        <IoSend size={15} />
                      </button>
                      <button
                        type="button"
                        className="remove-btn-css px-2"
                        onClick={(e) => {
                          setIsRecognising(true);
                        }}
                      >
                        <HiOutlineMicrophone size={15} />
                      </button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="botIconContainer">
        <button
          className="iconInner remove-btn-css"
          disabled={isVoiceRecognising}
          onClick={(e) => {
            setIsOpen(!isOpen);
            stopTexttoSpeech();
            dispatch(toggleSpeech(false));
          }}
        >
          {isOpen ? (
            <span className="rotate-icons flex items-center justify-center">
              <AiFillCloseCircle size={30} />
            </span>
          ) : (
            <span className="flex items-center justify-center shake-icons">
              <img
                src={chatbot_logo}
                style={{ width: "2.5rem" }}
                alt="Rhemabot"
              />
            </span>
          )}
        </button>
      </div>
      {NotificationJSX}
    </div>
  );
};

export default Chatbot;
