import React from "react";

const AIMessageJSX = ({ verseData }) => {
  if (typeof verseData !== "string") {
    return <div>Error: Invalid data type</div>;
  }

  try {
    const lines = verseData.split("\n");

    if (lines.length === 1 && lines[0] === "") {
      return <div>No content to display</div>;
    }

    return (
      <div>
        {lines.map((line, index) => (
          <React.Fragment key={index}>
            {line.includes('"') ? <strong>{line}</strong> : line}
            <br />
          </React.Fragment>
        ))}
      </div>
    );
  } catch (error) {
    console.error("Error parsing string:", error);
    return <div>Error: Unable to parse data</div>;
  }
};

export default AIMessageJSX;
