import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, notification, Modal, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
// import { updateBooks } from "../../../services/indexed-db/books.indexeddb";
// import { deleteFileSync } from "../../../services/sync-methods/draft-files.sync";
import {deleteDraftedFileFromServer} from "../../../services/api-calls/draft-files.api"
import { useLocation } from "react-router";
const { confirm } = Modal;

const DeleteModalComponent = ({ name, data, reload, isDisabled }) => {
  const location = useLocation();
  const booksReducer = useSelector((state) => state.booksReducer);
  useEffect(() => {
  }, [name, data]);
  const isRecordingStatus = useSelector(
    (state) => state.recordingStatusReducer
  );

  const [api, contextHolder] = notification.useNotification();
  const showNotification = (type, message) => {
    notification.destroy();
    api[type]({
      message,
      placement: "top",
    });
  };
  const showDeleteConfirm = () => {
    confirm({
      title: <span className="">Are you sure want to delete this file?</span>,
      icon: <ExclamationCircleOutlined />,
      okText: "Confirm",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        if (reload) {
          try {
              await deleteDraftedFileFromServer(data);
            // await deleteFileSync(data);
            reload();
            showNotification("success", "File removed successfully");
          } catch (error) {
            console.error("Delete file modal ", error);
            reload();
            showNotification("success", "File removed successfully");
          }
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const updateChunkData = async (book) => {
    let chunk = location.state.chunk;
    chunk.chunksDataProgress = "pending";

    let books = booksReducer.map((item) => {
      if (item.bookName == book.bookName) {
        let bookProgress = book.bookProgress.filter((item) => {
          item.pending += 1;
          item.completed -= 1;
        });
        let ChapterProgress = book.chaptersProgress.filter((item) => {
          if (item.chapters == location.state.chapter) {
            item.pending += 1;
            item.completed -= 1;
          }
        });

        let chapter = book.chapters.filter((item) => {
          if (item.chapterName == location.state.chapter) {
            item.chunks.map((element) => {
              if (element.chunkName === chunk.chunkName) {
                element = chunk;
              }
            });
          }
        });
      }
    });

    // await updateBooks(book.book_id, book);
  };

  const toggleModal = () => {
    if (isRecordingStatus == true) {
      showNotification("error", "Please stop recording");
    } else {
      showDeleteConfirm();
    }
  };
  return (
    <>
      <Tooltip placement="topLeft" title="Delete">
        <Button
          className="hover"
          onClick={() => toggleModal()}
          disabled={isDisabled}
        >
          {name}
        </Button>
      </Tooltip>

      {contextHolder}
    </>
  );
};

export default DeleteModalComponent;
