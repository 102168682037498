import {  Button, Modal, notification, Tooltip } from "antd";
import React,{ useState, useEffect } from "react";
import { useLocation } from "react-router";
import TechnicianFileModal from "./technician-file.modal";
import getHeader from "../../../services/config";
import { desktop, base_url, bible,draftFile } from "../../../services/apis";
// import { getProjectById } from "../../../services/indexed-db/project.indexeddb";
import SyncBar from "../../ui/sync-bar/index";
import axios from "axios";
// import { oneDriveSync } from "../../../services/sync-methods/oneDriveSync";
// import { getAllDraftFiles } from "../../../services/indexed-db/draftfiles.indexeddb";
import "../technician-modal/technician.scss";
import SpinnerComponent from "../../spinner/spinner.component";
import  {Toggle} from "../../toggle-button/custom-toggle-button";
import { useSelector } from "react-redux";
import { getDraftedFilesByChunksOnline } from "../../../services/api-calls/draft-files.api";
import { generatePdf } from "../../../services/generate-pdf";
import { MdPictureAsPdf } from "react-icons/md";

const { confirm } = Modal;

const TechnicianModal = ({
  chapter,
  displayModal,
  displayModalForBook,
  userType,
  book,
  selectedProject,
}) => {
  const [isShow, setIsShow] = useState(false);
  const [chunks, setChunks] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [draftedFiles, setDraftedFiles] = useState([]);
  const [syncOption, setSyncOption] = useState();
  const [project, setProject] = useState();
  const [role, setRole] = useState();
  const [chaptersData, setChaptersData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const booksReducer = useSelector((state) => state.booksReducer);
  const projectsReducer = useSelector((state) => state.projectsReducer);
  const stateData = location.state;
  const [showModal, setShowModal] = useState(false);
  const [selectedChunk, setSelectedChunk] = useState({})

  useEffect(() => {
    setRole(localStorage.getItem("role"));
    setIsLoading(true);
    userType == "projectProgress" ? loadProgressData() : loadChunks();
  }, [location, setIsShow, userType]);

  const handleCancel = () => {
    setIsShow(false);
    chapter !=undefined ? displayModal("", "", false):displayModalForBook(userType,book,false,selectedProject);
  };
  const [notificationApi, NotificationJSX] = notification.useNotification();
  const showNotification = (type, message) => {
    notification.destroy();
    notificationApi[type]({
      message,
    });
  };

  const loadProgressData = async () => {
    return new Promise(async (resolve, reject) => {
      setIsShow(true);
      setIsLoading(true);
      let role = localStorage.getItem("role").toLowerCase();
     if(chapter !=undefined && chapter !=""){
      book = book.length > 0 ? book[0]:book 
        let filter = {
          bookName: book.bookName,
          isSyncProject: selectedProject?.isSyncNeed,
          majorLanguage1: selectedProject?.majorLanguage1,
          majorLanguage2: selectedProject?.majorLanguage2,
          majorLanguage3: selectedProject?.majorLanguage3,
          type: "audio",
          projectLanguage: selectedProject?.language,
          targetType: "backTranslate",
          role: role,
          projectId: selectedProject?.id,
        };
        let res = await axios.post(
          `${base_url}${bible.bible}${bible.getAllChaptersByBookName}`,
          filter,
          getHeader()
        );
        res = res.data.progressData || res.data;
        let selectedChapterData = res.filter(
          (item) => item.chapters == chapter.chapterName
        );

        setChaptersData(selectedChapterData[0]);
    }else{
     
        let lanuages = [selectedProject.majorLanguage1, selectedProject.majorLanguage2, selectedProject.majorLanguage3]
        let filter = {
          language: lanuages,
          targetType: 'backTranslate',
          projectLanguage: selectedProject.language,
          role: role,
        bookName:book[0].bookName,
          projectId: selectedProject.id,
        }
        let res = await axios.post(
          `${base_url}${bible.bible}${bible.getSingleBookProgress}`,
          filter,
          getHeader()
        );
        let selectedChapterData = res.data.filter(
          (item) => item.bookName == book[[0]].bookName
        );
        setChaptersData(selectedChapterData[0]);
      }

      setIsLoading(false);
    });
  };

  const openNotification = (message) => {
    notification.success({
      message: "Success",
      duration: 2,
      description: message,
    });
  };

  const syncData = async () => {
    try {
      if (window.navigator.onLine == true) {
        let data = {
          projectId: project._id,
          projectName: project.projectName,
          bookName: book.bookName,
          chapterName: chapter.chapterName,
          projectLanguage: project.language,
          targetType: userType == "master" ? "masterRecord" : "draft",
          checkType: userType,
          azureUser: localStorage.getItem("email"),
        };

        let url = base_url + desktop.dt + desktop.syncFilesToOneDrive;
        let result = await axios.post(url, data);
        result = result.data;

        if (result.message == "Files Uploaded Succesfully") {
          // await oneDriveSync(draftedFiles)
          setToggle(true)
          openNotification("Files Uploaded to OneDrive Successfully");


        } else {
          showNotification("error", result.message);
        }
      } else {
        showNotification("error", "Please check internet connection");
      }
      // console.log("Textx")
      // bar.style.width = "0%";
      // percent = 0;
      // syncText.innerText = "";
      // clearInterval(interval);
    } catch (error) {
      showNotification("error", "User is Not verified by OneDrive");
    }
  };

  const getAllDraftFiles=()=>{
    return new Promise(async (resolve, reject) => {
      try {
        let bookData = book.length > 0 ? book[0]:book 
        let projectData= await projectsReducer.filter((item)=>{return item.id==bookData.projectId})[0];
        let data={
          projectId:projectData.id,
          bookName:bookData.bookName,
          chapterName:chapter.chapterName,
          targetType: ["draft", "masterRecord"],
          isSelected:true
        }
        let files= await getDraftedFilesByChunksOnline(data);
        resolve({projectData,files})
      } catch (error) {
        console.log("ERROR",error);
      }
    })
  }


  const loadChunks = async () => {
    setIsShow(true);
    setIsLoading(true);
    let data= await getAllDraftFiles();
    const [project, draftedFiles] = await Promise.all([
      data.projectData,
      data.files
    ]);

    // const [project, draftedFiles] = await Promise.all([
    //   ...projectsReducer.filter((item)=>{return item.id==book.projectId || book[0].projectId}),
    //   // getProjectById(book.projectId),
    //   getAllDraftFiles()
    // ]);

    setSyncOption(window.navigator.onLine);



    const filteredDraftFiles = await draftedFiles.filter(item => {
      const sameProject = item.projectId === book.projectId;
      const sameBook = item.bookName === book.bookName;
      const sameChapter = item.chapterName === chapter.chapterName;
      const targetType =  (userType === "master" ? (item.targetType === "masterRecord"|| item.targetType == "draft"): item.targetType === "draft");
      const consultantStatus = userType === "consultant" ? item.consultantStatusCheck : true;

      return sameProject && sameBook && sameChapter && targetType && consultantStatus;
    });

    setProject(project);
    setDraftedFiles(filteredDraftFiles);

    const userTypeTargetType = await userType === "master" ? "masterRecord" : "draft";
    const filesUserType = filteredDraftFiles.filter(item => item.targetType == userTypeTargetType);
    const sortAlphaNum = (a, b) => a.localeCompare(b, 'en', { numeric: true })

    const uniqueChunks = await [...new Set(filesUserType.map(item => item.sourceVerseName))].sort(sortAlphaNum);
    const syncedFile = filesUserType.filter(item => !item.isSyncOneDrive);
    setToggle(syncedFile.length === 0);
    setChunks(uniqueChunks);
    setIsLoading(false);
  };


  const changeToggle = () => {
    showDeleteConfirm();
  };

  const showDeleteConfirm = () => {
    confirm({
      title: "Are sure you want to publish this file?",
      // icon: <ExclamationCircleOutlined />,
      okText: "Confirm",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        try {
          await syncData();
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const techModal = (value) => {
    
    setShowModal(value)
  }

  return (
    <>
      <SyncBar />
      {NotificationJSX}

      <Modal
        className="Technician"
        title={
          <div className="w-100 flex">
            <div className="w-50 capitalize">
              {(chapter != undefined && chapter != "") ? chapter.chapterName : book[0].bookName}
              {userType == "projectProgress" && role!="Technician" ?
              <MdPictureAsPdf
                className="ml-2"
                size="20"
                onClick={async (e) => {
                  await generatePdf({
                    projectName: selectedProject.projectName,
                    bookName: book.length > 0 ? book[0].bookName : book.bookName,
                    chapterName: chapter ? chapter.chapterName:"",
                    chaptersData: chaptersData
                  });
                }}
              /> : null}
            </div>
            {role == "Coordinator" && userType == "projectProgress" && (
              <div className="text-center fw-bold">
                <span>{"Progress"}</span>
              </div>
            )}
            {isLoading ? (
              <SpinnerComponent />
            ) : (
              <div className="w-50 justify-content-end text-end flex mx-3 px-3">
                <div className="flex ">
                  {role == "Technician" && userType != "view" && (
                    <span className="">
                      <span className=" w-25 ">
                       
                        <Tooltip
                          placement="bottomLeft"
                          title={
                            syncOption == true
                              ? ((chunks.length<=0) ? "No files": (toggle==false ? "Sync Files":"Already synced"))
                              : "Your are offline"
                          }
                        >
                          <Toggle
                            toggled={toggle}
                            changeToggle={changeToggle}
                            disable={chunks.length>0 ? false: true }
                          />
                        </Tooltip>
                      </span>
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        }
        visible={isShow}
        width={800}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
      >
        {userType != "projectProgress" ? (
          <>
            {isLoading ? (
              <div className="technicianSpinner">
                <SpinnerComponent />
              </div>
            ) : (
              <div className="mx-2 pt-3">
                {chunks.length ? (
                  <>
                    <div className="mt-1">
                      {chunks.map((project) => {
                        return (
                          <>

                            <Tooltip placement="topLeft" title={"view Files"}>
                              <Button
                                shape="round"
                                key={project.chunkName}
                                className="mx-2 my-1 capitalize"
                                onClick={() => {
                                  techModal(true)
                                  setSelectedChunk(project)
                                }}
                              >
                                {project}
                              </Button>
                            </Tooltip>
                            {/* <TechnicianFileModal
                              book={book}
                              chapter={chapter}
                              chunkName={project}
                              draftedFiles={draftedFiles}
                              userType={userType}
                            /> */}
                          </>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <>
                    <span>No Data Found </span>
                  </>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            {isLoading ? (
              <SpinnerComponent />
            ) : (
              <div className="mx-2 pt-3  flex justify-content-around">
                <table className="rounded-md border technician w-100 shadow-lg">
                  <thead>
                    <tr className="tr-drafted border-2 total">
                      <th>Stage Name</th>
                      <th>Total Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      className="tr-drafted border"
                      style={{ borderColor: "#000" }}
                    >
                      <td className="td-drafted border mr-2">CHUNK UPLOAD</td>
                      <td className="td-drafted border">
                        {chaptersData.upload}
                      </td>
                    </tr>
                    <tr className="tr-drafted border">
                      <td className="td-drafted border mr-2">DRAFT</td>
                      <td className="td-drafted border">
                        {chaptersData.draft}
                      </td>
                    </tr>
                    <tr className="tr-drafted border">
                      <td className="td-drafted border mr-2">TEAM CHECK</td>
                      <td className="td-drafted border">
                        {chaptersData.teamCheck}
                      </td>
                    </tr>
                    <tr className="tr-drafted border ">
                      <td className="td-drafted border mr-2">
                        COMMUNITY CHECK
                      </td>
                      <td className="td-drafted border">
                        {chaptersData.communityCheck}
                      </td>
                    </tr>
                    <tr className="tr-drafted border">
                      <td className="td-drafted border mr-2">
                        BACK TRANSLATION
                      </td>
                      <td className="td-drafted border">
                        {chaptersData.backTranslation}
                      </td>
                    </tr>
                    <tr className="tr-drafted border">
                      <td className="td-drafted border mr-2">
                        CONSULTANT CHECK
                      </td>
                      <td className="td-drafted border">
                        {chaptersData.consultantCheck}
                      </td>
                    </tr>
                    <tr className="tr-drafted border">
                      <td className="td-drafted border">MASTER RECORD</td>
                      <td className="td-drafted border">
                        {chaptersData.masterRecord}
                      </td>
                    </tr>
                    <tr className="tr-drafted border">
                      <td className="td-drafted border">PUBLISHING</td>
                      <td className="td-drafted border">
                        {chaptersData.publishing}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}

        <>{showModal == true &&
          <TechnicianFileModal
            book={book}
            chapter={chapter}
            chunkName={selectedChunk}
            draftedFiles={draftedFiles}
            userType={userType}
            showModal={techModal}
            techModal = {showModal}
          />}</>
      </Modal>
    </>
  );
};
export default TechnicianModal;
