import {
  Alert,
  Button,
  message,
  Select,
  Table,
  Tabs,
  Modal,
  Popover,
  Tooltip,
  Checkbox,
  Dropdown,
  Menu,
  Form,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { useRef } from "react";

import { BsPlayCircle } from "react-icons/bs";
import { RiArrowRightSFill, RiDraftLine } from "react-icons/ri";
import { GoChecklist } from "react-icons/go";
import { HiOutlineUserGroup } from "react-icons/hi";
import { BsTranslate, BsPersonCheck } from "react-icons/bs";
import { IoRecordingOutline } from "react-icons/io5";
import {
  MdOutlineFactCheck,
  MdOutlinePublish,
} from "react-icons/md";
import { useLocation, useNavigate } from "react-router";
import MainLayout from "../../components/layout/main.layout";
import SpinnerComponent from "../../components/spinner/spinner.component";
import TitleBar from "../../components/ui/title-bar/title-bar.component";
import {
  extractNumberFromString,
  getChunkName,
  sortBooks,
  sortChapters,
  sortChunks,
} from "../../services/config";
// import { getBooksByUserRole } from "../../services/indexed-db/books.indexeddb";
// import {
//   getProjectById,
//   getProjectByRoleAndId,
// } from "../../services/indexed-db/project.indexeddb";
import SelectChunk from "../../components/draft-page-component/select-chunk";
import ProgressPopOver from "../../components/modal/progress-modal/index";
import { FaEye } from "react-icons/fa";
import { GiProgression } from "react-icons/gi";
import { base_url, projects,desktop } from "../../services/apis";
import getHeader from "../../services/config";
import axios from "axios";
import { useSelector,useDispatch } from "react-redux";
import {saveBooksAndProjects} from "../../services/BooksAndProjects"
import { storeBooks,clearBooks } from "../../redux/actions/books.action";
import {storeProjects} from "../../redux/actions/project.action"
import { generatePdf } from "../../services/generate-pdf";
import { MdPictureAsPdf } from "react-icons/md";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { citConCheckTypeToServer } from "../../services/api-calls/check_file.api";

const { confirm } = Modal;

const CitConsultant = () => {
  const projectsReducer = useSelector((state) => state.projectsReducer);
  const booksReducer = useSelector((state) => state.booksReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(true);
  const [project, setProject] = useState([]);
  const [currentProject, setCurrentProject] = useState();
  const [currentBook, setCurrentBook] = useState([]);
  const [selectedBook, setSelectedBook] = useState({});
  const [currentBookName, setCurrentBookName] = useState("");
  const [currentProjectName, setCurrentProjectName] = useState("");
  const [currentChapter, setCurrentChapter] = useState([]);
  const [currentChapterName, setCurrentChapterName] = useState("null");
  const [chapterPlay, setChapterPlay] = useState(false);
  const [currentChunks, setCurrentChunks] = useState([]);
  const [placeHolder, setPlaceHolder] = useState("Please Select a Project");
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [toggle, setToggle] = useState(false);
  const prevSelectedProject = JSON.parse(
    localStorage.getItem("selectedProject")
  );
  const location = useLocation();
  const [showAlertMsg, setShowAlertMsg] = useState(false);
  const [activeKey, setActiveKey] = useState("1");
  const [showDraftPlayingOption, setShowDraftPlayingOption] = useState(false);
  const [isChunkSelection, setIsChunkSelection] = useState(false);
  const [multiSelect, setMultiSelect] = useState(false);
  const [oldTestamentBooks, setOldTestamentBooks] = useState([]);
  const [newTestamentBooks, setNewTestamentBooks] = useState([]);
  const [type, setType] = useState();
  const [selectedOption, setSelectedOption] = useState("PROJECT");
  useEffect(() => {
    if (role == "Consultant") {
      setActiveKey("6");
    } else if (role == "CIT") {
      setActiveKey("5");
    }

    fetchUserData();
    localStorage.removeItem("aduioPlay");
  }, [location]);

  const [api, contextHolder] = notification.useNotification();
  const showNotification = (type, message) => {
    notification.destroy();
    api[type]({
      message,
      placement: "top",
    });
  };

  const addChunks = async(chapters, chapterProgress) => {
    // console.log("BookProceess: ",bookData, progressData)
    for (const progress of chapterProgress) {
      const matchingBook = chapters.find((chapter) => chapter.chapterName == progress.chapterName);
      if (matchingBook) {
        matchingBook.chaptersProgress = [{
          total: progress.chaptersProgress[0].total,
          completed: progress.chaptersProgress[0].completed,
          review: progress.chaptersProgress[0].review,
          pending: progress.chaptersProgress[0].pending,
        }];
      }
      matchingBook.chapterStatus = progress.chapterStatus
    }
    return chapters;
  }

  const fetchUserData = async () => {
    return new Promise(async (resolve, reject) => {
      setisLoading(true);
      try {
        let userId = localStorage.getItem("id");
        let projectsData= await projectsReducer;
        let booksData = await booksReducer;
        setProject(projectsData);
        // if(booksData.length<=0 || projectsReducer.length<=0){
        //   let data= await saveBooksAndProjects();
        //   projectsData=data.projectsData
        //   booksData=data.booksData;
        //   await dispatch(storeProjects(data.projectsData))
        //   await dispatch(storeBooks(data.booksData));

        //   // const obj={role:localStorage.getItem("role"),userID: {
        //   //   id: userId,
        //   // },}
        //   // const projectUrl= `${base_url}${projects.project}${projects.projectByRole}`
        //   // let resp = await axios.post(projectUrl,obj,getHeader());
        //   // console.log("resp",resp);
        //   // projectsData=resp.data
        //   // setProject(projectsData)

        //   // const bookUrl= `${base_url}${desktop.dt}${desktop.getBooksDetails}`;
        //   // let res = await axios.post(bookUrl,obj,getHeader());
        //   // booksData=res.data[0].books
        // }

        if (projectsReducer.length <= 0 || booksReducer.length <= 0) {
          let data = await saveBooksAndProjects();
          data.projectsData.length > 0 ? data.projectsData.forEach(async (item) => {
            await dispatch(storeProjects([item]));
          }) : await dispatch(storeProjects([data.projectsData]));
          projectsData = await data.projectsData

          data.booksData.length > 0 ? data.booksData.forEach(async (item) => {
            await dispatch(storeBooks([item]));
          }) : await dispatch(storeBooks([data.booksData]));
          booksData = await data.booksData;
        }

        // let userId = localStorage.getItem("id");
        // let projectsData = await getProjectByRoleAndId(role, userId);

        // let booksData = await getBooksByUserRole(role.toLocaleLowerCase());

        let oldTestament = await booksData.filter(
          (item) => item.testamentType == "old"
        );
        setOldTestamentBooks(oldTestament);
        let newTestament = await booksData.filter(
          (item) => item.testamentType == "new"
        );
        setNewTestamentBooks(newTestament);

        for (const project of projectsData) {
          project.bookData = [];
          for (const books of booksData) {
            if (project.id == books.projectId) {
              project.bookData.push(books);
            }
          }
        }

        await setProject(projectsData);
        if (projectsData != 0) {
          if (
            prevSelectedProject == null ||
            prevSelectedProject == undefined ||
            !Object.keys(prevSelectedProject).length
          ) {
            projectsData[0].bookData = await sortBooks(
              projectsData[0].bookData
            );
            setCurrentProject(projectsData[0]);
            setCurrentBook([]);
            setCurrentProjectName(projectsData[0].projectName);

            setCurrentChapter([]);
            // setCurrentChapter(projectsData[0]?.bookData[0]?.chapters[0]);
            resolve();
            setisLoading(false);
          } else {
            let lastSelectedProject = projectsData.filter(
              (item) => item.projectName == prevSelectedProject.projectName
            );

            setCurrentProject(lastSelectedProject[0]);
            let book = lastSelectedProject[0].bookData;
            setCurrentBook(book);

            book = await book.filter(
              (item) => item.bookName == prevSelectedProject.bookName
            );
            setSelectedBook(book[0])
            let chapterData = await sortChapters(book[0].chapters);
            setCurrentChapter(chapterData);

            chapterData =
              prevSelectedProject.chapterName != undefined
                ? chapterData.filter(
                  (item) =>
                    item.chapterName == prevSelectedProject.chapterName
                )
                : chapterData;
            if (lastSelectedProject.length >= 1) {
              lastSelectedProject[0].bookData = await sortBooks(
                lastSelectedProject[0].bookData
              );
              setCurrentProject(lastSelectedProject[0]);
               if (prevSelectedProject.chapterName != undefined) {
                setCurrentProjectName(
                  lastSelectedProject[0].projectName +
                  " " +
                  prevSelectedProject.bookName +
                  " " +
                  prevSelectedProject.chapterName.split("chapter")[1]
                )
                // setisLoading(false);
                selectChunk(chapterData[0], lastSelectedProject[0], prevSelectedProject.bookName);
                setSelectedOption("CHUNKS");
                // resolve();
              } else {
                setCurrentProjectName(
                  lastSelectedProject[0].projectName +
                  " " +
                  prevSelectedProject.bookName
                );
                 setSelectedOption("PROJECT");
                 setisLoading(false );
                   resolve();
              }
              // prevSelectedProject.chapterName != undefined
              //   ? setCurrentProjectName(
              //     lastSelectedProject[0].projectName +
              //     " " +
              //     prevSelectedProject.bookName +
              //     " " +
              //     prevSelectedProject.chapterName.split("chapter")[1]
              //   )
              //   : setCurrentProjectName(
              //     lastSelectedProject[0].projectName +
              //     " " +
              //     prevSelectedProject.bookName
              //   );
              // setCurrentProject(lastSelectedProject[0]);
              // selectChunk(chapterData[0]);
              // setSelectedOption("CHUNKS");
              // resolve();
            }
          }
        }
      } catch (error) {
        console.log("Fetch Data ", error);
      }
      // setisLoading(false );
    });
  };

  const onChange = (e) => {
    setMultiSelect(e.target.checked);
  };

  const addBookProgress = async(bookData, progressData) => {
    // console.log("BookProceess: ",bookData, progressData)
    for (const progress of progressData) {

      const matchingBook = bookData.find((book) => book.bookName == progress.bookName);
      if (matchingBook) {
        matchingBook.bookProgress = [{
          total: progress.total,
          completed: progress.completed,
          review: progress.review,
          pending: progress.pending,
        }];
      }
    }
    return bookData;
  }

  // changeCurrentBook
  const changeCurrentProject = async (value) => {
    let compareAbleValue = typeof value == "string" ? value : value.projectName;
    let availableProject = project.filter(
      (project) => project.projectName == compareAbleValue
    );
    if (availableProject.length) {
      availableProject[0].bookData = await sortBooks(
        availableProject[0].bookData
      );
      setisLoading(true);
      let bookProgress = await axios.post(`${base_url}${desktop.dt}${desktop.getAllBookProgressWeb}`,{project:availableProject[0],role:role});
     availableProject[0].bookData= await addBookProgress(availableProject[0].bookData,bookProgress.data.books)
      setCurrentProject(availableProject[0]);
      setCurrentProjectName(availableProject[0].projectName);
      let str = {
        projectName: availableProject[0].projectName,
      };
      setCurrentBook(availableProject[0].bookData);
      localStorage.setItem("selectedProject", JSON.stringify(str));
      setisLoading(false);
      setSelectedOption("BOOK");
     
    }

    setCurrentChapter([]);
  };

  const reload = () => {
    setIsChunkSelection(false);
    setisLoading(true);
  };

  const toggleChunkSelections = (type, fileType) => {
    if (type == "chapter") {
      if (currentChapter.length <= 0) {
        setShowAlertMsg(true);
        setTimeout(() => {
          setShowAlertMsg(false);
        }, 1500);
      } else {
        changeSelectedOption("CHAPTER");
        setShowDraftPlayingOption(false);
        setType(fileType);
        setIsChunkSelection(!isChunkSelection);
        setChapterPlay(true);
      }
    } else {
      if (currentChapter.length <= 0) {
        setShowAlertMsg(true);
        setTimeout(() => {
          setShowAlertMsg(false);
        }, 1500);
      } else {
        changeSelectedOption("CHUNKS");
        setShowDraftPlayingOption(false);
        setType(fileType);
        setChapterPlay(false);
        setIsChunkSelection(!isChunkSelection);
      }
    }
  };

  const statusCheckChapterWise = (chapter) => {
    confirm({
      title: (
        <p className="">
          Are you sure you want to{" "}
          <span className="lowercase">
            {role == "CIT" ? "CIT Check" : "Consultant Check"}
          </span>{" "}
          this file?
        </p>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: "Confirm",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        try {
          let data;
          if (role == "CIT" || role == "Consultant") {
            data = await citConCheckTypeToServer({
              projectId: currentProject.id,
              bookName: currentBookName,
              chapterName: chapter,
              role: role,
            });
            if (
              data == "CIT_CHECK completed successfully" ||
              data == "Consultant_CHECK completed successfully"
            ) {
              let newBookReducer;
              for (let i = 0; i < booksReducer.length; i++) {
                if (booksReducer[i].bookName == currentBookName) {
                  for (let j = 0; j < booksReducer[i].chapters.length; j++) {
                    if (booksReducer[i].chapters[j].chapterName == chapter) {
                      booksReducer[i].chapters[j].chapterStatus = true;
                      newBookReducer = booksReducer;
                    }
                  }
                }
              }
              await dispatch(clearBooks());
              await dispatch(storeBooks(newBookReducer));
              await showNotification("success", `${role} Check done successfully`);
            }
          }
        } catch (error) {
          showNotification("error", error.response.data.msg);
          console.log(error);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const BooksDataJSX = () => {
    return (
      <>
        <div className="mx-2 pt-3">
          {project.length ? (
            <>
              {selectedOption == "PROJECT" && project.length ? (
                <>
                  <div className="mt-1">
                    {project.map((project) => {
                      return (
                        <>
                          <Button
                            shape="round"
                            key={project.projectName}
                            className="mx-2 capitalize"
                            onClick={(e) => {
                              changeCurrentProject(project);
                            }}
                          >
                            {project.projectName}
                          </Button>
                        </>
                      );
                    })}
                  </div>
                </>
              ) : (
                <h6 className="flex items-center justify-center py-32"></h6>
              )}
              {selectedOption == "BOOK" && (
                <div className="mx-2">
                  {Object.keys(currentProject).length &&
                  currentBook.length > 0 &&
                  currentProject.projectType.jesusFilm == false ? (
                    <Tabs
                      defaultActiveKey="1"
                      type="card"
                      className="pt-2"
                      moreIcon={<RiArrowRightSFill />}
                      size="middle"
                    >
                      <Tabs.TabPane
                        key="Old Testament"
                        tab={<span>Old Testament</span>}
                      >
                        <div className="mt-1">
                          {oldTestamentBooks.length ? (
                            oldTestamentBooks.map((book) => {
                              return (
                                <>
                                  {book.testamentType == "old" &&
                                    book.projectId == currentProject.id && (
                                      <>
                                        <ProgressPopOver
                                        role={role}
                                          name={book.bookName}
                                          bookName={book.bookName}
                                          type={"projectBook"}
                                          data={book}
                                          progress={book.bookProgress}
                                          selectChapter={selectChapter}
                                        />
                                      </>
                                    )}
                                </>
                              );
                            })
                          ) : (
                            <span className="mx-2 px-2">
                              Books not assigned to this Testament{" "}
                            </span>
                          )}
                        </div>
                      </Tabs.TabPane>
                      <Tabs.TabPane
                        key="New Testament"
                        tab={<span>New Testament</span>}
                      >
                        <div className="mt-1">
                          {newTestamentBooks.length ? (
                            newTestamentBooks.map((book) => {
                              return (
                                <>
                                  {book.testamentType == "new" &&
                                    book.projectId == currentProject.id && (
                                      <>
                                        <ProgressPopOver
                                        role={role}
                                          name={book.bookName}
                                          bookName={book.bookName}
                                          type={"projectBook"}
                                          data={book}
                                          progress={book.bookProgress}
                                          selectChapter={selectChapter}
                                        />
                                      </>
                                    )}
                                </>
                              );
                            })
                          ) : (
                            <span className="mx-2 px-2">
                              Books not assigned to this Testament
                            </span>
                          )}
                        </div>
                      </Tabs.TabPane>
                    </Tabs>
                  ) : Object.keys(currentProject).length &&
                    currentProject.projectType.jesusFilm == true ? (
                    currentProject.bookData.map((book) => {
                      return (
                        // {book.testamentType == "old" && (
                        <>
                          <ProgressPopOver
                          role={role}
                            name={book.bookName}
                            bookName={book.bookName}
                            type={"projectBook"}
                            data={book}
                            progress={book.bookProgress}
                            selectChapter={selectChapter}
                          />
                        </>

                        // )}
                      );
                    })
                  ) : (
                    <>
                      <h6 className="flex items-center justify-center mt-6">
                        Please select project
                      </h6>
                    </>
                  )}
                </div>
              )}

              {selectedOption == "CHAPTER" && (
                <div className="mx-2 capitalize">
                  {currentChapter.length > 0 ? (
                    currentChapter.map((chapter) => {
                      return (
                        <>
                          <ProgressPopOver
                          role={role}
                            name={chapter.chapterName}
                            data={chapter}
                            bookName={currentBook.bookName}
                            type={"projectChapter"}
                            progress={chapter.chaptersProgress[0]}
                            selectChunk={selectChunk}
                          />
                          <MdPictureAsPdf
                            size="20"
                            className="mr-2"
                            onClick={async (e) => {
                              await generatePdf({
                                ...{ currentChapter: chapter.chapterName },
                                project: currentProject,
                                ...currentBook,
                              });
                            }}
                          />
                          <Checkbox
                              // className="ml-2"
                            onClick={() =>statusCheckChapterWise(chapter.chapterName)}
                            checked={chapter.chapterStatus.status}
                            className={`${chapter.chapterStatus.status == true ? "crossCheck" : ""}`}
                            disabled={chapter.chapterStatus?.status || !chapter?.chapterStatus.draftPresent}
                          ></Checkbox>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <h6 className="flex items-center justify-center mt-6">
                        Please select book
                      </h6>
                    </>
                  )}
                </div>
              )}

              {selectedOption == "CHUNKS" &&
                (role != "Technician" ||
                  role != "Coordinator" ||
                  role != "Translation Manager") && (
                  <>
                    {multiSelect === true ? (
                      <div className="mx-2 capitalize">
                        <SelectChunk
                          isBackTranslation={true}
                          updateState={setIsChunkSelection}
                          chunks={currentChunks}
                          currentBookName={currentBookName}
                          currentChapterName={currentChapterName}
                          book={selectedBook}
                          reload={reload}
                          project={currentProject}
                        />
                      </div>
                    ) : (
                      <>
                        {currentChunks.length > 0 ? (
                          <>
                            <form>
                              {currentChunks.map((chunk) => {
                                return (
                                  <>
                                    <Button
                                      className="mr-2 my-2"
                                      shape="round"
                                      onClick={(e) => {
                                        redirectToDraftDetails(chunk);
                                      }}
                                      style={{
                                        borderColor:
                                          chunk.chunksDataProgress ==
                                          "completed"
                                            ? "#3CB043"
                                            : chunk.chunksDataProgress ==
                                              "pending"
                                            ? "#C8102E"
                                            : "#FFBF00",
                                        color:
                                          chunk.chunksDataProgress ==
                                          "completed"
                                            ? "#3CB043"
                                            : chunk.chunksDataProgress ==
                                              "pending"
                                            ? "#C8102E"
                                            : "#FFBF00",
                                      }}
                                    >
                                      {chunk.chunkName}
                                    </Button>
                                  </>
                                );
                              })}
                            </form>
                          </>
                        ) : (
                          <>
                            <h6 className="flex items-center justify-center mt-6">
                              Please select chapter
                            </h6>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
            </>
          ) : (
            <>
              <h6 className="flex items-center justify-center mt-6">
                Please add Project
              </h6>
            </>
          )}
        </div>
      </>
    );
  };

  let BookSelectJSX = () => {
    return (
      <Select
        style={{
          width: "100%",
        }}
        value={currentProjectName ? currentProjectName : "Please add Project"}
        placeholder={placeHolder}
        onChange={changeCurrentProject}
      >
        {project.map((project) => (
          <Select.Option value={project.projectName} className="capitalize">
            {project.projectName}
          </Select.Option>
        ))}
      </Select>
    );
  };

  let SelectBookTableJSX = () => {
    return (
      <>
        {Object.keys(currentProject).length !== 0 ? (
          <Table
            columns={[
              {
                title: "S.No. ",
                dataIndex: "sno",
              },
              {
                title: "Chapter Name",
                dataIndex: "name",
              },
            ]}
            dataSource={[
              { value: "chapter1" },
              { value: "chapter2" },
              { value: "chapter3" },
              { value: "chapter4" },
              { value: "chapter5" },
              { value: "chapter6" },
            ]}
            bordered
            pagination={false}
            title={() => currentProject?.projectName}
          />
        ) : null}
      </>
    );
  };

  let changeSelectedOption = (value) => {
    setSelectedOption(value);
    setMultiSelect(false);
  };

  let selectChunk = async (data,projectData,bookName) => {
    let projectSelected = JSON.parse(localStorage.getItem("selectedProject"));
    let chunkData = [];
    if (
      role != "Technician" ||
      role != "Coordinator" ||
      role != "Translation Manager"
    ) {
      setisLoading(true);
      setCurrentProjectName(
        projectSelected.projectName +
          " " +
          projectSelected.bookName +
          " " +
          data.chapterName.split("chapter")[1]
      );
      let str = JSON.stringify({
        projectName: projectSelected.projectName,
        bookName: projectSelected.bookName,
        chapterName: data.chapterName,
      });
      let body = {
        bookName:currentBook.bookName||bookName,
        project:currentProject||projectData,
        chapterName:data.chapterName,
        role
      }

      localStorage.setItem("selectedProject", str);
      let chunkProgress = await axios.post(`${base_url}${desktop.dt}${desktop.getAllChunkProgressWeb}`, body)
      setisLoading(false)
      data.chunks = chunkProgress.data
      // let chunkData = await sortChunks(data.chunks);
      chunkData = await sortChunks(data.chunks);
      setisLoading(false)
    }

    setCurrentChapterName(data.chapterName);
    setCurrentChunks(chunkData);
    // toggleChunkSelections();
    // setSelectedOption("CHAPTER");
    (role != "Technician" ||
      role != "Coordinator" ||
      role != "Translation Manager") &&
      setSelectedOption("CHUNKS");
  };

  let selectChapter = async (data) => {
    setisLoading(true)
    let projectName = JSON.parse(localStorage.getItem("selectedProject"));
    setCurrentProjectName(projectName.projectName + " " + data.bookName);
    let str = JSON.stringify({
      projectName: projectName.projectName,
      bookName: data.bookName,
    });

    localStorage.setItem("selectedProject", str);
    // let chapterData = await sortChapters(data.chapters);
    const chapterNames = data.chapters.map((chapter) => chapter.chapterName);
    let body = {
      bookName:data.bookName,
      project:currentProject,
      chapters:chapterNames,
      role
    }
    
    let chapterProgress = await axios.post(`${base_url}${desktop.dt}${desktop.getAllProgressWeb}`,body)
    // data.chapters = chapterProgress.data;
    data.chapters = await addChunks(data.chapters,chapterProgress.data)
    data.chapters = await sortChapters(data.chapters);
    setisLoading(false)
    setCurrentChapterName(data.chapterName);
    setCurrentBookName(data.bookName);
    setCurrentBook(data);
    setSelectedBook(data);
    setCurrentChapter(data.chapters);
    setSelectedOption("CHAPTER");
    // };
  };

  let redirectToDraftDetails = async (data) => {
    setisLoading(true);
    // let project = await getProjectById(data.projectId);
    let headingName = await extractNumberFromString(data.chunkName);
    let chunkName = await getChunkName(
      currentProject.language,
      data.chunk[0].bookName,
      data.chunk[0].chapterName
    );
    if (role == "CIT" || role == "Consultant") {
      localStorage.setItem("selectedChunks", JSON.stringify([data.chunkName]));
    }

    // navigate("/project-details", {
    //   state: {
    //     chunk: [data.chunkName],
    //     draftChunk: [data],
    //     chapter: currentChapterName,
    //     book: currentBook,
    //     project,
    //   },
    // });

    // navigate("/cross-check", {
    //   state: {
    //     chunkName: chunkName,
    //     chunk: data,
    //     book: currentBook,
    //     verse: data.chunkName,
    //     heading: `${chunkName} ${headingName}`,
    //     chapter: data.chunk[0].chapterName,
    //     project,
    //     targetType:
    //       activeKey == 2 ? "draft" : activeKey == 3 ? "backTranslate" : "",
    //   },
    // });

    navigate("/cit-con-check", {
      state: {
        chunkName: chunkName,
        chunk: data,
        book: selectedBook,
        verse: data.chunkName,
        heading: `${chunkName} ${headingName}`,
        chapter: data.chunk[0].chapterName,
        project: currentProject,
      },
    });
  };

  let redirectToAudioPage = async (chapter, fileType) => {
    let chapterdata =
      chapter != "null"
        ? currentChapter
        : currentChapterName !== "null"
        ? currentChapter.filter(
            (item) => item.chapterName === currentChapterName
          )
        : currentChapter;
    if (chapterdata.length <= 0) {
      setShowAlertMsg(true);
      setTimeout(() => {
        setShowAlertMsg(false);
      }, 1500);
    } else {
      let project = currentProject;

      if (project.projectType.signLanguage == true) {
        navigate("/video-player", {
          state: {
            book: selectedBook,
            chapter: chapterdata,
            project,
          },
        });
      } else {
        if (fileType == "view") {
          navigate("/script-view", {
            state: {
              book: selectedBook,
              chapter: chapterdata,
              project,
              fileType,
            },
          });
        } else {
          navigate("/audio-playlist", {
            state: {
              book: selectedBook,
              chapter: chapterdata,
              project,
              fileType,
            },
          });
        }
      }
    }
  };

  const BookChapterChunkTabsPane = () => {
    return (
      <>
        <div className="flex items-center justify-evenly ">
          <span
            className={`cursor-pointer option  ${
              selectedOption == "PROJECT" ? "border-b-2" : ""
            }`}
            onClick={(e) => {
              changeSelectedOption("PROJECT");
            }}
          >
            Project
          </span>
          <span
            className={`cursor-pointer option  ${
              selectedOption == "BOOK" ? "border-b-2" : ""
            }`}
            onClick={(e) => {
              changeSelectedOption("BOOK");
            }}
          >
            Book
          </span>
          <p className="flex items-center">
            <span
              className={`cursor-pointer option flex mx-2 ${
                selectedOption == "CHAPTER" ? "border-b-2" : ""
              }`}
              onClick={(e) => {
                changeSelectedOption("CHAPTER");
              }}
            >
              Chapters
            </span>

            {(role == "CIT" || role == "Consultant") && (
              <>
                {currentProject.projectType.audio == true ||
                currentProject.projectType.signLanguage == true ? (
                  <>
                    <Popover
                      content={
                        <>
                          <div
                            className="border-bottom py-2 cursor-pointer"
                            onClick={() =>
                              redirectToAudioPage("chapter", "audio")
                            }
                          >
                            <span>Play all</span>
                          </div>
                          <div
                            className="border-bottom py-2 cursor-pointer"
                            onClick={() =>
                              toggleChunkSelections("chapter", "audio")
                            }
                          >
                            <span>Select to play</span>
                          </div>
                        </>
                      }
                      trigger="hover"
                      placement="bottom"
                    >
                      <BsPlayCircle />
                    </Popover>
                    {currentProject.projectType.text == true && (
                      <Popover
                        content={
                          <>
                            <div
                              className="border-bottom px-2 py-2 cursor-pointer"
                              onClick={() =>
                                redirectToAudioPage("chapter", "view")
                              }
                            >
                              <span>View all</span>
                            </div>
                            <div
                              className="border-bottom py-2 cursor-pointer"
                              onClick={() =>
                                toggleChunkSelections("chapter", "view")
                              }
                            >
                              <span>Select to view</span>
                            </div>
                          </>
                        }
                        trigger="hover"
                        placement="bottom"
                      >
                        <FaEye className="ml-2" />
                      </Popover>
                    )}
                  </>
                ) : (
                  <Popover
                    content={
                      <>
                        <div
                          className="border-bottom px-2 py-2 cursor-pointer"
                          onClick={() => redirectToAudioPage("chapter", "view")}
                        >
                          <span>View all</span>
                        </div>
                        <div
                          className="border-bottom py-2 cursor-pointer"
                          onClick={() =>
                            toggleChunkSelections("chapter", "view")
                          }
                        >
                          <span>Select to view</span>
                        </div>
                      </>
                    }
                    trigger="hover"
                    placement="bottom"
                  >
                    <FaEye className="ml-2" />
                  </Popover>
                )}
              </>
            )}
          </p>

          {(role == "CIT" || role == "Consultant") && (
            <p className="flex items-center">
              <span
                className={`cursor-pointer option flex mx-2 ${
                  selectedOption == "CHUNKS" ? "border-b-2" : ""
                }`}
                onClick={(e) => {
                  changeSelectedOption("CHUNKS");
                }}
              >
                Chunks
              </span>

              <>
                {currentProject.projectType.audio == true ||
                currentProject.projectType.signLanguage == true ? (
                  <>
                    <Popover
                      content={
                        <>
                          <div
                            className="border-bottom py-2 cursor-pointer"
                              onClick={() =>
                                redirectToAudioPage("null", "audio")
                              }
                          >
                            <span>Play all</span>
                          </div>
                          <div
                            className="border-bottom py-2 cursor-pointer"
                            onClick={() =>
                              toggleChunkSelections("null", "audio")
                            }
                          >
                            <span>Select to play</span>
                          </div>
                        </>
                      }
                      trigger="hover"
                      placement="bottom"
                    >
                      <BsPlayCircle />
                    </Popover>
                    {currentProject.projectType.text == true && (
                      <Popover
                        content={
                          <>
                            <div
                              className="border-bottom px-2 py-2 cursor-pointer"
                              onClick={() =>
                                redirectToAudioPage("null", "view")
                              }
                            >
                              <span>View all</span>
                            </div>
                            <div
                              className="border-bottom py-2 cursor-pointer"
                              onClick={() =>
                                toggleChunkSelections("null", "view")
                              }
                            >
                              <span>Select to view</span>
                            </div>
                          </>
                        }
                        trigger="hover"
                        placement="bottom"
                      >
                        <FaEye className="ml-2" />
                      </Popover>
                    )}
                  </>
                ) : (
                  <Popover
                    content={
                      <>
                        <div
                          className="border-bottom px-2 py-2 cursor-pointer"
                          onClick={() => redirectToAudioPage("null", "view")}
                        >
                          <span>View all</span>
                        </div>
                        <div
                          className="border-bottom py-2 cursor-pointer"
                            onClick={() =>
                              toggleChunkSelections("null", "view")
                            }
                        >
                          <span>Select to view</span>
                        </div>
                      </>
                    }
                    trigger="hover"
                    placement="bottom"
                  >
                    <FaEye className="ml-2" />
                  </Popover>
                )}
              </>
              {/* <span className="ml-2">
                <Tooltip
                  placement="topLeft"
                  title={
                    selectedOption == "CHUNKS"
                      ? "Multiple Select"
                      : "Please select chapter"
                  }
                >
                  <Checkbox
                    checked={multiSelect}
                    style={{ font: "bold" }}
                    disabled={selectedOption == "CHUNKS" ? false : true}
                    onChange={onChange}
                  ></Checkbox>
                </Tooltip>
              </span> */}
            </p>
          )}
        </div>
        {showAlertMsg && (
          <Alert
            message={
              selectedOption == "PROJECT"
                ? "Please Select Project"
                : selectedOption == "BOOK"
                ? "Please Select Book"
                : "Please Select Chapter"
            }
            type="error"
            closable={true}
            className="my-2"
          />
        )}
      </>
    );
  };

  const changeActiveKey = (e) => {
    setActiveKey(e);
  };

  return (
    <MainLayout>
      {isLoading ? (
        <SpinnerComponent />
      ) : (
        <div className="draft-details">
          <div className="draft-details-wrapper min-height h-85">
            <TitleBar title={"Project Files"} page={"myproject"} />

            <div className="pt-1">
              <BookSelectJSX />
              <div className="tabpanel">
                <Tabs
                  type="card"
                  className="pt-2"
                  moreIcon={<RiArrowRightSFill />}
                  size="middle"
                  activeKey={activeKey}
                  onChange={changeActiveKey}
                >
                  {/* MTT */}
                  <>
                    <>
                      <Tabs.TabPane
                        disabled={role == "MTT" ? false : true}
                        key="1"
                        tab={
                          <span>
                            <RiDraftLine
                              style={{ margin: "0px 5px", fontSize: "large" }}
                            />
                            <span style={{ fontSize: "12px" }}>Draft</span>
                          </span>
                        }
                      >
                        <BookChapterChunkTabsPane />
                        <BooksDataJSX 
                        key={`${Math.random()}`}
                        />
                      </Tabs.TabPane>
                    </>
                    <Tabs.TabPane
                      tab={
                        <span>
                          <GoChecklist
                            style={{ margin: "0px 5px", fontSize: "large" }}
                          />
                          <span style={{ fontSize: "12px" }}>Cross Check</span>
                        </span>
                      }
                      key="2"
                      disabled={role == "MTT" ? false : true}
                    >
                      <div className="seacrh w-100 mt-2 mb-1 p-2">
                        <BookSelectJSX />
                      </div>
                      <div className="bookSelection w-100 mt-2 mb-2 p-2">
                        <SelectBookTableJSX />
                      </div>
                    </Tabs.TabPane>
                  </>
                  {/* Facilitator */}
                  <>
                    <Tabs.TabPane
                      tab={
                        <span>
                          <HiOutlineUserGroup
                            style={{ margin: "0px 5px", fontSize: "large" }}
                          />
                          <span style={{ fontSize: "12px" }}>
                            Community Check
                          </span>
                        </span>
                      }
                      key="4"
                      disabled={role == "Facilitator" ? false : true}
                    >
                      <div className="seacrh w-100 mt-2 mb-1 p-2">
                        <BookSelectJSX />
                      </div>
                      <div className="bookSelection w-100 mt-2 mb-2 p-2">
                        <SelectBookTableJSX />
                      </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={
                        <span>
                          <BsTranslate
                            style={{ margin: "0px 5px", fontSize: "large" }}
                          />
                          <span style={{ fontSize: "12px" }}>
                            Back Translation
                          </span>
                        </span>
                      }
                      key="3"
                      disabled={role == "Facilitator" ? false : true}
                    >
                      <div className="seacrh w-100 mt-2 mb-1 p-2">
                        <BookChapterChunkTabsPane />
                      </div>
                      <BooksDataJSX 
                        key={`${Math.random()}`}
                      />                      
                    </Tabs.TabPane>
                  </>
                  {/* CIT */}
                  <>
                    <Tabs.TabPane
                      tab={
                        <span>
                          <MdOutlineFactCheck
                            style={{ margin: "0px 5px", fontSize: "large" }}
                          />
                          <span style={{ fontSize: "12px" }}>CIT Check</span>
                        </span>
                      }
                      key="5"
                      disabled={role == "CIT" ? false : true}
                    >
                      <BookChapterChunkTabsPane />
                      {/* <BooksDataJSX /> */}
                    <BooksDataJSX 
                        key={`${Math.random()}`}
                        />
                    </Tabs.TabPane>
                  </>

                  {/* Consultant Check */}
                  <>
                    <Tabs.TabPane
                      tab={
                        <span>
                          <BsPersonCheck
                            style={{ margin: "0px 5px", fontSize: "large" }}
                          />
                          <span style={{ fontSize: "12px" }}>
                            Consultant Check
                          </span>
                        </span>
                      }
                      key="6"
                      disabled={role == "CONSULATANT" ? false : true}
                    >
                      <BookChapterChunkTabsPane />
                      {/* <BooksDataJSX /> */}
                      <BooksDataJSX 
                        key={`${Math.random()}`}
                        />
                    </Tabs.TabPane>
                  </>
                  {/* TECHNICIAN */}
                  <>
                    <Tabs.TabPane
                      tab={
                        <span>
                          <IoRecordingOutline
                            style={{ margin: "0px 5px", fontSize: "large" }}
                          />
                          <span style={{ fontSize: "12px" }}>
                            Master Recording
                          </span>
                        </span>
                      }
                      key="7"
                      disabled={role == "TECHNICIAN" ? false : true}
                    >
                      <div className="seacrh w-100 mt-2 mb-1 p-2">
                        {/* <BookSelectJSX /> */}
                        <BooksDataJSX 
                        key={`${Math.random()}`}
                        />
                      </div>
                      <div className="bookSelection w-100 mt-2 mb-2 p-2">
                        <SelectBookTableJSX />
                      </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={
                        <span>
                          <MdOutlinePublish
                            style={{ margin: "0px 5px", fontSize: "large" }}
                          />
                          <span style={{ fontSize: "12px" }}>Publish</span>
                        </span>
                      }
                      key="8"
                      disabled={
                        role == "Technician" || role == "Translation Manager"
                          ? false
                          : true
                      }
                    >
                      <BookChapterChunkTabsPane />
                      {/* <BooksDataJSX /> */}
                      <BooksDataJSX 
                        key={`${Math.random()}`}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={
                        <span>
                          <GiProgression
                            style={{ margin: "0px 5px", fontSize: "large" }}
                          />
                          <span style={{ fontSize: "12px" }}>
                            Project Progress
                          </span>
                        </span>
                      }
                      key="9"
                      disabled={role == "Coordinator" ? false : true}
                    >
                      <BookChapterChunkTabsPane />
                      {/* <BooksDataJSX /> */}
                      <BooksDataJSX 
                        key={`${Math.random()}`}
                        />
                    </Tabs.TabPane>
                  </>
                </Tabs>
              </div>
            </div>
          </div>
          {contextHolder}
        </div>
      )}
      {isChunkSelection == true && chapterPlay !== true ? (
        <SelectChunk
          updateState={setIsChunkSelection}
          chunks={currentChunks}
          currentBookName={currentBookName}
          currentChapterName={currentChapterName}
          book={selectedBook}
          reload={reload}
          fileType={type}
          type={"chunk"}
          project={currentProject}
        />
      ) : isChunkSelection == true && chapterPlay == true ? (
        <SelectChunk
          updateState={setIsChunkSelection}
          chapters={currentChapter}
          currentBookName={currentBookName}
          book={selectedBook}
          reload={reload}
          fileType={type}
          type={"chapter"}
          project={currentProject}
        />
      ) : null}
    </MainLayout>
  );
};

export default CitConsultant;
