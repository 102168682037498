import React, { useEffect } from "react";
import { useRef } from "react";
import {  FaUser } from "react-icons/fa";

const UserMessage = ({ message }) => {
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      block: "end",
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // scrollToBottom();
  }, [message]);

  return (
    <>
      <div>
        <div className="text-black message-content clearfix">
          <span className="message user-message float-right capitalize">
            {message}
          </span>
        </div>
        <span className="message-user-icons mt-1 float-right">
          <FaUser size={20} color="black" />
        </span>
      </div>

      <span ref={messagesEndRef} />
    </>
  );
};

export default UserMessage;
