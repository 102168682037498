const saveUserDetailsToLocalStorgae = async (data) => {
    return new Promise(async (resolve, reject) => {
      localStorage.setItem("aud", data.aud);
      localStorage.setItem("email", data.email);
      localStorage.setItem("exp", data.exp);
      localStorage.setItem("firstName", data.firstName);
      localStorage.setItem("iat", data.iat);
      localStorage.setItem("iss", data.iss);
      localStorage.setItem("lastName", data.lastName);
      localStorage.setItem("role", data.role);
      localStorage.setItem("sub", data.sub);
      localStorage.setItem("id", data.userId.id);
      resolve();
    });
  };
  
  const removeUserDetailsFromLocalStorage = () => {
    return new Promise(async (resolve, reject) => {
      localStorage.removeItem("aud");
      localStorage.removeItem("email");
      localStorage.removeItem("exp");
      localStorage.removeItem("firstName");
      localStorage.removeItem("iat");
      localStorage.removeItem("iss");
      localStorage.removeItem("lastName");
      localStorage.removeItem("role");
      localStorage.removeItem("sub");
      localStorage.removeItem("id");
      localStorage.removeItem("selectedBook");
      resolve();
    });
  };
  
  export { saveUserDetailsToLocalStorgae, removeUserDetailsFromLocalStorage };
  