import { Button, Modal, Tooltip, notification, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { BsFillFileEarmarkPersonFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
// import { getDraftedFilesByChunks } from "../../../services/indexed-db/draftfiles.indexeddb";
import DraftScriptComponent from "../draft-script/index";
import DraftVideoComponent from "../draft-video/draft-video.component";
import DraftAudioComponent from "../draft-audio/index";
import SourceLanguage from "../source-language/index";
// import { crossCheckAndSync } from "../../../services/sync-methods/cross-check.sync";
import "../back-translation/backTranslation.scss";
// import { updateBooks } from "../../../services/indexed-db/books.indexeddb";
import FilenameComponent from "../../ui/filename.component";
import { postCheckTypeToServer } from "../../../services/api-calls/check_file.api"
import {getDraftedFilesByChunksOnline} from "../../../services/api-calls/draft-files.api"
import { waitForAWhile } from "../../../services/config";
const { confirm } = Modal;

const BackTranslationModal = ({
  chunk,
  draftFileName,
  isButtonDisabled,
  saveDataToLocalStorage,
  reload,
  fileData,

}) => {

  const booksReducer = useSelector((state) => state.booksReducer);
  const [isShow, setIsShow] = useState(false);
  const [review, setreview] = useState("");
  const [draftList, setDraftList] = useState([]);
  const [userProject, setUserProject] = useState({});
  const location = useLocation();
  const stateData = location.state;
  const [selectedChunk,setSelectedChunk]=useState();
  const [fileIds, setFileIds] = useState();
  const isPlayingStatus = useSelector((state) => state.playingStatusReducer);
  const isRecordingStatus = useSelector(
    (state) => state.recordingStatusReducer
  );
  let role = localStorage.getItem("role");

  const [notificationApi, NotificationJSX] = notification.useNotification();
  const showNotification = (type, message) => {
    notification.destroy();
    notificationApi[type]({
      message,
    });
  };
  useEffect(() => {
    setreview(JSON.parse(localStorage.getItem("statusChecked")));
    saveDataToLocalStoragefun(saveDataToLocalStorage);
    setreview(JSON.parse(localStorage.getItem("statusChecked")));
    loadFileSelectedDraftFiles();
  }, [location, setIsShow]);

  const saveDataToLocalStoragefun = async (record) => {

    if (record) {
      record = saveDataToLocalStorage;
      let role = localStorage.getItem("role");
      let statusChecked = false;
      setSelectedChunk(record.sourceVerseName)
      let fileID;
      if (record?.fileType == "video") {
        let backTranslateFileScript = record.backTranslate.filter(
          (item) => item.fileType == "script"
        );
        let backTranslateFileAudio = record.backTranslate.filter(
          (item) => item.fileType == "video"
        );
        fileID = [
          record.fileId,
          backTranslateFileScript.length !== 0
            ? backTranslateFileScript[0].fileId
            : record.fileId,
          backTranslateFileAudio.length !== 0
            ? backTranslateFileAudio[0].fileId
            : record.fileID,
        ];
        if (role == "CIT") {
          let scriptStatusChecked =
            backTranslateFileScript.length > 0 &&
            backTranslateFileScript[0].citStatusCheck == true
              ? true
              : backTranslateFileScript.length == 0
              ? true
              : false;
          let audioStatusChecked =
            backTranslateFileAudio.length > 0 &&
            backTranslateFileAudio[0].citStatusCheck == true
              ? true
              : backTranslateFileAudio.length == 0
              ? true
              : false;
          statusChecked =
            scriptStatusChecked === true &&
            audioStatusChecked === true &&
            record.citStatusCheck == true
              ? true
              : false;
        } else {
          let scriptStatusChecked =
            backTranslateFileScript.length > 0 &&
            backTranslateFileScript[0].consultantStatusCheck == true
              ? true
              : backTranslateFileScript.length == 0
              ? true
              : false;
          let audioStatusChecked =
            backTranslateFileAudio.length > 0 &&
            backTranslateFileAudio[0].consultantStatusCheck == true
              ? true
              : backTranslateFileAudio.length == 0
              ? true
              : false;
          statusChecked =
            scriptStatusChecked === true &&
            audioStatusChecked === true &&
            record.consultantStatusCheck == true
              ? true
              : false;
        }
      } else {
        let backTranslateFileScript = record.backTranslate.filter(
          (item) => item.fileType == "script"
        );
        let backTranslateFileAudio = record.backTranslate.filter(
          (item) => item.fileType == "audio"
        );
        fileID = [
          record.fileId,
          backTranslateFileScript.length !== 0
            ? backTranslateFileScript[0].fileId
            : record.fileId,
          backTranslateFileAudio.length !== 0
            ? backTranslateFileAudio[0].fileId
            : record.fileId,
        ];
        if (role == "CIT") {
          let scriptStatusChecked =
            backTranslateFileScript.length > 0 &&
            backTranslateFileScript[0].citStatusCheck == true
              ? true
              : backTranslateFileScript.length == 0
              ? true
              : false;
          let audioStatusChecked =
            backTranslateFileAudio.length > 0 &&
            backTranslateFileAudio[0].citStatusCheck == true
              ? true
              : backTranslateFileAudio.length == 0
              ? true
              : false;
          statusChecked =
            scriptStatusChecked === true &&
            audioStatusChecked === true &&
            record.citStatusCheck == true
              ? true
              : false;
        } else {
          let scriptStatusChecked =
            backTranslateFileScript.length > 0 &&
            backTranslateFileScript[0].consultantStatusCheck == true
              ? true
              : backTranslateFileScript.length == 0
              ? true
              : false;
          let audioStatusChecked =
            backTranslateFileAudio.length > 0 &&
            backTranslateFileAudio[0].consultantStatusCheck == true
              ? true
              : backTranslateFileAudio.length == 0
              ? true
              : false;
          statusChecked =
            scriptStatusChecked === true &&
            audioStatusChecked === true &&
            record.consultantStatusCheck == true
              ? true
              : false;
        }
      }
      setFileIds(fileID);
      localStorage.setItem("fileIds", JSON.stringify(fileID));
      localStorage.setItem("statusChecked", JSON.stringify(statusChecked));
    }
  };

  const onChange = async (e) => {
    review !== true && showDeleteConfirm();
  };

  const showDeleteConfirm = () => {
    return new Promise(async (resolve, reject) => {
    confirm({
      title: "Are you sure you want to review this file?",
      // icon: <ExclamationCircleOutlined />,
      okText: "Confirm",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,
      async onOk() {
        try {
          // let data = await crossCheckAndSync({
          //   userId: localStorage.getItem("id"),
          //   checkType: localStorage.getItem("role"),
          //   fileId: fileIds,
          // });
          let data;
          if (window.navigator.onLine == true) {
             data=await postCheckTypeToServer({
              userId: localStorage.getItem("id"),
              checkType: localStorage.getItem("role"),
              fileId: fileIds,
            });
          }
          if(role=="CIT"){
            data= "CIT Review completed successfully";
          }
          else{
            data="Consultant Review completed successfully"
          }
          // await updateChunkData(stateData?.book)
          localStorage.setItem("statusChecked", JSON.stringify(true));
          showNotification("success", data);
          setreview(true);
          loadData();
          resolve()
        } catch (error) {
          showNotification("error", error.response.data.msg);
          console.log(error);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  })
  };

  const updateChunkData = async (book) => {
    let chunk = selectedChunk;
    let books = booksReducer.map((item)=> {
      if(item.bookName == book.bookName){
        let bookChapter = book.bookProgress.filter((item)=>{
          item.pending -=1;
          item.completed +=1;
         })
         let ChapterProgress = book.chaptersProgress.filter((item)=>{
          if(item.chapters == stateData?.chapter){
            item.pending -=1;
            item.completed +=1;
          }
         })
          let chapter = book.chapters.filter((item) => {
            if (item.chapterName == stateData?.chapter) {
              item.chunks.map((element) => {
                if (element.chunkName == chunk) {
                  element.chunksDataProgress = "completed"
                }
              });
            }
          });
      }})
    // await updateBooks(book.book_id, book);
  };

  const loadData = async () => {
    await reload();
  };

  const openModal = async () => {
    saveDataToLocalStoragefun(saveDataToLocalStorage);

    if (isRecordingStatus == true) {
      showNotification("error", "Please stop recording");
    } else if (isPlayingStatus == true) {
      showNotification("error", "Please stop playing");
    } else {
      setFileIds(JSON.parse(localStorage.getItem("fileIds")));
      setreview(JSON.parse(localStorage.getItem("statusChecked")));
      setIsShow(true);
    }
  };
  const handleCancel = () => {
    if (isRecordingStatus == true) {
      showNotification("error", "Please stop recording");
    } else if (isPlayingStatus == true) {
      showNotification("error", "Please stop playing");
    } else {
      setIsShow(false);
    }
  };
  const loadFileSelectedDraftFiles = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        setUserProject(stateData.project[0] || stateData.project);

        let data={
          projectId:stateData?.project.id,
          sourceVerseName:stateData?.verse,
          bookName:stateData?.book.bookName,
          chapterName:stateData?.chapter,
          fileType:stateData.project.projectType.signLanguage == true
          ? "video"
          : "audio",
          // targetType:localStorage.getItem("role")=="MTT" ? "draft":"backTranslate"
          targetType:"draft"
        }
        let files= await getDraftedFilesByChunksOnline(data);

        data={
          projectId:stateData.project.id,
          sourceVerseName:stateData.verse,
          bookName:stateData.book.bookName,
          chapterName:stateData.chapter,
          fileType:stateData.project.projectType.text == true ? "script" : "",
          // targetType:localStorage.getItem("role")=="MTT" ? "draft":"backTranslate"
          targetType:"draft"
        }
        let textFiles= await getDraftedFilesByChunksOnline(data);

        // let files = await getDraftedFilesByChunks(
        //   `${chunk?.formattedName}`,
        //   location.state.project.projectType.signLanguage == true
        //     ? "video"
        //     : "audio",
        //   "draft"
        // );

        // let textFiles = await getDraftedFilesByChunks(
        //   `${chunk?.formattedName}`,
        //   location.state.project.projectType.text == true ? "script" : "",
        //   "draft"
        // );

        let selectedFiles = await files.filter(
          (item) => item.isSelected == true
        );
        let selectedTextFiles = await textFiles.filter(
          (item) => item.isSelected == true
        );
        setDraftList({
          chunk: [...selectedFiles, ...selectedTextFiles],
          languageName: files[0].languageName,
        });
        resolve();
      } catch (error) {
        console.log("back translation fetching error", error);
      }
    });
  };

  return (
    <>
      <Tooltip title="Back Translation">
        <Button disabled={isButtonDisabled} onClick={openModal}>
          <BsFillFileEarmarkPersonFill size={19} fill="true" />
        </Button>
      </Tooltip>

      <Modal
        className="backTranslate"
        title={
          localStorage.getItem("role") == "CIT" ||
          localStorage.getItem("role") == "Consultant" ? (
            <div className="w-100 flex">
              <div className="w-50">

              <FilenameComponent file={fileData} />
              </div>
              <div className="w-50 text-end flex mx-3 px-3">
                <div className="w-100 ">
                  <span className="font-weight-bold ">
                    {localStorage.getItem("role")}
                  </span>
                  <span className="mx-1 font-weight-bold ">{"Review "}</span>
                  <span className="">
                    <Checkbox
                      className="backTranslateCheck"
                      style={{ font: "bold" }}
                      onChange={onChange}
                      checked={review}
                      disabled={review}
                    ></Checkbox>
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <FilenameComponent file={fileData} />
          )
        }
        visible={isShow}
        width={1400}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
      >
        <SourceLanguage
          chunk={
            localStorage.getItem("role") == "CIT" ||
            localStorage.getItem("role") == "Consultant"
              ? chunk
              : draftList
          }
          title="Drafted Files"
          targetType="draft"
        />
        {Object.keys(userProject).length ? (
          <>
            {userProject.projectType.signLanguage == true ? (
              <DraftVideoComponent
                title={"Back Translation"}
                fileType={"video"}
                targetType="backTranslate"
                chunk={chunk}
                isDisableRecord={
                  localStorage.getItem("role") == "CIT" ||
                  localStorage.getItem("role") == "Consultant"
                }
                relatedDraft={draftFileName}
                project={userProject}
              />
            ) : null}
          </>
        ) : null}

        {Object.keys(userProject).length ? (
          <>
            {userProject.projectType.audio == true ? (
              <DraftAudioComponent
                title={"Back Translation"}
                fileType={"audio"}
                targetType="backTranslate"
                chunk={chunk}
                relatedDraft={draftFileName}
                isDisableRecord={
                  localStorage.getItem("role") == "CIT" ||
                  localStorage.getItem("role") == "Consultant"
                }
              />
            ) : null}
          </>
        ) : null}
        {Object.keys(userProject).length ? (
          <>
            {userProject.projectType.jesusFilm == true ? (
              <DraftAudioComponent
                title={"Back Translation"}
                fileType={"audio"}
                targetType="backTranslate"
                chunk={chunk}
                relatedDraft={draftFileName}
                isDisableRecord={
                  localStorage.getItem("role") == "CIT" ||
                  localStorage.getItem("role") == "Consultant"
                }
              />
            ) : null}
          </>
        ) : null}

        {Object.keys(userProject).length ? (
          <>
            {userProject.projectType.text == true ? (
              <DraftScriptComponent
                title={"Back Translation"}
                fileType="backTranslate"
                chunk={chunk}
                relatedDraft={draftFileName}
                targetType="backTranslate"
                isTextAreaDisplayed={
                  localStorage.getItem("role") == "Facilitator"
                }
              />
            ) : null}
          </>
        ) : null}
      </Modal>

      {NotificationJSX}
    </>
  );
};

export default BackTranslationModal;