import axios from "axios";
import { chatbot_api, desktop } from "../apis";

const getChatbotFilesByChapter = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await axios.post(`${desktop.getChatbotFilesByChapter}`, body);
      resolve(res.data);
    } catch (error) {
      resolve([]);
    }
  });
};
const getChatbotLanguage = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios.post(
        `${chatbot_api.getChatbotLanguage}`,
        body
      );
      resolve(response.data);
    } catch (error) {
      resolve([]);
    }
  });
};

export { getChatbotFilesByChapter, getChatbotLanguage };
