let first_load_initial_state = false;
let isRecording_initial_state = false;
let isPlaying_intial_state = false;
let sideNavBar_intial_state = false;
let tranScript_initial_state = [];

const firstLoadReducer = (state = first_load_initial_state, action) => {
  switch (action.type) {
    case "FIRST_LOAD":
      return state;
    case "FIRST_LOAD_EXECUTED":
      return (state = action.payload);

    default:
      return state;
  }
};

const recordingStatusReducer = (state = isRecording_initial_state, action) => {
  switch (action.type) {
    case "TOGGLE_RECORDING":
      return (state = action.payload);
    default:
      return state;
  }
};

const playingStatusReducer = (state = isPlaying_intial_state, action) => {
  switch (action.type) {
    case "TOGGLE_PLAYING":
      return (state = action.payload);
    default:
      return state;
  }
};

let homeLoadingStatus = false;
const homeLoadingReducer = (state = homeLoadingStatus, action) => {
  switch (action.type) {
    case "TOGGLE_HOME_LOADING":
      return (state = action.payload);
    default:
      return state;
  }
};

let selctedChunkPlaylist = [];
const selctedChunkPlaylistReducer = (state = selctedChunkPlaylist, action) => {
  switch (action.type) {
    case "TOGGLE_PLAYLIST":
      return [...[action.payload], ...state];
    case "CLEAR_SELECTED_CHUNK_PLAYLIST":
      return [...selctedChunkPlaylist];
    default:
      return state;
  }
};

let textSpeechStatus = false;

const textSpeechStatusReducer = (state = textSpeechStatus, action) => {
  switch (action.type) {
    case "TOGGLE_SPEECH":
      return (state = action.payload);
    default:
      return state;
  }
};

let voiceRecognitionStatus = false;

const voiceRecognitionReducer = (state = voiceRecognitionStatus, action) => {
  switch (action.type) {
    case "VOICE_RECOGNITION_STATUS":
      return (state = action.payload);
    default:
      return state;
  }
};

export default firstLoadReducer;

let syncStatus = false;

const syncStatusReducer = (state = syncStatus, action) => {
  switch (action.type) {
    case "TOGGLE_SYNC_BUTTON":
      return (state = action.payload);
    default:
      return state;
  }
};

const sideNavBarStatusReducer = (state = sideNavBar_intial_state, action) => {
  switch (action.type) {
    case "SET_SIDE_BAR":
      return (!action.payload);
    default:
      return state;
  }
};

const tranScriptedText = (state = tranScript_initial_state, action) => {
  switch (action.type) {
    case "SET_TRANSCRIPTED_TEXT":
      //return (state = action.payload);
      return [...[...action.payload], ...state];
      case "CLEAR_TRANSCRIPTED_TEXT":
        return [...tranScript_initial_state];

    default:
      return state;
  }
};

export {
  recordingStatusReducer,
  playingStatusReducer,
  selctedChunkPlaylistReducer,
  homeLoadingReducer,
  textSpeechStatusReducer,
  voiceRecognitionReducer,
  syncStatusReducer,
  sideNavBarStatusReducer,
  tranScriptedText
};
