import React, { useEffect, useState } from "react";
import { Button, DatePicker, Select, notification, Tooltip } from "antd";
// import ProgressBar from "react-bootstrap/ProgressBar";
import { useNavigate } from "react-router-dom";
import { Card } from "antd";
import moment from "moment";
import MainLayout from "../../components/layout/main.layout";
import { BiArrowBack } from "react-icons/bi";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import "./my-progress.scss";
import { base_url, dashboard, bible } from "../../services/apis";
import getHeader, { waitForAWhile } from "../../services/config";
import SpinnerComponent from "../../components/spinner/spinner.component";
// import { getProjectByRoleAndId } from "../../services/indexed-db/project.indexeddb";
import { useSelector,useDispatch } from "react-redux";
import {saveBooksAndProjects} from "../../services/BooksAndProjects"
import { storeBooks } from "../../redux/actions/books.action";
import {storeProjects} from "../../redux/actions/project.action";
const MyProgressPage = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [key, setKey] = useState(false);
  const [showChunkData, setShowChunkData] = useState(false);
  const [projectDate, setProjectDate] = useState();
  const [projectEndDate, setProjectEndDate] = useState();
  const [chunkProgress, setChunkProgress] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const [loadPage, setLoadPage] = useState(true);
  const [projectName, setProjectName] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [selectedProject, setSelectedProject] = useState({});
  const [projects, setProjects] = useState([]);
  const role = localStorage.getItem("role");
  const [progressValue, setProgressValue] = useState([]);
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const audioRef = useSelector((state) => state.audioReducer);

  const projectsReducer = useSelector((state) => state.projectsReducer);
  const booksReducer = useSelector((state) => state.booksReducer);

  const [subbtnDisabled, setSubBtnDisabled] = useState(
    startDate == null || endDate == null ? true : false
  );
  const [resBtnDisabled, setResBtnDisabled] = useState(
    startDate == null || endDate == null ? true : false
  );
  let date = new Date(projectDate);

  useEffect(() => {
    getProjectDetails();
  }, []);

  const getProgressBarData = async (projectData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = {
          projectId: projectData.id,
          userId: localStorage.getItem("id"),
          language: projectData.language,
          role: localStorage.getItem("role"),
          stageName: "all",
        };
        let result = await axios.post(
          `${base_url}${dashboard.dashboard}${dashboard.getRemainingDays}`,
          data
        );
        result = result.data;
        let progressData = [];
        result.map((result) => {
          const val = (result.RemainingDays / result.TotalDays) * 100;
          if (result.TotalDays > 0 && result.DaysSpent > 0) {
            if (result.DaysSpent > result.TotalDays) {
              progressData.push({
                remainingDays: val,
                background: `linear-gradient(to right, #ff0000 100%,#ff0000 0%)`,
                daysSpent: `${result.DaysSpent} days`,
                stageName: result.stageName,
                content: `${result.RemainingDays} days`,
              });
            } else {
              progressData.push({
                remainingDays: val,
                background: `linear-gradient(to right, #ffff01 ${val}%, #008000 0%)`,
                daysSpent: `${result.DaysSpent} days`,
                stageName: result.stageName,
                content: `${result.RemainingDays} days`,
              });
            }
          }
          if (result.TotalDays == 0 && result.DaysSpent > 0) {
            progressData.push({
              remainingDays: val,
              background: `linear-gradient(to right, #ffff01 40%, #008000 0%)`,
              daysSpent: `${result.DaysSpent} days`,
              stageName: result.stageName,
              content: `0 days`,
            });
          }
          if (result.TotalDays > 0 && result.DaysSpent == 0) {
            progressData.push({
              remainingDays: val,
              background: `linear-gradient(to right, #ffff01 100%, #008000 0%)`,
              daysSpent: "",
              stageName: result.stageName,
              content: `${result.RemainingDays} days`,
            });
          }
          if (result.TotalDays == 0 && result.DaysSpent == 0) {
            progressData.push({
              remainingDays: val,
              background: `linear-gradient(to right, transparent 100%, 0%)`,
              daysSpent: "",
              stageName: result.stageName,
              content: `Missing Time Data for the user`,
            });
          }
        });
        await setProgressValue(progressData);
        setLoadPage(false);

        resolve();
      } catch (error) {
        console.log(error);
      }
    });
  };

  const getProjectDetails = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = {
          role: localStorage.getItem("role"),
          userID: localStorage.getItem("id"),
        };
        setUserData(data);

        // let projectsData = await getProjectByRoleAndId(role, data.userID);
        
        // let books = booksReducer;
  
        // if(books.length<=0 || projectsReducer.length<=0){
  
        //   let data= await saveBooksAndProjects();
        //   books=data.booksData;
        //   await dispatch(storeProjects(data.projectsData))
        //   await dispatch(storeBooks(data.booksData));
        // }

        if(projectsReducer.length<=0 || booksReducer.length<=0){
          let data= await saveBooksAndProjects();
  
          data.projectsData.length >0 ? data.projectsData.forEach(async (item)=>{
            await dispatch(storeProjects([item]));
          }) : await dispatch(storeProjects([data.projectsData]));
      
          data.booksData.length>0 ? data.booksData.forEach(async(item)=>{
            await dispatch(storeBooks([item]));
          }): await dispatch(storeBooks([data.booksData]));
        }
        let projectsData= await projectsReducer;
        // await saveProjectDataToLocalStorgae(data);
        waitForAWhile(1000)
        await setProjects(projectsData);
        await setSelectedProject(projectsData[0]);
        await setProjectName(projectsData[0].projectName);
        await setProjectDate(projectsData[0].startDate);
        await setProjectEndDate(projectsData[0].endDate);
        await getProgressBarData(projectsData[0]);
        // getProgressBarData();
        
        resolve();
      } catch (error) {
        console.log(error);
      }
    });
  };

  const onChange = (value) => {
    setProjectName(value);
    let project = projects.filter((item) => item.projectName == value);
    setSelectedProject(project[0]);
    getProgressBarData(project[0]);
  };

  const disabledDate = (current) => {
    let customDate = moment(new Date(startDate)).format("YYYY-MM-DD");
    return current && current < moment(customDate, "YYYY-MM-DD");
  };

  const goBack = () => {
    navigate(-1);
  };

  const onsubmitProgress = async () => {
    if (startDate != null) {
      if (endDate != null && Date.parse(endDate) <= Date.parse(startDate)) {
        showNotification &&
          openNotification("To date should not older then From date");
        setShowNotification(false);
        setShowChunkData(false);
        setChunkProgress({});
      } else {
        setShowChunkData(true);
        setIsLoader(true);
        let userstartDate = moment(new Date(startDate)).format("YYYY-MM-DD");
        let userendDate =
          endDate !== null
            ? moment(new Date(endDate)).format("YYYY-MM-DD")
            : "";
        let majorLanguages = [
          selectedProject.majorLanguage1,
          selectedProject.majorLanguage2,
          selectedProject.majorLanguage3,
        ];
        let projectId = selectedProject.id;
        let data = {
          startDate: userstartDate,
          endDate: userendDate,
          majorLanguages: majorLanguages,
          userId: userData.userID,
          role: userData.role.toLowerCase(),
          projectId: projectId,
        };
        let res = await axios.post(
          `${base_url}${bible.bible}${bible.getChunkProgressData}`,
          data,
          getHeader()
        );
        setSubBtnDisabled(true);
        setChunkProgress(res.data[0]);
        setIsLoader(false);
      }
    } else {
      // if(showNotification==true) openNotification("Please select From Date")
      openNotification("Please select From Date");
      setShowNotification(false);
    }
  };

  const openNotification = (message) => {
    notification.warning({
      message: "Warning",
      duration: 2,
      description: message,
    });
  };

  const resetDate = () => {
    setSubBtnDisabled(true);
    setResBtnDisabled(true);
    setStartDate(null);
    setEndDate(null);
    setKey((k) => !k);
    setShowChunkData(false);
  };

  return (
    <div>
      <MainLayout>
        {loadPage ? (
          <SpinnerComponent />
        ) : (
          <div className="draft-details myProgress">
            <div
              className="draft-details-wrapper oyscrl"
            >
              <div className="grid grid-cols-3 border-bottom">
                <div className="back-arrow">
                  <BiArrowBack size={24} onClick={goBack} />
                </div>
                <div className="flex items-center justify-center chunkname">
                  <h4 className="fsize">{"My Progress"}</h4>
                </div>
                <div className="  d-flex flex-column  ">
                  {/* <div className=" dateTitle text-end text-decoration-underline pe-5 mr-4-5">
                    <span style={{ fontWeight: "bold" }}>{"Project "}</span>
                  </div> */}
                  <div className="projectDate text-end mt-2 ">
                    {/* <span style={{fontWeight:'bold'}}>{projectName}</span> */}
                    {role == "MTT" || role == "Facilitator" ? (
                      <span
                        className="text-end dateTitle fsizesm"
                        style={{ fontWeight: "bold" }}
                      >
                        {projectName}
                      </span>
                    ) : (
                      <Select
                        bordered={0}
                        value={projectName}
                        onChange={onChange}
                      >
                        {projects.map((project) => (
                          <Select.Option
                            value={project.projectName}
                            className="capitalize" 
                          > 
                            <span className="fsizesm" style={{ fontWeight: "bold",display:"block" }}>
                              {project.projectName}
                            </span>
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                    <br/>
                    <Tooltip placement="bottom" title={"Project Started at "}>
                      <span className="mx-2 fsizesm">
                        ({moment(date).format("DD-MMM-YYYY")})
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>

              <div className="card  rounded-0">
                <div className="myProgressCard bg-white">
                  <div className="flex justify-content-evenly w-100">
                    {progressValue.length > 0 ? (
                      progressValue.length > 1 ? (
                        progressValue.map((data) => (
                          <div className=" d-flex text-center flex-column p-2">
                            <div className="text-center  ">
                              <div className="w-100 text-center mb-1 fsize">
                                <span className=" mx-1">{data.stageName}</span>
                              </div>
                              <div
                                className="progress pgr-dimensions"
                                style={{
                                  border: "1px solid black",
                                  borderRadius: "50%",
                                  // boxShadow: "0px 0 5px 2px #333",
                                  backgroundImage: data.background,
                                }}
                              >
                                <div
                                  className="progress-bar "
                                  role="progressbar"
                                  // role="spinbutton"
                                  style={{
                                    // width: data.remainingDays + "%",
                                    height: "80%",
                                    width: "80%",
                                    top: "10%",
                                    left: "10%",
                                    position: "relative",
                                    backgroundColor: "#fff",
                                    borderRadius: "50%",
                                    border: "1px solid black",
                                  }}
                                  aria-valuenow="50"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  <span className="flex">
                                    <span
                                      className=" w-50 text-start mx-1"
                                      style={{
                                        fontSize: "16px",
                                        // color: "black",
                                      }}
                                    >
                                      {data.content}
                                    </span>
                                    <span
                                      className=" w-50 text-end px-1"
                                      style={{
                                        fontSize: "16px",
                                        // color: "black",
                                      }}
                                    >
                                      {data.daysSpent}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        progressValue.map((data) => (
                          <div className=" d-flex text-center flex-column p-2">
                            <div className="text-center  ">
                              <div className="w-100 text-center mb-1">
                                <span className="mx-1 fsize">{data.stageName}</span>
                              </div>
                              <div
                                className="progress pgr-dimensions"
                                style={{
                                  border: "1px solid black",
                                  borderRadius: "50%",
                                  // boxShadow: "0px 0 5px 2px #333",
                                  backgroundImage: data.background,
                                }}
                              >
                                <div
                                  className="progress-bar "
                                  role="progressbar"
                                  // role="spinbutton"
                                  style={{
                                    // width: data.remainingDays + "%",
                                    height: "80%",
                                    width: "80%",
                                    top: "10%",
                                    left: "10%",
                                    position: "relative",
                                    backgroundColor: "#fff",
                                    borderRadius: "50%",
                                    border: "1px solid black",
                                  }}
                                  aria-valuenow="50"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  <span className="flex w-100">
                                    <span
                                      className=" w-50 text-start mx-1"
                                      style={{
                                        fontSize: "16px",
                                        // color: "black",
                                      }}
                                    >
                                      {" "}
                                      {data.content}
                                    </span>

                                    <span
                                      className=" w-50 text-end px-1"
                                      style={{
                                        fontSize: "16px",
                                        color: "black",
                                      }}
                                    >
                                      {" "}
                                      {data.daysSpent}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      )
                    ) : (
                      <div
                        className="mt-2 text-bold"
                        style={{ fontWeight: "bold" }}
                      >
                        Missing Time Data For The Users
                      </div>
                    )}
                  </div>
                  <div className="w-100 mt-1 flex justify-content-evenly ">
                    <p className="flex items-center">
                      <span style={{ color: "red", fontSize: "2rem" }}>*</span>
                      <span>Timeline Exceeded</span>
                    </p>

                    <p className="flex items-center">
                      <span style={{ color: "green", fontSize: "2rem" }}>
                        *
                      </span>
                      <span>Used</span>
                    </p>
                    <p className="flex items-center">
                      <span style={{ color: "#ffe400", fontSize: "2rem" }}>
                        *
                      </span>
                      <span>Remaining</span>
                    </p>
                  </div>

                  <div className="d-flex flex-column gap-3 w-100  mb-1 p-2">
                    <div className="border-bottom border-1 border-dark">
                      <label className="mt-1">From Date</label>
                      <DatePicker
                        key={key}
                        placeholder="Select Date"
                        className=" border-0 float-end mb-2"
                        onChange={(date) => {
                          setStartDate(date);
                          setSubBtnDisabled(false);
                          setResBtnDisabled(false);
                          setShowNotification(true);
                        }}
                        rules={[
                          {
                            required: true,
                            message: "Please select date!",
                          },
                        ]}
                      />
                    </div>
                    <div className="border-bottom border-1 border-dark">
                      <label className="mt-1">To Date</label>
                      <DatePicker
                        className="border-0 float-end mb-2"
                        format="YYYY-MM-DD"
                        key={key}
                        onChange={(date) => {
                          setEndDate(date);
                          setSubBtnDisabled(false);
                          setShowNotification(true);
                        }}
                        placeholder="Select Date"
                        disabledDate={disabledDate}
                      />
                    </div>
                  </div>

                  <div className=" d-flex text-center flex-row gap-3 w-100 mt-3 mb-auto p-2">
                    <div className="mpr-resetbtn w-50">
                      {subbtnDisabled == true ? (
                        <Tooltip
                          placement="topLeft"
                          title={"Please select From Date"}
                        >
                          <Button
                            className="btnSty w-25 toolbtn"
                            onClick={onsubmitProgress}
                            disabled={subbtnDisabled}
                          >
                            Submit
                          </Button>
                        </Tooltip>
                      ) : (
                        <Button
                          className="btnSty w-25"
                          onClick={onsubmitProgress}
                          disabled={subbtnDisabled}
                        >
                          Submit
                        </Button>
                      )}
                    </div>
                    <div className="mpr-resetbtn w-50">
                      <Button
                        className="btnSty w-25"
                        onClick={() => resetDate()}
                        disabled={resBtnDisabled}
                      >
                        Reset
                      </Button>
                    </div>
                  </div>

                  <div className="chunkStatus w-100">
                    {showChunkData ? (
                      <Card
                        className="border-0"
                        style={{
                          margin: "20px",
                        }}
                      >
                        {isLoader ? (
                          <div className="text-center my-2 py-2  flex items-center justify-center">
                            <div>
                              {/* <i className="fas fa-spinner fa-2x fa-spin spinner spinnerTop"></i> */}
                            </div>
                          </div>
                        ) : (
                          <div className="chunkData">
                            {chunkProgress != undefined ? (
                              <div className="d-flex">
                                <div className=" text-center justify-content-center d-flex w-50 mt-2 mb-1 ">
                                  <span
                                    className=" text-white fs-7 rounded-pill border-1 p-2 w-75 "
                                    style={{ backgroundColor: "#007000" }}
                                  >
                                    Chunk Completed:{" "}
                                    {chunkProgress.completedChunk}
                                  </span>
                                </div>
                                <div className="text-center justify-content-center d-flex w-50 mt-2 mb-1">
                                  <span
                                    className=" text-white fs-7 rounded-pill border-1 p-2 w-75"
                                    style={{ backgroundColor: "#F9002C" }}
                                  >
                                    Chunk Pending: {chunkProgress.pendingChunk}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div className="text-center justify-content-center d-flex w-100 mt-2 mb-1 ">
                                <span className="bg-warning p-2 w-75">
                                  {"No Data Found "}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </Card>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </MainLayout>
    </div>
  );
};

export default MyProgressPage;
