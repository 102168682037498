import axios from "axios";
import getHeader, { waitForAWhile } from "./config";
import { base_url, projects,desktop } from "../services/apis";

const saveBooksAndProjects = async (navigate) => {
    return new Promise(async (resolve, reject) => {
      // try {        
        const res = await loadUserBookAndProject(navigate);
        console.log("TE REPSONSE",res);
        if( localStorage.getItem("role") == "Technician" ||
        localStorage.getItem("role") == "Coordinator" ||
        localStorage.getItem("role") == "Translation Manager"){
          const projectsData = await res.data.map(item => item.project);
          const responseBooksData =await res.data.map(item => item.book);
           resolve({ projectsData, booksData:responseBooksData[0] });
        }else{
          resolve({projectsData:res.data.projects||res.data[0].project,booksData:res.data.books||res.data[0].book})
        }

      //   let projectsData=[];
      //   let booksData = []
       
      //   for(let i=0;i<res.data.length;i++){
      //     projectsData.push(res.data[i].project)
      //     let books = res.data[i].books||res.data[i].book
      //     for(let j=0;j<books.length;j++){
      //       booksData.push(books[j])
      //     }
      //     if(i==res.data.length-1){
      //       resolve({projectsData,booksData})
      //     }
      // }
    });
  };
  
  const loadUserBookAndProject = (navigate) => {
    return new Promise(async (resolve, reject) => {
      let res ;
      try {
        let url =
          localStorage.getItem("role") == "Technician" ||
          localStorage.getItem("role") == "Coordinator" ||
          localStorage.getItem("role") == "Translation Manager"
            ? `${base_url}${desktop.dt}${desktop.getTecnicianBookList}`
            : `${base_url}${desktop.dt}${desktop.getBooksDetails}`;
            console.log("URL: ",url)
  
        let body = {
          role: localStorage.getItem("role"),
          userID: {
            id: localStorage.getItem("id"),
          },
        };
         res = await axios.post(url, body, getHeader());
          resolve(res); 
      } catch (error) {
        if (res == undefined) {
          localStorage.clear();
          navigate("/signin");
          return;
        }
        reject(error);
      }
    });
  };

  export {
    saveBooksAndProjects
}