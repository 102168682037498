let initial_state = [];

const booksReducer = (state = initial_state, action) => {
  // console.log("action",action,initial_state);
  switch (action.type) {
    case "GET_BOOKS":
      return state;

    case "STORE_BOOKS":
      return [...state, ...action.payload];
    case "CLEAR_BOOKS":
      return [...initial_state];

    default:
      return state;
  }
};

export default booksReducer;