import React, { useContext, useEffect, useRef, useState } from "react";
import { Alert } from "antd";
import { FaPause } from "react-icons/fa";
import { BsPlayCircle } from "react-icons/bs";
import Moment from "moment";
import { useLocation } from "react-router";
import { s3_files_url } from "../../../services/apis";
import ScriptTextBox from "./script-textbox.component";
// import Waveform from "../../waveform/waveform.component";
import { IoLanguage } from "react-icons/io5";
import { UNSAFE_NavigationContext } from "react-router-dom";
import VideoPlayer from "../../video/video-player";
import { useDispatch, useSelector } from "react-redux";
import { togglePlaying, } from "../../../redux/actions/util.action";
import { setAudioRef } from "../../../redux/actions/audio.action";
import SmallSpinnerComponent from "../../spinner/small-spinner.component";
import Waveform from "../../waveform/waveform.component";
import StaticWaveform from "../../waveform/static.waveform";
import WaveSurfer from "wavesurfer.js";
import { isTablet, isSafari,isMobile } from 'react-device-detect';
import { storeValue } from "../../../services/config";

// import { getProjectById } from "../../../services/indexed-db/project.indexeddb";
// import { unitArrayToBlob } from "../../../services/conversion.service";
const SourceLanguage = ({ chunk, title, key }) => {
  const audioRef = useRef(null);
  const countRef = useRef(null);
  const [isMobiles, setIsMobiles] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  // const [waveLoading,setWaveLoading] = useState(false);
  const [language, setLanguage] = useState("");
  const [chunks, setChunks] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [count, setCount] = useState(0)
  const [alertMessage, setAlertMessage] = useState({
    type: "",
    message: "",
  });
  const isPlayingRef = useRef(false);
  const setTimeoutRef = useRef();
  const [secs, setSecs] = useState(0);
  const [endingTime, setEndingTime] = useState(0);
  const [isAudioLoading, setIsAudioLoading] = useState(false);
  const [projectType, setProjectType] = useState({});
  const dispatch = useDispatch();
  const projectsReducer = useSelector((state) => state.projectsReducer);
  const isPlayingStatus = useSelector((state) => state.playingStatusReducer);
  const isRecordingStatus = useSelector(
    (state) => state.recordingStatusReducer
  );
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [totalTime, setTotalTime] = useState();
  const [currentTime, setCurrentTime] = useState();
  const [remainingTime, setRemainingTime] = useState();
  const location = useLocation();
  const [audioDetails, setAudioDetails] = useState([]);
  const [currentAudioIndex, setCurrentAudioIndex] = useState();

  useEffect(() => {
    setIsPlaying(false);
    loadDetails();
    // if (!isTablet && !isSafari) {
    //   loadDetails()
    // } else {
    //   loadStaticDetails()
    // }
    // !isTablet && !isSafari ? loadDetails() : loadStaticDetails();
    const listener = ({ location, action }) => {
      if (action === "PUSH") {
        if (isPlayingRef.current == true) {
          pauseAudio();
        }
      }
    };
    const checkWindowSize = () => {
      if (window.innerWidth <= 768) { // Adjust the screen width as needed
        setIsMobiles(true);
      } else {
        setIsMobiles(false);
      }
    };
    // Initial check when the component mounts
    checkWindowSize();
    // Listen for window resize events and update the state
    window.addEventListener('resize', checkWindowSize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', checkWindowSize);
    };
    // const unlisten = navigator.listen(listener);
    // return unlisten;
  }, [chunk, key]);

  useEffect(() => {
    window.addEventListener("hashchange", () => {
      if (isPlaying == true) {
        pauseAudio();
      }
    });
  }, [setIsPlaying]);

  useEffect(() => {
    if (chunks.length > 0 && count < 1 && !isTablet && !isSafari) {
      // Call getWaveForm when chunks have been set
      getWaveForm();
    }
  }, [chunks, key]);

  const loadDetails = async () => {
    console.log("loadDetails");
    return new Promise(async (resolve, reject) => {
      try {
        if (location.pathname == "/cross-check" && !isTablet && !isSafari) {
          if (wavesurfer.current) {
            wavesurfer.current.destroy();
            wavesurfer.current = null;
          }
        }
        setChunks([]);
        setIsAudioLoading(true);
        // setWaveLoading(true);
        chunk = chunk[0] || chunk;
        // let lang = chunk.languageName || chunk.chunk[0].language || chunk.language || chunk.chunk[0].majorLanguage;
        // console.log("lang: ",lang)
        // setLanguage(lang);
        // setChunks(chunk.chunk || [chunk]);
        let uniqueLanguages = new Set();
        if (localStorage.getItem('role') == "MTT" || (localStorage.getItem('role') == "Facilitator")||(localStorage.getItem('role') == "Technician")) {
          if ('chunk' in chunk) {
            chunk.chunk.forEach(obj => {
              uniqueLanguages.add(obj.language||obj.languageName);
            });
            setLanguage(Array.from(uniqueLanguages));
            setChunks(chunk?.chunk || [chunk]);
          } else {
            setLanguage([chunk.languageName])
            setChunks(chunk.draftFile);
          }
        }
        else {

          let lang = chunk.languageName || chunk.chunk[0].language || chunk.language || chunk.chunk[0].majorLanguage;
          setLanguage([lang]);
          setChunks(chunk?.chunk || chunk.draftFile || [chunk]);
        }
        let project = await projectsReducer[0]
        setProjectType(project.projectType);
        if (!isTablet && !isSafari) {
          setCount(0)
          setIsAudioLoading(false);
        } else {
          let Achunk = chunk?.chunk || chunk.draftFile || [chunk];
          let audioChunks = Achunk.filter((item) => item.fileType === "audio");

          const audioDurations = await Promise.all(
            audioChunks.map(async (audioChunk) => {
              const url = await getAduio(audioChunk);
              const duration = await getAudioDuration(url);
              return {
                chunk: audioChunk,
                duration: duration
              };
            })
          );

          console.log("After setting: ", chunks)
          setAudioDetails(audioDurations);
          setCurrentAudioIndex(0);
        }



      } catch (error) {
        console.error("Source Langauge ", error);
      }

      resolve();
    });
  };


  const getAudioDuration = async (url) => {
    return new Promise((resolve, reject) => {
      const audio = new Audio(url);
      audio.addEventListener('loadedmetadata', () => {
        resolve(audio.duration);
      });
      audio.addEventListener('error', (error) => {
        reject(error);
      });
    });
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const playCurrentAudio = async (chunkIndex, item) => {
    try {
      if (chunkIndex < 0 || chunkIndex >= audioDetails.length) {
        return; // Return early if the index is out of range
      }

      let indexValue = audioDetails.findIndex((items) => items.chunk._id === item._id);

      const { chunk } = audioDetails[indexValue];
      const url = await getAduio(chunk);

      audioRef.current = new Audio(url); // Update the audioRef

      audioRef.current.addEventListener("loadedmetadata", async () => {
        const duration = audioRef.current.duration;

        await audioRef.current.play();

        setIsPlaying(true);
        dispatch(togglePlaying(true));
        storeValue(audioRef)
       
        isPlayingRef.current = true;
        countRef.current = setInterval(() => {
          setSecs((secs) => secs + 1);
        }, 1000);
        setTimeoutRef.current = setTimeout(async () => {
          if (isPlayingRef.current) {
            await pauseAudio();
          }
        }, duration * 1000);
      });

      audioRef.current.load();
    } catch (error) {
      console.error("Error playing audio: ", error);
    }
  };


  const getWaveForm = async () => {
    // setWaveLoading(false);  
    dispatch(togglePlaying(false));
    isPlayingRef.current = false;
    if (wavesurfer.current || waveformRef.current) {
      wavesurfer.current = null;
    }
    chunk = chunk[0] || chunk;
    let Achunk = chunk?.chunk || chunk.draftFile || [chunk];
    let audioChunks = Achunk.filter((item) => item.fileType === "audio");

    const audioDurations = await Promise.all(
      audioChunks.map(async (audioChunk) => {
        setIsAudioLoading(true);
        const url = await getAduio(audioChunk);
        setIsAudioLoading(false);
        const options = await formWaveSurferOptions(waveformRef.current);
        wavesurfer.current = await WaveSurfer.create(options);
        wavesurfer.current.load(url);

      })
    );
    setCount(count + 1)
    // setWaveLoading(false);
  }



  const getAduio = async (item) => {
    let url;
    url = `${s3_files_url}/${item.fileId}/${item.fileName}`;
    setIsAudioLoading(false);
    return (url)
  }

  const formWaveSurferOptions = (ref) => ({
    container: ref,
    waveColor: '#11172b',
    progressColor: '#708090',
    cursorColor: "black",
    cursorHeight: 10,
    barWidth: 0,
    barRadius: 3,
    responsive: true,
    height: 60,
    // If true, normalize by the maximum peak instead of 1.0.
    // normalize: true,m 
    // Use the PeakCache to improve rendering speed of large waveforms.
    partialRender: true,
    xhr: {
      cache: "default",
      mode: "cors",
      method: "GET",
      credentials: "include",
      headers: [
        { key: "cache-control", value: "no-cache" },
        { key: "pragma", value: "no-cache" }
      ]
    }
  });

  const playAudio = async (item) => {
    try {
      if (isRecordingStatus == true) {
        showAlertMessage("error", "Please stop recording");
      } else if (isPlayingStatus == true) {
        showAlertMessage("error", "Please stop current playing");
      } else {
        let url;
        setIsAudioLoading(true);
        url = `${s3_files_url}/${item.fileId}/${item.fileName}`;
        setIsAudioLoading(false);
        if (!wavesurfer.current) {
          // const options = await formWaveSurferOptions(waveformRef.current);
          // wavesurfer.current = await WaveSurfer.create(options);
          // wavesurfer.current.load(url);
          wavesurfer.current.on("ready", async function () {
            try {
              await wavesurfer.current.playPause();
            } catch (error) {
              console.log("ERROR", error);
            }
          });

        } else {
          await wavesurfer.current.playPause();
        }
        wavesurfer.current.on("audioprocess", async function () {
          if (wavesurfer.current.isPlaying()) {
            const totalTimeValue = await wavesurfer.current.getDuration();

            const currentTimeValue = await wavesurfer.current.getCurrentTime();
            const remainingTimeValue = await totalTimeValue - currentTimeValue;

            setTotalTime(Math.floor(totalTimeValue))
            setCurrentTime(Math.floor(currentTimeValue))
            setRemainingTime(Math.floor(remainingTimeValue))
          }
        });

        wavesurfer.current.on("finish", async function () {
          setIsPlaying(false);
          setTotalTime(0);
          setCurrentTime(0);
          // setCount(0)
          wavesurfer.current.destroy();
          wavesurfer.current = null;
          const options = await formWaveSurferOptions(waveformRef.current);
          wavesurfer.current = await WaveSurfer.create(options);
          wavesurfer.current.load(url);
          dispatch(togglePlaying(false));
          isPlayingRef.current = false;
          // setPlay(!playing);
        });
        dispatch(togglePlaying(true));
        isPlayingRef.current = true;
        setIsPlaying(true);
        // audioRef.current = new Audio(url);
        // console.log("Source Audio: ", audioRef.current)
        // setIsPlaying(true);
        // countRef.current = setInterval(() => {
        //   setSecs((secs) => secs + 1);
        // }, 1000);
        // console.log("Play: ", audioRef.current.play())
        // await audioRef.current.play();
        // let timePlay = await audioRef.current.duration;
        // setEndingTime(Math.ceil(timePlay));
        // isPlayingRef.current = true;
        // setTimeoutRef.current = setTimeout(async () => {
        //   if (isPlayingRef.current == true) {
        //     await pauseAudio();
        //   }
        // }, timePlay * 1000);
      }
    } catch (error) {
      console.log(error);
      showAlertMessage(
        "error",
        "Something went wrong, Please try after some time"
      );
      if (isPlaying === true) {
        pauseAudio();
      }
      setIsAudioLoading(false);
    }
  };

  const pauseAudio = async () => {
    return new Promise(async (resolve, reject) => {
      if (!isTablet && !isSafari) {
        dispatch(togglePlaying(false));
        await wavesurfer.current.playPause();
        isPlayingRef.current = false;
        setSecs(0);
        setIsPlaying(false);
      } else {
        dispatch(togglePlaying(false));
        if (audioRef.current) audioRef.current.currentTime = 0;
        clearTimeout(setTimeoutRef.current);
        await audioRef.current.pause();
        clearInterval(countRef.current);
        isPlayingRef.current = false;
        setSecs(0);
        setIsPlaying(false);
      }
      resolve();
    });
  };

  const showAlertMessage = async (type, message) => {
    setAlertMessage({
      ...alertMessage,
      type,
      message,
    });
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 1500);
  };
  return (
    <>
      {
        !isTablet && !isSafari ?
          <div key={key}>
            <div className="flex flex-row">
              <h4 className="flex fsize">
                {title}
                <span className="px-2">
                  <IoLanguage size={isMobiles ? 16 : 26} />
                </span>
              </h4>
              <span className="capitalize fsize">{language}</span>
            </div>

            <div>
              {showAlert && (
                <Alert
                  message={alertMessage.message}
                  type={alertMessage.type}
                  showIcon
                  className="w-86 my-1"
                  closable
                />
              )}
            </div>

            <div className="py-2 mx-2 select-none w-100">
              <div className="row">
                {chunks &&
                  chunks.map((item, i) => (
                    <div key={i} className="col-md-6 col-lg-6 mb-4">
                      <div className="video-source">
                        {item.fileType == "video" && (
                          <div className="mx-2">
                            <VideoPlayer data={item} fileType="DRAFTFILE" />
                          </div>
                        )}

                      </div>
                      <div id="audio-source" className={isPlaying ? "mb-2" : ""}>
                        {item.fileType == "audio" && (
                          <div key={i}>
                            <>
                              <div className="flex">
                                <span className={isPlaying ? " flex mt-4" : "flex "}>
                                  {isPlaying ?
                                    <div className="justify-content-center">
                                      <FaPause
                                        size={24}
                                        onClick={pauseAudio}
                                        className="mx-2"
                                      />
                                    </div>
                                    : ( // </button> //   <FaPause size={26} onClick={pauseAudio} /> // <button className="remove-btn-css">
                                      <>
                                        <button
                                          className="remove-btn-css ml-4 mr-2"
                                          disabled={
                                            isRecordingStatus || isPlayingStatus
                                          }
                                          onClick={(e) => {
                                            playAudio(item);
                                          }}
                                        >
                                          {isAudioLoading == true ? (
                                            <SmallSpinnerComponent />
                                          ) : projectType.signLanguage == false ? (
                                            <BsPlayCircle
                                              size={26}
                                              color={
                                                (isRecordingStatus ||
                                                  isPlayingStatus) == true
                                                  ? "gray"
                                                  : "black"
                                              }
                                            />
                                          ) : (
                                            <p> View Interpreter Resource </p>
                                          )}
                                        </button>
                                      </>
                                    )}
                                </span>
                                {/* <div className="flex flex-row"></div> */}

                                {isAudioLoading == true && !waveformRef ? (
                                  <SmallSpinnerComponent />
                                ) : <div className="container">
                                  <div id="waveform" ref={waveformRef} />
                                </div>}
                              </div>
                              <div className="pt-4 text-center">
                                {isPlaying && (
                                  <span className="flex items-center justify-center" style={{ fontSize: "small" }}>
                                    {Moment.utc(currentTime * 1000).format("mm:ss")}/{Moment.utc(totalTime * 1000).format("mm:ss")}
                                    {/* {currentTime}/{totalTime} */}
                                  </span>
                                )}
                              </div>
                            </>
                          </div>
                        )}
                      </div>
                      <div id="script-source">
                        {item.fileType == "script" && <ScriptTextBox item={item} />}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div> :
          <div className="source mx-3 mt-8">
            {showAlert && (
              <Alert
                message={alertMessage.message}
                type={alertMessage.type}
                showIcon
                className="w-86 my-1"
                closable
              />
            )}
            <div className="ml-2 ant-form-item">
              <h4 className="flex items-center">
                {title}
                <span className="px-2">
                  <IoLanguage size={26} />
                </span>
              </h4>
            </div>




            {language && language.map((name, langIndex) => (
              <div key={langIndex} className="language-content">
                <span className="ml-2 capitalize">{name}</span>
                {chunks?.length > 0 && (
                  <div className="row">
                    {chunks
                      .filter((item) => item.language === name || item.languageName == name)
                      .map((item, chunkIndex) => {
                        // const playAudioWithIndex = () => playAudio(item, chunkIndex);

                        return (
                          <div className="col-md-6">
                             <div key={chunkIndex} className="flex">
                            {/* Your existing code for rendering components */}
                            <div id="video-source">
                              {item.fileType === "video" && (
                                <div className="mx-2">
                                  <VideoPlayer data={item} url={""} isDisabled={false} />
                                </div>
                              )}
                            </div>
                            <div className="row">
                            <div className="flex">
                              {item.fileType === "audio" && (
                                <div className="flex">
                                  <span className={isPlaying ? "flex mt-4" : "flex"}>
                                    <div className="flex justify-center items-center">
                                      <span className="flex">
                                        {isPlaying && currentAudioIndex === item._id ? (
                                          <div className="flex justify-center">
                                            <FaPause
                                              size={24}
                                              onClick={pauseAudio}
                                              className="mx-2"
                                            />
                                          </div>
                                        ) : (
                                          <>
                                            <button
                                              className="remove-btn-css ml-4 mr-2"
                                              disabled={isRecordingStatus || isPlayingStatus}
                                              onClick={() => {
                                                setCurrentAudioIndex(item._id)
                                                playCurrentAudio(chunkIndex, item);
                                              }}
                                            >
                                              {isAudioLoading == true ? (
                                                <SmallSpinnerComponent />
                                              ) : projectType.signLanguage == false ? (
                                                <BsPlayCircle
                                                  size={26}
                                                  color={
                                                    (isRecordingStatus ||
                                                      isPlayingStatus) == true
                                                      ? "gray"
                                                      : "black"
                                                  }
                                                />
                                              ) : (
                                                <p> View Interpreter Resource </p>
                                              )}
                                            </button>
                                          </>
                                        )}
                                      </span>
                                      <div>
                                        {isPlaying && currentAudioIndex === item._id ? (
                                          <Waveform isPlaying={false} lines={isTablet ? 50: isMobile ? 10:120} />
                                        ) : (
                                          <StaticWaveform lines={isTablet ? 50: isMobile ? 10:120} />
                                        )}
                                      </div>
                                      {(isPlaying && currentAudioIndex === item._id) ? (
                                        <div className=" text-center">
                                          {isPlaying && currentAudioIndex === item._id && (
                                            <span className="flex items-center justify-center" style={{ fontSize: "small" }}>
                                              {Moment.utc(secs * 1000).format("mm:ss")}
                                            </span>
                                          )}
                                        </div>
                                      ) : (
                                        <div className="text-center">
                                          <span className="end-time">
                                            {audioDetails.find((audio) => audio.chunk._id === item._id)?.duration && (
                                              <span>{formatTime(audioDetails.find((audio) => audio.chunk._id === item._id)?.duration)}</span>
                                            )}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </span>
                                </div>
                              )}
                              {/* ... */}
                            </div>
                            <div id="script-source" className="pt-2">
                              {item.fileType === "script" && <ScriptTextBox item={item} />}
                            </div>
                            </div>
                          </div>
                          </div>
                         
                        );
                      })}
                  </div>
                )}
              </div>
            ))}


          </div>
      }

    </>
  );
};

export default SourceLanguage;