import { useState } from 'react'
import './custom-toggle.scss'

export const Toggle = ({ label, toggled, changeToggle, disable }) => {
    const [isToggled, toggle] = useState(toggled)

    const handleClick = () => {
        if (!isToggled) {
            toggle(true); // Toggle the state to true
            changeToggle(); // Call the changeToggle function
        }
    }

    return (
        <div className='toggleButton'>
            <label>
                <input
                    type="checkbox"
                    defaultChecked={isToggled}
                    disabled={disable || isToggled} // Disable if isToggled is true
                    onClick={handleClick}
                />
                <span />
                <strong>{label}</strong>
            </label>
        </div>
    )
}