import {
  Alert,
  Button,
  Select,
  Table,
  Tabs,
  Modal,
  Dropdown,
  Menu,
} from "antd";
import React, { useEffect, useState } from "react";

import { BsPlayCircle } from "react-icons/bs";
import { RiArrowRightSFill, RiDraftLine } from "react-icons/ri";
import { GoChecklist } from "react-icons/go";
import { HiOutlineUserGroup } from "react-icons/hi";
import { BsTranslate, BsPersonCheck } from "react-icons/bs";
import { IoRecordingOutline } from "react-icons/io5";
import {
  MdOutlineFactCheck,
  MdOutlinePublish,
} from "react-icons/md";
import { useNavigate } from "react-router";
import MainLayout from "../../components/layout/main.layout";
import SpinnerComponent from "../../components/spinner/spinner.component";
import TitleBar from "../../components/ui/title-bar/title-bar.component";
import {
  sortBooks,
  sortChapters,
} from "../../services/config";
// import { getBooksByUserRole } from "../../services/indexed-db/books.indexeddb";
// import {
//   getProjectByRoleAndId,
// } from "../../services/indexed-db/project.indexeddb";
import { useSelector,useDispatch } from "react-redux";
import {saveBooksAndProjects} from "../../services/BooksAndProjects"
import { storeBooks } from "../../redux/actions/books.action";
import {storeProjects} from "../../redux/actions/project.action";
import TechnicianModal from "../../components/modal/technician-modal";
import { GiProgression } from "react-icons/gi";
const { confirm } = Modal;

const TechCord = () => {
  const projectsReducer = useSelector((state) => state.projectsReducer);
  const booksReducer = useSelector((state) => state.booksReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(true);
  const [projects, setProject] = useState([]);
  const [currentProject, setCurrentProject] = useState({});
  const [currentBook, setCurrentBook] = useState([]);
  const [currentBookName, setCurrentBookName] = useState("");
  const [currentProjectName, setCurrentProjectName] = useState("");
  const [currentChapter, setCurrentChapter] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState();
  const [currentChapterName, setCurrentChapterName] = useState("null");
  const [showModal, setShowModal] = useState(false);
  const [placeHolder, setPlaceHolder] = useState("Please Select a Project");
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [toggle, setToggle] = useState(false);
  const prevSelectedProject = JSON.parse(
    localStorage.getItem("selectedProject")
  );
  const [showNotification, setShowNotification] = useState(false);
  const [activeKey, setActiveKey] = useState("1");
  const [multiSelect, setMultiSelect] = useState(false);
  const [oldTestamentBooks, setOldTestamentBooks] = useState([]);
  const [newTestamentBooks, setNewTestamentBooks] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [selectedBook, setSelectedBook] = useState({});
  const [selectedOption, setSelectedOption] = useState("PROJECT");

  useEffect(() => {
    if (role == "Technician" || role == "Translation Manager") {
      setActiveKey("8");
    } else if (role == "Coordinator") {
      setActiveKey("9");
    }
    fetchUserData();
    localStorage.removeItem("aduioPlay");
  }, []);

  const fetchUserData = async () => {
    return new Promise(async (resolve, reject) => {
      setisLoading(true);
      try {
        // let userId = localStorage.getItem("id");
        // let projectsData = await getProjectByRoleAndId(role, userId);

        // let booksData = await getBooksByUserRole(role.toLocaleLowerCase());

        let userId = localStorage.getItem("id");
        let projectsData= await projectsReducer;
        let booksData = await booksReducer;
        setProject(projectsData);
        // if(booksData.length<=0 || projectsReducer.length<=0){
        //   let data= await saveBooksAndProjects();
        //   projectsData=data.projectsData
        //   booksData=data.booksData;
        //   await dispatch(storeProjects(data.projectsData))
        //   await dispatch(storeBooks(data.booksData));
        // }
        if(projectsReducer.length<=0 || booksReducer.length<=0){
          let data= await saveBooksAndProjects();
  
          data.projectsData.length >0 ? data.projectsData.forEach(async (item)=>{
            await dispatch(storeProjects([item]));
          }) : await dispatch(storeProjects([data.projectsData]));
          projectsData= await data.projectsData
      
          data.booksData.length>0 ? data.booksData.forEach(async(item)=>{
            await dispatch(storeBooks([item]));
          }): await dispatch(storeBooks([data.booksData]));
          booksData= await data.booksData;
        }

        let oldTestament = await booksData.filter(
          (item) => item.testamentType == "old"
        );
        setOldTestamentBooks(oldTestament);
        let newTestament = await booksData.filter(
          (item) => item.testamentType == "new"
        );
        setNewTestamentBooks(newTestament);

        for (const project of projectsData) {
          project.bookData = [];
          for (const books of booksData) {
            if (project.id == books.projectId) {
              project.bookData.push(books);
            }
          }
        }
        await setProject(projectsData);
        if (projectsData != 0) {
          if (
            prevSelectedProject == null ||
            prevSelectedProject == undefined ||
            !Object.keys(prevSelectedProject).length
          ) {
            projectsData[0].bookData = await sortBooks(
              projectsData[0].bookData
            );
            setCurrentProject(projectsData[0]);
            setCurrentBook([]);
            setCurrentProjectName(projectsData[0].projectName);

            setCurrentChapter([]);
            // setCurrentChapter(projectsData[0]?.bookData[0]?.chapters[0]);
            resolve();
          } else {
            let lastSelectedProject = projectsData.filter(
              (item) => item.projectName == prevSelectedProject.projectName
            );
            setCurrentProject(projectsData[0]);
            let book = lastSelectedProject[0].bookData;
            setCurrentBook(book);
            book = book.filter(
              (item) => item.bookName == prevSelectedProject.bookName
            );
            setSelectedBook(book[0])
            let chapterData = await sortChapters(book[0].chapters);
            setCurrentChapter(chapterData);

            chapterData =
              prevSelectedProject.chapterName != undefined
                ? chapterData.filter(
                    (item) =>
                      item.chapterName == prevSelectedProject.chapterName
                  )
                : chapterData;
            if (lastSelectedProject.length >= 1) {
              lastSelectedProject[0].bookData = await sortBooks(
                lastSelectedProject[0].bookData
              );
              setCurrentProject(lastSelectedProject[0]);
              prevSelectedProject.chapterName != undefined
                ? setCurrentProjectName(
                    lastSelectedProject[0].projectName +
                      " " +
                      prevSelectedProject.bookName +
                      " " +
                      prevSelectedProject.chapterName.split("chapter")[1]
                  )
                : setCurrentProjectName(
                    lastSelectedProject[0].projectName +
                      " " +
                      prevSelectedProject.bookName
                  );
              setCurrentProject(lastSelectedProject[0]);
              setCurrentChapter(chapterData);

              setSelectedOption("CHAPTER");
              resolve();
            }
          }
        }
      } catch (error) {
        console.log("Fetch Data ", error);
      }
      setisLoading(false);
    });
  };

  const onChange = (e) => {
    setMultiSelect(e.target.checked);
  };

  // changeCurrentBook
  const changeCurrentProject = async (value) => {
    let compareAbleValue = typeof value == "string" ? value : value.projectName;
    let availableProject = projects.filter(
      (project) => project.projectName == compareAbleValue
    );
    if (availableProject.length) {
      availableProject[0].bookData = await sortBooks(
        availableProject[0].bookData
      );
      setCurrentProject(availableProject[0]);
      setCurrentProjectName(availableProject[0].projectName);
      let str = {
        projectName: availableProject[0].projectName,
      };
      setCurrentBook(availableProject[0].bookData);
      localStorage.setItem("selectedProject", JSON.stringify(str));
      setSelectedOption("BOOK");
    }

    setCurrentChapter([]);
  };

 
  const displayModalForBook = (userType, book, toggle, selectedProject) => {
    setSelectedType(userType);
    setCurrentBook([book]);
    setShowModal(toggle);
  };

  const displayModal = async (userType, chapter, toggle) => {
    setSelectedType(userType);
    setSelectedChapter(chapter);
    setShowModal(toggle);
  };

  const BooksDataJSX = () => {
    return (
      <>
        <div className="mx-2 pt-3">
          {projects.length ? (
            <>
              {selectedOption == "PROJECT" && projects.length ? (
                <>
                  <div className="mt-1">
                    {projects.map((project) => {
                      return (
                        <>
                          <Button
                            shape="round"
                            key={project.projectName}
                            className="mx-2 capitalize"
                            onClick={(e) => {
                              changeCurrentProject(project);
                            }}
                          >
                            {project.projectName}
                          </Button>
                        </>
                      );
                    })}
                  </div>
                </>
              ) : (
                <h6 className="flex items-center justify-center py-32"></h6>
              )}
              {selectedOption == "BOOK" && (
                <div className="mx-2">
                  {Object.keys(currentProject).length &&
                  currentBook.length > 0 &&
                  currentProject.projectType.jesusFilm == false ? (
                    <Tabs
                      defaultActiveKey="1"
                      type="card"
                      className="pt-2"
                      moreIcon={<RiArrowRightSFill />}
                      size="middle"
                    >
                      <Tabs.TabPane
                        key="Old Testament"
                        tab={<span>Old Testament</span>}
                      >
                        <div className="mt-1">
                          {oldTestamentBooks.length ? (
                            oldTestamentBooks.map((book) => {
                              return (
                                <>
                                  {book.testamentType == "old" &&
                                    book.projectId == currentProject.id && (
                                      <>
                                        {role == "Coordinator" ? (
                                          <>
                                            {console.log(role == "Coordinator")}
                                            <Dropdown
                                              overlay={
                                                <Menu>
                                                  <Menu.Item
                                                    key="0"
                                                    onClick={() =>
                                                      selectChapter(book)
                                                    }
                                                  >
                                                    View Chapters
                                                  </Menu.Item>
                                                  <Menu.Item
                                                    key="1"
                                                    onClick={() =>
                                                      displayModalForBook(
                                                        "projectProgress",
                                                        book,
                                                        true,
                                                        currentProject
                                                      )
                                                    }
                                                  >
                                                    Project Progress
                                                  </Menu.Item>
                                                </Menu>
                                              }
                                              trigger={["click"]}
                                            >
                                              <Button
                                                shape="round"
                                                key={book.bookName}
                                                className="mx-2 capitalize"
                                              >
                                                {book.bookName}
                                              </Button>
                                            </Dropdown>
                                          </>
                                        ) : (
                                          <Button
                                            shape="round"
                                            key={book.bookName}
                                            className="mx-2 capitalize"
                                            onClick={(e) => {
                                              selectChapter(book);
                                            }}
                                          >
                                            {book.bookName}
                                          </Button>
                                        )}
                                      </>
                                    )}
                                </>
                              );
                            })
                          ) : (
                            <span className="mx-2 px-2">
                              Books not assigned to this Testament{" "}
                            </span>
                          )}
                        </div>
                      </Tabs.TabPane>
                      <Tabs.TabPane
                        key="New Testament"
                        tab={<span>New Testament</span>}
                      >
                        <div className="mt-1">
                          {newTestamentBooks.length ? (
                            newTestamentBooks.map((book) => {
                              return (
                                <>
                                  {book.testamentType == "new" &&
                                    book.projectId == currentProject.id && (
                                        <>
                                        {role == "Coordinator" ? (
                                          <>
                                            {console.log(role == "Coordinator")}
                                            <Dropdown
                                              overlay={
                                                <Menu>
                                                  <Menu.Item
                                                    key="0"
                                                    onClick={() =>
                                                      selectChapter(book)
                                                    }
                                                  >
                                                    View Chapters
                                                  </Menu.Item>
                                                  <Menu.Item
                                                    key="1"
                                                    onClick={() =>
                                                      displayModalForBook(
                                                        "projectProgress",
                                                        book,
                                                        true,
                                                        currentProject
                                                      )
                                                    }
                                                  >
                                                    Project Progress
                                                  </Menu.Item>
                                                </Menu>
                                              }
                                              trigger={["click"]}
                                            >
                                              <Button
                                                shape="round"
                                                key={book.bookName}
                                                className="mx-2 capitalize"
                                              >
                                                {book.bookName}
                                              </Button>
                                            </Dropdown>
                                          </>
                                        ) : (
                                          <Button
                                            shape="round"
                                            key={book.bookName}
                                            className="mx-2 capitalize"
                                            onClick={(e) => {
                                              selectChapter(book);
                                            }}
                                          >
                                            {book.bookName}
                                          </Button>
                                        )}
                                      </>
                                    )}
                                </>
                              );
                            })
                          ) : (
                            <span className="mx-2 px-2">
                              Books not assigned to this Testament
                            </span>
                          )}
                        </div>
                      </Tabs.TabPane>
                    </Tabs>
                  ) : Object.keys(currentProject).length &&
                    currentProject.projectType.jesusFilm == true ? (
                    currentProject.bookData.map((book) => {
                      return (
                        // {book.testamentType == "old" && (
                        <>
                         
                            <Button
                              shape="round"
                              key={book.bookName}
                              className="mx-2 capitalize"
                              onClick={(e) => {
                                selectChapter(book);
                              }}
                            >
                              {book.bookName}
                            </Button>
                          
                        </>

                        // )}
                      );
                    })
                  ) : (
                    <>
                      <h6 className="flex items-center justify-center mt-6">
                        Please select project
                      </h6>
                    </>
                  )}
                </div>
              )}

              {selectedOption == "CHAPTER" && (
                <div className="mx-2 capitalize">
                  {currentChapter.length > 0 ? (
                    currentChapter.map((chapter) => {
                      return (
                        <>
                          <>
                              <Dropdown
                                overlay={
                                  <Menu>
                                    <Menu.Item
                                      key="0"
                                      onClick={() =>
                                        displayModal("view", chapter, true)
                                      }
                                    >
                                      View Project
                                    </Menu.Item>
                                    {/* <Menu.Item
                                        key="0"
                                        onClick={() =>
                                          displayModal("cit", chapter, true)
                                        }
                                      >
                                        CIT Reviewed
                                      </Menu.Item> */}

                                    {role != "Coordinator" ? (
                                      <>
                                        <Menu.Item
                                          key="1"
                                          onClick={() =>
                                            displayModal(
                                              "consultant",
                                              chapter,
                                              true
                                            )
                                          }
                                        >
                                          Consultant Reviewed
                                        </Menu.Item>
                                        <Menu.Item
                                          key="2"
                                          onClick={() =>
                                            displayModal(
                                              "master",
                                              chapter,
                                              true
                                            )
                                          }
                                        >
                                          Master Record
                                        </Menu.Item>
                                      </>
                                    ) : (
                                      <>
                                        <Menu.Item
                                          key="1"
                                          onClick={() =>
                                            displayModal(
                                              "projectProgress",
                                              chapter,
                                              true
                                            )
                                          }
                                        >
                                          Project Progress
                                        </Menu.Item>
                                      </>
                                    )}
                                  </Menu>
                                }
                                trigger={["click"]}
                              >
                                <Button
                                  className="mx-2 my-2 capitalize"
                                  shape="round"
                                  // onClick={(e) => {
                                  //   selectChunk(chapter);
                                  // }}
                                >
                                  {chapter.chapterName}
                                </Button>
                              </Dropdown>
                            </>
                        </>
                      );
                    })
                  ) : (
                    <>
                      <h6 className="flex items-center justify-center mt-6">
                        Please select book
                      </h6>
                    </>
                  )}
                </div>
              )}

              
            </>
          ) : (
            <>
              <h6 className="flex items-center justify-center mt-6">
                Please add Project
              </h6>
            </>
          )}
        </div>
      </>
    );
  };

  let BookSelectJSX = () => {
    return (
      <Select
        style={{
          width: "100%",
        }}
        value={currentProjectName ? currentProjectName : "Please add Project"}
        placeholder={placeHolder}
        onChange={changeCurrentProject}
      >
        {projects.map((project) => (
          <Select.Option value={project.projectName} className="capitalize">
            {project.projectName}
          </Select.Option>
        ))}
      </Select>
    );
  };

  let SelectBookTableJSX = () => {
    return (
      <>
        {Object.keys(currentProject).length !== 0 ? (
          <Table
            columns={[
              {
                title: "S.No. ",
                dataIndex: "sno",
              },
              {
                title: "Chapter Name",
                dataIndex: "name",
              },
            ]}
            dataSource={[
              { value: "chapter1" },
              { value: "chapter2" },
              { value: "chapter3" },
              { value: "chapter4" },
              { value: "chapter5" },
              { value: "chapter6" },
            ]}
            bordered
            pagination={false}
            title={() => currentProject?.projectName}
          />
        ) : null}
      </>
    );
  };

  let changeSelectedOption = (value) => {
    setSelectedOption(value);
    setMultiSelect(false);
  };

  let selectChapter = async (data) => {
    let projectName = JSON.parse(localStorage.getItem("selectedProject"));
    setCurrentProjectName(projectName.projectName + " " + data.bookName);
    let str = JSON.stringify({
      projectName: projectName.projectName,
      bookName: data.bookName,
    });

    localStorage.setItem("selectedProject", str);
    let chapterData = await sortChapters(data.chapters);
    setCurrentChapterName(data.chapterName);
    setCurrentBook(data);
    setSelectedBook(data);
    setCurrentChapter(chapterData);
    setSelectedOption("CHAPTER");
    // };
  };

  const BookChapterChunkTabsPane = () => {
    return (
      <>
        <div className="flex items-center justify-evenly ">
          <span
            className={`cursor-pointer option  ${
              selectedOption == "PROJECT" ? "border-b-2" : ""
            }`}
            onClick={(e) => {
              changeSelectedOption("PROJECT");
            }}
          >
            Project
          </span>
          <span
            className={`cursor-pointer option  ${
              selectedOption == "BOOK" ? "border-b-2" : ""
            }`}
            onClick={(e) => {
              changeSelectedOption("BOOK");
            }}
          >
            Book
          </span>
          <p className="flex items-center">
            <span
              className={`cursor-pointer option flex mx-2 ${
                selectedOption == "CHAPTER" ? "border-b-2" : ""
              }`}
              onClick={(e) => {
                changeSelectedOption("CHAPTER");
              }}
            >
              Chapters
            </span>          
          </p>

        </div>
        {showNotification && (
          <Alert
            message={
              selectedOption == "PROJECT"
                ? "Please Select Project"
                : selectedOption == "BOOK"
                ? "Please Select Book"
                : "Please Select Chapter"
            }
            type="error"
            closable={true}
            className="my-2"
          />
        )}
      </>
    );
  };

  const changeActiveKey = (e) => {
    setActiveKey(e);
  };

  return (
    <MainLayout>
      {isLoading ? (
        <SpinnerComponent />
      ) : (
        <div className="draft-details">
          <div className="draft-details-wrapper min-height h-85">
            <TitleBar title={"Project Files"} page={"myproject"} />

            <div className="pt-1">
              <BookSelectJSX />
              <div className="tabpanel">
                <Tabs
                  type="card"
                  className="pt-2"
                  moreIcon={<RiArrowRightSFill />}
                  size="middle"
                  activeKey={activeKey}
                  onChange={changeActiveKey}
                >
                  {/* MTT */}
                  <>
                    <>
                      <Tabs.TabPane
                        disabled={role == "MTT" ? false : true}
                        key="1"
                        tab={
                          <span>
                            <RiDraftLine
                              style={{ margin: "0px 5px", fontSize: "large" }}
                            />
                            <span style={{ fontSize: "12px" }}>Draft</span>
                          </span>
                        }
                      >
                        <BookChapterChunkTabsPane />
                        <BooksDataJSX />
                      </Tabs.TabPane>
                    </>
                    <Tabs.TabPane
                      tab={
                        <span>
                          <GoChecklist
                            style={{ margin: "0px 5px", fontSize: "large" }}
                          />
                          <span style={{ fontSize: "12px" }}>Cross Check</span>
                        </span>
                      }
                      key="2"
                      disabled={role == "MTT" ? false : true}
                    >
                      <div className="seacrh w-100 mt-2 mb-1 p-2">
                        <BookSelectJSX />
                      </div>
                      <div className="bookSelection w-100 mt-2 mb-2 p-2">
                        <SelectBookTableJSX />
                      </div>
                    </Tabs.TabPane>
                  </>
                  {/* Facilitator */}
                  <>
                    <Tabs.TabPane
                      tab={
                        <span>
                          <HiOutlineUserGroup
                            style={{ margin: "0px 5px", fontSize: "large" }}
                          />
                          <span style={{ fontSize: "12px" }}>
                            Community Check
                          </span>
                        </span>
                      }
                      key="4"
                      disabled={role == "Facilitator" ? false : true}
                    >
                      <div className="seacrh w-100 mt-2 mb-1 p-2">
                        <BookSelectJSX />
                      </div>
                      <div className="bookSelection w-100 mt-2 mb-2 p-2">
                        <SelectBookTableJSX />
                      </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={
                        <span>
                          <BsTranslate
                            style={{ margin: "0px 5px", fontSize: "large" }}
                          />
                          <span style={{ fontSize: "12px" }}>
                            Back Translation
                          </span>
                        </span>
                      }
                      key="3"
                      disabled={role == "Facilitator" ? false : true}
                    >
                      <div className="seacrh w-100 mt-2 mb-1 p-2">
                        <BookChapterChunkTabsPane />
                      </div>
                      <BooksDataJSX />
                    </Tabs.TabPane>
                  </>
                  {/* CIT */}
                  <>
                    <Tabs.TabPane
                      tab={
                        <span>
                          <MdOutlineFactCheck
                            style={{ margin: "0px 5px", fontSize: "large" }}
                          />
                          <span style={{ fontSize: "12px" }}>CIT Check</span>
                        </span>
                      }
                      key="5"
                      disabled={role == "CIT" ? false : true}
                    >
                      <BookChapterChunkTabsPane />
                      <BooksDataJSX />
                    </Tabs.TabPane>
                  </>

                  {/* Consultant Check */}
                  <>
                    <Tabs.TabPane
                      tab={
                        <span>
                          <BsPersonCheck
                            style={{ margin: "0px 5px", fontSize: "large" }}
                          />
                          <span style={{ fontSize: "12px" }}>
                            Consultant Check
                          </span>
                        </span>
                      }
                      key="6"
                      disabled={role == "CONSULATANT" ? false : true}
                    >
                      <BookChapterChunkTabsPane />
                      <BooksDataJSX />
                    </Tabs.TabPane>
                  </>
                  {/* TECHNICIAN */}
                  <>
                    <Tabs.TabPane
                      tab={
                        <span>
                          <IoRecordingOutline
                            style={{ margin: "0px 5px", fontSize: "large" }}
                          />
                          <span style={{ fontSize: "12px" }}>
                            Master Recording
                          </span>
                        </span>
                      }
                      key="7"
                      disabled={role == "TECHNICIAN" ? false : true}
                    >
                      <div className="seacrh w-100 mt-2 mb-1 p-2">
                        <BookSelectJSX />
                      </div>
                      <div className="bookSelection w-100 mt-2 mb-2 p-2">
                        <SelectBookTableJSX />
                      </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={
                        <span>
                          <MdOutlinePublish
                            style={{ margin: "0px 5px", fontSize: "large" }}
                          />
                          <span style={{ fontSize: "12px" }}>Publish</span>
                        </span>
                      }
                      key="8"
                      disabled={
                        role == "Technician" || role == "Translation Manager"
                          ? false
                          : true
                      }
                    >
                      <BookChapterChunkTabsPane />
                      <BooksDataJSX />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      tab={
                        <span>
                          <GiProgression
                            style={{ margin: "0px 5px", fontSize: "large" }}
                          />
                          <span style={{ fontSize: "12px" }}>
                            Project Progress
                          </span>
                        </span>
                      }
                      key="9"
                      disabled={role == "Coordinator" ? false : true}
                    >
                      <BookChapterChunkTabsPane />
                      <BooksDataJSX />
                    </Tabs.TabPane>
                  </>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      )}
    

      {showModal ? (
        <TechnicianModal
          book={currentBook}
          chapter={selectedChapter}
          displayModal={displayModal}
          displayModalForBook={displayModalForBook}
          userType={selectedType}
          selectedProject={currentProject}
        />
      ) : null}
    </MainLayout>
  );
};

export default TechCord;
