import { Button, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { FiExternalLink } from "react-icons/fi";
import { waitForAWhile } from "../../services/config";

const SelectChunkJSX = ({
  chunks,
  selectChunk,
  elementId,
  splitScreen,
  isMaximized,
  botTyping,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const chunk = useRef(chunks);

  useEffect(() => {
    setIsLoaded(true);
    chunk.current = chunks;
  }, [isMaximized]);
  return (
    <>
      {isLoaded == true ? (
        <div className="text-black message-content clearfix">
          <div className="message bot-message float-left">
            <div className="d-block w-full" id={`${elementId}fgh`}>
              <div>
                {chunk.current.length > 0 ? (
                  <>
                    <p> Please select resource</p>
                    {chunk.current.map((item) => (
                      <div
                        className="mt-2 mb-4 flex items-center"
                        key={item.verse}
                      >
                        <div
                          className="border px-2 border-dark rounded   cursor-pointer flex items-center"
                          onClick={(e) => selectChunk(item, true)}
                        >
                          <span>{item.verse}</span>
                        </div>
                        {isMaximized == true ? (
                          <Button
                            className="mx-2 bg-chatbot px-1 py-1 "
                            disabled={botTyping}
                          >
                            <Tooltip title="New tab">
                              <FiExternalLink
                                size={15}
                                onClick={(e) => {
                                  splitScreen(item);
                                }}
                              />
                            </Tooltip>
                          </Button>
                        ) : null}
                      </div>
                    ))}
                  </>
                ) : (
                  <p>Resource not availabe</p>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SelectChunkJSX;
