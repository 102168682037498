import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { IoMdArrowDropleftCircle } from "react-icons/io";
import { UserOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { auth_route } from "../../../services/apis";
import NotificationComponent from "../../../components/notification/notification.component";

const ForgotPasswordPage = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [form] = Form.useForm();
  const [isNotification, setIsNotification] = useState(false);
  const [notification, setNotification] = useState({
    type: "",
    content: "",
  });

  const onFinish = async (values) => {
    setIsLoader(true);
    try {
      let res = await axios.post(`${auth_route.reset_password}`, values);
      displayNotification("success", "Admin will contact you soon");
      form.resetFields();
    } catch (error) {
      console.error(error);
      if (error.code == "ERR_NETWORK") {
        displayNotification("error", "Please check internet connection");
      } else {
        const { response } = error;
        displayNotification("error", response.data.message);
        const { request, ...errorObject } = response;
      }
    }
    setIsLoader(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  const displayNotification = async (type, content) => {
    setNotification({
      type,
      content,
    });
    setIsNotification(true);
    setTimeout(() => {
      setIsNotification(false);
      setNotification({
        type: "",
        content: "",
      });
    }, 1500);
  };

  return (
    <div className="signin container">
      <div className="forPasswordModal">
        <div className="forgotPassword-container">
          <span className="reset-password-text">Reset Password</span>

          <div className="px-4">
            <Form
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout={"vertical"}
              form={form}
            >
              {isNotification && (
                <NotificationComponent
                  type={notification.type}
                  content={notification.content}
                />
              )}
              <Form.Item
                label="Email"
                extra=" Request Admin to reset the password"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                  {
                    type: "email",
                    warningOnly: true,
                    message: "Please enter valid email",
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-btn"
                  style={{ background: "#031832", color: "white" }}
                  loading={isLoader}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
            <div
              className="backBtn mt-5"
              style={{ display: "flex", flexDirection: "row" }}
            >
              {isLoader ? (
                <span></span>
              ) : (
                <NavLink
                  exact
                  to="/signin"
                  className="text-black flex items-center "
                >
                  <IoMdArrowDropleftCircle
                    style={{
                      marginRight: "5px",
                      fontSize: "20px",
                    }}
                  />
                  <span style={{ fontSize: "14px" }}>Back To Login</span>
                </NavLink>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
