let initial_state = [];

const projectsReducer = (state = initial_state, action) => {
  switch (action.type) {
    case "GET_PROJECTS":
      return state;

    case "STORE_PROJECTS":
      return [...state, ...action.payload];
    case "CLEAR_PROJECTS":
      return [...initial_state];

    default:
      return state;
  }
};

export default projectsReducer;