const countryCode =
    [
        {
            "country": "Afghanistan",
            "language": "Pashto",
            "two_letter": "ps-AF",
            "three_lettter": "ps-AFG",
            "number": "1123"
        },
        {
            "country": "Afghanistan",
            "language": "Persian",
            "two_letter": "fa-AF",
            "three_lettter": "fa-AFG",
            "number": "4096"
        },
        {
            "country": "Afghanistan",
            "language": "Uzbek",
            "two_letter": "uz-AF",
            "three_lettter": "uz-AFG",
            "number": "4096"
        },
        {
            "country": "Åland Islands",
            "language": "Swedish",
            "two_letter": "sv-AX",
            "three_lettter": "sv-ALA",
            "number": "4096"
        },
        {
            "country": "Albania",
            "language": "Albanian",
            "two_letter": "sq-AL",
            "three_lettter": "sq-ALB",
            "number": "1052"
        },
        {
            "country": "Albania",
            "language": "English",
            "two_letter": "en-AL",
            "three_lettter": "en-ALB",
            "number": "4096"
        },
        {
            "country": "Algeria",
            "language": "Arabic",
            "two_letter": "ar-DZ",
            "three_lettter": "ar-DZA",
            "number": "5121"
        },
        {
            "country": "Algeria",
            "language": "French",
            "two_letter": "fr-DZ",
            "three_lettter": "fr-DZA",
            "number": "4096"
        },
        {
            "country": "Algeria",
            "language": "Kabyle",
            "two_letter": "kab-DZ",
            "three_lettter": "kab-DZA",
            "number": "4096"
        },
        {
            "country": "American Samoa",
            "language": "English",
            "two_letter": "en-AS",
            "three_lettter": "en-ASM",
            "number": "4096"
        },
        {
            "country": "Andorra",
            "language": "Catalan",
            "two_letter": "ca-AD",
            "three_lettter": "ca-AND",
            "number": "4096"
        },
        {
            "country": "Andorra",
            "language": "English",
            "two_letter": "en-AD",
            "three_lettter": "en-AND",
            "number": "4096"
        },
        {
            "country": "Angola",
            "language": "Lingala",
            "two_letter": "ln-AO",
            "three_lettter": "ln-AGO",
            "number": "4096"
        },
        {
            "country": "Angola",
            "language": "Portuguese",
            "two_letter": "pt-AO",
            "three_lettter": "pt-AGO",
            "number": "4096"
        },
        {
            "country": "Anguilla",
            "language": "English",
            "two_letter": "en-AI",
            "three_lettter": "en-AIA",
            "number": "4096"
        },
        {
            "country": "Anguilla",
            "language": "Spanish",
            "two_letter": "es-AI",
            "three_lettter": "es-AIA",
            "number": "4096"
        },
        {
            "country": "Antigua & Barbuda",
            "language": "English",
            "two_letter": "en-AG",
            "three_lettter": "en-ATG",
            "number": "4096"
        },
        {
            "country": "Antigua & Barbuda",
            "language": "Spanish",
            "two_letter": "es-AG",
            "three_lettter": "es-ATG",
            "number": "4096"
        },
        {
            "country": "Argentina",
            "language": "English",
            "two_letter": "en-AR",
            "three_lettter": "en-ARG",
            "number": "4096"
        },
        {
            "country": "Argentina",
            "language": "Spanish",
            "two_letter": "es-AR",
            "three_lettter": "es-ARG",
            "number": "11274"
        },
        {
            "country": "Armenia",
            "language": "Armenian",
            "two_letter": "hy-AM",
            "three_lettter": "hy-ARM",
            "number": "1067"
        },
        {
            "country": "Aruba",
            "language": "Dutch",
            "two_letter": "nl-AW",
            "three_lettter": "nl-ABW",
            "number": "4096"
        },
        {
            "country": "Aruba",
            "language": "Spanish",
            "two_letter": "es-AW",
            "three_lettter": "es-ABW",
            "number": "4096"
        },
        {
            "country": "Australia",
            "language": "English",
            "two_letter": "en-AU",
            "three_lettter": "en-AUS",
            "number": "3081"
        },
        {
            "country": "Austria",
            "language": "English",
            "two_letter": "en-AT",
            "three_lettter": "en-AUT",
            "number": "4096"
        },
        {
            "country": "Austria",
            "language": "German",
            "two_letter": "de-AT",
            "three_lettter": "de-AUT",
            "number": "3079"
        },
        {
            "country": "Azerbaijan",
            "language": "Azerbaijani",
            "two_letter": "az-AZ",
            "three_lettter": "az-AZE",
            "number": "1068"
        },
        {
            "country": "Azerbaijan",
            "language": "Azerbaijani",
            "two_letter": "az-AZ",
            "three_lettter": "az-AZE",
            "number": "2092"
        },
        {
            "country": "Bahamas",
            "language": "English",
            "two_letter": "en-BS",
            "three_lettter": "en-BHS",
            "number": "4096"
        },
        {
            "country": "Bahamas",
            "language": "Spanish",
            "two_letter": "es-BS",
            "three_lettter": "es-BHS",
            "number": "4096"
        },
        {
            "country": "Bahrain",
            "language": "Arabic",
            "two_letter": "ar-BH",
            "three_lettter": "ar-BHR",
            "number": "15361"
        },
        {
            "country": "Bangladesh",
            "language": "Bangla",
            "two_letter": "bn-BD",
            "three_lettter": "bn-BGD",
            "number": "2117"
        },
        {
            "country": "Bangladesh",
            "language": "Chakma",
            "two_letter": "ccp-BD",
            "three_lettter": "ccp-BGD",
            "number": "4096"
        },
        {
            "country": "Bangladesh",
            "language": "English",
            "two_letter": "en-BD",
            "three_lettter": "en-BGD",
            "number": "4096"
        },
        {
            "country": "Barbados",
            "language": "English",
            "two_letter": "en-BB",
            "three_lettter": "en-BRB",
            "number": "4096"
        },
        {
            "country": "Barbados",
            "language": "Spanish",
            "two_letter": "es-BB",
            "three_lettter": "es-BRB",
            "number": "4096"
        },
        {
            "country": "Belarus",
            "language": "Belarusian",
            "two_letter": "be-BY",
            "three_lettter": "be-BLR",
            "number": "1059"
        },
        {
            "country": "Belarus",
            "language": "Russian",
            "two_letter": "ru-BY",
            "three_lettter": "ru-BLR",
            "number": "4096"
        },
        {
            "country": "Belgium",
            "language": "Dutch",
            "two_letter": "nl-BE",
            "three_lettter": "nl-BEL",
            "number": "2067"
        },
        {
            "country": "Belgium",
            "language": "English",
            "two_letter": "en-BE",
            "three_lettter": "en-BEL",
            "number": "4096"
        },
        {
            "country": "Belgium",
            "language": "French",
            "two_letter": "fr-BE",
            "three_lettter": "fr-BEL",
            "number": "2060"
        },
        {
            "country": "Belgium",
            "language": "German",
            "two_letter": "de-BE",
            "three_lettter": "de-BEL",
            "number": "4096"
        },
        {
            "country": "Belgium",
            "language": "Walloon",
            "two_letter": "wa-BE",
            "three_lettter": "wa-BEL",
            "number": "4096"
        },
        {
            "country": "Belize",
            "language": "English",
            "two_letter": "en-BZ",
            "three_lettter": "en-BLZ",
            "number": "10249"
        },
        {
            "country": "Belize",
            "language": "Spanish",
            "two_letter": "es-BZ",
            "three_lettter": "es-BLZ",
            "number": "4096"
        },
        {
            "country": "Benin",
            "language": "French",
            "two_letter": "fr-BJ",
            "three_lettter": "fr-BEN",
            "number": "4096"
        },
        {
            "country": "Benin",
            "language": "Yoruba",
            "two_letter": "yo-BJ",
            "three_lettter": "yo-BEN",
            "number": "4096"
        },
        {
            "country": "Bermuda",
            "language": "English",
            "two_letter": "en-BM",
            "three_lettter": "en-BMU",
            "number": "4096"
        },
        {
            "country": "Bermuda",
            "language": "Spanish",
            "two_letter": "es-BM",
            "three_lettter": "es-BMU",
            "number": "4096"
        },
        {
            "country": "Bhutan",
            "language": "Dzongkha",
            "two_letter": "dz-BT",
            "three_lettter": "dz-BTN",
            "number": "3153"
        },
        {
            "country": "Bolivia",
            "language": "Quechua",
            "two_letter": "qu-BO",
            "three_lettter": "qu-BOL",
            "number": "4096"
        },
        {
            "country": "Bolivia",
            "language": "Spanish",
            "two_letter": "es-BO",
            "three_lettter": "es-BOL",
            "number": "16394"
        },
        {
            "country": "Bosnia & Herzegovina",
            "language": "Bosnian",
            "two_letter": "bs-BA",
            "three_lettter": "bs-BIH",
            "number": "5146"
        },
        {
            "country": "Bosnia & Herzegovina",
            "language": "Bosnian",
            "two_letter": "bs-BA",
            "three_lettter": "bs-BIH",
            "number": "8218"
        },
        {
            "country": "Bosnia & Herzegovina",
            "language": "Croatian",
            "two_letter": "hr-BA",
            "three_lettter": "hr-BIH",
            "number": "4122"
        },
        {
            "country": "Bosnia & Herzegovina",
            "language": "English",
            "two_letter": "en-BA",
            "three_lettter": "en-BIH",
            "number": "4096"
        },
        {
            "country": "Bosnia & Herzegovina",
            "language": "Serbian",
            "two_letter": "sr-BA",
            "three_lettter": "sr-BIH",
            "number": "6170"
        },
        {
            "country": "Bosnia & Herzegovina",
            "language": "Serbian",
            "two_letter": "sr-BA",
            "three_lettter": "sr-BIH",
            "number": "7194"
        },
        {
            "country": "Botswana",
            "language": "English",
            "two_letter": "en-BW",
            "three_lettter": "en-BWA",
            "number": "4096"
        },
        {
            "country": "Botswana",
            "language": "Tswana",
            "two_letter": "tn-BW",
            "three_lettter": "tn-BWA",
            "number": "2098"
        },
        {
            "country": "Brazil",
            "language": "English",
            "two_letter": "en-BR",
            "three_lettter": "en-BRA",
            "number": "4096"
        },
        {
            "country": "Brazil",
            "language": "Portuguese",
            "two_letter": "pt-BR",
            "three_lettter": "pt-BRA",
            "number": "1046"
        },
        {
            "country": "Brazil",
            "language": "Spanish",
            "two_letter": "es-BR",
            "three_lettter": "es-BRA",
            "number": "4096"
        },
        {
            "country": "British Indian Ocean Territory",
            "language": "English",
            "two_letter": "en-IO",
            "three_lettter": "en-IOT",
            "number": "4096"
        },
        {
            "country": "British Virgin Islands",
            "language": "English",
            "two_letter": "en-VG",
            "three_lettter": "en-VGB",
            "number": "4096"
        },
        {
            "country": "British Virgin Islands",
            "language": "Spanish",
            "two_letter": "es-VG",
            "three_lettter": "es-VGB",
            "number": "4096"
        },
        {
            "country": "Brunei",
            "language": "Malay",
            "two_letter": "ms-BN",
            "three_lettter": "ms-BRN",
            "number": "2110"
        },
        {
            "country": "Brunei",
            "language": "Malay",
            "two_letter": "ms-BN",
            "three_lettter": "ms-BRN",
            "number": "4096"
        },
        {
            "country": "Bulgaria",
            "language": "Bulgarian",
            "two_letter": "bg-BG",
            "three_lettter": "bg-BGR",
            "number": "1026"
        },
        {
            "country": "Bulgaria",
            "language": "English",
            "two_letter": "en-BG",
            "three_lettter": "en-BGR",
            "number": "4096"
        },
        {
            "country": "Burkina Faso",
            "language": "French",
            "two_letter": "fr-BF",
            "three_lettter": "fr-BFA",
            "number": "4096"
        },
        {
            "country": "Burkina Faso",
            "language": "Fulah",
            "two_letter": "ff-BF",
            "three_lettter": "ff-BFA",
            "number": "4096"
        },
        {
            "country": "Burundi",
            "language": "English",
            "two_letter": "en-BI",
            "three_lettter": "en-BDI",
            "number": "4096"
        },
        {
            "country": "Burundi",
            "language": "French",
            "two_letter": "fr-BI",
            "three_lettter": "fr-BDI",
            "number": "4096"
        },
        {
            "country": "Burundi",
            "language": "Rundi",
            "two_letter": "rn-BI",
            "three_lettter": "rn-BDI",
            "number": "4096"
        },
        {
            "country": "Cambodia",
            "language": "Khmer",
            "two_letter": "km-KH",
            "three_lettter": "km-KHM",
            "number": "1107"
        },
        {
            "country": "Cameroon",
            "language": "Aghem",
            "two_letter": "agq-CM",
            "three_lettter": "agq-CMR",
            "number": "4096"
        },
        {
            "country": "Cameroon",
            "language": "Bafia",
            "two_letter": "ksf-CM",
            "three_lettter": "ksf-CMR",
            "number": "4096"
        },
        {
            "country": "Cameroon",
            "language": "Basaa",
            "two_letter": "bas-CM",
            "three_lettter": "bas-CMR",
            "number": "4096"
        },
        {
            "country": "Cameroon",
            "language": "Duala",
            "two_letter": "dua-CM",
            "three_lettter": "dua-CMR",
            "number": "4096"
        },
        {
            "country": "Cameroon",
            "language": "English",
            "two_letter": "en-CM",
            "three_lettter": "en-CMR",
            "number": "4096"
        },
        {
            "country": "Cameroon",
            "language": "Ewondo",
            "two_letter": "ewo-CM",
            "three_lettter": "ewo-CMR",
            "number": "4096"
        },
        {
            "country": "Cameroon",
            "language": "French",
            "two_letter": "fr-CM",
            "three_lettter": "fr-CMR",
            "number": "11276"
        },
        {
            "country": "Cameroon",
            "language": "Fulah",
            "two_letter": "ff-CM",
            "three_lettter": "ff-CMR",
            "number": "4096"
        },
        {
            "country": "Cameroon",
            "language": "Kako",
            "two_letter": "kkj-CM",
            "three_lettter": "kkj-CMR",
            "number": "4096"
        },
        {
            "country": "Cameroon",
            "language": "Kwasio",
            "two_letter": "nmg-CM",
            "three_lettter": "nmg-CMR",
            "number": "4096"
        },
        {
            "country": "Cameroon",
            "language": "Metaʼ",
            "two_letter": "mgo-CM",
            "three_lettter": "mgo-CMR",
            "number": "4096"
        },
        {
            "country": "Cameroon",
            "language": "Mundang",
            "two_letter": "mua-CM",
            "three_lettter": "mua-CMR",
            "number": "4096"
        },
        {
            "country": "Cameroon",
            "language": "Ngiemboon",
            "two_letter": "nnh-CM",
            "three_lettter": "nnh-CMR",
            "number": "4096"
        },
        {
            "country": "Cameroon",
            "language": "Ngomba",
            "two_letter": "jgo-CM",
            "three_lettter": "jgo-CMR",
            "number": "4096"
        },
        {
            "country": "Cameroon",
            "language": "Yangben",
            "two_letter": "yav-CM",
            "three_lettter": "yav-CMR",
            "number": "4096"
        },
        {
            "country": "Canada",
            "language": "English",
            "two_letter": "en-CA",
            "three_lettter": "en-CAN",
            "number": "4105"
        },
        {
            "country": "Canada",
            "language": "French",
            "two_letter": "fr-CA",
            "three_lettter": "fr-CAN",
            "number": "3084"
        },
        {
            "country": "Canada",
            "language": "Inuktitut",
            "two_letter": "iu-CA",
            "three_lettter": "iu-CAN",
            "number": "4096"
        },
        {
            "country": "Canada",
            "language": "Mohawk",
            "two_letter": "moh-CA",
            "three_lettter": "moh-CAN",
            "number": "1148"
        },
        {
            "country": "Canada",
            "language": "Spanish",
            "two_letter": "es-CA",
            "three_lettter": "es-CAN",
            "number": "4096"
        },
        {
            "country": "Canary Islands",
            "language": "Spanish",
            "two_letter": "es-IC",
            "three_lettter": "es-IC",
            "number": "4096"
        },
        {
            "country": "Cape Verde",
            "language": "Kabuverdianu",
            "two_letter": "kea-CV",
            "three_lettter": "kea-CPV",
            "number": "4096"
        },
        {
            "country": "Cape Verde",
            "language": "Portuguese",
            "two_letter": "pt-CV",
            "three_lettter": "pt-CPV",
            "number": "4096"
        },
        {
            "country": "Caribbean Netherlands",
            "language": "Dutch",
            "two_letter": "nl-BQ",
            "three_lettter": "nl-BES",
            "number": "4096"
        },
        {
            "country": "Caribbean Netherlands",
            "language": "Spanish",
            "two_letter": "es-BQ",
            "three_lettter": "es-BES",
            "number": "4096"
        },
        {
            "country": "Cayman Islands",
            "language": "English",
            "two_letter": "en-KY",
            "three_lettter": "en-CYM",
            "number": "4096"
        },
        {
            "country": "Cayman Islands",
            "language": "Spanish",
            "two_letter": "es-KY",
            "three_lettter": "es-CYM",
            "number": "4096"
        },
        {
            "country": "Central African Republic",
            "language": "French",
            "two_letter": "fr-CF",
            "three_lettter": "fr-CAF",
            "number": "4096"
        },
        {
            "country": "Central African Republic",
            "language": "Lingala",
            "two_letter": "ln-CF",
            "three_lettter": "ln-CAF",
            "number": "4096"
        },
        {
            "country": "Central African Republic",
            "language": "Sango",
            "two_letter": "sg-CF",
            "three_lettter": "sg-CAF",
            "number": "4096"
        },
        {
            "country": "Ceuta & Melilla",
            "language": "Spanish",
            "two_letter": "es-EA",
            "three_lettter": "es-EA",
            "number": "4096"
        },
        {
            "country": "Chad",
            "language": "Arabic",
            "two_letter": "ar-TD",
            "three_lettter": "ar-TCD",
            "number": "4096"
        },
        {
            "country": "Chad",
            "language": "French",
            "two_letter": "fr-TD",
            "three_lettter": "fr-TCD",
            "number": "4096"
        },
        {
            "country": "Chile",
            "language": "English",
            "two_letter": "en-CL",
            "three_lettter": "en-CHL",
            "number": "4096"
        },
        {
            "country": "Chile",
            "language": "Mapuche",
            "two_letter": "arn-CL",
            "three_lettter": "arn-CHL",
            "number": "1146"
        },
        {
            "country": "Chile",
            "language": "Spanish",
            "two_letter": "es-CL",
            "three_lettter": "es-CHL",
            "number": "13322"
        },
        {
            "country": "China mainland",
            "language": "Cantonese",
            "two_letter": "yue-CN",
            "three_lettter": "yue-CHN",
            "number": "4096"
        },
        {
            "country": "China mainland",
            "language": "Chinese",
            "two_letter": "zh-CN",
            "three_lettter": "zh-CHN",
            "number": "4096"
        },
        {
            "country": "China mainland",
            "language": "Chinese",
            "two_letter": "zh-CN",
            "three_lettter": "zh-CHN",
            "number": "4096"
        },
        {
            "country": "China mainland",
            "language": "English",
            "two_letter": "en-CN",
            "three_lettter": "en-CHN",
            "number": "4096"
        },
        {
            "country": "China mainland",
            "language": "Sichuan Yi",
            "two_letter": "ii-CN",
            "three_lettter": "ii-CHN",
            "number": "1144"
        },
        {
            "country": "China mainland",
            "language": "Tibetan",
            "two_letter": "bo-CN",
            "three_lettter": "bo-CHN",
            "number": "1105"
        },
        {
            "country": "China mainland",
            "language": "Uyghur",
            "two_letter": "ug-CN",
            "three_lettter": "ug-CHN",
            "number": "1152"
        },
        {
            "country": "Christmas Island",
            "language": "English",
            "two_letter": "en-CX",
            "three_lettter": "en-CXR",
            "number": "4096"
        },
        {
            "country": "Cocos (Keeling) Islands",
            "language": "English",
            "two_letter": "en-CC",
            "three_lettter": "en-CCK",
            "number": "4096"
        },
        {
            "country": "Colombia",
            "language": "English",
            "two_letter": "en-CO",
            "three_lettter": "en-COL",
            "number": "4096"
        },
        {
            "country": "Colombia",
            "language": "Spanish",
            "two_letter": "es-CO",
            "three_lettter": "es-COL",
            "number": "9226"
        },
        {
            "country": "Comoros",
            "language": "Arabic",
            "two_letter": "ar-KM",
            "three_lettter": "ar-COM",
            "number": "4096"
        },
        {
            "country": "Comoros",
            "language": "French",
            "two_letter": "fr-KM",
            "three_lettter": "fr-COM",
            "number": "4096"
        },
        {
            "country": "Congo - Brazzaville",
            "language": "French",
            "two_letter": "fr-CG",
            "three_lettter": "fr-COG",
            "number": "4096"
        },
        {
            "country": "Congo - Brazzaville",
            "language": "Lingala",
            "two_letter": "ln-CG",
            "three_lettter": "ln-COG",
            "number": "4096"
        },
        {
            "country": "Congo - Kinshasa",
            "language": "French",
            "two_letter": "fr-CD",
            "three_lettter": "fr-COD",
            "number": "9228"
        },
        {
            "country": "Congo - Kinshasa",
            "language": "Lingala",
            "two_letter": "ln-CD",
            "three_lettter": "ln-COD",
            "number": "4096"
        },
        {
            "country": "Congo - Kinshasa",
            "language": "Luba-Katanga",
            "two_letter": "lu-CD",
            "three_lettter": "lu-COD",
            "number": "4096"
        },
        {
            "country": "Congo - Kinshasa",
            "language": "Swahili",
            "two_letter": "sw-CD",
            "three_lettter": "sw-COD",
            "number": "4096"
        },
        {
            "country": "Cook Islands",
            "language": "English",
            "two_letter": "en-CK",
            "three_lettter": "en-COK",
            "number": "4096"
        },
        {
            "country": "Costa Rica",
            "language": "Spanish",
            "two_letter": "es-CR",
            "three_lettter": "es-CRI",
            "number": "5130"
        },
        {
            "country": "Côte d’Ivoire",
            "language": "French",
            "two_letter": "fr-CI",
            "three_lettter": "fr-CIV",
            "number": "12300"
        },
        {
            "country": "Croatia",
            "language": "Croatian",
            "two_letter": "hr-HR",
            "three_lettter": "hr-HRV",
            "number": "1050"
        },
        {
            "country": "Croatia",
            "language": "English",
            "two_letter": "en-HR",
            "three_lettter": "en-HRV",
            "number": "4096"
        },
        {
            "country": "Cuba",
            "language": "Spanish",
            "two_letter": "es-CU",
            "three_lettter": "es-CUB",
            "number": "23562"
        },
        {
            "country": "Curaçao",
            "language": "Dutch",
            "two_letter": "nl-CW",
            "three_lettter": "nl-CUW",
            "number": "4096"
        },
        {
            "country": "Curaçao",
            "language": "Spanish",
            "two_letter": "es-CW",
            "three_lettter": "es-CUW",
            "number": "4096"
        },
        {
            "country": "Cyprus",
            "language": "English",
            "two_letter": "en-CY",
            "three_lettter": "en-CYP",
            "number": "4096"
        },
        {
            "country": "Cyprus",
            "language": "Greek",
            "two_letter": "el-CY",
            "three_lettter": "el-CYP",
            "number": "4096"
        },
        {
            "country": "Cyprus",
            "language": "Turkish",
            "two_letter": "tr-CY",
            "three_lettter": "tr-CYP",
            "number": "4096"
        },
        {
            "country": "Czechia",
            "language": "Czech",
            "two_letter": "cs-CZ",
            "three_lettter": "cs-CZE",
            "number": "1029"
        },
        {
            "country": "Czechia",
            "language": "English",
            "two_letter": "en-CZ",
            "three_lettter": "en-CZE",
            "number": "4096"
        },
        {
            "country": "Denmark",
            "language": "Danish",
            "two_letter": "da-DK",
            "three_lettter": "da-DNK",
            "number": "1030"
        },
        {
            "country": "Denmark",
            "language": "English",
            "two_letter": "en-DK",
            "three_lettter": "en-DNK",
            "number": "4096"
        },
        {
            "country": "Denmark",
            "language": "Faroese",
            "two_letter": "fo-DK",
            "three_lettter": "fo-DNK",
            "number": "4096"
        },
        {
            "country": "Diego Garcia",
            "language": "English",
            "two_letter": "en-DG",
            "three_lettter": "en-DGA",
            "number": "4096"
        },
        {
            "country": "Djibouti",
            "language": "Arabic",
            "two_letter": "ar-DJ",
            "three_lettter": "ar-DJI",
            "number": "4096"
        },
        {
            "country": "Djibouti",
            "language": "French",
            "two_letter": "fr-DJ",
            "three_lettter": "fr-DJI",
            "number": "4096"
        },
        {
            "country": "Djibouti",
            "language": "Somali",
            "two_letter": "so-DJ",
            "three_lettter": "so-DJI",
            "number": "4096"
        },
        {
            "country": "Dominica",
            "language": "English",
            "two_letter": "en-DM",
            "three_lettter": "en-DMA",
            "number": "4096"
        },
        {
            "country": "Dominica",
            "language": "Spanish",
            "two_letter": "es-DM",
            "three_lettter": "es-DMA",
            "number": "4096"
        },
        {
            "country": "Dominican Republic",
            "language": "Spanish",
            "two_letter": "es-DO",
            "three_lettter": "es-DOM",
            "number": "7178"
        },
        {
            "country": "Ecuador",
            "language": "Quechua",
            "two_letter": "qu-EC",
            "three_lettter": "qu-ECU",
            "number": "4096"
        },
        {
            "country": "Ecuador",
            "language": "Spanish",
            "two_letter": "es-EC",
            "three_lettter": "es-ECU",
            "number": "12298"
        },
        {
            "country": "Egypt",
            "language": "Arabic",
            "two_letter": "ar-EG",
            "three_lettter": "ar-EGY",
            "number": "3073"
        },
        {
            "country": "El Salvador",
            "language": "Spanish",
            "two_letter": "es-SV",
            "three_lettter": "es-SLV",
            "number": "17418"
        },
        {
            "country": "Equatorial Guinea",
            "language": "French",
            "two_letter": "fr-GQ",
            "three_lettter": "fr-GNQ",
            "number": "4096"
        },
        {
            "country": "Equatorial Guinea",
            "language": "Portuguese",
            "two_letter": "pt-GQ",
            "three_lettter": "pt-GNQ",
            "number": "4096"
        },
        {
            "country": "Equatorial Guinea",
            "language": "Spanish",
            "two_letter": "es-GQ",
            "three_lettter": "es-GNQ",
            "number": "4096"
        },
        {
            "country": "Eritrea",
            "language": "Arabic",
            "two_letter": "ar-ER",
            "three_lettter": "ar-ERI",
            "number": "4096"
        },
        {
            "country": "Eritrea",
            "language": "Blin",
            "two_letter": "byn-ER",
            "three_lettter": "byn-ERI",
            "number": "4096"
        },
        {
            "country": "Eritrea",
            "language": "English",
            "two_letter": "en-ER",
            "three_lettter": "en-ERI",
            "number": "4096"
        },
        {
            "country": "Eritrea",
            "language": "Geez",
            "two_letter": "gez-ER",
            "three_lettter": "gez-ERI",
            "number": "4096"
        },
        {
            "country": "Eritrea",
            "language": "Tigre",
            "two_letter": "tig-ER",
            "three_lettter": "tig-ERI",
            "number": "4096"
        },
        {
            "country": "Eritrea",
            "language": "Tigrinya",
            "two_letter": "ti-ER",
            "three_lettter": "ti-ERI",
            "number": "2163"
        },
        {
            "country": "Estonia",
            "language": "English",
            "two_letter": "en-EE",
            "three_lettter": "en-EST",
            "number": "4096"
        },
        {
            "country": "Estonia",
            "language": "Estonian",
            "two_letter": "et-EE",
            "three_lettter": "et-EST",
            "number": "1061"
        },
        {
            "country": "Eswatini",
            "language": "English",
            "two_letter": "en-SZ",
            "three_lettter": "en-SWZ",
            "number": "4096"
        },
        {
            "country": "Eswatini",
            "language": "Swati",
            "two_letter": "ss-SZ",
            "three_lettter": "ss-SWZ",
            "number": "4096"
        },
        {
            "country": "Ethiopia",
            "language": "Amharic",
            "two_letter": "am-ET",
            "three_lettter": "am-ETH",
            "number": "1118"
        },
        {
            "country": "Ethiopia",
            "language": "Geez",
            "two_letter": "gez-ET",
            "three_lettter": "gez-ETH",
            "number": "4096"
        },
        {
            "country": "Ethiopia",
            "language": "Oromo",
            "two_letter": "om-ET",
            "three_lettter": "om-ETH",
            "number": "1138"
        },
        {
            "country": "Ethiopia",
            "language": "Somali",
            "two_letter": "so-ET",
            "three_lettter": "so-ETH",
            "number": "4096"
        },
        {
            "country": "Ethiopia",
            "language": "Tigrinya",
            "two_letter": "ti-ET",
            "three_lettter": "ti-ETH",
            "number": "1139"
        },
        {
            "country": "Ethiopia",
            "language": "Wolaytta",
            "two_letter": "wal-ET",
            "three_lettter": "wal-ETH",
            "number": "4096"
        },
        {
            "country": "Europe",
            "language": "English",
            "two_letter": "en-150",
            "three_lettter": "en-",
            "number": "4096"
        },
        {
            "country": "Falkland Islands",
            "language": "English",
            "two_letter": "en-FK",
            "three_lettter": "en-FLK",
            "number": "4096"
        },
        {
            "country": "Falkland Islands",
            "language": "Spanish",
            "two_letter": "es-FK",
            "three_lettter": "es-FLK",
            "number": "4096"
        },
        {
            "country": "Faroe Islands",
            "language": "Faroese",
            "two_letter": "fo-FO",
            "three_lettter": "fo-FRO",
            "number": "1080"
        },
        {
            "country": "Fiji",
            "language": "English",
            "two_letter": "en-FJ",
            "three_lettter": "en-FJI",
            "number": "4096"
        },
        {
            "country": "Finland",
            "language": "English",
            "two_letter": "en-FI",
            "three_lettter": "en-FIN",
            "number": "4096"
        },
        {
            "country": "Finland",
            "language": "Finnish",
            "two_letter": "fi-FI",
            "three_lettter": "fi-FIN",
            "number": "1035"
        },
        {
            "country": "Finland",
            "language": "Inari Sami",
            "two_letter": "smn-FI",
            "three_lettter": "smn-FIN",
            "number": "9275"
        },
        {
            "country": "Finland",
            "language": "Northern Sami",
            "two_letter": "se-FI",
            "three_lettter": "se-FIN",
            "number": "3131"
        },
        {
            "country": "Finland",
            "language": "Swedish",
            "two_letter": "sv-FI",
            "three_lettter": "sv-FIN",
            "number": "2077"
        },
        {
            "country": "France",
            "language": "Breton",
            "two_letter": "br-FR",
            "three_lettter": "br-FRA",
            "number": "1150"
        },
        {
            "country": "France",
            "language": "Catalan",
            "two_letter": "ca-FR",
            "three_lettter": "ca-FRA",
            "number": "4096"
        },
        {
            "country": "France",
            "language": "Corsican",
            "two_letter": "co-FR",
            "three_lettter": "co-FRA",
            "number": "1155"
        },
        {
            "country": "France",
            "language": "English",
            "two_letter": "en-FR",
            "three_lettter": "en-FRA",
            "number": "4096"
        },
        {
            "country": "France",
            "language": "French",
            "two_letter": "fr-FR",
            "three_lettter": "fr-FRA",
            "number": "1036"
        },
        {
            "country": "France",
            "language": "Occitan",
            "two_letter": "oc-FR",
            "three_lettter": "oc-FRA",
            "number": "1154"
        },
        {
            "country": "France",
            "language": "Portuguese",
            "two_letter": "pt-FR",
            "three_lettter": "pt-FRA",
            "number": "4096"
        },
        {
            "country": "France",
            "language": "Swiss German",
            "two_letter": "gsw-FR",
            "three_lettter": "gsw-FRA",
            "number": "1156"
        },
        {
            "country": "French Guiana",
            "language": "French",
            "two_letter": "fr-GF",
            "three_lettter": "fr-GUF",
            "number": "4096"
        },
        {
            "country": "French Guiana",
            "language": "Spanish",
            "two_letter": "es-GF",
            "three_lettter": "es-GUF",
            "number": "4096"
        },
        {
            "country": "French Polynesia",
            "language": "French",
            "two_letter": "fr-PF",
            "three_lettter": "fr-PYF",
            "number": "4096"
        },
        {
            "country": "Gabon",
            "language": "French",
            "two_letter": "fr-GA",
            "three_lettter": "fr-GAB",
            "number": "4096"
        },
        {
            "country": "Gambia",
            "language": "English",
            "two_letter": "en-GM",
            "three_lettter": "en-GMB",
            "number": "4096"
        },
        {
            "country": "Gambia",
            "language": "Fulah",
            "two_letter": "ff-GM",
            "three_lettter": "ff-GMB",
            "number": "4096"
        },
        {
            "country": "Georgia",
            "language": "Georgian",
            "two_letter": "ka-GE",
            "three_lettter": "ka-GEO",
            "number": "1079"
        },
        {
            "country": "Georgia",
            "language": "Ossetic",
            "two_letter": "os-GE",
            "three_lettter": "os-GEO",
            "number": "4096"
        },
        {
            "country": "Germany",
            "language": "Colognian",
            "two_letter": "ksh-DE",
            "three_lettter": "ksh-DEU",
            "number": "4096"
        },
        {
            "country": "Germany",
            "language": "English",
            "two_letter": "en-DE",
            "three_lettter": "en-DEU",
            "number": "4096"
        },
        {
            "country": "Germany",
            "language": "German",
            "two_letter": "de-DE",
            "three_lettter": "de-DEU",
            "number": "1031"
        },
        {
            "country": "Germany",
            "language": "Low German",
            "two_letter": "nds-DE",
            "three_lettter": "nds-DEU",
            "number": "4096"
        },
        {
            "country": "Germany",
            "language": "Lower Sorbian",
            "two_letter": "dsb-DE",
            "three_lettter": "dsb-DEU",
            "number": "2094"
        },
        {
            "country": "Germany",
            "language": "Upper Sorbian",
            "two_letter": "hsb-DE",
            "three_lettter": "hsb-DEU",
            "number": "1070"
        },
        {
            "country": "Ghana",
            "language": "Akan",
            "two_letter": "ak-GH",
            "three_lettter": "ak-GHA",
            "number": "4096"
        },
        {
            "country": "Ghana",
            "language": "English",
            "two_letter": "en-GH",
            "three_lettter": "en-GHA",
            "number": "4096"
        },
        {
            "country": "Ghana",
            "language": "Ewe",
            "two_letter": "ee-GH",
            "three_lettter": "ee-GHA",
            "number": "4096"
        },
        {
            "country": "Ghana",
            "language": "Fulah",
            "two_letter": "ff-GH",
            "three_lettter": "ff-GHA",
            "number": "4096"
        },
        {
            "country": "Ghana",
            "language": "Ga",
            "two_letter": "gaa-GH",
            "three_lettter": "gaa-GHA",
            "number": "4096"
        },
        {
            "country": "Ghana",
            "language": "Hausa",
            "two_letter": "ha-GH",
            "three_lettter": "ha-GHA",
            "number": "4096"
        },
        {
            "country": "Gibraltar",
            "language": "English",
            "two_letter": "en-GI",
            "three_lettter": "en-GIB",
            "number": "4096"
        },
        {
            "country": "Greece",
            "language": "English",
            "two_letter": "en-GR",
            "three_lettter": "en-GRC",
            "number": "4096"
        },
        {
            "country": "Greece",
            "language": "Greek",
            "two_letter": "el-GR",
            "three_lettter": "el-GRC",
            "number": "1032"
        },
        {
            "country": "Greenland",
            "language": "Danish",
            "two_letter": "da-GL",
            "three_lettter": "da-GRL",
            "number": "4096"
        },
        {
            "country": "Greenland",
            "language": "Kalaallisut",
            "two_letter": "kl-GL",
            "three_lettter": "kl-GRL",
            "number": "1135"
        },
        {
            "country": "Greenland",
            "language": "Spanish",
            "two_letter": "es-GL",
            "three_lettter": "es-GRL",
            "number": "4096"
        },
        {
            "country": "Grenada",
            "language": "English",
            "two_letter": "en-GD",
            "three_lettter": "en-GRD",
            "number": "4096"
        },
        {
            "country": "Grenada",
            "language": "Spanish",
            "two_letter": "es-GD",
            "three_lettter": "es-GRD",
            "number": "4096"
        },
        {
            "country": "Guadeloupe",
            "language": "French",
            "two_letter": "fr-GP",
            "three_lettter": "fr-GLP",
            "number": "4096"
        },
        {
            "country": "Guadeloupe",
            "language": "Spanish",
            "two_letter": "es-GP",
            "three_lettter": "es-GLP",
            "number": "4096"
        },
        {
            "country": "Guam",
            "language": "English",
            "two_letter": "en-GU",
            "three_lettter": "en-GUM",
            "number": "4096"
        },
        {
            "country": "Guatemala",
            "language": "Spanish",
            "two_letter": "es-GT",
            "three_lettter": "es-GTM",
            "number": "4106"
        },
        {
            "country": "Guernsey",
            "language": "English",
            "two_letter": "en-GG",
            "three_lettter": "en-GGY",
            "number": "4096"
        },
        {
            "country": "Guinea-Bissau",
            "language": "Fulah",
            "two_letter": "ff-GW",
            "three_lettter": "ff-GNB",
            "number": "4096"
        },
        {
            "country": "Guinea-Bissau",
            "language": "Portuguese",
            "two_letter": "pt-GW",
            "three_lettter": "pt-GNB",
            "number": "4096"
        },
        {
            "country": "Guinea",
            "language": "French",
            "two_letter": "fr-GN",
            "three_lettter": "fr-GIN",
            "number": "4096"
        },
        {
            "country": "Guinea",
            "language": "Fulah",
            "two_letter": "ff-GN",
            "three_lettter": "ff-GIN",
            "number": "4096"
        },
        {
            "country": "Guinea",
            "language": "Kpelle",
            "two_letter": "kpe-GN",
            "three_lettter": "kpe-GIN",
            "number": "4096"
        },
        {
            "country": "Guinea",
            "language": "N’Ko",
            "two_letter": "nqo-GN",
            "three_lettter": "nqo-GIN",
            "number": "4096"
        },
        {
            "country": "Guyana",
            "language": "English",
            "two_letter": "en-GY",
            "three_lettter": "en-GUY",
            "number": "4096"
        },
        {
            "country": "Guyana",
            "language": "Spanish",
            "two_letter": "es-GY",
            "three_lettter": "es-GUY",
            "number": "4096"
        },
        {
            "country": "Haiti",
            "language": "French",
            "two_letter": "fr-HT",
            "three_lettter": "fr-HTI",
            "number": "15372"
        },
        {
            "country": "Haiti",
            "language": "Spanish",
            "two_letter": "es-HT",
            "three_lettter": "es-HTI",
            "number": "4096"
        },
        {
            "country": "Honduras",
            "language": "Spanish",
            "two_letter": "es-HN",
            "three_lettter": "es-HND",
            "number": "18442"
        },
        {
            "country": "Hong Kong",
            "language": "Cantonese",
            "two_letter": "yue-HK",
            "three_lettter": "yue-HKG",
            "number": "4096"
        },
        {
            "country": "Hong Kong",
            "language": "Chinese",
            "two_letter": "zh-HK",
            "three_lettter": "zh-HKG",
            "number": "4096"
        },
        {
            "country": "Hong Kong",
            "language": "Chinese",
            "two_letter": "zh-HK",
            "three_lettter": "zh-HKG",
            "number": "4096"
        },
        {
            "country": "Hong Kong",
            "language": "English",
            "two_letter": "en-HK",
            "three_lettter": "en-HKG",
            "number": "15369"
        },
        {
            "country": "Hungary",
            "language": "English",
            "two_letter": "en-HU",
            "three_lettter": "en-HUN",
            "number": "4096"
        },
        {
            "country": "Hungary",
            "language": "Hungarian",
            "two_letter": "hu-HU",
            "three_lettter": "hu-HUN",
            "number": "1038"
        },
        {
            "country": "Iceland",
            "language": "English",
            "two_letter": "en-IS",
            "three_lettter": "en-ISL",
            "number": "4096"
        },
        {
            "country": "Iceland",
            "language": "Icelandic",
            "two_letter": "is-IS",
            "three_lettter": "is-ISL",
            "number": "1039"
        },
        {
            "country": "India",
            "language": "Assamese",
            "two_letter": "as-IN",
            "three_lettter": "as-IND",
            "number": "1101"
        },
        {
            "country": "India",
            "language": "Bangla",
            "two_letter": "bn-IN",
            "three_lettter": "bn-IND",
            "number": "1093"
        },
        {
            "country": "India",
            "language": "Bodo",
            "two_letter": "brx-IN",
            "three_lettter": "brx-IND",
            "number": "4096"
        },
        {
            "country": "India",
            "language": "Chakma",
            "two_letter": "ccp-IN",
            "three_lettter": "ccp-IND",
            "number": "4096"
        },
        {
            "country": "India",
            "language": "English",
            "two_letter": "en-IN",
            "three_lettter": "en-IND",
            "number": "16393"
        },
        {
            "country": "India",
            "language": "Gujarati",
            "two_letter": "gu-IN",
            "three_lettter": "gu-IND",
            "number": "1095"
        },
        {
            "country": "India",
            "language": "Hindi",
            "two_letter": "hi-IN",
            "three_lettter": "hi-IND",
            "number": "1081"
        },
        {
            "country": "India",
            "language": "Kannada",
            "two_letter": "kn-IN",
            "three_lettter": "kn-IND",
            "number": "1099"
        },
        {
            "country": "India",
            "language": "Kashmiri",
            "two_letter": "ks-IN",
            "three_lettter": "ks-IND",
            "number": "4096"
        },
        {
            "country": "India",
            "language": "Kashmiri",
            "two_letter": "ks-IN",
            "three_lettter": "ks-IND",
            "number": "4096"
        },
        {
            "country": "India",
            "language": "Kashmiri",
            "two_letter": "ks-IN",
            "three_lettter": "ks-IND",
            "number": "4096"
        },
        {
            "country": "India",
            "language": "Konkani",
            "two_letter": "kok-IN",
            "three_lettter": "kok-IND",
            "number": "1111"
        },
        {
            "country": "India",
            "language": "Malayalam",
            "two_letter": "ml-IN",
            "three_lettter": "ml-IND",
            "number": "1100"
        },
        {
            "country": "India",
            "language": "Manipuri",
            "two_letter": "mni-IN",
            "three_lettter": "mni-IND",
            "number": "4096"
        },
        {
            "country": "India",
            "language": "Manipuri",
            "two_letter": "mni-IN",
            "three_lettter": "mni-IND",
            "number": "4096"
        },
        {
            "country": "India",
            "language": "Marathi",
            "two_letter": "mr-IN",
            "three_lettter": "mr-IND",
            "number": "1102"
        },
        {
            "country": "India",
            "language": "Nepali",
            "two_letter": "ne-IN",
            "three_lettter": "ne-IND",
            "number": "2145"
        },
        {
            "country": "India",
            "language": "Odia",
            "two_letter": "or-IN",
            "three_lettter": "or-IND",
            "number": "1096"
        },
        {
            "country": "India",
            "language": "Punjabi",
            "two_letter": "pa-IN",
            "three_lettter": "pa-IND",
            "number": "4096"
        },
        {
            "country": "India",
            "language": "Sanskrit",
            "two_letter": "sa-IN",
            "three_lettter": "sa-IND",
            "number": "1103"
        },
        {
            "country": "India",
            "language": "Santali",
            "two_letter": "sat-IN",
            "three_lettter": "sat-IND",
            "number": "4096"
        },
        {
            "country": "India",
            "language": "Santali",
            "two_letter": "sat-IN",
            "three_lettter": "sat-IND",
            "number": "4096"
        },
        {
            "country": "India",
            "language": "Tamil",
            "two_letter": "ta-IN",
            "three_lettter": "ta-IND",
            "number": "1097"
        },
        {
            "country": "India",
            "language": "Telugu",
            "two_letter": "te-IN",
            "three_lettter": "te-IND",
            "number": "1098"
        },
        {
            "country": "India",
            "language": "Tibetan",
            "two_letter": "bo-IN",
            "three_lettter": "bo-IND",
            "number": "4096"
        },
        {
            "country": "India",
            "language": "Urdu",
            "two_letter": "ur-IN",
            "three_lettter": "ur-IND",
            "number": "2080"
        },
        {
            "country": "India",
            "language": "Urdu",
            "two_letter": "ur-IN",
            "three_lettter": "ur-IND",
            "number": "4096"
        },
        {
            "country": "India",
            "language": "Urdu",
            "two_letter": "ur-IN",
            "three_lettter": "ur-IND",
            "number": "4096"
        },
        {
            "country": "Indonesia",
            "language": "English",
            "two_letter": "en-ID",
            "three_lettter": "en-IDN",
            "number": "14345"
        },
        {
            "country": "Indonesia",
            "language": "Indonesian",
            "two_letter": "id-ID",
            "three_lettter": "id-IDN",
            "number": "1057"
        },
        {
            "country": "Indonesia",
            "language": "Javanese",
            "two_letter": "jv-ID",
            "three_lettter": "jv-IDN",
            "number": "4096"
        },
        {
            "country": "Iran",
            "language": "Kurdish, Sorani",
            "two_letter": "ckb-IR",
            "three_lettter": "ckb-IRN",
            "number": "4096"
        },
        {
            "country": "Iran",
            "language": "Mazanderani",
            "two_letter": "mzn-IR",
            "three_lettter": "mzn-IRN",
            "number": "4096"
        },
        {
            "country": "Iran",
            "language": "Northern Luri",
            "two_letter": "lrc-IR",
            "three_lettter": "lrc-IRN",
            "number": "4096"
        },
        {
            "country": "Iran",
            "language": "Persian",
            "two_letter": "fa-IR",
            "three_lettter": "fa-IRN",
            "number": "1065"
        },
        {
            "country": "Iraq",
            "language": "Arabic",
            "two_letter": "ar-IQ",
            "three_lettter": "ar-IRQ",
            "number": "2049"
        },
        {
            "country": "Iraq",
            "language": "Kurdish, Sorani",
            "two_letter": "ckb-IQ",
            "three_lettter": "ckb-IRQ",
            "number": "4096"
        },
        {
            "country": "Iraq",
            "language": "Northern Luri",
            "two_letter": "lrc-IQ",
            "three_lettter": "lrc-IRQ",
            "number": "4096"
        },
        {
            "country": "Iraq",
            "language": "Syriac",
            "two_letter": "syr-IQ",
            "three_lettter": "syr-IRQ",
            "number": "4096"
        },
        {
            "country": "Ireland",
            "language": "English",
            "two_letter": "en-IE",
            "three_lettter": "en-IRL",
            "number": "6153"
        },
        {
            "country": "Ireland",
            "language": "Irish",
            "two_letter": "ga-IE",
            "three_lettter": "ga-IRL",
            "number": "2108"
        },
        {
            "country": "Isle of Man",
            "language": "English",
            "two_letter": "en-IM",
            "three_lettter": "en-IMN",
            "number": "4096"
        },
        {
            "country": "Isle of Man",
            "language": "Manx",
            "two_letter": "gv-IM",
            "three_lettter": "gv-IMN",
            "number": "4096"
        },
        {
            "country": "Israel",
            "language": "Arabic",
            "two_letter": "ar-IL",
            "three_lettter": "ar-ISR",
            "number": "4096"
        },
        {
            "country": "Israel",
            "language": "English",
            "two_letter": "en-IL",
            "three_lettter": "en-ISR",
            "number": "4096"
        },
        {
            "country": "Israel",
            "language": "Hebrew",
            "two_letter": "he-IL",
            "three_lettter": "he-ISR",
            "number": "1037"
        },
        {
            "country": "Italy",
            "language": "Catalan",
            "two_letter": "ca-IT",
            "three_lettter": "ca-ITA",
            "number": "4096"
        },
        {
            "country": "Italy",
            "language": "English",
            "two_letter": "en-IT",
            "three_lettter": "en-ITA",
            "number": "4096"
        },
        {
            "country": "Italy",
            "language": "Friulian",
            "two_letter": "fur-IT",
            "three_lettter": "fur-ITA",
            "number": "4096"
        },
        {
            "country": "Italy",
            "language": "German",
            "two_letter": "de-IT",
            "three_lettter": "de-ITA",
            "number": "4096"
        },
        {
            "country": "Italy",
            "language": "Italian",
            "two_letter": "it-IT",
            "three_lettter": "it-ITA",
            "number": "1040"
        },
        {
            "country": "Italy",
            "language": "Sardinian",
            "two_letter": "sc-IT",
            "three_lettter": "sc-ITA",
            "number": "4096"
        },
        {
            "country": "Italy",
            "language": "Sicilian",
            "two_letter": "scn-IT",
            "three_lettter": "scn-ITA",
            "number": "4096"
        },
        {
            "country": "Jamaica",
            "language": "English",
            "two_letter": "en-JM",
            "three_lettter": "en-JAM",
            "number": "8201"
        },
        {
            "country": "Japan",
            "language": "English",
            "two_letter": "en-JP",
            "three_lettter": "en-JPN",
            "number": "4096"
        },
        {
            "country": "Japan",
            "language": "Japanese",
            "two_letter": "ja-JP",
            "three_lettter": "ja-JPN",
            "number": "1041"
        },
        {
            "country": "Jersey",
            "language": "English",
            "two_letter": "en-JE",
            "three_lettter": "en-JEY",
            "number": "4096"
        },
        {
            "country": "Jordan",
            "language": "Arabic",
            "two_letter": "ar-JO",
            "three_lettter": "ar-JOR",
            "number": "11265"
        },
        {
            "country": "Kazakhstan",
            "language": "Kazakh",
            "two_letter": "kk-KZ",
            "three_lettter": "kk-KAZ",
            "number": "1087"
        },
        {
            "country": "Kazakhstan",
            "language": "Russian",
            "two_letter": "ru-KZ",
            "three_lettter": "ru-KAZ",
            "number": "4096"
        },
        {
            "country": "Kenya",
            "language": "Embu",
            "two_letter": "ebu-KE",
            "three_lettter": "ebu-KEN",
            "number": "4096"
        },
        {
            "country": "Kenya",
            "language": "English",
            "two_letter": "en-KE",
            "three_lettter": "en-KEN",
            "number": "4096"
        },
        {
            "country": "Kenya",
            "language": "Gusii",
            "two_letter": "guz-KE",
            "three_lettter": "guz-KEN",
            "number": "4096"
        },
        {
            "country": "Kenya",
            "language": "Kalenjin",
            "two_letter": "kln-KE",
            "three_lettter": "kln-KEN",
            "number": "4096"
        },
        {
            "country": "Kenya",
            "language": "Kamba",
            "two_letter": "kam-KE",
            "three_lettter": "kam-KEN",
            "number": "4096"
        },
        {
            "country": "Kenya",
            "language": "Kikuyu",
            "two_letter": "ki-KE",
            "three_lettter": "ki-KEN",
            "number": "4096"
        },
        {
            "country": "Kenya",
            "language": "Luo",
            "two_letter": "luo-KE",
            "three_lettter": "luo-KEN",
            "number": "4096"
        },
        {
            "country": "Kenya",
            "language": "Luyia",
            "two_letter": "luy-KE",
            "three_lettter": "luy-KEN",
            "number": "4096"
        },
        {
            "country": "Kenya",
            "language": "Masai",
            "two_letter": "mas-KE",
            "three_lettter": "mas-KEN",
            "number": "4096"
        },
        {
            "country": "Kenya",
            "language": "Meru",
            "two_letter": "mer-KE",
            "three_lettter": "mer-KEN",
            "number": "4096"
        },
        {
            "country": "Kenya",
            "language": "Oromo",
            "two_letter": "om-KE",
            "three_lettter": "om-KEN",
            "number": "4096"
        },
        {
            "country": "Kenya",
            "language": "Samburu",
            "two_letter": "saq-KE",
            "three_lettter": "saq-KEN",
            "number": "4096"
        },
        {
            "country": "Kenya",
            "language": "Somali",
            "two_letter": "so-KE",
            "three_lettter": "so-KEN",
            "number": "4096"
        },
        {
            "country": "Kenya",
            "language": "Swahili",
            "two_letter": "sw-KE",
            "three_lettter": "sw-KEN",
            "number": "1089"
        },
        {
            "country": "Kenya",
            "language": "Taita",
            "two_letter": "dav-KE",
            "three_lettter": "dav-KEN",
            "number": "4096"
        },
        {
            "country": "Kenya",
            "language": "Teso",
            "two_letter": "teo-KE",
            "three_lettter": "teo-KEN",
            "number": "4096"
        },
        {
            "country": "Kiribati",
            "language": "English",
            "two_letter": "en-KI",
            "three_lettter": "en-KIR",
            "number": "4096"
        },
        {
            "country": "Kosovo",
            "language": "Albanian",
            "two_letter": "sq-XK",
            "three_lettter": "sq-XKK",
            "number": "4096"
        },
        {
            "country": "Kosovo",
            "language": "Serbian",
            "two_letter": "sr-XK",
            "three_lettter": "sr-XKK",
            "number": "4096"
        },
        {
            "country": "Kosovo",
            "language": "Serbian",
            "two_letter": "sr-XK",
            "three_lettter": "sr-XKK",
            "number": "4096"
        },
        {
            "country": "Kuwait",
            "language": "Arabic",
            "two_letter": "ar-KW",
            "three_lettter": "ar-KWT",
            "number": "13313"
        },
        {
            "country": "Kyrgyzstan",
            "language": "Kyrgyz",
            "two_letter": "ky-KG",
            "three_lettter": "ky-KGZ",
            "number": "1088"
        },
        {
            "country": "Kyrgyzstan",
            "language": "Russian",
            "two_letter": "ru-KG",
            "three_lettter": "ru-KGZ",
            "number": "4096"
        },
        {
            "country": "Laos",
            "language": "Lao",
            "two_letter": "lo-LA",
            "three_lettter": "lo-LAO",
            "number": "1108"
        },
        {
            "country": "Latin America",
            "language": "Spanish",
            "two_letter": "es-419",
            "three_lettter": "es-",
            "number": "22538"
        },
        {
            "country": "Latvia",
            "language": "English",
            "two_letter": "en-LV",
            "three_lettter": "en-LVA",
            "number": "4096"
        },
        {
            "country": "Latvia",
            "language": "Latvian",
            "two_letter": "lv-LV",
            "three_lettter": "lv-LVA",
            "number": "1062"
        },
        {
            "country": "Lebanon",
            "language": "Arabic",
            "two_letter": "ar-LB",
            "three_lettter": "ar-LBN",
            "number": "12289"
        },
        {
            "country": "Lesotho",
            "language": "English",
            "two_letter": "en-LS",
            "three_lettter": "en-LSO",
            "number": "4096"
        },
        {
            "country": "Lesotho",
            "language": "Southern Sotho",
            "two_letter": "st-LS",
            "three_lettter": "st-LSO",
            "number": "4096"
        },
        {
            "country": "Liberia",
            "language": "English",
            "two_letter": "en-LR",
            "three_lettter": "en-LBR",
            "number": "4096"
        },
        {
            "country": "Liberia",
            "language": "Fulah",
            "two_letter": "ff-LR",
            "three_lettter": "ff-LBR",
            "number": "4096"
        },
        {
            "country": "Liberia",
            "language": "Kpelle",
            "two_letter": "kpe-LR",
            "three_lettter": "kpe-LBR",
            "number": "4096"
        },
        {
            "country": "Liberia",
            "language": "Vai",
            "two_letter": "vai-LR",
            "three_lettter": "vai-LBR",
            "number": "4096"
        },
        {
            "country": "Liberia",
            "language": "Vai",
            "two_letter": "vai-LR",
            "three_lettter": "vai-LBR",
            "number": "4096"
        },
        {
            "country": "Libya",
            "language": "Arabic",
            "two_letter": "ar-LY",
            "three_lettter": "ar-LBY",
            "number": "4097"
        },
        {
            "country": "Liechtenstein",
            "language": "German",
            "two_letter": "de-LI",
            "three_lettter": "de-LIE",
            "number": "5127"
        },
        {
            "country": "Liechtenstein",
            "language": "Swiss German",
            "two_letter": "gsw-LI",
            "three_lettter": "gsw-LIE",
            "number": "4096"
        },
        {
            "country": "Lithuania",
            "language": "English",
            "two_letter": "en-LT",
            "three_lettter": "en-LTU",
            "number": "4096"
        },
        {
            "country": "Lithuania",
            "language": "Lithuanian",
            "two_letter": "lt-LT",
            "three_lettter": "lt-LTU",
            "number": "1063"
        },
        {
            "country": "Luxembourg",
            "language": "English",
            "two_letter": "en-LU",
            "three_lettter": "en-LUX",
            "number": "4096"
        },
        {
            "country": "Luxembourg",
            "language": "French",
            "two_letter": "fr-LU",
            "three_lettter": "fr-LUX",
            "number": "5132"
        },
        {
            "country": "Luxembourg",
            "language": "German",
            "two_letter": "de-LU",
            "three_lettter": "de-LUX",
            "number": "4103"
        },
        {
            "country": "Luxembourg",
            "language": "Luxembourgish",
            "two_letter": "lb-LU",
            "three_lettter": "lb-LUX",
            "number": "1134"
        },
        {
            "country": "Luxembourg",
            "language": "Portuguese",
            "two_letter": "pt-LU",
            "three_lettter": "pt-LUX",
            "number": "4096"
        },
        {
            "country": "Macao",
            "language": "Chinese",
            "two_letter": "zh-MO",
            "three_lettter": "zh-MAC",
            "number": "4096"
        },
        {
            "country": "Macao",
            "language": "Chinese",
            "two_letter": "zh-MO",
            "three_lettter": "zh-MAC",
            "number": "4096"
        },
        {
            "country": "Macao",
            "language": "English",
            "two_letter": "en-MO",
            "three_lettter": "en-MAC",
            "number": "4096"
        },
        {
            "country": "Macao",
            "language": "Portuguese",
            "two_letter": "pt-MO",
            "three_lettter": "pt-MAC",
            "number": "4096"
        },
        {
            "country": "Madagascar",
            "language": "English",
            "two_letter": "en-MG",
            "three_lettter": "en-MDG",
            "number": "4096"
        },
        {
            "country": "Madagascar",
            "language": "French",
            "two_letter": "fr-MG",
            "three_lettter": "fr-MDG",
            "number": "4096"
        },
        {
            "country": "Madagascar",
            "language": "Malagasy",
            "two_letter": "mg-MG",
            "three_lettter": "mg-MDG",
            "number": "4096"
        },
        {
            "country": "Malawi",
            "language": "English",
            "two_letter": "en-MW",
            "three_lettter": "en-MWI",
            "number": "4096"
        },
        {
            "country": "Malawi",
            "language": "Nyanja",
            "two_letter": "ny-MW",
            "three_lettter": "ny-MWI",
            "number": "4096"
        },
        {
            "country": "Malaysia",
            "language": "English",
            "two_letter": "en-MY",
            "three_lettter": "en-MYS",
            "number": "17417"
        },
        {
            "country": "Malaysia",
            "language": "Malay",
            "two_letter": "ms-MY",
            "three_lettter": "ms-MYS",
            "number": "1086"
        },
        {
            "country": "Malaysia",
            "language": "Malay",
            "two_letter": "ms-MY",
            "three_lettter": "ms-MYS",
            "number": "4096"
        },
        {
            "country": "Malaysia",
            "language": "Tamil",
            "two_letter": "ta-MY",
            "three_lettter": "ta-MYS",
            "number": "4096"
        },
        {
            "country": "Maldives",
            "language": "Dhivehi",
            "two_letter": "dv-MV",
            "three_lettter": "dv-MDV",
            "number": "1125"
        },
        {
            "country": "Maldives",
            "language": "English",
            "two_letter": "en-MV",
            "three_lettter": "en-MDV",
            "number": "4096"
        },
        {
            "country": "Mali",
            "language": "Bambara",
            "two_letter": "bm-ML",
            "three_lettter": "bm-MLI",
            "number": "4096"
        },
        {
            "country": "Mali",
            "language": "French",
            "two_letter": "fr-ML",
            "three_lettter": "fr-MLI",
            "number": "13324"
        },
        {
            "country": "Mali",
            "language": "Koyra Chiini",
            "two_letter": "khq-ML",
            "three_lettter": "khq-MLI",
            "number": "4096"
        },
        {
            "country": "Mali",
            "language": "Koyraboro Senni",
            "two_letter": "ses-ML",
            "three_lettter": "ses-MLI",
            "number": "4096"
        },
        {
            "country": "Malta",
            "language": "English",
            "two_letter": "en-MT",
            "three_lettter": "en-MLT",
            "number": "4096"
        },
        {
            "country": "Malta",
            "language": "Maltese",
            "two_letter": "mt-MT",
            "three_lettter": "mt-MLT",
            "number": "1082"
        },
        {
            "country": "Marshall Islands",
            "language": "English",
            "two_letter": "en-MH",
            "three_lettter": "en-MHL",
            "number": "4096"
        },
        {
            "country": "Martinique",
            "language": "French",
            "two_letter": "fr-MQ",
            "three_lettter": "fr-MTQ",
            "number": "4096"
        },
        {
            "country": "Martinique",
            "language": "Spanish",
            "two_letter": "es-MQ",
            "three_lettter": "es-MTQ",
            "number": "4096"
        },
        {
            "country": "Mauritania",
            "language": "Arabic",
            "two_letter": "ar-MR",
            "three_lettter": "ar-MRT",
            "number": "4096"
        },
        {
            "country": "Mauritania",
            "language": "French",
            "two_letter": "fr-MR",
            "three_lettter": "fr-MRT",
            "number": "4096"
        },
        {
            "country": "Mauritania",
            "language": "Fulah",
            "two_letter": "ff-MR",
            "three_lettter": "ff-MRT",
            "number": "4096"
        },
        {
            "country": "Mauritius",
            "language": "English",
            "two_letter": "en-MU",
            "three_lettter": "en-MUS",
            "number": "4096"
        },
        {
            "country": "Mauritius",
            "language": "French",
            "two_letter": "fr-MU",
            "three_lettter": "fr-MUS",
            "number": "4096"
        },
        {
            "country": "Mauritius",
            "language": "Morisyen",
            "two_letter": "mfe-MU",
            "three_lettter": "mfe-MUS",
            "number": "4096"
        },
        {
            "country": "Mayotte",
            "language": "French",
            "two_letter": "fr-YT",
            "three_lettter": "fr-MYT",
            "number": "4096"
        },
        {
            "country": "Mexico",
            "language": "English",
            "two_letter": "en-MX",
            "three_lettter": "en-MEX",
            "number": "4096"
        },
        {
            "country": "Mexico",
            "language": "Spanish",
            "two_letter": "es-MX",
            "three_lettter": "es-MEX",
            "number": "2058"
        },
        {
            "country": "Micronesia",
            "language": "English",
            "two_letter": "en-FM",
            "three_lettter": "en-FSM",
            "number": "4096"
        },
        {
            "country": "Moldova",
            "language": "Romanian",
            "two_letter": "ro-MD",
            "three_lettter": "ro-MDA",
            "number": "2072"
        },
        {
            "country": "Moldova",
            "language": "Russian",
            "two_letter": "ru-MD",
            "three_lettter": "ru-MDA",
            "number": "2073"
        },
        {
            "country": "Monaco",
            "language": "French",
            "two_letter": "fr-MC",
            "three_lettter": "fr-MCO",
            "number": "6156"
        },
        {
            "country": "Mongolia",
            "language": "Mongolian",
            "two_letter": "mn-MN",
            "three_lettter": "mn-MNG",
            "number": "1104"
        },
        {
            "country": "Montenegro",
            "language": "English",
            "two_letter": "en-ME",
            "three_lettter": "en-MNE",
            "number": "4096"
        },
        {
            "country": "Montenegro",
            "language": "Serbian",
            "two_letter": "sr-ME",
            "three_lettter": "sr-MNE",
            "number": "11290"
        },
        {
            "country": "Montenegro",
            "language": "Serbian",
            "two_letter": "sr-ME",
            "three_lettter": "sr-MNE",
            "number": "12314"
        },
        {
            "country": "Montserrat",
            "language": "English",
            "two_letter": "en-MS",
            "three_lettter": "en-MSR",
            "number": "4096"
        },
        {
            "country": "Montserrat",
            "language": "Spanish",
            "two_letter": "es-MS",
            "three_lettter": "es-MSR",
            "number": "4096"
        },
        {
            "country": "Morocco",
            "language": "Arabic",
            "two_letter": "ar-MA",
            "three_lettter": "ar-MAR",
            "number": "6145"
        },
        {
            "country": "Morocco",
            "language": "Central Atlas Tamazight",
            "two_letter": "tzm-MA",
            "three_lettter": "tzm-MAR",
            "number": "4096"
        },
        {
            "country": "Morocco",
            "language": "French",
            "two_letter": "fr-MA",
            "three_lettter": "fr-MAR",
            "number": "14348"
        },
        {
            "country": "Morocco",
            "language": "Standard Moroccan Tamazight",
            "two_letter": "zgh-MA",
            "three_lettter": "zgh-MAR",
            "number": "4096"
        },
        {
            "country": "Morocco",
            "language": "Tachelhit",
            "two_letter": "shi-MA",
            "three_lettter": "shi-MAR",
            "number": "4096"
        },
        {
            "country": "Morocco",
            "language": "Tachelhit",
            "two_letter": "shi-MA",
            "three_lettter": "shi-MAR",
            "number": "4096"
        },
        {
            "country": "Mozambique",
            "language": "Makhuwa-Meetto",
            "two_letter": "mgh-MZ",
            "three_lettter": "mgh-MOZ",
            "number": "4096"
        },
        {
            "country": "Mozambique",
            "language": "Portuguese",
            "two_letter": "pt-MZ",
            "three_lettter": "pt-MOZ",
            "number": "4096"
        },
        {
            "country": "Mozambique",
            "language": "Sena",
            "two_letter": "seh-MZ",
            "three_lettter": "seh-MOZ",
            "number": "4096"
        },
        {
            "country": "Myanmar (Burma)",
            "language": "Burmese",
            "two_letter": "my-MM",
            "three_lettter": "my-MMR",
            "number": "1109"
        },
        {
            "country": "Myanmar (Burma)",
            "language": "English",
            "two_letter": "en-MM",
            "three_lettter": "en-MMR",
            "number": "4096"
        },
        {
            "country": "Namibia",
            "language": "Afrikaans",
            "two_letter": "af-NA",
            "three_lettter": "af-NAM",
            "number": "4096"
        },
        {
            "country": "Namibia",
            "language": "English",
            "two_letter": "en-NA",
            "three_lettter": "en-NAM",
            "number": "4096"
        },
        {
            "country": "Namibia",
            "language": "Nama",
            "two_letter": "naq-NA",
            "three_lettter": "naq-NAM",
            "number": "4096"
        },
        {
            "country": "Nauru",
            "language": "English",
            "two_letter": "en-NR",
            "three_lettter": "en-NRU",
            "number": "4096"
        },
        {
            "country": "Nepal",
            "language": "Nepali",
            "two_letter": "ne-NP",
            "three_lettter": "ne-NPL",
            "number": "1121"
        },
        {
            "country": "Netherlands",
            "language": "Dutch",
            "two_letter": "nl-NL",
            "three_lettter": "nl-NLD",
            "number": "1043"
        },
        {
            "country": "Netherlands",
            "language": "English",
            "two_letter": "en-NL",
            "three_lettter": "en-NLD",
            "number": "4096"
        },
        {
            "country": "Netherlands",
            "language": "Low German",
            "two_letter": "nds-NL",
            "three_lettter": "nds-NLD",
            "number": "4096"
        },
        {
            "country": "Netherlands",
            "language": "Western Frisian",
            "two_letter": "fy-NL",
            "three_lettter": "fy-NLD",
            "number": "1122"
        },
        {
            "country": "New Caledonia",
            "language": "French",
            "two_letter": "fr-NC",
            "three_lettter": "fr-NCL",
            "number": "4096"
        },
        {
            "country": "New Zealand",
            "language": "English",
            "two_letter": "en-NZ",
            "three_lettter": "en-NZL",
            "number": "5129"
        },
        {
            "country": "New Zealand",
            "language": "Maori",
            "two_letter": "mi-NZ",
            "three_lettter": "mi-NZL",
            "number": "1153"
        },
        {
            "country": "Nicaragua",
            "language": "Spanish",
            "two_letter": "es-NI",
            "three_lettter": "es-NIC",
            "number": "19466"
        },
        {
            "country": "Niger",
            "language": "French",
            "two_letter": "fr-NE",
            "three_lettter": "fr-NER",
            "number": "4096"
        },
        {
            "country": "Niger",
            "language": "Fulah",
            "two_letter": "ff-NE",
            "three_lettter": "ff-NER",
            "number": "4096"
        },
        {
            "country": "Niger",
            "language": "Hausa",
            "two_letter": "ha-NE",
            "three_lettter": "ha-NER",
            "number": "4096"
        },
        {
            "country": "Niger",
            "language": "Tasawaq",
            "two_letter": "twq-NE",
            "three_lettter": "twq-NER",
            "number": "4096"
        },
        {
            "country": "Niger",
            "language": "Zarma",
            "two_letter": "dje-NE",
            "three_lettter": "dje-NER",
            "number": "4096"
        },
        {
            "country": "Nigeria",
            "language": "English",
            "two_letter": "en-NG",
            "three_lettter": "en-NGA",
            "number": "4096"
        },
        {
            "country": "Nigeria",
            "language": "Fulah",
            "two_letter": "ff-NG",
            "three_lettter": "ff-NGA",
            "number": "4096"
        },
        {
            "country": "Nigeria",
            "language": "Hausa",
            "two_letter": "ha-NG",
            "three_lettter": "ha-NGA",
            "number": "4096"
        },
        {
            "country": "Nigeria",
            "language": "Igbo",
            "two_letter": "ig-NG",
            "three_lettter": "ig-NGA",
            "number": "1136"
        },
        {
            "country": "Nigeria",
            "language": "Jju",
            "two_letter": "kaj-NG",
            "three_lettter": "kaj-NGA",
            "number": "4096"
        },
        {
            "country": "Nigeria",
            "language": "Tyap",
            "two_letter": "kcg-NG",
            "three_lettter": "kcg-NGA",
            "number": "4096"
        },
        {
            "country": "Nigeria",
            "language": "Yoruba",
            "two_letter": "yo-NG",
            "three_lettter": "yo-NGA",
            "number": "1130"
        },
        {
            "country": "Niue",
            "language": "English",
            "two_letter": "en-NU",
            "three_lettter": "en-NIU",
            "number": "4096"
        },
        {
            "country": "Norfolk Island",
            "language": "English",
            "two_letter": "en-NF",
            "three_lettter": "en-NFK",
            "number": "4096"
        },
        {
            "country": "North Korea",
            "language": "Korean",
            "two_letter": "ko-KP",
            "three_lettter": "ko-PRK",
            "number": "4096"
        },
        {
            "country": "North Macedonia",
            "language": "Albanian",
            "two_letter": "sq-MK",
            "three_lettter": "sq-MKD",
            "number": "4096"
        },
        {
            "country": "North Macedonia",
            "language": "Macedonian",
            "two_letter": "mk-MK",
            "three_lettter": "mk-MKD",
            "number": "1071"
        },
        {
            "country": "Northern Mariana Islands",
            "language": "English",
            "two_letter": "en-MP",
            "three_lettter": "en-MNP",
            "number": "4096"
        },
        {
            "country": "Norway",
            "language": "English",
            "two_letter": "en-NO",
            "three_lettter": "en-NOR",
            "number": "4096"
        },
        {
            "country": "Norway",
            "language": "Northern Sami",
            "two_letter": "se-NO",
            "three_lettter": "se-NOR",
            "number": "1083"
        },
        {
            "country": "Norway",
            "language": "Norwegian Bokmål",
            "two_letter": "nb-NO",
            "three_lettter": "nb-NOR",
            "number": "1044"
        },
        {
            "country": "Norway",
            "language": "Norwegian Nynorsk",
            "two_letter": "nn-NO",
            "three_lettter": "nn-NOR",
            "number": "2068"
        },
        {
            "country": "Oman",
            "language": "Arabic",
            "two_letter": "ar-OM",
            "three_lettter": "ar-OMN",
            "number": "8193"
        },
        {
            "country": "Pakistan",
            "language": "English",
            "two_letter": "en-PK",
            "three_lettter": "en-PAK",
            "number": "4096"
        },
        {
            "country": "Pakistan",
            "language": "Pashto",
            "two_letter": "ps-PK",
            "three_lettter": "ps-PAK",
            "number": "4096"
        },
        {
            "country": "Pakistan",
            "language": "Punjabi",
            "two_letter": "pa-PK",
            "three_lettter": "pa-PAK",
            "number": "2118"
        },
        {
            "country": "Pakistan",
            "language": "Punjabi",
            "two_letter": "pa-PK",
            "three_lettter": "pa-PAK",
            "number": "4096"
        },
        {
            "country": "Pakistan",
            "language": "Sindhi",
            "two_letter": "sd-PK",
            "three_lettter": "sd-PAK",
            "number": "4096"
        },
        {
            "country": "Pakistan",
            "language": "Urdu",
            "two_letter": "ur-PK",
            "three_lettter": "ur-PAK",
            "number": "1056"
        },
        {
            "country": "Pakistan",
            "language": "Urdu",
            "two_letter": "ur-PK",
            "three_lettter": "ur-PAK",
            "number": "4096"
        },
        {
            "country": "Pakistan",
            "language": "Urdu",
            "two_letter": "ur-PK",
            "three_lettter": "ur-PAK",
            "number": "4096"
        },
        {
            "country": "Palau",
            "language": "English",
            "two_letter": "en-PW",
            "three_lettter": "en-PLW",
            "number": "4096"
        },
        {
            "country": "Palestinian Territories",
            "language": "Arabic",
            "two_letter": "ar-PS",
            "three_lettter": "ar-PSE",
            "number": "4096"
        },
        {
            "country": "Panama",
            "language": "Spanish",
            "two_letter": "es-PA",
            "three_lettter": "es-PAN",
            "number": "6154"
        },
        {
            "country": "Papua New Guinea",
            "language": "English",
            "two_letter": "en-PG",
            "three_lettter": "en-PNG",
            "number": "4096"
        },
        {
            "country": "Paraguay",
            "language": "Guarani",
            "two_letter": "gn-PY",
            "three_lettter": "gn-PRY",
            "number": "1140"
        },
        {
            "country": "Paraguay",
            "language": "Spanish",
            "two_letter": "es-PY",
            "three_lettter": "es-PRY",
            "number": "15370"
        },
        {
            "country": "Peru",
            "language": "Quechua",
            "two_letter": "qu-PE",
            "three_lettter": "qu-PER",
            "number": "4096"
        },
        {
            "country": "Peru",
            "language": "Spanish",
            "two_letter": "es-PE",
            "three_lettter": "es-PER",
            "number": "10250"
        },
        {
            "country": "Philippines",
            "language": "Cebuano",
            "two_letter": "ceb-PH",
            "three_lettter": "ceb-PHL",
            "number": "4096"
        },
        {
            "country": "Philippines",
            "language": "English",
            "two_letter": "en-PH",
            "three_lettter": "en-PHL",
            "number": "13321"
        },
        {
            "country": "Philippines",
            "language": "Filipino",
            "two_letter": "fil-PH",
            "three_lettter": "fil-PHL",
            "number": "1124"
        },
        {
            "country": "Philippines",
            "language": "Spanish",
            "two_letter": "es-PH",
            "three_lettter": "es-PHL",
            "number": "4096"
        },
        {
            "country": "Pitcairn Islands",
            "language": "English",
            "two_letter": "en-PN",
            "three_lettter": "en-PCN",
            "number": "4096"
        },
        {
            "country": "Poland",
            "language": "English",
            "two_letter": "en-PL",
            "three_lettter": "en-POL",
            "number": "4096"
        },
        {
            "country": "Poland",
            "language": "Polish",
            "two_letter": "pl-PL",
            "three_lettter": "pl-POL",
            "number": "1045"
        },
        {
            "country": "Portugal",
            "language": "English",
            "two_letter": "en-PT",
            "three_lettter": "en-PRT",
            "number": "4096"
        },
        {
            "country": "Portugal",
            "language": "Portuguese",
            "two_letter": "pt-PT",
            "three_lettter": "pt-PRT",
            "number": "2070"
        },
        {
            "country": "Puerto Rico",
            "language": "English",
            "two_letter": "en-PR",
            "three_lettter": "en-PRI",
            "number": "4096"
        },
        {
            "country": "Puerto Rico",
            "language": "Spanish",
            "two_letter": "es-PR",
            "three_lettter": "es-PRI",
            "number": "20490"
        },
        {
            "country": "Qatar",
            "language": "Arabic",
            "two_letter": "ar-QA",
            "three_lettter": "ar-QAT",
            "number": "16385"
        },
        {
            "country": "Réunion",
            "language": "French",
            "two_letter": "fr-RE",
            "three_lettter": "fr-REU",
            "number": "8204"
        },
        {
            "country": "Romania",
            "language": "English",
            "two_letter": "en-RO",
            "three_lettter": "en-ROU",
            "number": "4096"
        },
        {
            "country": "Romania",
            "language": "Romanian",
            "two_letter": "ro-RO",
            "three_lettter": "ro-ROU",
            "number": "1048"
        },
        {
            "country": "Russia",
            "language": "Bashkir",
            "two_letter": "ba-RU",
            "three_lettter": "ba-RUS",
            "number": "1133"
        },
        {
            "country": "Russia",
            "language": "Chechen",
            "two_letter": "ce-RU",
            "three_lettter": "ce-RUS",
            "number": "4096"
        },
        {
            "country": "Russia",
            "language": "Chuvash",
            "two_letter": "cv-RU",
            "three_lettter": "cv-RUS",
            "number": "4096"
        },
        {
            "country": "Russia",
            "language": "English",
            "two_letter": "en-RU",
            "three_lettter": "en-RUS",
            "number": "4096"
        },
        {
            "country": "Russia",
            "language": "Erzya",
            "two_letter": "myv-RU",
            "three_lettter": "myv-RUS",
            "number": "4096"
        },
        {
            "country": "Russia",
            "language": "Ossetic",
            "two_letter": "os-RU",
            "three_lettter": "os-RUS",
            "number": "4096"
        },
        {
            "country": "Russia",
            "language": "Russian",
            "two_letter": "ru-RU",
            "three_lettter": "ru-RUS",
            "number": "1049"
        },
        {
            "country": "Russia",
            "language": "Sakha",
            "two_letter": "sah-RU",
            "three_lettter": "sah-RUS",
            "number": "1157"
        },
        {
            "country": "Russia",
            "language": "Tatar",
            "two_letter": "tt-RU",
            "three_lettter": "tt-RUS",
            "number": "1092"
        },
        {
            "country": "Rwanda",
            "language": "English",
            "two_letter": "en-RW",
            "three_lettter": "en-RWA",
            "number": "4096"
        },
        {
            "country": "Rwanda",
            "language": "French",
            "two_letter": "fr-RW",
            "three_lettter": "fr-RWA",
            "number": "4096"
        },
        {
            "country": "Rwanda",
            "language": "Kinyarwanda",
            "two_letter": "rw-RW",
            "three_lettter": "rw-RWA",
            "number": "1159"
        },
        {
            "country": "Samoa",
            "language": "English",
            "two_letter": "en-WS",
            "three_lettter": "en-WSM",
            "number": "4096"
        },
        {
            "country": "San Marino",
            "language": "Italian",
            "two_letter": "it-SM",
            "three_lettter": "it-SMR",
            "number": "4096"
        },
        {
            "country": "São Tomé & Príncipe",
            "language": "Portuguese",
            "two_letter": "pt-ST",
            "three_lettter": "pt-STP",
            "number": "4096"
        },
        {
            "country": "Saudi Arabia",
            "language": "Arabic",
            "two_letter": "ar-SA",
            "three_lettter": "ar-SAU",
            "number": "1025"
        },
        {
            "country": "Saudi Arabia",
            "language": "English",
            "two_letter": "en-SA",
            "three_lettter": "en-SAU",
            "number": "4096"
        },
        {
            "country": "Senegal",
            "language": "French",
            "two_letter": "fr-SN",
            "three_lettter": "fr-SEN",
            "number": "10252"
        },
        {
            "country": "Senegal",
            "language": "Fulah",
            "two_letter": "ff-SN",
            "three_lettter": "ff-SEN",
            "number": "2151"
        },
        {
            "country": "Senegal",
            "language": "Jola-Fonyi",
            "two_letter": "dyo-SN",
            "three_lettter": "dyo-SEN",
            "number": "4096"
        },
        {
            "country": "Senegal",
            "language": "Wolof",
            "two_letter": "wo-SN",
            "three_lettter": "wo-SEN",
            "number": "1160"
        },
        {
            "country": "Serbia",
            "language": "English",
            "two_letter": "en-RS",
            "three_lettter": "en-SRB",
            "number": "4096"
        },
        {
            "country": "Serbia",
            "language": "Serbian",
            "two_letter": "sr-RS",
            "three_lettter": "sr-SRB",
            "number": "10266"
        },
        {
            "country": "Serbia",
            "language": "Serbian",
            "two_letter": "sr-RS",
            "three_lettter": "sr-SRB",
            "number": "9242"
        },
        {
            "country": "Seychelles",
            "language": "English",
            "two_letter": "en-SC",
            "three_lettter": "en-SYC",
            "number": "4096"
        },
        {
            "country": "Seychelles",
            "language": "French",
            "two_letter": "fr-SC",
            "three_lettter": "fr-SYC",
            "number": "4096"
        },
        {
            "country": "Sierra Leone",
            "language": "English",
            "two_letter": "en-SL",
            "three_lettter": "en-SLE",
            "number": "4096"
        },
        {
            "country": "Sierra Leone",
            "language": "Fulah",
            "two_letter": "ff-SL",
            "three_lettter": "ff-SLE",
            "number": "4096"
        },
        {
            "country": "Singapore",
            "language": "Chinese",
            "two_letter": "zh-SG",
            "three_lettter": "zh-SGP",
            "number": "4096"
        },
        {
            "country": "Singapore",
            "language": "English",
            "two_letter": "en-SG",
            "three_lettter": "en-SGP",
            "number": "18441"
        },
        {
            "country": "Singapore",
            "language": "Malay",
            "two_letter": "ms-SG",
            "three_lettter": "ms-SGP",
            "number": "4096"
        },
        {
            "country": "Singapore",
            "language": "Tamil",
            "two_letter": "ta-SG",
            "three_lettter": "ta-SGP",
            "number": "4096"
        },
        {
            "country": "Sint Maarten",
            "language": "Dutch",
            "two_letter": "nl-SX",
            "three_lettter": "nl-SXM",
            "number": "4096"
        },
        {
            "country": "Sint Maarten",
            "language": "English",
            "two_letter": "en-SX",
            "three_lettter": "en-SXM",
            "number": "4096"
        },
        {
            "country": "Sint Maarten",
            "language": "Spanish",
            "two_letter": "es-SX",
            "three_lettter": "es-SXM",
            "number": "4096"
        },
        {
            "country": "Slovakia",
            "language": "English",
            "two_letter": "en-SK",
            "three_lettter": "en-SVK",
            "number": "4096"
        },
        {
            "country": "Slovakia",
            "language": "Slovak",
            "two_letter": "sk-SK",
            "three_lettter": "sk-SVK",
            "number": "1051"
        },
        {
            "country": "Slovenia",
            "language": "English",
            "two_letter": "en-SI",
            "three_lettter": "en-SVN",
            "number": "4096"
        },
        {
            "country": "Slovenia",
            "language": "Slovenian",
            "two_letter": "sl-SI",
            "three_lettter": "sl-SVN",
            "number": "1060"
        },
        {
            "country": "Solomon Islands",
            "language": "English",
            "two_letter": "en-SB",
            "three_lettter": "en-SLB",
            "number": "4096"
        },
        {
            "country": "Somalia",
            "language": "Arabic",
            "two_letter": "ar-SO",
            "three_lettter": "ar-SOM",
            "number": "4096"
        },
        {
            "country": "Somalia",
            "language": "Somali",
            "two_letter": "so-SO",
            "three_lettter": "so-SOM",
            "number": "1143"
        },
        {
            "country": "South Africa",
            "language": "Afrikaans",
            "two_letter": "af-ZA",
            "three_lettter": "af-ZAF",
            "number": "1078"
        },
        {
            "country": "South Africa",
            "language": "English",
            "two_letter": "en-ZA",
            "three_lettter": "en-ZAF",
            "number": "7177"
        },
        {
            "country": "South Africa",
            "language": "Northern Sotho",
            "two_letter": "nso-ZA",
            "three_lettter": "nso-ZAF",
            "number": "1132"
        },
        {
            "country": "South Africa",
            "language": "South Ndebele",
            "two_letter": "nr-ZA",
            "three_lettter": "nr-ZAF",
            "number": "4096"
        },
        {
            "country": "South Africa",
            "language": "Southern Sotho",
            "two_letter": "st-ZA",
            "three_lettter": "st-ZAF",
            "number": "1072"
        },
        {
            "country": "South Africa",
            "language": "Swati",
            "two_letter": "ss-ZA",
            "three_lettter": "ss-ZAF",
            "number": "4096"
        },
        {
            "country": "South Africa",
            "language": "Tsonga",
            "two_letter": "ts-ZA",
            "three_lettter": "ts-ZAF",
            "number": "1073"
        },
        {
            "country": "South Africa",
            "language": "Tswana",
            "two_letter": "tn-ZA",
            "three_lettter": "tn-ZAF",
            "number": "1074"
        },
        {
            "country": "South Africa",
            "language": "Venda",
            "two_letter": "ve-ZA",
            "three_lettter": "ve-ZAF",
            "number": "1075"
        },
        {
            "country": "South Africa",
            "language": "Xhosa",
            "two_letter": "xh-ZA",
            "three_lettter": "xh-ZAF",
            "number": "1076"
        },
        {
            "country": "South Africa",
            "language": "Zulu",
            "two_letter": "zu-ZA",
            "three_lettter": "zu-ZAF",
            "number": "1077"
        },
        {
            "country": "South Korea",
            "language": "English",
            "two_letter": "en-KR",
            "three_lettter": "en-KOR",
            "number": "4096"
        },
        {
            "country": "South Korea",
            "language": "Korean",
            "two_letter": "ko-KR",
            "three_lettter": "ko-KOR",
            "number": "1042"
        },
        {
            "country": "South Sudan",
            "language": "Arabic",
            "two_letter": "ar-SS",
            "three_lettter": "ar-SSD",
            "number": "4096"
        },
        {
            "country": "South Sudan",
            "language": "English",
            "two_letter": "en-SS",
            "three_lettter": "en-SSD",
            "number": "4096"
        },
        {
            "country": "South Sudan",
            "language": "Nuer",
            "two_letter": "nus-SS",
            "three_lettter": "nus-SSD",
            "number": "4096"
        },
        {
            "country": "Spain",
            "language": "Asturian",
            "two_letter": "ast-ES",
            "three_lettter": "ast-ESP",
            "number": "4096"
        },
        {
            "country": "Spain",
            "language": "Basque",
            "two_letter": "eu-ES",
            "three_lettter": "eu-ESP",
            "number": "1069"
        },
        {
            "country": "Spain",
            "language": "Catalan",
            "two_letter": "ca-ES",
            "three_lettter": "ca-ESP",
            "number": "1027"
        },
        {
            "country": "Spain",
            "language": "English",
            "two_letter": "en-ES",
            "three_lettter": "en-ESP",
            "number": "4096"
        },
        {
            "country": "Spain",
            "language": "Galician",
            "two_letter": "gl-ES",
            "three_lettter": "gl-ESP",
            "number": "1110"
        },
        {
            "country": "Spain",
            "language": "Spanish",
            "two_letter": "es-ES",
            "three_lettter": "es-ESP",
            "number": "3082"
        },
        {
            "country": "Sri Lanka",
            "language": "Sinhala",
            "two_letter": "si-LK",
            "three_lettter": "si-LKA",
            "number": "1115"
        },
        {
            "country": "Sri Lanka",
            "language": "Tamil",
            "two_letter": "ta-LK",
            "three_lettter": "ta-LKA",
            "number": "2121"
        },
        {
            "country": "St. Barthélemy",
            "language": "French",
            "two_letter": "fr-BL",
            "three_lettter": "fr-BLM",
            "number": "4096"
        },
        {
            "country": "St. Barthélemy",
            "language": "Spanish",
            "two_letter": "es-BL",
            "three_lettter": "es-BLM",
            "number": "4096"
        },
        {
            "country": "St. Helena",
            "language": "English",
            "two_letter": "en-SH",
            "three_lettter": "en-SHN",
            "number": "4096"
        },
        {
            "country": "St. Kitts & Nevis",
            "language": "English",
            "two_letter": "en-KN",
            "three_lettter": "en-KNA",
            "number": "4096"
        },
        {
            "country": "St. Kitts & Nevis",
            "language": "Spanish",
            "two_letter": "es-KN",
            "three_lettter": "es-KNA",
            "number": "4096"
        },
        {
            "country": "St. Lucia",
            "language": "English",
            "two_letter": "en-LC",
            "three_lettter": "en-LCA",
            "number": "4096"
        },
        {
            "country": "St. Lucia",
            "language": "Spanish",
            "two_letter": "es-LC",
            "three_lettter": "es-LCA",
            "number": "4096"
        },
        {
            "country": "St. Martin",
            "language": "French",
            "two_letter": "fr-MF",
            "three_lettter": "fr-MAF",
            "number": "4096"
        },
        {
            "country": "St. Martin",
            "language": "Spanish",
            "two_letter": "es-MF",
            "three_lettter": "es-MAF",
            "number": "4096"
        },
        {
            "country": "St. Pierre & Miquelon",
            "language": "French",
            "two_letter": "fr-PM",
            "three_lettter": "fr-SPM",
            "number": "4096"
        },
        {
            "country": "St. Pierre & Miquelon",
            "language": "Spanish",
            "two_letter": "es-PM",
            "three_lettter": "es-SPM",
            "number": "4096"
        },
        {
            "country": "St. Vincent & Grenadines",
            "language": "English",
            "two_letter": "en-VC",
            "three_lettter": "en-VCT",
            "number": "4096"
        },
        {
            "country": "St. Vincent & Grenadines",
            "language": "Spanish",
            "two_letter": "es-VC",
            "three_lettter": "es-VCT",
            "number": "4096"
        },
        {
            "country": "Sudan",
            "language": "Arabic",
            "two_letter": "ar-SD",
            "three_lettter": "ar-SDN",
            "number": "4096"
        },
        {
            "country": "Sudan",
            "language": "English",
            "two_letter": "en-SD",
            "three_lettter": "en-SDN",
            "number": "4096"
        },
        {
            "country": "Suriname",
            "language": "Dutch",
            "two_letter": "nl-SR",
            "three_lettter": "nl-SUR",
            "number": "4096"
        },
        {
            "country": "Suriname",
            "language": "Spanish",
            "two_letter": "es-SR",
            "three_lettter": "es-SUR",
            "number": "4096"
        },
        {
            "country": "Svalbard & Jan Mayen",
            "language": "Norwegian Bokmål",
            "two_letter": "nb-SJ",
            "three_lettter": "nb-SJM",
            "number": "4096"
        },
        {
            "country": "Sweden",
            "language": "English",
            "two_letter": "en-SE",
            "three_lettter": "en-SWE",
            "number": "4096"
        },
        {
            "country": "Sweden",
            "language": "Northern Sami",
            "two_letter": "se-SE",
            "three_lettter": "se-SWE",
            "number": "2107"
        },
        {
            "country": "Sweden",
            "language": "Swedish",
            "two_letter": "sv-SE",
            "three_lettter": "sv-SWE",
            "number": "1053"
        },
        {
            "country": "Switzerland",
            "language": "English",
            "two_letter": "en-CH",
            "three_lettter": "en-CHE",
            "number": "4096"
        },
        {
            "country": "Switzerland",
            "language": "French",
            "two_letter": "fr-CH",
            "three_lettter": "fr-CHE",
            "number": "4108"
        },
        {
            "country": "Switzerland",
            "language": "German",
            "two_letter": "de-CH",
            "three_lettter": "de-CHE",
            "number": "2055"
        },
        {
            "country": "Switzerland",
            "language": "Italian",
            "two_letter": "it-CH",
            "three_lettter": "it-CHE",
            "number": "2064"
        },
        {
            "country": "Switzerland",
            "language": "Portuguese",
            "two_letter": "pt-CH",
            "three_lettter": "pt-CHE",
            "number": "4096"
        },
        {
            "country": "Switzerland",
            "language": "Romansh",
            "two_letter": "rm-CH",
            "three_lettter": "rm-CHE",
            "number": "1047"
        },
        {
            "country": "Switzerland",
            "language": "Swiss German",
            "two_letter": "gsw-CH",
            "three_lettter": "gsw-CHE",
            "number": "4096"
        },
        {
            "country": "Switzerland",
            "language": "Walser",
            "two_letter": "wae-CH",
            "three_lettter": "wae-CHE",
            "number": "4096"
        },
        {
            "country": "Syria",
            "language": "Arabic",
            "two_letter": "ar-SY",
            "three_lettter": "ar-SYR",
            "number": "10241"
        },
        {
            "country": "Syria",
            "language": "French",
            "two_letter": "fr-SY",
            "three_lettter": "fr-SYR",
            "number": "4096"
        },
        {
            "country": "Syria",
            "language": "Syriac",
            "two_letter": "syr-SY",
            "three_lettter": "syr-SYR",
            "number": "1114"
        },
        {
            "country": "Taiwan",
            "language": "Chinese",
            "two_letter": "zh-TW",
            "three_lettter": "zh-TWN",
            "number": "4096"
        },
        {
            "country": "Taiwan",
            "language": "English",
            "two_letter": "en-TW",
            "three_lettter": "en-TWN",
            "number": "4096"
        },
        {
            "country": "Taiwan",
            "language": "Taroko",
            "two_letter": "trv-TW",
            "three_lettter": "trv-TWN",
            "number": "4096"
        },
        {
            "country": "Tajikistan",
            "language": "Tajik",
            "two_letter": "tg-TJ",
            "three_lettter": "tg-TJK",
            "number": "4096"
        },
        {
            "country": "Tanzania",
            "language": "Asu",
            "two_letter": "asa-TZ",
            "three_lettter": "asa-TZA",
            "number": "4096"
        },
        {
            "country": "Tanzania",
            "language": "Bena",
            "two_letter": "bez-TZ",
            "three_lettter": "bez-TZA",
            "number": "4096"
        },
        {
            "country": "Tanzania",
            "language": "English",
            "two_letter": "en-TZ",
            "three_lettter": "en-TZA",
            "number": "4096"
        },
        {
            "country": "Tanzania",
            "language": "Langi",
            "two_letter": "lag-TZ",
            "three_lettter": "lag-TZA",
            "number": "4096"
        },
        {
            "country": "Tanzania",
            "language": "Machame",
            "two_letter": "jmc-TZ",
            "three_lettter": "jmc-TZA",
            "number": "4096"
        },
        {
            "country": "Tanzania",
            "language": "Makonde",
            "two_letter": "kde-TZ",
            "three_lettter": "kde-TZA",
            "number": "4096"
        },
        {
            "country": "Tanzania",
            "language": "Masai",
            "two_letter": "mas-TZ",
            "three_lettter": "mas-TZA",
            "number": "4096"
        },
        {
            "country": "Tanzania",
            "language": "Rombo",
            "two_letter": "rof-TZ",
            "three_lettter": "rof-TZA",
            "number": "4096"
        },
        {
            "country": "Tanzania",
            "language": "Rwa",
            "two_letter": "rwk-TZ",
            "three_lettter": "rwk-TZA",
            "number": "4096"
        },
        {
            "country": "Tanzania",
            "language": "Sangu",
            "two_letter": "sbp-TZ",
            "three_lettter": "sbp-TZA",
            "number": "4096"
        },
        {
            "country": "Tanzania",
            "language": "Shambala",
            "two_letter": "ksb-TZ",
            "three_lettter": "ksb-TZA",
            "number": "4096"
        },
        {
            "country": "Tanzania",
            "language": "Swahili",
            "two_letter": "sw-TZ",
            "three_lettter": "sw-TZA",
            "number": "4096"
        },
        {
            "country": "Tanzania",
            "language": "Vunjo",
            "two_letter": "vun-TZ",
            "three_lettter": "vun-TZA",
            "number": "4096"
        },
        {
            "country": "Thailand",
            "language": "English",
            "two_letter": "en-TH",
            "three_lettter": "en-THA",
            "number": "4096"
        },
        {
            "country": "Thailand",
            "language": "Thai",
            "two_letter": "th-TH",
            "three_lettter": "th-THA",
            "number": "1054"
        },
        {
            "country": "Timor-Leste",
            "language": "Portuguese",
            "two_letter": "pt-TL",
            "three_lettter": "pt-TLS",
            "number": "4096"
        },
        {
            "country": "Togo",
            "language": "Ewe",
            "two_letter": "ee-TG",
            "three_lettter": "ee-TGO",
            "number": "4096"
        },
        {
            "country": "Togo",
            "language": "French",
            "two_letter": "fr-TG",
            "three_lettter": "fr-TGO",
            "number": "4096"
        },
        {
            "country": "Tokelau",
            "language": "English",
            "two_letter": "en-TK",
            "three_lettter": "en-TKL",
            "number": "4096"
        },
        {
            "country": "Tonga",
            "language": "English",
            "two_letter": "en-TO",
            "three_lettter": "en-TON",
            "number": "4096"
        },
        {
            "country": "Tonga",
            "language": "Tongan",
            "two_letter": "to-TO",
            "three_lettter": "to-TON",
            "number": "4096"
        },
        {
            "country": "Trinidad & Tobago",
            "language": "English",
            "two_letter": "en-TT",
            "three_lettter": "en-TTO",
            "number": "11273"
        },
        {
            "country": "Trinidad & Tobago",
            "language": "Spanish",
            "two_letter": "es-TT",
            "three_lettter": "es-TTO",
            "number": "4096"
        },
        {
            "country": "Tunisia",
            "language": "Arabic",
            "two_letter": "ar-TN",
            "three_lettter": "ar-TUN",
            "number": "7169"
        },
        {
            "country": "Tunisia",
            "language": "French",
            "two_letter": "fr-TN",
            "three_lettter": "fr-TUN",
            "number": "4096"
        },
        {
            "country": "Turkey",
            "language": "English",
            "two_letter": "en-TR",
            "three_lettter": "en-TUR",
            "number": "4096"
        },
        {
            "country": "Turkey",
            "language": "Kurdish",
            "two_letter": "ku-TR",
            "three_lettter": "ku-TUR",
            "number": "4096"
        },
        {
            "country": "Turkey",
            "language": "Turkish",
            "two_letter": "tr-TR",
            "three_lettter": "tr-TUR",
            "number": "1055"
        },
        {
            "country": "Turkmenistan",
            "language": "Turkmen",
            "two_letter": "tk-TM",
            "three_lettter": "tk-TKM",
            "number": "1090"
        },
        {
            "country": "Turks & Caicos Islands",
            "language": "English",
            "two_letter": "en-TC",
            "three_lettter": "en-TCA",
            "number": "4096"
        },
        {
            "country": "Turks & Caicos Islands",
            "language": "Spanish",
            "two_letter": "es-TC",
            "three_lettter": "es-TCA",
            "number": "4096"
        },
        {
            "country": "Tuvalu",
            "language": "English",
            "two_letter": "en-TV",
            "three_lettter": "en-TUV",
            "number": "4096"
        },
        {
            "country": "U.S. Outlying Islands",
            "language": "English",
            "two_letter": "en-UM",
            "three_lettter": "en-UMI",
            "number": "4096"
        },
        {
            "country": "U.S. Virgin Islands",
            "language": "English",
            "two_letter": "en-VI",
            "three_lettter": "en-VIR",
            "number": "4096"
        },
        {
            "country": "U.S. Virgin Islands",
            "language": "Spanish",
            "two_letter": "es-VI",
            "three_lettter": "es-VIR",
            "number": "4096"
        },
        {
            "country": "Uganda",
            "language": "Chiga",
            "two_letter": "cgg-UG",
            "three_lettter": "cgg-UGA",
            "number": "4096"
        },
        {
            "country": "Uganda",
            "language": "English",
            "two_letter": "en-UG",
            "three_lettter": "en-UGA",
            "number": "4096"
        },
        {
            "country": "Uganda",
            "language": "Ganda",
            "two_letter": "lg-UG",
            "three_lettter": "lg-UGA",
            "number": "4096"
        },
        {
            "country": "Uganda",
            "language": "Nyankole",
            "two_letter": "nyn-UG",
            "three_lettter": "nyn-UGA",
            "number": "4096"
        },
        {
            "country": "Uganda",
            "language": "Soga",
            "two_letter": "xog-UG",
            "three_lettter": "xog-UGA",
            "number": "4096"
        },
        {
            "country": "Uganda",
            "language": "Swahili",
            "two_letter": "sw-UG",
            "three_lettter": "sw-UGA",
            "number": "4096"
        },
        {
            "country": "Uganda",
            "language": "Teso",
            "two_letter": "teo-UG",
            "three_lettter": "teo-UGA",
            "number": "4096"
        },
        {
            "country": "Ukraine",
            "language": "English",
            "two_letter": "en-UA",
            "three_lettter": "en-UKR",
            "number": "4096"
        },
        {
            "country": "Ukraine",
            "language": "Russian",
            "two_letter": "ru-UA",
            "three_lettter": "ru-UKR",
            "number": "4096"
        },
        {
            "country": "Ukraine",
            "language": "Ukrainian",
            "two_letter": "uk-UA",
            "three_lettter": "uk-UKR",
            "number": "1058"
        },
        {
            "country": "United Arab Emirates",
            "language": "Arabic",
            "two_letter": "ar-AE",
            "three_lettter": "ar-ARE",
            "number": "14337"
        },
        {
            "country": "United Arab Emirates",
            "language": "English",
            "two_letter": "en-AE",
            "three_lettter": "en-ARE",
            "number": "4096"
        },
        {
            "country": "United Kingdom",
            "language": "Cornish",
            "two_letter": "kw-GB",
            "three_lettter": "kw-GBR",
            "number": "4096"
        },
        {
            "country": "United Kingdom",
            "language": "English",
            "two_letter": "en-GB",
            "three_lettter": "en-GBR",
            "number": "2057"
        },
        {
            "country": "United Kingdom",
            "language": "Scottish Gaelic",
            "two_letter": "gd-GB",
            "three_lettter": "gd-GBR",
            "number": "1169"
        },
        {
            "country": "United Kingdom",
            "language": "Welsh",
            "two_letter": "cy-GB",
            "three_lettter": "cy-GBR",
            "number": "1106"
        },
        {
            "country": "United States",
            "language": "Cherokee",
            "two_letter": "chr-US",
            "three_lettter": "chr-USA",
            "number": "4096"
        },
        {
            "country": "United States",
            "language": "English (United States)",
            "two_letter": "en-US",
            "three_lettter": "en-USA",
            "number": "4096"
        },
        {
            "country": "United States",
            "language": "English",
            "two_letter": "en-US",
            "three_lettter": "en-USA",
            "number": "1033"
        },
        {
            "country": "United States",
            "language": "Hawaiian",
            "two_letter": "haw-US",
            "three_lettter": "haw-USA",
            "number": "1141"
        },
        {
            "country": "United States",
            "language": "Lakota",
            "two_letter": "lkt-US",
            "three_lettter": "lkt-USA",
            "number": "4096"
        },
        {
            "country": "United States",
            "language": "Spanish",
            "two_letter": "es-US",
            "three_lettter": "es-USA",
            "number": "21514"
        },
        {
            "country": "Uruguay",
            "language": "Spanish",
            "two_letter": "es-UY",
            "three_lettter": "es-URY",
            "number": "14346"
        },
        {
            "country": "Uzbekistan",
            "language": "Uzbek",
            "two_letter": "uz-UZ",
            "three_lettter": "uz-UZB",
            "number": "1091"
        },
        {
            "country": "Uzbekistan",
            "language": "Uzbek",
            "two_letter": "uz-UZ",
            "three_lettter": "uz-UZB",
            "number": "2115"
        },
        {
            "country": "Vanuatu",
            "language": "English",
            "two_letter": "en-VU",
            "three_lettter": "en-VUT",
            "number": "4096"
        },
        {
            "country": "Vanuatu",
            "language": "French",
            "two_letter": "fr-VU",
            "three_lettter": "fr-VUT",
            "number": "4096"
        },
        {
            "country": "Vatican City",
            "language": "Italian",
            "two_letter": "it-VA",
            "three_lettter": "it-VAT",
            "number": "4096"
        },
        {
            "country": "Venezuela",
            "language": "Spanish",
            "two_letter": "es-VE",
            "three_lettter": "es-VEN",
            "number": "8202"
        },
        {
            "country": "Vietnam",
            "language": "Vietnamese",
            "two_letter": "vi-VN",
            "three_lettter": "vi-VNM",
            "number": "1066"
        },
        {
            "country": "Wallis & Futuna",
            "language": "French",
            "two_letter": "fr-WF",
            "three_lettter": "fr-WLF",
            "number": "4096"
        },
        {
            "country": "Western Sahara",
            "language": "Arabic",
            "two_letter": "ar-EH",
            "three_lettter": "ar-ESH",
            "number": "4096"
        },
        {
            "country": "World",
            "language": "Arabic",
            "two_letter": "ar-001",
            "three_lettter": "ar-",
            "number": "4096"
        },
        {
            "country": "World",
            "language": "English",
            "two_letter": "en-001",
            "three_lettter": "en-",
            "number": "4096"
        },
        {
            "country": "World",
            "language": "Esperanto",
            "two_letter": "eo-001",
            "three_lettter": "eo-",
            "number": "4096"
        },
        {
            "country": "World",
            "language": "Ido",
            "two_letter": "io-001",
            "three_lettter": "io-",
            "number": "4096"
        },
        {
            "country": "World",
            "language": "Interlingua",
            "two_letter": "ia-001",
            "three_lettter": "ia-",
            "number": "4096"
        },
        {
            "country": "World",
            "language": "Lojban",
            "two_letter": "jbo-001",
            "three_lettter": "jbo-",
            "number": "4096"
        },
        {
            "country": "World",
            "language": "Yiddish",
            "two_letter": "yi-001",
            "three_lettter": "yi-",
            "number": "1085"
        },
        {
            "country": "Yemen",
            "language": "Arabic",
            "two_letter": "ar-YE",
            "three_lettter": "ar-YEM",
            "number": "9217"
        },
        {
            "country": "Zambia",
            "language": "Bemba",
            "two_letter": "bem-ZM",
            "three_lettter": "bem-ZMB",
            "number": "4096"
        },
        {
            "country": "Zambia",
            "language": "English",
            "two_letter": "en-ZM",
            "three_lettter": "en-ZMB",
            "number": "4096"
        },
        {
            "country": "Zimbabwe",
            "language": "English",
            "two_letter": "en-ZW",
            "three_lettter": "en-ZWE",
            "number": "12297"
        },
        {
            "country": "Zimbabwe",
            "language": "North Ndebele",
            "two_letter": "nd-ZW",
            "three_lettter": "nd-ZWE",
            "number": "4096"
        },
        {
            "country": "Zimbabwe",
            "language": "Shona",
            "two_letter": "sn-ZW",
            "three_lettter": "sn-ZWE",
            "number": "4096"
        }
    ]

export {
    countryCode
}