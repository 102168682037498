import { notification } from "antd";
import React, { useEffect, useState } from "react";
// import { BiArrowBack } from "react-icons/bi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useLocation, useNavigate } from "react-router";
import DownDownSelectChunk from "../../components/draft-page-component/dropdown-select-chunk";
import SourceLanguage from "../../components/draft-page-component/source-language";
import MainLayout from "../../components/layout/main.layout";
import CrossCheckModal from "../../components/modal/cross-check-modal";
import SpinnerComponent from "../../components/spinner/spinner.component";
import { extractNumberFromString,getStoredValue, storeValue } from "../../services/config";
import { useSelector,useDispatch } from "react-redux";
import {saveBooksAndProjects} from "../../services/BooksAndProjects"
import { storeBooks } from "../../redux/actions/books.action";
import {storeProjects} from "../../redux/actions/project.action"
import DraftAudioComponent from "../../components/draft-page-component/draft-audio";
import DraftScriptComponent from "../../components/draft-page-component/draft-script";
import DraftVideoComponent from "../../components/draft-page-component/draft-video/draft-video.component";
import Chatbot from "../../components/chatbot";
import {clearCheckFilesName,clearFileCount } from "../../redux/actions/check-files.action";
import { clearTranScriptedText, togglePlaying } from "../../redux/actions/util.action";
import { isSafari,isTablet } from "react-device-detect";

const CitConCheckPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [project, setProject] = useState({});
  const [isLoader, setIsLoader] = useState(true);
  const [isForwardDisabled, setIsForwardDisabled] = useState(false);
  const [chapterChunks, setChapterChunks] = useState([]);
  const [currentChunkIndex, setCurrentChunkIndex] = useState("");
  const projectsReducer = useSelector((state) => state.projectsReducer);
  const booksReducer = useSelector((state) => state.booksReducer);
  const isPlayingStatus = useSelector((state) => state.playingStatusReducer);
  const [role, setRole] = useState(localStorage.getItem("role"));
  const isRecordingStatus = useSelector(
    (state) => state.recordingStatusReducer
  );
  // const dispatch = useDispatch();
  const toggleCheckFilesCheckboxReducer = useSelector(
    (state) => state.toggleCheckFilesCheckboxReducer
  );

  const [api, contextHolder] = notification.useNotification();
  const showNotification = (type, message) => {
    notification.destroy();
    api[type]({
      message,
    });
  };

  useEffect(() => {
    updateProject();
    checkCurrentChunkIndex();

    return () => {
      localStorage.removeItem("checkPageMsg");
    };
  }, [location.state.verse]);

  const updateProject = () => {
    return new Promise(async (resolve, reject) => {
      let data= await saveBooksAndProjects();
      if(projectsReducer.length==0 || booksReducer.length==0){
        data.projectsData.length >0 ? data.projectsData.forEach(async (item)=>{
          await dispatch(storeProjects([item]));
        }) : await dispatch(storeProjects([data.projectsData]));
    
        data.booksData.length>0 ? data.booksData.forEach(async(item)=>{
          await dispatch(storeBooks([item]));
        }): await dispatch(storeBooks([data.booksData]));
      }
      setIsLoader(true);
      setIsForwardDisabled(false);
      setProject(location.state.project);
      checkCurrentChunkIndex();
      setIsLoader(false);
      resolve();
    });
  };

  const checkCurrentChunkIndex = async () => {
    try {
      let currentChapter = await location.state?.book?.chapters.filter(
        (item) => item.chapterName == location?.state?.chapter
      );
      if (currentChapter.length > 0) {
        setChapterChunks(currentChapter[0].chunks);
        let index = await currentChapter[0].chunks.findIndex(
          (item) => item.chunkName == location?.state?.verse
        );
        setCurrentChunkIndex(index);

        if (currentChapter[0].chunks.length - 1 == index) {
          setIsForwardDisabled(true);
        }
      } else {
        setIsForwardDisabled(true);
      }
    } catch (error) {}
  };

  const goBack = async() => {
    await dispatch(clearCheckFilesName());
    await dispatch(clearFileCount());
    
    if (role == "MTT" || role == "Facilitator") {
      await dispatch(clearTranScriptedText());
    }
    if (isPlayingStatus == true) {
      showNotification("error", "Please stop playing");
    } else if (isRecordingStatus == true) {
      showNotification("error", "Please stop recording");
    } else {
      navigate("/myProject");
    }
  };

  const goNext = async () => {
    if (isForwardDisabled == true) {
      showNotification("info", "This is last chunk");
    } else if (chapterChunks.length - 1 !== currentChunkIndex) {
      setIsLoader(true);
      let nextChunk = await chapterChunks[currentChunkIndex + 1];
      let headingName = await extractNumberFromString(nextChunk.chunkName);
      await dispatch(clearCheckFilesName());
      await dispatch(clearFileCount())
      await dispatch(clearTranScriptedText());

      if(isSafari || isTablet){
        let audio =  getStoredValue();
        audio != undefined && audio.current?.pause();
        dispatch(togglePlaying(false));
        storeValue(undefined)
       }

      navigate("/cit-con-check", {
        state: {
          chunkName: location.state?.chunkName,
          chunk: nextChunk,
          book: location.state?.book,
          verse: nextChunk.chunkName,
          heading: `${location.state?.chunkName} ${headingName}`,
          chapter: location.state?.chapter,
          project:location.state?.project,
        },
      });
      setIsLoader(false);
    }
  };

  return (
    <MainLayout>
      {isLoader ? (
        <SpinnerComponent />
      ) : (
        <div className="draft-details">
          <div className="draft-details-wrapper">
            <div className="grid grid-cols-3 border-bottom   ">
              <div className="back-arrow">
                <IoIosArrowBack size={26} onClick={goBack} />
              </div>
              <div className="flex items-center justify-center chunkname">
                <h4>{location.state.heading}</h4>
                <DownDownSelectChunk
                  url={"/cit-con-check"}
                  size={30}
                  className="pb-2"
                  isloader = {setIsLoader}
                  />
              </div>

              <div className="flex items-center justify-end">
                <CrossCheckModal />
                <button className="back-arrow ml-2 remove-btn-css">
                  <IoIosArrowForward
                    size={26}
                    onClick={goNext}
                    color={isForwardDisabled == true ? "gray" : "black"}
                  />
                </button>
              </div>
            </div>

            <div className="pt-4">
              {Object.keys(project).length ? (
                <>
                  {project.projectType.signLanguage == true && (
                    <DraftVideoComponent
                      chunk={location.state?.chunk}
                      project={project}
                      title={"Draft Sign"}
                      targetType="draft"
                      isDisableRecord={
                        localStorage.getItem("role") == "Facilitator"
                      }
                      isBackTranslationDisplay={true}
                    />
                  )}
                </>
              ) : null}

              {Object.keys(project).length ? (
                <>
                  {project.projectType.audio == true ? (
                    <DraftAudioComponent
                      chunk={location.state?.chunk}
                      fileType={"audio"}
                      title={"OBT Draft"}
                      targetType="draft"
                      isDisableRecord={
                        localStorage.getItem("role") == "Facilitator" ||
                        localStorage.getItem("role") == "CIT" ||
                        localStorage.getItem("role") == "Consultant"
                      }
                      isBackTranslationDisplay={true}
                      relatedDraft=""
                    />
                  ) : null}
                </>
              ) : null}
              {Object.keys(project).length ? (
                <>
                  {project.projectType.jesusFilm == true ? (
                    <DraftAudioComponent
                      chunk={location.state?.chunk}
                      fileType={"audio"}
                      title={"OBT Draft"}
                      targetType="draft"
                      isDisableRecord={
                        localStorage.getItem("role") == "Facilitator" ||
                        localStorage.getItem("role") == "CIT" ||
                        localStorage.getItem("role") == "Consultant"
                      }
                      isBackTranslationDisplay={true}
                      relatedDraft=""
                    />
                  ) : null}
                </>
              ) : null}

              {Object.keys(project).length ? (
                <>
                  {project.projectType.text == true ? (
                    <DraftScriptComponent
                      title={"Script Draft"}
                      targetType="draft"
                      chunk={location.state?.chunk}
                      isTextAreaDisplayed={
                        localStorage.getItem("role") == "MTT"
                      }
                      isBackTranslationDisplay={true}
                      relatedDraft=""
                    />
                  ) : null}
                </>
              ) : null}
            </div>

            <div>
              <Chatbot />
            </div>
            {contextHolder}
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default CitConCheckPage;