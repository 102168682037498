import { Modal } from "antd";
import React from "react";
import { useState } from "react";
import { FaComment } from "react-icons/fa";
import CommentsComponent from "../../comments/comments.component";

const CommentModal = ({ draftFile }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <button className="remove-btn-css">
        <FaComment size={20} onClick={showModal} />
      </button>

      <Modal
        onOk={handleOk}
        onCancel={handleCancel}
        visible={isModalOpen}
        width={900}
        footer={null}
      >
        <CommentsComponent chunk={draftFile} />
      </Modal>
    </>
  );
};

export default CommentModal;
