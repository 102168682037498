import React, { useEffect, useState } from "react";
import "./waveform.scss";

const Waveform = ({ isPlaying, lines }) => {
  useEffect(() => {
    loadWaves();
  }, []);

  const loadWaves = () => {
    const bar = document.querySelectorAll(".bar");

    for (let i = 0; i < bar.length; i++) {
      bar.forEach((item, j) => {
        item.style.animationDuration = `${Math.random() * (0.7 - 0.2) + 0.2}s`; // Change the numbers for speed / ( max - min ) + min / ex. ( 0.5 - 0.1 ) + 0.1
      });
    }
  };

  return (
    <>
      <div className="waveform mx-2">
        <div className="sound-wave">
          {[...Array(lines)].map((x, i) => (
            <div className="bar" key={i}></div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Waveform;
