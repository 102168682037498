export const getAllBooks = () => {
  return {
    type: "GET_BOOKS",
  };
};

export const storeBooks = (payload) => {
  return {
    type: "STORE_BOOKS",
    payload,
  };
};

export const clearBooks = () => {
  return { type: "CLEAR_BOOKS" };
};
