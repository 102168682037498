import { Button, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineDown } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router";
import { extractNumberFromString, getChunkName, getStoredValue, storeValue } from "../../../services/config";
import SpinnerComponent from "../../spinner/spinner.component";
import "../draft-script/script.scss"
import { clearCheckFilesName, clearFileCount } from "../../../redux/actions/check-files.action";
import { useDispatch, useSelector } from "react-redux";
import { clearTranScriptedText, togglePlaying } from "../../../redux/actions/util.action";
import { isSafari,isTablet } from "react-device-detect";

const DownDownSelectChunk = ({ url, size, isloader, className }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSelectValue, setCurrentSelectValue] = useState("");
  const [modalLoading, setModalLoading] = useState(false);
  const [role, setRole] = useState(localStorage.getItem("role"));

  const audioRef = useSelector((state) => state.audioReducer);

  useEffect(() => {
    loadChunkList();
  }, [location.state.verse]);

  const loadChunkList = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
          let chapterName = await location.state.chapter;
          let chapter = await location.state.book.chapters.filter(
            (item) => item.chapterName == chapterName
          );
          if (chapter.length > 0) {
            setList(chapter[0].chunks);
          }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    });
  };

  const handleChange = (value) => {
    setCurrentSelectValue(value);
  };

  const handleOk = async() => {
    setModalLoading(true);
    return new Promise(async (resolve, reject) => {
      try {
        if(isSafari || isTablet){
          let audio =  getStoredValue();
          audio != undefined && audio.current?.pause();
         
          dispatch(togglePlaying(false));
          storeValue(undefined)
          // navigate("/dashboard")
         }
        if (
          currentSelectValue == location.state.verse ||
          currentSelectValue.length == 0
        ) {
          setModalLoading(false);
          setIsModalOpen(false);
        } else {
          isloader(true)
          
          await dispatch(clearCheckFilesName());
          if (role == "MTT" || role == "Facilitator") {
            await dispatch(clearTranScriptedText());
          }

          await dispatch(clearFileCount());
          let headingName = await extractNumberFromString(currentSelectValue);
          let chunkName = await getChunkName(
            location.state?.project.language,
            location.state?.book.bookName,
            location.state?.chapter
          );


          let currentChunk = await list.filter((item) => item.chunkName == currentSelectValue);
          console.log("currentChunk",currentChunk);

          currentChunk[0].chunk=currentChunk[0].chunk.sort((a, b) => {
            // Extracting file extensions (assuming all files have extensions)
            const extensionA = a.fileName.split('.').pop();
            const extensionB = b.fileName.split('.').pop();        
            // Assigning priorities based on file type
            const priority = { 'mp3': 1, 'txt': 2 };
            // Comparing based on priorities
            return priority[extensionA] - priority[extensionB];
          });  

          navigate(url, {
            state: {
              chunkName: chunkName,
              chunk:  currentChunk[0],
              book: location.state?.book,
              verse: currentSelectValue,
              heading: `${chunkName} ${headingName}`,
              chapter: location.state?.chapter,
              project: location.state?.project,
              targetType: "draft",
              isReadOnly: true,
            },
          });
          setModalLoading(false);
          setIsModalOpen(false);
        }
      } catch (error) {
        console.log(error);
        setIsModalOpen(false);
        setModalLoading(false);
      }
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <AiOutlineDown
        className={className}
        onClick={(e) => {
          setIsModalOpen(true);
        }}
        size={size}
      />
      <Modal
        title={<span className="  ">Select Chunk</span>}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={modalLoading}
        footer={
          <>
          <div className="script">
          <Button
              onClick={handleCancel}
              style={{ background: "#F9002B", color: "white" }}
              type="danger"
              className="hover-text-white"
              disabled={isLoading}
              onFocus={(e)=>{e.target.style.color="white"}}
              onMouseOver={(e) => { e.target.style.color = "white"; }}   
            >
              Cancel
            </Button>
            <Button
              onClick={handleOk}
              className="submit-btn"
              style={{ background: "#031832", color: "white" }}
              onFocus={(e)=>{e.target.style.color="white"}}
              onMouseOver={(e) => { e.target.style.color = "white"; }}   
            >
              Go
            </Button>
            </div> 
           
          </>
        }
      >
        {isLoading == true ? (
          <SpinnerComponent />
        ) : (
          <>
            {list && list.length > 0 ? (
              <>
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  onChange={handleChange}
                  defaultValue={location?.state?.verse}
                >
                  <>
                    {list.map((item) => (
                      <Select.Option
                        value={item.chunkName}
                        key={item.chunkName}
                        className="capitalize"
                      >
                        {item.chunkName}
                      </Select.Option>
                    ))}
                  </>
                </Select>
              </>
            ) : (
              <>
                <div>Chunks not assigned</div>
              </>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default DownDownSelectChunk;
