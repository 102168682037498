import React, { useEffect, useState } from "react";
import rhema_logo from "../../../assets/images/1050x310_Rhema Logo.png";
import "./header.scss";
import { Layout, Menu, notification } from "antd";
import { FaUser, FaChevronDown, FaBell } from "react-icons/fa";
import { AiOutlineLogout } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import { removeUserDetailsFromLocalStorage } from "../../../services/save-user";
import { useDispatch, useSelector } from "react-redux";
import SyncBar from "../sync-bar/index";
import { getStoredValue, storeValue } from "../../../services/config";
// import { clearBooks } from "../../../redux/actions/books.action";
// import { clearAllDataFromObjectStores } from "../../../services/indexed-db/clear-data.indexeddb";
import {
  firstLoadExecuted,
  togglePlaying,
  toggleRecording,
  toggleSyncStatus,
  toogleHomeLoading,
  sideNavBarStatusReducer,
  clearTranScriptedText
} from "../../../redux/actions/util.action";
import { clearBooks } from "../../../redux/actions/books.action";
import { clearProjects } from "../../../redux/actions/project.action";
import { isSafari,isTablet } from "react-device-detect";
import { toggleCheckFilesCheckbox } from "../../../redux/actions/check-files.action";
// import { getAllFileFromSyncDB } from "../../../services/indexed-db/sync.indexeddb";
// import { syncAllData } from "../../../services/sync-methods/sync.methods";
// import SyncBar from "../sync-bar";
// import { waitForAWhile } from "../../../services/config";
const { Header } = Layout;

const HeaderUIComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isRecordingStatus = useSelector(
    (state) => state.recordingStatusReducer
  );

  const audioRef = useSelector((state) => state.audioReducer);
  let isFirstLoad = useSelector((state) => state.firstLoadReducer);
  let syncStatus = useSelector((state) => state.syncStatusReducer);
  let setNavBar = useSelector((state) => state.sideNavBarStatusReducer);
  const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight })
  const [api, contextHolder] = notification.useNotification();
  const showNotification = (type, message) => {
    notification.destroy();
    api[type]({
      message,
    });
  };
  useEffect(() => {
  })

  const logout = async () => {
    try {
      var checkInternet = navigator.onLine;
      if(isSafari || isTablet){
        let audio =  getStoredValue();
        audio != undefined && audio.current?.pause();
        dispatch(togglePlaying(false));
        storeValue(undefined)
       
       }
      dispatch(toogleHomeLoading(true));
      // let data = await getAllFileFromSyncDB();

      if (isRecordingStatus == true) {
        showNotification("error", "Please stop recording");
        dispatch(toogleHomeLoading(false));
      }
      // else if (data.length > 0) {
      //   showNotification("error", "Please sync your data");
      //   dispatch(toogleHomeLoading(false));
      // } 
      else if (checkInternet == false) {
        showNotification("error", "Please check internet connection");
        dispatch(toogleHomeLoading(false));
      } else {
        // dispatch(clearBooks());
        // dispatch(firstLoadExecuted(false));
        dispatch(togglePlaying(false));
        dispatch(toggleCheckFilesCheckbox(false));
        dispatch(toggleRecording(false));
        dispatch(toogleHomeLoading(false));
        dispatch(clearTranScriptedText());
        dispatch(clearBooks())
        dispatch(clearProjects())
        
        localStorage.setItem("loggedIn", "false");
        localStorage.removeItem("isBackTranslation");
        localStorage.removeItem("checkPageMsg");
        await removeUserDetailsFromLocalStorage();
        // await clearAllDataFromObjectStores();
        navigate("/signin");

      }
    } catch (error) {

    }
  };
  const sourcePlay =()=>{
    if(isSafari || isTablet){
      let audio =  getStoredValue();
      audio != undefined && audio.current?.pause();
      dispatch(togglePlaying(false));
      storeValue(undefined)
      navigate("/dashboard")
     }else{
      navigate("/dashboard")
    }
  }

  // const syncData = async () => {
  //   try {
  //     if (window.navigator.onLine == true) {
  //       let msg = await syncAllData();

  //       if (msg == "Files saved to cloud") {
  //         dispatch(toggleSyncStatus(true));
  //         setTimeout(() => {
  //           dispatch(toggleSyncStatus(false));
  //         }, 6000);
  //         showNotification("success", msg);
  //         await waitForAWhile(1200);
  //         localStorage.setItem("loggedIn", "false");
  //         dispatch(firstLoadExecuted(false));
  //         dispatch(toggleSyncStatus(true));
  //         setTimeout(() => {
  //           dispatch(toggleSyncStatus(false));
  //         }, 5000);
  //         navigate("/dashboard");
  //       } else {
  //         showNotification("success", msg);
  //       }
  //     } else {
  //       showNotification("error", "Please check internet connection");
  //     }
  //   } catch (error) {
  //     showNotification("error", "Please contact admin");
  //   }
  // };

  return (
    <>
      <SyncBar />
      <Header className="site-layout-background layout-wrapper">
        <div className="flex justify-between items-center">
          <div className="flex justify-center items-center">
            <span className="nav-icon">
              <RxHamburgerMenu
                className="mr-2"
                size={25} color="white"
                onClick={() => dispatch(sideNavBarStatusReducer(setNavBar))} />
            </span>


            <div onClick={sourcePlay}>
              <img
                src={rhema_logo}
                // style={{ width: "150px", height: "70px" }}
                className="logoImg"
                alt="Rhema"
              />
            </div>
          </div>
          <div className="bg-primary">
            <Menu mode="horizontal">
              {/* <Menu.Item key="sync-outside">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="20"
                  viewBox="0 96 960 960"
                  width="20"
                  onClick={syncData}
                >
                  <path
                    fill={syncStatus == true ? "green" : "gray"}
                    d="m408 778 239-239-48-47-189 189-101-101-48 49 147 149ZM253 921q-97 0-166.5-68.177Q17 784.645 17 687q0-84 51-150.5T201 456q24-100 102.171-162.5Q381.343 231 482.293 231 598 231 680 313.5T770 513v24q75 7 124 60.703Q943 651.407 943 729q0 80-56 136t-136 56H253Zm0-94h496q40.26 0 70.13-29.5Q849 768 849 727t-29.87-70.5Q789.26 627 749 627h-72v-95q0-86.66-57.534-146.33-57.533-59.67-141-59.67Q393 326 335.5 389.5 278 453 278 543h-28q-58.243 0-98.621 40.828Q111 624.657 111 684.828 111 744 152.379 785.5 193.757 827 253 827Zm227-251Z"
                  />
                </svg>
                {syncStatus == true ? (
                  <>
                    {window.navigator.onLine == true ? (
                      <span className="px-2">Saved to cloud</span>
                    ) : (
                      <span className="px-2">Saved to device</span>
                    )}
                  </>
                ) : null}
              </Menu.Item> */}
              <Menu.Item
                key="notification"
                className="ant-header-outer-items-css"
              >
                <FaBell />
              </Menu.Item>

              <Menu.SubMenu
                key="SubMenu"
                title={<FaChevronDown />}
                icon={<FaUser />}
                className="ant-header-outer-items-css"
              >
                <Menu.Item
                  key="profile"
                  icon={<CgProfile />}
                  className="ant-header-items-css"
                >
                  <span> {localStorage.getItem("firstName")}</span>
                </Menu.Item>

                <Menu.Item
                  key="logout"
                  icon={<AiOutlineLogout />}
                  onClick={logout}
                  className="ant-header-items-css"
                >
                  Logout
                </Menu.Item>

                {/* {isFirstLoad == true && (
                  <Menu.Item
                    key="logout"
                    icon={<AiOutlineLogout />}
                    onClick={logout}
                    className="ant-header-items-css"
                  >
                    Logout
                  </Menu.Item>
                )} */}
              </Menu.SubMenu>
            </Menu>
          </div>
        </div>
      </Header>
      {contextHolder}
    </>
  );
};

export default HeaderUIComponent;