export const firstLoad = () => {
  return {
    type: "FIRST_LOAD",
  };
};

export const firstLoadExecuted = (payload) => {
  return {
    type: "FIRST_LOAD_EXECUTED",
    payload,
  };
};

// FOR AUDIO PLAYING
/** @When playing audio, it will disabled recording and other operations like delete etc */
export const togglePlaying = (payload) => {
  return { type: "TOGGLE_PLAYING", payload };
};

// FOR AUDIO RECORDING
/**@When recording audio, it will disabled audio player and other operations like delete etc */
export const toggleRecording = (payload) => {
  return { type: "TOGGLE_RECORDING", payload };
};

// FOR CHUNK AUDIO PLAYLIST
export const toggleSelctedChunkPlaylist = (payload) => {
  return { type: "TOGGLE_PLAYLIST", payload };
};

// FOR HOME LOADING
export const toogleHomeLoading = (payload) => {
  return { type: "TOGGLE_HOME_LOADING", payload };
};

// for text to speech speaking
export const toggleSpeech = (payload) => {
  return { type: "TOGGLE_SPEECH", payload };
};

/**  @VoiceRecognition   */

export const toggleVoiceRecognition = (payload) => {
  return { type: "VOICE_RECOGNITION_STATUS", payload };
};

/** @SyncICON in navbar */
export const toggleSyncStatus = (payload) => {
  return { type: "TOGGLE_SYNC_BUTTON", payload };
};

export const sideNavBarStatusReducer = (payload) => {
  return {
    type: "SET_SIDE_BAR",
    payload,
  };
};

export const tranScriptedText = (payload) => {
  return {
    type: "SET_TRANSCRIPTED_TEXT",
    payload,
  };
};

export const clearTranScriptedText = () => {
  return {
    type: "CLEAR_TRANSCRIPTED_TEXT",
  };
};