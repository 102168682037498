import axios from "axios";
import { pdf_api } from "./apis";
// import { getProjectById } from "./indexed-db/project.indexeddb";

const generatePdf = async (data) => {
  let syncText = await document.getElementById("sync-text");
  const bar = await document.querySelector(".bar--progress");
  return new Promise(async (resolve, reject) => {
    if (window.navigator.onLine == false) {
      syncText.innerText = `Please check internet connection`;
      bar.style.width = "100%";
      setTimeout(() => {
        syncText.innerText = "";
        bar.style.width = "0%";
      }, 1000);
    } else {
      try {
        syncText.innerText = `Please wait, We are downloading pdf file`;
        bar.style.width = "100%";
        let project, pdfFileName, file;

        // let project = await getProjectById(data.projectId);
        if (localStorage.getItem("role") == "Coordinator") {
          let res = await axios.post(pdf_api.draftByChapter, {
            projectName: data.projectName,
            bookName: data.bookName,
            chapter: data.chapterName,
            role: localStorage.getItem("role"),
            chaptersData: data.chaptersData
          });

          pdfFileName = `${data.projectName}-${data.bookName}-${data.chapterName}`;
          file = encodeURI(`${pdf_api.download_pdf}?name=${pdfFileName}`);
        } else {
          project = await data.project;
          let res = await axios.post(pdf_api.draftByChapter, {
            bookName: data.bookName,
            chapter: data.currentChapter,
            language: project.language,
            projectId: data.projectId,
            targetType: "draft",
            role: localStorage.getItem("role"),
            majorLanguage1: project.majorLanguage1,
            majorLanguage2: project.majorLanguage2,
            majorLanguage3: project.majorLanguage3,
            projectName: project.projectName,
          });

          pdfFileName = `${project.projectName}-${data.bookName}-${data.currentChapter}`;
          file = encodeURI(`${pdf_api.download_pdf}?name=${pdfFileName}`);

        }

        let elem = document.createElement("a");
        elem.href = file;
        elem.click();

        setTimeout(() => {
          syncText.innerText = "";
          bar.style.width = "0%";
        }, 1000);
      } catch (error) {
        syncText.innerText = `Please try after some time`;
        bar.style.width = "100%";
        setTimeout(() => {
          syncText.innerText = "";
          bar.style.width = "0%";
        }, 2000);
      }
    }
  });
};

const generateTranslatedPdf = async (data, conversionLanguage) => {
  let syncText = await document.getElementById("sync-text");
  const bar = await document.querySelector(".bar--progress");
  return new Promise(async (resolve, reject) => {
    if (window.navigator.onLine == false) {
      syncText.innerText = `Please check internet connection`;
      bar.style.width = "100%";
      setTimeout(() => {
        syncText.innerText = "";
        bar.style.width = "0%";
      }, 1000);
      resolve();
    } else {
      try {
        syncText.innerText = `Please wait, We are downloading pdf file`;
        bar.style.width = "100%";
        let project, pdfFileName, file;
        project = await data.project;
        let res = await axios.post(pdf_api.translatedFile, {
          bookName: data.bookName,
          chapter: data.currentChapter,
          language: project.language,
          projectId: data.projectId,
          role: localStorage.getItem("role"),
          majorLanguage1: project.majorLanguage1,
          majorLanguage2: project.majorLanguage2,
          majorLanguage3: project.majorLanguage3,
          projectName: project.projectName,
          conversionLanguage,
        });

        pdfFileName = `${project.projectName}-${data.bookName}-${data.currentChapter}`;
        file = encodeURI(
          `${pdf_api.download_pdf}?name=${pdfFileName}-translated`
        );

        let elem = document.createElement("a");
        elem.href = file;
        elem.click();
        resolve();
        setTimeout(() => {
          syncText.innerText = "";
          bar.style.width = "0%";
        }, 1000);
      } catch (error) {
        syncText.innerText = `Please try after some time`;
        bar.style.width = "100%";
        setTimeout(() => {
          syncText.innerText = "";
          bar.style.width = "0%";
        }, 2000);
        resolve();
      }
    }
  });
};

export { generatePdf, generateTranslatedPdf };
