import { Button, Modal, Tooltip } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { s3_files_url } from "../../../services/apis";
import FilenameComponent from "../../ui/filename.component";

const DraftScriptModal = ({ name, data, isDisabled }) => {
  const [isShow, setIsShow] = useState(false);
  const [scriptData, setScriptData] = useState({});
  const [scriptText, setScriptText] = useState("");

  useEffect(() => {
    setScriptData(data);
    loadText();
  }, [data,isShow]);

  const loadText = async () => {
    try {
      let scriptContent
      try {
        scriptContent = await window.api.readFile("readFile", data);
      } catch (error) {
      }

      if (scriptContent) {
        setScriptText(scriptContent);
      } else {
        let res = await axios.get(
          `${s3_files_url}/${data?.fileId}/${data?.fileName}`
        );
        setScriptText(res.data);
      }
    } catch (error) {
      console.log(error)
      setScriptText("");
    }
  };
  const closeModal = () => {
    setIsShow(false);
  };

  return (
    <>
      <Tooltip title="View">
        <Button
          disabled={isDisabled}
          onClick={(e) => {
            setIsShow(true);
          }}
        >
          <FaEye size={20} />
        </Button>
      </Tooltip>
      <Modal
        title={
          <span className="  ">
            <FilenameComponent file={data} />
          </span>
        }
        visible={isShow}
        width={900}
        onCancel={closeModal}
        footer={null}
      >
        <p
          className="break-spaces"
          dangerouslySetInnerHTML={{ __html: scriptText }}
        ></p>
      </Modal>
    </>
  );
};
export default DraftScriptModal;
