
// const base_url = "http://localhost:5000/api";
//const base_url = "https://rhemaapi.olivetech.com/api";
//const base_url = "https://beta.api.rhema.app/api";
const base_url = "https://api.rhema.app/api";  // PROD API
/**
 * * AWS URL
 */
// const s3_url = "https://s3.us-east-2.amazonaws.com/rhema-bucket-dev/";
// const s3_files_url =
//   "https://s3.us-east-2.amazonaws.com/rhema-bucket-dev/rhema-files";
// const s3_video_url =
//   "https://s3.us-east-2.amazonaws.com/rhema-bucket-dev/rhema-video-files";

  /** PROD AWS URLS**/

const s3_url = "https://s3.us-east-2.amazonaws.com/prod-audio-bucket/";
const s3_files_url = "https://s3.us-east-2.amazonaws.com/prod-audio-bucket/rhema-prod-files";
const s3_video_url = "https://s3.us-east-2.amazonaws.com/prod-audio-bucket/rhema-video-files";
const s3_support_url ="https://s3.us-east-2.amazonaws.com/prod-audio-bucket/rhema-support-files";

//NO More used
// const s3_url = "https://s3.us-east-2.amazonaws.com/prod-desktop-bucket/";
// const s3_files_url = "https://s3.us-east-2.amazonaws.com/prod-desktop-bucket/prod-audio-files";
// const s3_video_url = "https://s3.us-east-2.amazonaws.com/prod-desktop-bucket/prod-video-files";
// const s3_support_url ="https://s3.us-east-2.amazonaws.com/prod-desktop-bucket/prod-support-files"

/**
 * * API
 */

const bible = {
  bible: "/bible",
  // getBooksDetails: "/getBooksDetails",
  getFileByVersesName: "/bible/getFileByVersesName",
  getChunkProgressData: "/getChunkProgressData",
  getRemainingDays: "/getRemainingDays",
  getSingleBookProgress: "/getSingleBookProgress",
  getAllChaptersByBookName: "/getAllChaptersByBookName",
};

const projects = {
  project: "/project",
  projectByRole: "/getProjectsByrole",
  projectsByUserId:"/getProjectsByUserId"
};

const desktop = {
  dt: "/v2/dt",
  getBooksDetails: "/get-books-details-new",
  getAllProgressWeb:"/getAllProgressWeb",
  getAllBookProgressWeb:"/getAllBookProgressWeb",
  getAllChunkProgressWeb:"/getAllChunkProgressWeb",
  uploadDraftFiles: "/upload-draft-file",
  getTecnicianBookList: "/get-technician-books-list",
  getChatbotFilesByChapter: `${base_url}/v2/dt/chatbot-file-by-chapter`,
  syncFilesToOneDrive : "/uploadConsultantCitApprovedDraftedFilesToOneDrive",
  bardApi :"/chatbot-bardapi",
  chatBotApi :"/chatbot-gptapi",
};

/*** *AUTH ROUTE */

let auth_route = {
  login: `${base_url}/auth/native/login`,
  reset_password: `${base_url}/users/admin-resetPassword`,
  sign_up: `${base_url}/users/send-mail`,
};

/**
 * * DRAFT FILE
 */

let draftFile = {
  uploadDraftFiles: `${base_url}/v2/dt/upload-draft-file`,
  selectDraftFile: `${base_url}/v2/dt/select-draft-file`,
  selectDraftedFile: `${base_url}/v2/dt/select-drafted-file`,
  updateDraftFile: `${base_url}/v2/dt/update-draft-file`,
  updateDraftedFile: `${base_url}/v2/dt/update-drafted-file`,
  deleteDraftFile: `${base_url}/v2/dt/delete-draft-file`,
  deleteDraftedFile: `${base_url}/v2/dt/delete-drafted-file`,
  getDraftedFile: `${base_url}/file/getDraftedFiles`,
  getAllDraftedFiles: `${base_url}/v2/dt/get-all-drafted-files`,
  getDraftedFilesByChunksOnline: `${base_url}/file/getDraftedFilesByChunksOnline`,
  getDraftedFileByFileId:`${base_url}/file/getDraftedFileByFileId`,
  getDraftedFilesByBookNameOnline:`${base_url}/file/getDraftedFilesByBookNameOnline`
};

let pdf_api = {
  draftByChapter: `${base_url}/file/getScriptDraftedFilesListByChapter`,
  download_pdf: `${base_url}/file/downloadPDF`,
  translatedFile: `${base_url}/file/getTranslatedFiles`
};

// dashboard API:

let dashboard = {
  dashboard: "/dashboard",
  getRemainingDays: "/getRemainingDays",
};

// suppoet API:

let support = {
  createRequest: `${base_url}/support/create-support-request`,
};

/**
 * * Comment API
 */
let commentApi = {
  saveComment: `${base_url}/v2/dt/save-comment`,
  savedComment: `${base_url}/v2/dt/saved-comment`,
  deleteComment: `${base_url}/v2/dt/delete-comment`,
  deletedComment: `${base_url}/v2/dt/deleted-comment`,
  updateComment: `${base_url}/v2/dt/update-comment`,
  updatedComment: `${base_url}/v2/dt/updated-comment`,
  updateToDoCommentToServer:`${base_url}/comment/updateToDoCommentToServer`,
  createToDoComment:`${base_url}/comment/createToDoComment`,
  getToDoCommentFromServer:`${base_url}/comment/getToDoCommentFromServer`,
  checkToDoReviewComment:`${base_url}/comment/checkToDoReviewComment`
};

let checkType = `${base_url}/v2/dt/status-check`;
let citConCheckType = `${base_url}/v2/dt/cit-status-check`;

let chatbot_api = {
  getChatbotFilesByChapter: `${base_url}/chatbot/getChatbotFilesByChapter`,
  getFileByVersesName: `${base_url}/chatbot/getChatbotFileByVersesName`,
  getChatbotLanguage: `${base_url}/chatbot/getChatbotLanguages`,
  getSingleChunkChatbot: `${base_url}/chatbot/get-chatbot-chunk`,
};

let speech_api = {
  index: `${base_url}/v2/speech/speech-to-text`,
};

export {
  base_url,
  s3_url,
  s3_files_url,
  auth_route,
  bible,
  projects,
  desktop,
  draftFile,
  s3_video_url,
  commentApi,
  dashboard,
  support,
  checkType,
  citConCheckType,
  chatbot_api,
  pdf_api,
  speech_api,
};
