import { Button, Tooltip, notification } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaPause } from "react-icons/fa";
import { BsPlayCircle } from "react-icons/bs";
import { s3_files_url } from "../../services/apis";
import Moment from "moment";
import { UNSAFE_NavigationContext } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { togglePlaying } from "../../redux/actions/util.action";
import WaveSurfer from "wavesurfer.js";
import {isTablet,isSafari,isMobile} from 'react-device-detect';
import SmallSpinnerComponent from "../../components/spinner/small-spinner.component";
import Waveform from "../waveform/waveform.component";
import { storeValue } from "../../services/config";
const AudioPlayer = ({
  data,
  fileName,
  audioType,
  isDisabled,
  revokeOtherFunctions,
  isReverse,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [startTimer, setStartTimer] = useState(false);
  const navigator = useContext(UNSAFE_NavigationContext).navigator;
  const [secs, setSecs] = useState(0);
  const audioRef = useRef();
  const countRef = useRef(null);
  const isPlayingRef = useRef(false);
  const setTimeoutRef = useRef();
  const dispatch = useDispatch();
  const [isAudioLoading, setIsAudioLoading] = useState(false);
  const isRecording = useSelector((state) => state.recordingStatusReducer);
  const isPlayingStatus = useSelector((state) => state.playingStatusReducer);
  const [api, contextHolder] = notification.useNotification();
  const [count, setCount] = useState(0);
  const lastPlayedAt = useRef(Number(0));
  const [endingTime, setEndingTime] = useState(0);
  const showNotification = (type, message) => {
    notification.destroy();
    api[type]({
      message,
    });
  };
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [totalTime, setTotalTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);

  useEffect(() => {
    window.addEventListener("hashchange", async () => {
      await pauseAudio();
    });

    const listener = ({ location, action }) => {
      if (action === "PUSH") {
        pauseAudio();
      }
    };
    loadAudio();
  }, [setIsPlaying, navigator, fileName]);

  useEffect(() => {
    if (count === 0 && audioType!="COMMENTFILE" && !isTablet && !isSafari ) {
      getWaveForm();
    }
  }, [count,fileName]);

  // Create the waveform instance once
  useEffect(() => {
    // if (!wavesurfer.current) {
    //   wavesurfer.current = WaveSurfer.create(formWaveSurferOptions(waveformRef.current));
    // }
  }, []);

  const loadAudio = async () => {
    try {
      if(audioType == "COMMENTFILE" && !isTablet && !isSafari){

        getWaveForm();
      }
      
      audioRef.current.src = `${s3_files_url}/${data.fileId}/${data.fileName}`;
      // audioRef.current.controlsList = "nodownload";
    } catch (error) {}
  };
  const getWaveForm = async () => {
    let url = `${s3_files_url}/${data.fileId}/${data.fileName}`;
    if (!wavesurfer.current) {
      const options = await formWaveSurferOptions(waveformRef.current);
      wavesurfer.current = WaveSurfer.create(options);
    }
    wavesurfer.current.load(url);
  };

  const formWaveSurferOptions = (ref) => ({
    container: ref,
    waveColor: "#708090",
    progressColor: "#11172b",
    cursorColor: "black",
    barWidth: 0,
    barRadius: 2,
    responsive: true,
    height: 30,
    normalize: true,
    partialRender: true,
  });

  const playAudio = async (data) => {
    try {
      setCount(1);
      // checking other audio is being played or not
      if (isPlayingStatus == true) {
        showNotification("error", " Please pause current playing audio");
      }
      // checking status of recording
      else if (isRecording == true) {
        showNotification("error", "Please stop recording");
      } else {
        if (revokeOtherFunctions) revokeOtherFunctions(true);
        console.log("isTablet isSafari",isTablet,isSafari);
        if(isTablet || isSafari){
          await pauseAudio();
          dispatch(togglePlaying(true));
          console.log("in manual play");
         
          audioRef.current.src = `${s3_files_url}/${data.fileId}/${data.fileName}`;
          await play(`${s3_files_url}/${data.fileId}/${data.fileName}`);
        }else{
        dispatch(togglePlaying(true));
        isPlayingRef.current = true;
        setIsPlaying(true);
        setIsAudioLoading(true);
        let url = `${s3_files_url}/${data.fileId}/${data.fileName}`;
        setIsAudioLoading(false);
        setStartTimer(true);

          if (!wavesurfer.current.isPlaying()) {
            try {
              await wavesurfer.current.playPause();
            } catch (error) {
              console.log("ERROR", error);
            }
          }
  
          wavesurfer.current.on("audioprocess", async function () {
            if (wavesurfer.current.isPlaying()) {
              const totalTimeValue = await wavesurfer.current.getDuration();
              const currentTimeValue = await wavesurfer.current.getCurrentTime();
              const remainingTimeValue = await totalTimeValue - currentTimeValue;
  
              setTotalTime(Math.floor(totalTimeValue));
              setCurrentTime(Math.floor(currentTimeValue));
              setRemainingTime(Math.floor(remainingTimeValue));
            }
          });
          wavesurfer.current.on("finish", async function () {
            setIsPlaying(false);
            setStartTimer(true);
            setTotalTime(0);
            setCurrentTime(0);
            setCount(0)
            wavesurfer.current.destroy();
            wavesurfer.current=null;
            const options = await formWaveSurferOptions(waveformRef.current);
            wavesurfer.current = await WaveSurfer.create(options);
            wavesurfer.current.load(url);
            dispatch(togglePlaying(false));
            if (revokeOtherFunctions) revokeOtherFunctions(false);
            isPlayingRef.current = false;
            // setPlay(!playing);
          });
        }
        
      }
    } catch (error) {
      console.log(error);
      runOnError();
    }
  };

  const play = async(value) => {
    return new Promise(async (resolve, reject) => {
      try {
        // initialize audio file and start playing
        audioRef.current.currentTime=lastPlayedAt.current;
        
        await audioRef.current.play();
        storeValue(audioRef)
        setIsPlaying(true);
        setSecs(lastPlayedAt.current);
        // audioRef.current.currentTime = 0;

        // start timer
        countRef.current = setInterval(() => {
          setSecs((secs) => secs + 1);
          lastPlayedAt.current = Number(lastPlayedAt.current) + Number(1);
        }, 1000);

        isPlayingRef.current = true;

        //calculate total time of audio
        let timePlay = await Math.floor(audioRef.current.duration);
        setEndingTime(timePlay);

        // Audio will stop automatically on completion
        setTimeoutRef.current = setTimeout(async () => {
          if (isPlayingRef.current == true) {
            await pauseAudio();
          }
        }, (timePlay - lastPlayedAt.current+1 > 0 ? timePlay - lastPlayedAt.current+1 : 0) * 1000);

        // await toggleWavefrom();
      } catch (error) {
        console.log(error);
        await runOnError();
      }
    });
  };

  // const play = async(value) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       // initialize audio file and start playing
  //       await audioRef.current.play();
  //       setIsPlaying(true);
  //       setSecs(lastPlayedAt.current);
  //       // audioRef.current.currentTime = 0;

  //       // start timer
  //       countRef.current = setInterval(() => {
  //         setSecs((secs) => secs + 1);
  //         lastPlayedAt.current = Number(lastPlayedAt.current) + Number(1);
  //       }, 1000);

  //       isPlayingRef.current = true;

  //       //calculate total time of audio
  //       let timePlay = await Math.floor(audioRef.current.duration);
  //       setEndingTime(timePlay);

  //       // Audio will stop automatically on completion
  //       setTimeoutRef.current = setTimeout(async () => {
  //         if (isPlayingRef.current == true) {
  //           await pauseAudio();
  //         }
  //       }, (timePlay - lastPlayedAt.current > 0 ? timePlay - lastPlayedAt.current : 0) * 1000);

  //       // await toggleWavefrom();
  //     } catch (error) {
  //       console.log(error);
  //       await runOnError();
  //     }
  //   });
  // };

  const runOnError = async () => {
    return new Promise(async (resolve, reject) => {
      if (revokeOtherFunctions) revokeOtherFunctions(false);
      showNotification(
        "error",
        "Error while loading file, Please contact admin"
      );
      setIsPlaying(false);
      isPlayingRef.current = false;
      clearInterval(countRef.current);
      dispatch(togglePlaying(false));
      clearTimeout(setTimeoutRef.current);
      setSecs(0);
      resolve();
    });
  };

  const pauseAudio = () => {
    return new Promise(async (resolve, reject) => {
      if (isPlayingRef.current == true) {
        setIsPlaying(false);
        if(!isTablet && !isSafari){
          await wavesurfer.current.pause();
          isPlayingRef.current = false;
          clearInterval(countRef.current);
          dispatch(togglePlaying(false));
          clearTimeout(setTimeoutRef.current);
          if (revokeOtherFunctions) revokeOtherFunctions(false);
          resolve();
        }else{
          audioRef.current?.pause();
        // audioRef.current.currentTime = 0;
        isPlayingRef.current = false;
        clearInterval(countRef.current);
        dispatch(togglePlaying(false));
        clearTimeout(setTimeoutRef.current);
        if (revokeOtherFunctions) revokeOtherFunctions(false);

        if (
          Number(lastPlayedAt.current) == Math.floor(audioRef.current.duration)
        ) {
          setSecs(0);
          lastPlayedAt.current = 0;
          audioRef.current.currentTime = 0;
        }

        resolve();
        }

        // isPlayingRef.current = false;
        // clearInterval(countRef.current);
        // dispatch(togglePlaying(false));
        // clearTimeout(setTimeoutRef.current);
        // if (revokeOtherFunctions) revokeOtherFunctions(false);
        // resolve();

        // if (
        //   Number(lastPlayedAt.current) == Math.floor(audioRef.current.duration)
        // ) {
        //   setSecs(0);
        //   lastPlayedAt.current = 0;
        //   audioRef.current.currentTime = 0;
        // }
      } else {
        if (revokeOtherFunctions) revokeOtherFunctions(false);
        dispatch(togglePlaying(false));
        resolve();
      }
    });
  };

  return <>
  {!isTablet && !isSafari ?
    <div
    className={`flex items-center w-full${isReverse == true ? "flex-reverse" : ""}`}
  >
    {contextHolder}
    {audioType != "COMMENTFILE" ? (
      <>
        {startTimer && (
          <div
            className={`text-center flex items-center w-25`}
            // ${data?.isSelected == true
            //   ? "pr-12"
            //   : localStorage.getItem("role") == "Consultant" ||
            //     localStorage.getItem("role") == "CIT"
            //     ? "pr-10"
            //     : "pr-44"
            //   }`}
          
          >
            {isPlaying &&
            <>
            <div className="mx-2">
              <span className="flex row">
                {Moment.utc(currentTime * 1000).format("mm:ss")} /{Moment.utc(totalTime * 1000).format("mm:ss")}
                
              </span>
            </div>
          </>
           } 
            
          </div>
        )}
      </>
    ) : null}

    {audioType == "COMMENTFILE" ? (
      <div className="flex items-center">
        {isPlaying && (
          <div className="mx-2">
            <span>  {Moment.utc(currentTime * 1000).format("mm:ss")}</span>
          </div>
        )}
        <div className="text-center flex item-center">
          {isPlaying && (
            <>
              {/* <Waveform
                className="mx-2"
                lines={audioType == "COMMENTFILE" ? 10 : 140}
              /> */}
            </>
          )}
        </div>
      </div>
    ) : null}

    <div className="flex items-center w-full">
        <div className="container">
          <div id="waveform" ref={waveformRef} />
        </div>

      {isPlaying ? (
        <Tooltip placement="topLeft" title="Pause">
          <Button
            onClick={(e) => {
              pauseAudio();
            }}
            className="ant-btn-foucs"
          >
            <FaPause size={16} />
          </Button>
        </Tooltip>
      ) : (
        <Tooltip placement="topLeft" title="Play">
          <Button

            disabled={isDisabled || isRecording || isPlayingStatus}
            onClick={(e) => {
              playAudio(data);
            }}
            className="hover"
          >
            <BsPlayCircle size={24} />
          </Button>
        </Tooltip>
      )}
    </div>
  </div> : 
  <div
  className={`flex items-center ${isReverse == true ? "flex-reverse" : ""}`}
>
  {contextHolder}
  {audioType != "COMMENTFILE" ? (
    <>
    <>{console.log("Iplaing:",isPlaying)}</>
      {isPlaying && (
        <div
          className={`text-center flex items-center ${data?.isSelected == true
              ? "pr-12"
              : localStorage.getItem("role") == "Consultant" ||
                localStorage.getItem("role") == "CIT"
                ? "pr-12"
                : "pr-44"
            }`}
        >
          <>
            <div className="mx-2">
              <span>             
                {Moment.utc(secs * 1000).format("mm:ss")} /
                {Moment.utc(endingTime * 1000).format("mm:ss")}
              </span>
            </div>
            <Waveform
              className="mx-2"
              lines={
                audioType == "COMMENTFILE"
                  ? 10
                  : isTablet ? 50 : isMobile ? 10 : 140
              }
            />
          </>
        </div>
      )}
    </>
  ) : null}

  {audioType == "COMMENTFILE" ? (
    <div className="flex items-center">
      {isPlaying && (
        <div className="mx-2">
          <span> {Moment.utc(secs * 1000).format("mm:ss")} </span>
        </div>
      )}
      <div className="text-center flex item-center">
        {isPlaying && (
          <>
            <Waveform
              className="mx-2"
              lines={audioType == "COMMENTFILE" ? 10 : 140}
            />
          </>
        )}
      </div>
    </div>
  ) : null}

  <div>
    {isPlaying ? (
      <Tooltip placement="topLeft" title="Pause">
        <Button
          onClick={(e) => {
            pauseAudio();
          }}
          className="ant-btn-foucs"
        >
          <FaPause size={16} />
        </Button>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title="Play">
        <Button
          disabled={isDisabled || isRecording || isPlayingStatus}
          onClick={(e) => {
            playAudio(data);
          }}
          className="hover"
        >
          <BsPlayCircle size={24} />
        </Button>
      </Tooltip>
    )}

    <audio id="audio_player" ref={audioRef} />
  </div>
</div>
  }
  </>
};

export default AudioPlayer;

// import { Button, Tooltip, notification } from "antd";
// import React, { useContext, useEffect, useRef, useState } from "react";
// import { FaPause, FaPlay } from "react-icons/fa";
// import { BsPlayCircle } from "react-icons/bs";
// import { s3_files_url } from "../../services/apis";
// import Moment from "moment";
// import Waveform from "../waveform/waveform.component";
// import { useLocation } from "react-router";
// import { UNSAFE_NavigationContext } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { togglePlaying } from "../../redux/actions/util.action";
// import { waitForAWhile } from "../../services/config";
// // import { getFileChunks } from "../../services/indexed-db/files.indexeddb";
// // import { unitArrayToBlob } from "../../services/conversion.service";

// const AudioPlayer = ({
//   data,
//   fileName,
//   audioType,
//   isDisabled,
//   revokeOtherFunctions,
//   isReverse,
// }) => {
//   const [isPlaying, setIsPlaying] = useState(false);
//   const navigator = useContext(UNSAFE_NavigationContext).navigator;
//   const [secs, setSecs] = useState(0);
//   const [endingTime, setEndingTime] = useState(0);
//   const lastPlayedAt = useRef(Number(0));
//   const audioRef = useRef();
//   const countRef = useRef(null);
//   const isPlayingRef = useRef(false);
//   const setTimeoutRef = useRef();
//   const dispatch = useDispatch();

//   const isRecording = useSelector((state) => state.recordingStatusReducer);
//   const isPlayingStatus = useSelector((state) => state.playingStatusReducer);
//   const [api, contextHolder] = notification.useNotification();
//   const showNotification = (type, message) => {
//     notification.destroy();
//     api[type]({
//       message,
//     });
//   };

//   const tagId = useRef("15");
//   let j = 0;
//   let removedElement = 0;

//   useEffect(() => {
//     window.addEventListener("hashchange", async () => {
//       await pauseAudio();
//     });

//     const listener = ({ location, action }) => {
//       if (action === "PUSH") {
//         pauseAudio();
//       }
//     };
//     loadAudio()
//     // fetchData();
//     // const unlisten = navigator.listen(listener);
//     // return unlisten;
//   }, [setIsPlaying, navigator, fileName]);

//   // const fetchData = async () => {
//   //   try {
//   //     let content = await getFileChunks(data.fileName);
//   //     if (content.length > 0) {
//   //       const blob = await new Blob(content, {
//   //         type: "audio/mp3",
//   //       });
//   //       let v = await URL.createObjectURL(blob);
//   //       audioRef.current.src = v;
//   //     }
//   //   } catch (error) {}
//   // };


//   const loadAudio = () => {
//     try {
//       audioRef.current.src = `${s3_files_url}/${data.fileId}/${data.fileName}`;
//       audioRef.current.controlsList = "nodownload"
//     } catch (error) {

//     }
//   }
//   // const playAudio = async (data) => {
//   //   try {
//   //     console.log("THE DATA", data);
//   //     // checking other audio is being played or not
//   //     if (isPlayingStatus == true) {
//   //       showNotification("error", " Please pause current playing audio");
//   //     }
//   //     // checking status of recording
//   //     else if (isRecording == true) {
//   //       showNotification("error", "Please stop recording");
//   //     } else {
//   //       // first pause the other audio
//   //       await pauseAudio();
//   //       if (revokeOtherFunctions) revokeOtherFunctions(true);
//   //       dispatch(togglePlaying(true));
//   //       // let localData = await window.api.readFile("readFile", {
//   //       //   fileId: data.fileId,
//   //       //   fileName: data.fileName,
//   //       // });
//   //       // let content = await unitArrayToBlob(localData, data.fileType);
//   //       // if (content.length > 0) {
//   //       //   audioRef.current.src = content;
//   //       //   await play(content);
//   //       // } else {
//   //       let url = `${s3_files_url}/${data.fileId}/${data.fileName}`;
//   //       console.log("THE URL", url);
//   //       // audioRef.current.src = url;
//   //       await play(url);
//   //       // }
//   //     }
//   //   } catch (error) {
//   //     console.log(error);
//   //     runOnError();
//   //   }
//   // };

//   // const play = (value) => {
//   //   return new Promise(async (resolve, reject) => {
//   //     try {
//   //       // initialize audio file and start playing
//   //       audioRef.current = new Audio(value);
//   //       await audioRef.current.load();

//   //       // audioRef.current.src = value;
//   //       await audioRef.current.play();
//   //       setIsPlaying(true);
//   //       setSecs(lastPlayedAt.current);
//   //       audioRef.current.currentTime = 0;

//   //       console.log(audioRef.current)
//   //       // start timer
//   //       countRef.current = setInterval(() => {
//   //         setSecs((secs) => secs + 1);
//   //         lastPlayedAt.current = Number(lastPlayedAt.current) + Number(1);
//   //       }, 1000);

//   //       isPlayingRef.current = true;
//   //       //calculate total time of audio
//   //       console.log("audioRef.current.duration", audioRef.current.duration)

//   //       // let timePlay = Math.floor(audioRef.current.duration);
//   //       // setEndingTime(timePlay);

//   //       // // Audio will stop automatically on completion
//   //       // setTimeoutRef.current = setTimeout(async () => {
//   //       //   if (isPlayingRef.current == true) {
//   //       //     await pauseAudio();
//   //       //   }
//   //       // }, (timePlay - lastPlayedAt.current > 0 ? timePlay - lastPlayedAt.current : 0) * 1000);

//   //       // await toggleWavefrom();
//   //     } catch (error) {
//   //       console.log(error);
//   //       await runOnError();
//   //     }
//   //   });
//   // };

//   const playAudio = async (data) => {
//     try {
//       // checking other audio is being played or not
//       if (isPlayingStatus == true) {
//         showNotification("error", " Please pause current playing audio");
//       }
//       // checking status of recording
//       else if (isRecording == true) {
//         showNotification("error", "Please stop recording");
//       } else {
//         // first pause the other audio
//         await pauseAudio();
//         if (revokeOtherFunctions) revokeOtherFunctions(true);
//         dispatch(togglePlaying(true));
//         if(window.navigator.onLine == true){
//           audioRef.current.src = `${s3_files_url}/${data.fileId}/${data.fileName}`;

//           // Perform audio playbackjasgd
//           await play(`${s3_files_url}/${data.fileId}/${data.fileName}`);
//         }
//         // else{
//         //   let localData = await window.api.readFile("readFile", {
//         //     fileId: data.fileId,
//         //     fileName: data.fileName,
//         //   });
//         //   let content = await unitArrayToBlob(localData, data.fileType);
//         //   audioRef.current.src = content;
//         //   await play(content);
//         // }
        
//       }
//     } catch (error) {
//       console.log(error);
//       runOnError();
//     }
//   };

//   const play = (value) => {
//     return new Promise(async (resolve, reject) => {
//       try {
//         // initialize audio file and start playing
//         await audioRef.current.play();
//         setIsPlaying(true);
//         setSecs(lastPlayedAt.current);
//         // audioRef.current.currentTime = 0;

//         // start timer
//         countRef.current = setInterval(() => {
//           setSecs((secs) => secs + 1);
//           lastPlayedAt.current = Number(lastPlayedAt.current) + Number(1);
//         }, 1000);

//         isPlayingRef.current = true;

//         //calculate total time of audio
//         let timePlay = Math.floor(audioRef.current.duration);
//         setEndingTime(timePlay);

//         // Audio will stop automatically on completion
//         setTimeoutRef.current = setTimeout(async () => {
//           if (isPlayingRef.current == true) {
//             await pauseAudio();
//           }
//         }, (timePlay - lastPlayedAt.current > 0 ? timePlay - lastPlayedAt.current : 0) * 1000);

//         // await toggleWavefrom();
//       } catch (error) {
//         console.log(error);
//         await runOnError();
//       }
//     });
//   };


//   const runOnError = async () => {
//     return new Promise(async (resolve, reject) => {
//       if (revokeOtherFunctions) revokeOtherFunctions(false);
//       showNotification(
//         "error",
//         "Error while loading file, Please contact admin"
//       );
//       setIsPlaying(false);
//       isPlayingRef.current = false;
//       clearInterval(countRef.current);
//       dispatch(togglePlaying(false));
//       clearTimeout(setTimeoutRef.current);
//       setSecs(0);

//       audioRef.current?.pause();
//       audioRef.current.currentTime = 0;

//       resolve();
//     });
//   };

//   const pauseAudio = () => {
//     return new Promise(async (resolve, reject) => {
//       if (isPlayingRef.current == true) {
//         setIsPlaying(false);
//         audioRef.current?.pause();
//         // audioRef.current.currentTime = 0;
//         isPlayingRef.current = false;
//         clearInterval(countRef.current);
//         dispatch(togglePlaying(false));
//         clearTimeout(setTimeoutRef.current);
//         if (revokeOtherFunctions) revokeOtherFunctions(false);

//         if (
//           Number(lastPlayedAt.current) == Math.floor(audioRef.current.duration)
//         ) {
//           setSecs(0);
//           lastPlayedAt.current = 0;
//           audioRef.current.currentTime = 0;
//         }
//         resolve();
//       } else {
//         if (revokeOtherFunctions) revokeOtherFunctions(false);
//         dispatch(togglePlaying(false));
//         resolve();
//       }
//     });
//   };

//   // const play = (value) => {
//   //   return new Promise(async (resolve, reject) => {
//   //     try {
//   //       // load file
//   //       audioRef.current = new Audio(value);
//   //       // initialize audio file and start playing
//   //       await audioRef.current.load();
//   //       await audioRef.current.play();

       
//   //       setIsPlaying(true);
//   //       // start timer
//   //       countRef.current = setInterval(() => {
//   //         setSecs((secs) => secs + 1);
//   //       }, 1000);
//   //       isPlayingRef.current = true;
//   //       //calculate total time of audio
//   //       let timePlay = Math.floor(audioRef.current.duration);
//   //       setEndingTime(timePlay);
//   //       // Audio will stop automatically on completion
//   //       setTimeoutRef.current = setTimeout(async () => {
//   //         if (isPlayingRef.current == true) {
//   //           await pauseAudio();
//   //         }
//   //       }, timePlay * 1000);
//   //     } catch (error) {
//   //       console.log(error);
//   //       await runOnError();
//   //     }
//   //   });
//   // };
//   // const runOnError = async () => {
//   //   return new Promise(async (resolve, reject) => {
//   //     if (revokeOtherFunctions) revokeOtherFunctions(false);
//   //     showNotification(
//   //       "error",
//   //       "Error while loading file, Please contact admin"
//   //     );
//   //     setIsPlaying(false);
//   //     isPlayingRef.current = false;
//   //     clearInterval(countRef.current);
//   //     dispatch(togglePlaying(false));
//   //     clearTimeout(setTimeoutRef.current);
//   //     setSecs(0);

//   //     audioRef.current?.pause();
//   //     audioRef.current.currentTime = 0;

//   //     resolve();
//   //   });
//   // };

//   // const pauseAudio = () => {
//   //   return new Promise(async (resolve, reject) => {
//   //     if (isPlayingRef.current == true) {
//   //       setIsPlaying(false);
//   //       audioRef.current?.pause();
//   //       // audioRef.current.currentTime = 0;
//   //       isPlayingRef.current = false;
//   //       clearInterval(countRef.current);
//   //       dispatch(togglePlaying(false));
//   //       clearTimeout(setTimeoutRef.current);
//   //       if (revokeOtherFunctions) revokeOtherFunctions(false);
//   //       console.log(" Number(lastPlayedAt.current): ", Number(lastPlayedAt.current))
//   //       console.log(" Math.floor(audioRef.current.duration) ",audioRef.current.duration)
//   //       if (
//   //         Number(lastPlayedAt.current) == Math.floor(audioRef.current.duration)
//   //       ) {
//   //         setSecs(0);
//   //         lastPlayedAt.current = 0;
//   //         audioRef.current.currentTime = 0;
//   //       }
//   //       resolve();
//   //     } else {
//   //       if (revokeOtherFunctions) revokeOtherFunctions(false);
//   //       dispatch(togglePlaying(false));
//   //       resolve();
//   //     }
//   //   });
//   // };

//   return (
//     // <div
//     //   className={`flex items-center ${isReverse == true ? "flex-reverse" : ""}`}
//     // >
//     //   {contextHolder}
//     //   {audioType != "COMMENTFILE" ? (
//     //     <>
//     //       {isPlaying && (
//     //         <div
//     //           className={`text-center flex items-center ${data?.isSelected == true
//     //             ? "pr-12"
//     //             : localStorage.getItem("role") == "Consultant" ||
//     //               localStorage.getItem("role") == "CIT"
//     //               ? "pr-12"
//     //               : "pr-44"
//     //             }`}
//     //         >
//     //           <>
//     //             <div className="mx-2">
//     //               <span>
//     //                 {Moment.utc(secs * 1000).format("mm:ss")} /
//     //                 {Moment.utc(endingTime * 1000).format("mm:ss")}
//     //               </span>
//     //             </div>
//     //             <Waveform
//     //               className="mx-2"
//     //               lines={
//     //                 audioType == "COMMENTFILE"
//     //                   ? 10
//     //                   : localStorage.getItem("role") == "Consultant" ||
//     //                     localStorage.getItem("role") == "CIT"
//     //                     ? 140
//     //                     : 140
//     //               }
//     //             />
//     //           </>
//     //         </div>
//     //       )}
//     //     </>
//     //   ) : null}

//     //   {audioType == "COMMENTFILE" ? (
//     //     <div className="flex items-center">
//     //       {isPlaying && (
//     //         <div className="mx-2">
//     //           <span> {Moment.utc(secs * 1000).format("mm:ss")} </span>
//     //         </div>
//     //       )}
//     //       <div className="text-center flex item-center">
//     //         {isPlaying && (
//     //           <>
//     //             <Waveform
//     //               className="mx-2"
//     //               lines={audioType == "COMMENTFILE" ? 10 : 140}
//     //             />
//     //           </>
//     //         )}
//     //       </div>
//     //     </div>
//     //   ) : null}

//     //   <div>
//     //     {isPlaying ? (
//     //       <Tooltip placement="topLeft" title="Pause">
//     //         <Button
//     //           onClick={(e) => {
//     //             pauseAudio();
//     //           }}
//     //           className="ant-btn-foucs"
//     //         >
//     //           <FaPause size={16} />
//     //         </Button>
//     //       </Tooltip>
//     //     ) : (
//     //       <Tooltip placement="topLeft" title="Play">
//     //         <Button
//     //           // hidden
//     //           disabled={isDisabled || isRecording || isPlayingStatus}
//     //           onClick={(e) => {
//     //             playAudio(data);
//     //           }}
//     //           className="hover"
//     //         >
//     //           <BsPlayCircle size={24} />
//     //         </Button>
//     //         <audio id="audio_player" hidden ref={audioRef} controls />
//     //       </Tooltip>
//     //     )}

//     //   </div>
//     // </div>
//     <div
//     className={`flex items-center ${isReverse == true ? "flex-reverse" : ""}`}
//   >
//     {contextHolder}
//     {audioType != "COMMENTFILE" ? (
//       <>
//         {isPlaying && (
//           <div
//             className={`text-center flex items-center ${data?.isSelected == true
//                 ? "pr-12"
//                 : localStorage.getItem("role") == "Consultant" ||
//                   localStorage.getItem("role") == "CIT"
//                   ? "pr-12"
//                   : "pr-44"
//               }`}
//           >
//             <>
//               <div className="mx-2">
//                 <span>
//                   {Moment.utc(secs * 1000).format("mm:ss")} /
//                   {Moment.utc(endingTime * 1000).format("mm:ss")}
//                 </span>
//               </div>
//               <Waveform
//                 className="mx-2"
//                 lines={
//                   audioType == "COMMENTFILE"
//                     ? 10
//                     : localStorage.getItem("role") == "Consultant" ||
//                       localStorage.getItem("role") == "CIT"
//                       ? 140
//                       : 140
//                 }
//               />
//             </>
//           </div>
//         )}
//       </>
//     ) : null}

//     {audioType == "COMMENTFILE" ? (
//       <div className="flex items-center">
//         {isPlaying && (
//           <div className="mx-2">
//             <span> {Moment.utc(secs * 1000).format("mm:ss")} </span>
//           </div>
//         )}
//         <div className="text-center flex item-center">
//           {isPlaying && (
//             <>
//               <Waveform
//                 className="mx-2"
//                 lines={audioType == "COMMENTFILE" ? 10 : 140}
//               />
//             </>
//           )}
//         </div>
//       </div>
//     ) : null}

//     <div>
//       {isPlaying ? (
//         <Tooltip placement="topLeft" title="Pause">
//           <Button
//             onClick={(e) => {
//               pauseAudio();
//             }}
//             className="ant-btn-foucs"
//           >
//             <FaPause size={16} />
//           </Button>
//         </Tooltip>
//       ) : (
//         <Tooltip placement="topLeft" title="Play">
//           <Button
//             disabled={isDisabled || isRecording || isPlayingStatus}
//             onClick={(e) => {
//               playAudio(data);
//             }}
//             className="hover"
//           >
//             <BsPlayCircle size={24} />
//           </Button>
//         </Tooltip>
//       )}

//       <audio id="audio_player" ref={audioRef} />
//     </div>
//   </div>
//   );
// };

// export default AudioPlayer;