// import React, { useState, useEffect } from "react";
// import MainLayout from "../../components/layout/main.layout";
// import { useNavigate, useLocation } from "react-router";
// import TitleBar from "../../components/ui/title-bar/title-bar.component";
// import { Table, Tooltip, Button, notification } from "antd";
// import DraftScriptModal from "../../components/modal/draft-scirpt-modal/index";
// // import {  getDraftFileByBookName,} from "../../services/indexed-db/draftfiles.indexeddb";
// import { waitForAWhile } from "../../services/config";
// import AudioPlayer from "../../components/audio-player/audio-player.component";
// import { useSelector, useDispatch } from "react-redux";
// import CommentsComponent from "../../components/comments/comments.component";
// import BackTranslationModal from "../../components/draft-page-component/back-translation-with-modal";
// import SmallSpinnerComponent from "../../components/spinner/small-spinner.component";
// import VideoPlayer from "../../components/video/video-player";
// import Chatbot from "../../components/chatbot";
// import FilenameComponent from "../../components/ui/filename.component";
// import {getDraftedFilesByBookNameOnline} from "../../services/api-calls/draft-files.api"
// import { storeBooks } from "../../redux/actions/books.action";
// import { storeProjects } from "../../redux/actions/project.action";
// import { saveBooksAndProjects } from "../../services/BooksAndProjects";

// const ProjecDetails = (relatedDraft) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const dispatch = useDispatch();
//   const projectsReducer = useSelector((state) => state.projectsReducer);
//   const booksReducer = useSelector((state) => state.booksReducer);
//   let stateData = location.state;
//   const [backTranslateFiles, setBackTranslateFiles] = useState([]);
//   const [isButtonDisabled, setIsButtonDisabled] = useState(false);
//   const [isTableLoading, setIsTableLoading] = useState(false);
//   const [currentChunk, setCurrentChunk] = useState({});
//   const [allChunks, setAllChunks] = useState([]);
//   const [isLoader, setIsLoader] = useState(true);
//   const [showComments, setShowComments] = useState(false);
//   const isRecordingStatus = useSelector(
//     (state) => state.recordingStatusReducer
//   );

//   useEffect(() => {
//     loadFiles();
//     setAllChunks(location.state.draftChunk);
//   }, [location]);

//   const [api, contextHolder] = notification.useNotification();

//   const getDraftBTFiles = async (chunks, draftedFile) => {
//     let filteredAudio = [];
//     chunks = JSON.parse(localStorage.getItem("selectedChunks"));
//     let targetType;
//     for (let i = 0; i < chunks.length; i++) {
//       targetType = "draft";
//       let draftData = await draftedFile.filter(
//         (item) =>
//           (item.fileType == "script" ||
//             item.fileType == "audio" ||
//             item.fileType == "video") &&
//           (item.chapterName === location.state.chapter ||
//             item.chapter === location.state.chapter) &&
//           item.targetType === targetType &&
//           item.sourceVerseName == chunks[i] &&
//           item.isSelected === true
//       );
//       targetType = "backTranslate";
//       for (const singleDraftFile in draftData) {
//         let backTranslateData = await draftedFile.filter(
//           (item) =>
//             (item.fileType === "script" ||
//               item.fileType === "audio" ||
//               item.fileType == "video") &&
//             (item.chapterName === location.state.chapter ||
//               item.chapter === location.state.chapter) &&
//             item.relatedDraft === draftData[singleDraftFile].fileName &&
//             item.targetType === targetType &&
//             item.sourceVerseName === chunks[i] &&
//             item.isSelected === true
//         );
//         if (backTranslateData.length > 0) {
//           // draftData=draftData[singleDraftFile]
//           draftData[singleDraftFile].backTranslate = backTranslateData;
//           let data = [draftData[singleDraftFile]];
//           filteredAudio = [...filteredAudio, ...data];
//           // break;
//         }
//       }
//     }
//     setBackTranslateFiles(filteredAudio);
//     return filteredAudio;
//   };

//   // const saveDataToLocalStorage = async(record) => {

//   //   let role = localStorage.getItem('role')
//   //   let statusChecked=false;
//   //   let backTranslateFileScript = record.backTranslate.filter((item)=> item.fileType=="script")
//   //   let backTranslateFileAudio = record.backTranslate.filter((item)=> item.fileType=="audio")
//   //   let fileID=[record.fileId,backTranslateFileScript.length>0 && backTranslateFileScript[0].fileId ,backTranslateFileAudio.length>0 && backTranslateFileAudio[0].fileId]
//   //  if(role == "CIT"){
//   //   let scriptStatusChecked = (backTranslateFileScript.length>0 && backTranslateFileScript[0].citStatusCheck == true)? true:false
//   //   let audioStatusChecked = (backTranslateFileAudio.length>0 && backTranslateFileAudio[0].citStatusCheck == true)? true:false
//   //   statusChecked = (scriptStatusChecked === true && audioStatusChecked === true) ? true:false
//   //  }
//   //  else{
//   //   let scriptStatusChecked = (backTranslateFileScript.length>0 && backTranslateFileScript[0].consultantStatusCheck == true)? true:false
//   //   let audioStatusChecked = (backTranslateFileAudio.length>0 && backTranslateFileAudio[0].consultantStatusCheck == true)? true:false
//   //   statusChecked = (scriptStatusChecked === true && audioStatusChecked === true) ? true:false
//   //  }

//   //   await localStorage.setItem("fileIds",JSON.stringify(fileID))
//   //   await localStorage.setItem("statusChecked",JSON.stringify(statusChecked))
//   // }

//   const loadFiles = async () => {
//     if (projectsReducer.length <= 0) {
//       let data = await saveBooksAndProjects();
//       await dispatch(storeProjects(data.projectsData));
//       await dispatch(storeBooks(data.booksData));
//     }
//     setIsLoader(true);
//     // let draftedFile = await getDraftFileByBookName(
//     //   location.state.book.bookName
//     // );
//     // let draftedFile = await getDraftedFilesByBookName
//     let draftData = {
//       projectId: stateData?.book.projectId,
//       bookName: stateData?.book.bookName,
//     };
//     let draftedFile = await getDraftedFilesByBookNameOnline(draftData);
//     let chunks = location.state.chunk;
//     let files = await getDraftBTFiles(chunks, draftedFile);
//     console.log("getDraftBTFiles",files);
//     await waitForAWhile(1000);
//     setBackTranslateFiles(files);
//     setIsLoader(false);
//   };

//   const columns = [
//     {
//       title: "Draft File",
//       dataIndex: "fileName",
//       render: (value, record) => (
//         <>
//           <div className="flex items-center">
//             <span className="mr-2">
//               <FilenameComponent file={record} />
//             </span>

//             {record.fileType == "video" ? (
//               <VideoPlayer
//                 data={record}
//                 isDisabled={isTableLoading}
//                 fileType="DRAFTFILE"
//                 isReverse={true}
//               />
//             ) : record.fileType == "audio" ? (
//               <AudioPlayer
//                 revokeOtherFunctions={setIsButtonDisabled}
//                 data={record}
//                 audioType={record.fileType}
//                 isDisabled={isTableLoading}
//                 isReverse={true}
//               />
//             ) : (
//               <DraftScriptModal data={record} isDisabled={false} />
//             )}
//           </div>
//         </>
//       ),
//     },
//     {
//       title: "Back Translation File",
//       dataIndex: "backTranslate",
//       render: (value, record) => (
//         <>{console.log("backTranslate",value)}
//           {value.map((BTfile) => {
//             return (
//               <p className="flex items-center mb-2">
//                 <span className="mr-2">
//                   <FilenameComponent file={BTfile} />
//                 </span>
//               </p>
//             );
//           })}
//         </>
//       ),
//     },
//     {
//       title: <span className={`flex items-center justify-center`}>Action</span>,
//       dataIndex: "",
//       key: "x",
//       render: (item, record) => (
//         <>
//           <span className="flex items-center justify-center">
//             {localStorage.getItem("role") == "CIT" ||
//             localStorage.getItem("role") == "Consultant" ? (
//               <BackTranslationModal
//                 chunk={record}
//                 draftFileName={item.fileName}
//                 isButtonDisabled={isTableLoading || isButtonDisabled}
//                 targetType={"draft"}
//                 fileType="audio"
//                 draftedFileId={item.id}
//                 reload={loadFiles}
//                 fileData={item}
//                 saveDataToLocalStorage={record}
//               />
//             ) : null}
//           </span>
//         </>
//       ),
//     },
//   ];

//   return (
//     <div>
//       <MainLayout>
//         <div className="draft-details ">
//           <div className="draft-details-wrapper h-screen">
//             <TitleBar title={"Back Translation"} />

//             <Table
//               loading={{
//                 spinning: isLoader,
//                 indicator: (
//                   <span>
//                     <SmallSpinnerComponent />
//                   </span>
//                 ),
//               }}
//               pagination={{
//                 pageSize: 3,
//                 hideOnSinglePage: true,
//               }}
//               columns={columns}
//               dataSource={backTranslateFiles}
//             />

//             <div className="pb-2">
//               {showComments && <CommentsComponent chunk={currentChunk} />}
//             </div>

//             <div>
//               <Chatbot />
//             </div>
//           </div>
//         </div>
//       </MainLayout>
//     </div>
//   );
// };

// export default ProjecDetails;

import React, { useState, useEffect } from "react";
import MainLayout from "../../components/layout/main.layout";
import { useNavigate, useLocation } from "react-router";
import TitleBar from "../../components/ui/title-bar/title-bar.component";
import { Table, Tooltip, Button, notification } from "antd";
import DraftScriptModal from "../../components/modal/draft-scirpt-modal/index";
// import {  getDraftFileByBookName,} from "../../services/indexed-db/draftfiles.indexeddb";
import { waitForAWhile } from "../../services/config";
import AudioPlayer from "../../components/audio-player/audio-player.component";
import { useSelector, useDispatch } from "react-redux";
import CommentsComponent from "../../components/comments/comments.component";
import BackTranslationModal from "../../components/draft-page-component/back-translation-with-modal";
import SmallSpinnerComponent from "../../components/spinner/small-spinner.component";
import VideoPlayer from "../../components/video/video-player";
import Chatbot from "../../components/chatbot";
import FilenameComponent from "../../components/ui/filename.component";
import { getDraftedFilesByBookNameOnline } from "../../services/api-calls/draft-files.api";
import { storeBooks } from "../../redux/actions/books.action";
import { storeProjects } from "../../redux/actions/project.action";
import { saveBooksAndProjects } from "../../services/BooksAndProjects";
import BackTranslation from "../../components/draft-page-component/back-translation";

const ProjecDetails = (relatedDraft) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const projectsReducer = useSelector((state) => state.projectsReducer);
  const booksReducer = useSelector((state) => state.booksReducer);
  let stateData = location.state;
  const [backTranslateFiles, setBackTranslateFiles] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [currentChunk, setCurrentChunk] = useState({});
  const [allChunks, setAllChunks] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [showComments, setShowComments] = useState(false);
  const isRecording = useSelector((state) => state.recordingStatusReducer);
  const isPlayingStatus = useSelector((state) => state.playingStatusReducer);
  const [backTranslateDetails, setBackTranslateDetails] = useState();
  const [showBtFile, setShowBtFile] = useState(true);

  useEffect(() => {
    loadFiles();
    setAllChunks(location.state.draftChunk);
  }, [location]);

  const [api, contextHolder] = notification.useNotification();
  const showNotification = (type, message) => {
    notification.destroy();
    api[type]({
      message,
      placement: "top",
    });
  };
  const getDraftBTFiles = async (chunks, draftedFile) => {
    let filteredAudio = [];
    chunks = JSON.parse(localStorage.getItem("selectedChunks"));
    let targetType;
    for (let i = 0; i < chunks.length; i++) {
      targetType = "draft";
      let draftData = await draftedFile.filter(
        (item) =>
          (item.fileType == "script" ||
            item.fileType == "audio" ||
            item.fileType == "video") &&
          (item.chapterName === location.state.chapter ||
            item.chapter === location.state.chapter) &&
          item.targetType === targetType &&
          item.sourceVerseName == chunks[i] &&
          item.isSelected === true
      );
      targetType = "backTranslate";
      for (const singleDraftFile in draftData) {
        let backTranslateData = await draftedFile.filter(
          (item) =>
            (item.fileType === "script" ||
              item.fileType === "audio" ||
              item.fileType == "video") &&
            (item.chapterName === location.state.chapter ||
              item.chapter === location.state.chapter) &&
            item.relatedDraft === draftData[singleDraftFile].fileName &&
            item.targetType === targetType &&
            item.sourceVerseName === chunks[i] &&
            item.isSelected === true
        );
        if (backTranslateData.length > 0) {
          // draftData=draftData[singleDraftFile]
          draftData[singleDraftFile].backTranslate = backTranslateData;
          let data = [draftData[singleDraftFile]];
          filteredAudio = [...filteredAudio, ...data];
          // break;
        }
      }
    }
    setBackTranslateFiles(filteredAudio);
    return filteredAudio;
  };

  // const saveDataToLocalStorage = async(record) => {

  //   let role = localStorage.getItem('role')
  //   let statusChecked=false;
  //   let backTranslateFileScript = record.backTranslate.filter((item)=> item.fileType=="script")
  //   let backTranslateFileAudio = record.backTranslate.filter((item)=> item.fileType=="audio")
  //   let fileID=[record.fileId,backTranslateFileScript.length>0 && backTranslateFileScript[0].fileId ,backTranslateFileAudio.length>0 && backTranslateFileAudio[0].fileId]
  //  if(role == "CIT"){
  //   let scriptStatusChecked = (backTranslateFileScript.length>0 && backTranslateFileScript[0].citStatusCheck == true)? true:false
  //   let audioStatusChecked = (backTranslateFileAudio.length>0 && backTranslateFileAudio[0].citStatusCheck == true)? true:false
  //   statusChecked = (scriptStatusChecked === true && audioStatusChecked === true) ? true:false
  //  }
  //  else{
  //   let scriptStatusChecked = (backTranslateFileScript.length>0 && backTranslateFileScript[0].consultantStatusCheck == true)? true:false
  //   let audioStatusChecked = (backTranslateFileAudio.length>0 && backTranslateFileAudio[0].consultantStatusCheck == true)? true:false
  //   statusChecked = (scriptStatusChecked === true && audioStatusChecked === true) ? true:false
  //  }

  //   await localStorage.setItem("fileIds",JSON.stringify(fileID))
  //   await localStorage.setItem("statusChecked",JSON.stringify(statusChecked))
  // }

  const loadFiles = async () => {
    // if (projectsReducer.length <= 0) {
    //   let data = await saveBooksAndProjects();
    //   await dispatch(storeProjects(data.projectsData));
    //   await dispatch(storeBooks(data.booksData));
    // }

    if(projectsReducer.length<=0 || booksReducer.length<=0){
      let data= await saveBooksAndProjects();

      data.projectsData.length >0 ? data.projectsData.forEach(async (item)=>{
        await dispatch(storeProjects([item]));
      }) : await dispatch(storeProjects([data.projectsData]));
  
      data.booksData.length>0 ? data.booksData.forEach(async(item)=>{
        await dispatch(storeBooks([item]));
      }): await dispatch(storeBooks([data.booksData]));
    }
    setIsLoader(true);
    // let draftedFile = await getDraftFileByBookName(
    //   location.state.book.bookName
    // );
    // let draftedFile = await getDraftedFilesByBookName
    let draftData = {
      projectId: stateData?.book.projectId,
      bookName: stateData?.book.bookName,
    };
    let draftedFile = await getDraftedFilesByBookNameOnline(draftData);
    let chunks = location.state.chunk;
    let files = await getDraftBTFiles(chunks, draftedFile);
    await waitForAWhile(1000);
    setBackTranslateFiles(files);
    await setBackTranslateDetails(files[0]);
    setShowBtFile(false)
    setIsLoader(false);
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "",
      render: (item, record) => {
        return (
          <div className="flex items-center">
            <span className="mr-2">
              <FilenameComponent file={record} />
            </span>
          </div>
        );
      },
    },
    {
      title: (
        <span className={`flex items-center  justify-end mr-4-5 w-full`}>Action</span>
      ),      
      dataIndex: "",
      render: (item, record) => {
        return (
          <div className="flex items-center">
            {record.fileType == "video" ? (
              <VideoPlayer
                data={record}
                isDisabled={isTableLoading}
                fileType="DRAFTFILE"
                isReverse={true}
              />
            ) : record.fileType == "audio" ? (
              <div className="w-full">
                <AudioPlayer
                revokeOtherFunctions={setIsButtonDisabled}
                data={record}
                audioType={record.fileType}
                isDisabled={isTableLoading}
                isReverse={false}
              />
              </div>
            ) : (
              <DraftScriptModal data={record} isDisabled={false} />
            )}
          </div>
        );
      },
    },
  ];

  const fetchRecords = async (page, pageSize) => {
    // Calculate the start and end indices for the current page
    if (isPlayingStatus == true) {
      showNotification("error", " Please pause current playing audio");
    }
    // checking status of recording
    else if (isRecording == true) {
      showNotification("error", "Please stop recording");
    }else{
      await setShowBtFile(true);
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      // Get the data for the current page
      let currentPageData = await backTranslateFiles.slice(startIndex, endIndex);
      await setBackTranslateDetails(currentPageData[0]);
      await setShowBtFile(false);
    }
  };

  return (
    <div>{contextHolder}
      <MainLayout>
        <div className="draft-details">
          <div className="draft-details-wrapper">
            <TitleBar title={"Back Translation"} />
            <div className="mt-2">
              {localStorage.getItem('role')=="CIT" || localStorage.getItem("role") == "Consultant" ? <h4 className="mb-0">OBT Draft</h4>:null}
              <Table
                loading={{
                  spinning: isLoader,
                  indicator: (
                    <span>
                      <SmallSpinnerComponent />
                    </span>
                  ),
                }}
                pagination={{
                  pageSize: 1,
                  hideOnSinglePage: true,
                  onChange: (page, pageSize) => {
                    fetchRecords(page, pageSize);
                  },
                }}
                columns={columns}
                dataSource={backTranslateFiles}
              />

              {showBtFile || !backTranslateDetails ? (
                <span className="flex justify-center items-center">
                 { backTranslateDetails !=undefined ? <SmallSpinnerComponent />:null}
                </span>
              ) : (
                <div className="flex items-center justify-center">
                  {localStorage.getItem("role") == "CIT" ||
                  localStorage.getItem("role") == "Consultant" ? (
                    <BackTranslation
                      chunk={backTranslateDetails}
                      draftFileName={backTranslateDetails.fileName}
                      isButtonDisabled={isTableLoading || isButtonDisabled}
                      targetType={"draft"}
                      fileType={backTranslateDetails.fileType}
                      draftedFileId={backTranslateDetails.id}
                      reload={loadFiles}
                      saveDataToLocalStorage={backTranslateDetails}
                      showType={backTranslateDetails.fileType}
                    />
                  ) : null}
                </div>
              )}
            </div>

            {/* <Table
              loading={{
                spinning: isLoader,
                indicator: (
                  <span>
                    <SmallSpinnerComponent />
                  </span>
                ),
              }}
              pagination={{
                pageSize: 3,
                hideOnSinglePage: true,
              }}
              columns={columns}
              dataSource={backTranslateFiles}
            /> */}

            <div className="pb-2">
              {showComments && <CommentsComponent chunk={currentChunk} />}
            </div>
            <div>
              <Chatbot />
            </div>
          </div>
        </div>
      </MainLayout>
    </div>
  );
};

export default ProjecDetails;
