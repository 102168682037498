import axios from "axios";
import { draftFile } from "../apis";
import getHeader from "../config";
import { bufferToBase64 } from "../conversion.service";
// Upload Draft File to Server
const uploadDraftFileToServer = async (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await axios.post(
        `${draftFile.uploadDraftFiles}`,
        body,
        getHeader()
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

// It update Draft file , isSelected== true
// const selectDraftFileOnServer = async (body) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let res = await axios.post(`${draftFile.selectDraftFile}/${body}`);
//       resolve(res.data);
//     } catch (error) {
//       console.log("Select File error" ,error)
//       reject(error);
//     }
//   });
// };

//select drafted file
const selectDraftedFileOnServer = async (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await axios.post(`${draftFile.selectDraftedFile}/${body}`);
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

// It will delete Draft file from the server along with Comments ,
// Master Recording and Back translation related to that file

// const deleteDraftFileFromServer = async (body) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let res = await axios.post(
//         `${draftFile.deleteDraftFile}/${body.fileName}`,
//         body
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

//delete drafted file
const deleteDraftedFileFromServer = async (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await axios.post(
        `${draftFile.deleteDraftedFile}/${body.fileId}`,
        body
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};
// Get all drafted file by Book Name, Chapter name , chunk name and language name
const getDraftedFilesFromServer = async (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await axios.post(
        `${draftFile.getDraftedFile}`,
        body,
        getHeader()
      );
      resolve(res?.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Update draft file on server
const updateDraftFileOnServer = async (name, body) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await axios.post(
        `${draftFile.updateDraftFile}/${name}`,
        body,
        getHeader()
      );
      resolve(res?.data);
    } catch (error) {
      reject(error);
    }
  });
};

// Update drafted file on server
const updateDraftedFileOnServer = async (fileId, body) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await axios.post(
        `${draftFile.updateDraftedFile}/${fileId}`,
        body,
        getHeader()
      );
      resolve(res?.data);
    } catch (error) {
      reject(error);
    }
  });
};

const saveDraftFileToServer = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      let fileObj = body;
      let blob = body.blob;

      delete body.blob;
      if (window.navigator.onLine == true) {
        if (body.fileType == "audio" || body.fileType == "video") {
          let base64 = await bufferToBase64(blob);
          body = { ...body, ...{ base64 } };
        } else {
          body = { ...body, ...{ base64: blob } };
        }
        let res = await uploadDraftFileToServer(body);
        resolve();
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  })
}

const getDraftedFilesByChunksOnline = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await axios.post(
        `${draftFile.getDraftedFilesByChunksOnline}`,
        body,
        getHeader()
      );
      resolve(res?.data)
    } catch (error) {
      console.log("ERROR", error);
    }
  })
}

const getDraftedFilesByBookNameOnline = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await axios.post(
        `${draftFile.getDraftedFilesByBookNameOnline}`,
        body,
        getHeader()
      );
      resolve(res?.data)
    } catch (error) {
      console.log("ERROR", error);
    }
  })
}



export {
  uploadDraftFileToServer,
  saveDraftFileToServer,
  // selectDraftFileOnServer,
  selectDraftedFileOnServer,
  // deleteDraftFileFromServer,
  deleteDraftedFileFromServer,
  getDraftedFilesFromServer,
  updateDraftFileOnServer,
  updateDraftedFileOnServer,
  getDraftedFilesByChunksOnline,
  getDraftedFilesByBookNameOnline
};
