import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleSpeech } from "../../redux/actions/util.action";
import { stopTexttoSpeech, textToSpeech } from "../../services/speech";

const SpeechPlay = ({ text }) => {
  const [isChunkPlaying, setIsChunkPlaying] = useState(false);
  const dispatch = useDispatch();
  const textToSpeechStatus = useSelector(
    (state) => state.textSpeechStatusReducer
  );

  const playAudio = async () => {
    if (isChunkPlaying == false) {
      setIsChunkPlaying(true);

      dispatch(toggleSpeech(true));
      if (typeof text == "object") {
        text =
          "Hi, I’m Rhema Bot, your virtual agent.  How can I help you today?";
      }

      let utterence = await textToSpeech(text);
      utterence.addEventListener("end", (evt) => {
        const { charIndex, utterance } = evt;
        if (charIndex + 1 === utterance.text.length) {
          setIsChunkPlaying(false);
          dispatch(toggleSpeech(false));
        } else {
          setIsChunkPlaying(false);
          dispatch(toggleSpeech(false));
        }
      });
    } else {
      setIsChunkPlaying(false);
      dispatch(toggleSpeech(false));
      stopTexttoSpeech();
    }
  };
  return (
    <>
      <div className="float-right">
        {isChunkPlaying == false ? (
          <>
            <button
              className={`remove-btn-css text-theme`}
              onClick={playAudio}
              disabled={textToSpeechStatus}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="25"
                viewBox="0 96 960 960"
                width="25"
                color={`${textToSpeechStatus == true ? "gray" : ""}`}
              >
                <path d="M617.827 918.652q-17.696 6.131-32.109-5-14.413-11.13-14.413-30.391 0-9.131 4.783-16.196 4.782-7.065 13.913-10.195 89.869-32 145.587-109 55.717-77 55.717-172.87t-55.717-173.087q-55.718-77.217-145.587-109.348-8.566-2.565-13.631-10.413-5.065-7.848-5.065-16.978 0-18.131 14.913-29.044t31.609-4.782q108.13 39.13 173.913 133.043Q857.523 458.304 857.523 575q0 116.696-65.783 210.609-65.783 93.913-173.913 133.043ZM142.652 710.131q-17.522 0-28.848-11.326-11.327-11.326-11.327-28.283V481.478q0-16.957 11.327-28.566 11.326-11.608 28.848-11.608h128.304l152.392-152.392q19.087-18.522 43.522-8.196 24.435 10.327 24.435 35.979V834.74q0 26.217-24.435 36.544-24.435 10.326-43.522-8.761L270.956 710.131H142.652Zm408.653 38.956V401.913q56.261 18.13 91.239 66.543Q677.523 516.87 677.523 576q0 60.13-34.979 107.261-34.978 47.13-91.239 65.826Z" />
              </svg>
            </button>
          </>
        ) : (
          <>
            <button className="remove-btn-css" onClick={playAudio}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="25"
                viewBox="0 96 960 960"
                width="25"
              >
                <path d="m793.261 988.74-108.87-108.87q-17.173 11.739-35.564 21.826-18.392 10.087-38.565 16.956-16.566 5.566-31.544-5-14.978-10.565-14.978-30.391 0-7.435 3.369-13.37 3.37-5.934 10.239-8.5 15.174-4.869 30.978-12.586 15.804-7.718 29.109-17.587L483.74 675.957V834.74q0 26.783-24.435 36.826-24.435 10.044-42.957-9.043L263.956 710.131H135.087q-16.957 0-28.283-11.326-11.327-11.326-11.327-28.283V481.478q0-16.957 11.327-28.566 11.326-11.608 28.283-11.608h116.956L57.565 245.13q-10.13-10.131-9.63-23.761.5-13.631 10.63-23.761 10.13-10.131 23.543-10.131 13.413 0 23.544 10.131l736.131 744.175q10.13 10.13 10.13 23.761 0 13.63-10.13 23.196-10.131 10.695-24.261 10.695-14.131 0-24.261-10.695ZM610.262 231.348q108.13 39.13 174.195 133.043Q850.523 458.304 850.523 575q0 52.696-14.848 102.826-14.848 50.131-43.413 94.696L744.74 723.87q20-33.435 29.5-71.153 9.5-37.717 9.5-77.717 0-97.87-54.935-176.587-54.935-78.717-145.804-106.848-8.566-2.565-13.913-9.913-5.348-7.348-5.348-16.478 0-18.131 14.913-29.044t31.609-4.782Zm48.565 406.609-95.087-96.218V408.913q48.695 23.13 77.739 68.261 29.044 45.13 29.044 98.826 0 16.13-3.066 31.761-3.065 15.63-8.63 30.196ZM483.74 460.609 365.043 340.216l51.305-51.304q18.522-18.522 42.957-8.196 24.435 10.327 24.435 35.979v143.914Z" />
              </svg>
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default SpeechPlay;
