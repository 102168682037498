import React from "react";

const SmallSpinnerComponent = () => {
  return (
    <span className=".spin-icon text-black mx-2">
      {/* <i className="fas fa-spinner fa-spin"></i>{" "} */} 
      <span class="dots-circle-spinner loading"></span>
    </span>
  );
};

export default SmallSpinnerComponent;
