import React from "react";

import {isTablet,isSafari,isMobile} from 'react-device-detect';
import AudioStaticPlaylist from './audio-static-playlist'
import AudioWavePlayList from './audio.page'

const AudioPlayListPage = () => {

  return (
    <>
    {
    !isTablet && !isSafari ? <AudioWavePlayList /> : <AudioStaticPlaylist />
    }
    </>
    
  );
};

export default AudioPlayListPage;