import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import headpic from "../../../assets/images/login.jpg";
import { Button, Checkbox, Form, Input, message } from "antd";
import axios from "axios";
import jwt from "jwt-decode";
import "../signin/signin.scss";
import "react-toastify/dist/ReactToastify.css";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { auth_route } from "../../../services/apis";
import NotificationComponent from "../../../components/notification/notification.component";
import { saveUserDetailsToLocalStorgae } from "../../../services/save-user";
import { removeUserDetailsFromLocalStorage } from "../../../services/save-user";

import { useEffect } from "react";

// import { firstLoadExecuted } from "../../../redux/actions/util.action";
import { useDispatch } from "react-redux";

const SigninPage = () => {
  const history = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    // loadAllSchema();
    form.setFieldsValue({
      email: localStorage.getItem("lastEmail"),
    });
    let checkLogin = localStorage.getItem("id");
    if (checkLogin) {
      history("/dashboard");
    } else if (!checkLogin) {
      localStorage.setItem("loggedIn", "false");
    
    }
  }, []);

  const [isLoader, setIsLoader] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notification, setNotification] = useState({
    type: "",
    content: "",
  });

  const onFinish = async (values) => {
    setIsLoader(true);
    try {
      let { email, password, keepSignedIn } = values;
      let res = await axios.post(`${auth_route.login}`, { email, password });

      if (res.status == 200) {
        await saveUserDetailsToLocalStorgae(jwt(res.data.token.rhemaServer));
        // if (keepSignedIn) localStorage.setItem("loggedIn", true);
        let role = localStorage.getItem("role");
        // dispatch(firstLoadExecuted(false));
        if (
          role == "Technician" ||
          role == "Coordinator" ||
          role == "Translation Manager"
        ) {
          if (window.navigator.onLine == true) {
            localStorage.setItem("lastEmail", email);
            
            history("/dashboard");
          } else {
            displayNotification("error", "Please check internet connection");
            localStorage.setItem("loggedIn", "false");
            await removeUserDetailsFromLocalStorage();
          }
        } else {
          localStorage.setItem("lastEmail", email);
          history("/dashboard");
        }
      }
    } catch (error) {
      console.log("Singin Error ", error);

      const { response, code } = error;
      if (code == "ERR_NETWORK") {
        displayNotification("error", "Please check internet connection");
      } else {
        if (typeof response.data.message == "object") {
          displayNotification("error", "Invalid values , please try again");
        } else {
          displayNotification("error", response.data);
          const { request, ...errorObject } = response;
        }
      }
    }
    setIsLoader(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const displayNotification = (type, content) => {
    setNotification({
      type,
      content,
    });
    setIsNotification(true);
    setTimeout(() => {
      setIsNotification(false);
      setNotification({
        type: "",
        content: "",
      });
    }, 1500);
  };

  return (
    <div className="signin container">
      <div className="login col-sm-4 col-sm-10 col-sm-6 mb-auto mt-auto">
       
        <div className="card shadow-lg border loginPage">
        <div className="headline ">
          <p className="fs-5 fs-md-4 fs-lg-3 fs-xl-2" style={{ color: "#031832" }}>
            Welcome to RhemaApp
          </p>
        </div>
          <div className="pic">
            <img
              src={headpic}
              style={{ width: "100%", height: "300px" }}
              alt="head pic"
            />
          </div>
          <div className="container col-sm-10 loginform w-100">
            <Form
              form={form}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              {isNotification && (
                <NotificationComponent
                  type={notification.type}
                  content={notification.content}
                />
              )}
              <div className="mb-4">
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                    {
                      type: "email",
                      warningOnly: true,
                      message: "Please enter valid email",
                    },
                  ]}
                >
                  <Input
                    placeholder="Email"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                  />
                </Form.Item>
              </div>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                />
              </Form.Item>

              <Form.Item>
                <div className="d-flex items-center justify-between">
                  {isLoader ? (
                    <span> </span>
                  ) : (
                    <NavLink
                      exact
                      to="/forgot-password"
                      className="login-form-forgot text-black"
                      href=""
                    >
                      Forgot password ?
                    </NavLink>
                  )}

                  <Form.Item
                    name="keepSignedIn"
                    valuePropName="checked"
                    noStyle
                  >
                    <Checkbox disabled={isLoader}>Keep me Signed In</Checkbox>
                  </Form.Item>
                </div>
              </Form.Item>

              <Form.Item
              className="signbutton"
                wrapperCol={{
                  // offset: 8,
                  span: 16,
                }}
                // style={{alignItems:'center'}}
              >
                <Button

                  htmlType="submit"
                  className="submit-btn m-auto"
                  style={{ background: "#031832", color: "white" }}
                  loading={isLoader}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>

        {/* <div className="signLink">
          {isLoader ? (
            <span></span>
          ) : (
            <Link to="/signup">
              <span className="signup"> Reach Rhema &gt;</span>{" "}
            </Link>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default SigninPage;
