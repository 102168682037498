import { notification } from "antd";
import React, { useEffect, useState } from "react";
// import { BiArrowBack } from "react-icons/bi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useLocation, useNavigate } from "react-router";
import DownDownSelectChunk from "../../components/draft-page-component/dropdown-select-chunk";
import SourceLanguage from "../../components/draft-page-component/source-language";
import MainLayout from "../../components/layout/main.layout";
import CrossCheckModal from "../../components/modal/cross-check-modal";
import SpinnerComponent from "../../components/spinner/spinner.component";
import { extractNumberFromString, getStoredValue, storeValue } from "../../services/config";
import CrossCheckAudio from "./cross-check-audio";
import CrossCheckScript from "./cross-check-script";
import CrossCheckVideo from "./cross-check-video";
import { useSelector,useDispatch } from "react-redux";
import {saveBooksAndProjects} from "../../services/BooksAndProjects"
import { storeBooks } from "../../redux/actions/books.action";
import {storeProjects} from "../../redux/actions/project.action"
import { clearCheckFilesName, clearFileCount } from "../../redux/actions/check-files.action";
import { clearTranScriptedText, togglePlaying } from "../../redux/actions/util.action";
import { setAudioRef } from "../../redux/actions/audio.action";
import { isSafari, isTablet } from "react-device-detect";

const CheckPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [project, setProject] = useState({});
  const [isLoader, setIsLoader] = useState(true);
  const [isForwardDisabled, setIsForwardDisabled] = useState(false);
  const [chapterChunks, setChapterChunks] = useState([]);
  const [currentChunkIndex, setCurrentChunkIndex] = useState("");
  const projectsReducer = useSelector((state) => state.projectsReducer);
  const booksReducer = useSelector((state) => state.booksReducer);
  const isPlayingStatus = useSelector((state) => state.playingStatusReducer);
  const audioRef = useSelector((state) => state.audioReducer);
  const [role, setRole] = useState(localStorage.getItem("role"));
  const isRecordingStatus = useSelector(
    (state) => state.recordingStatusReducer
  );

  const [api, contextHolder] = notification.useNotification();
  const showNotification = (type, message) => {
    notification.destroy();
    api[type]({
      message,
    });
  };

  useEffect(() => {
    updateProject();
    checkCurrentChunkIndex();

    return () => {
      localStorage.removeItem("checkPageMsg");
    };
  }, [location.state.verse]);

  const updateProject = () => {
    return new Promise(async (resolve, reject) => {
      let data= await saveBooksAndProjects();
      if(projectsReducer.length==0 || booksReducer.length==0){
        data.projectsData.length >0 ? data.projectsData.forEach(async (item)=>{
          await dispatch(storeProjects([item]));
        }) : await dispatch(storeProjects([data.projectsData]));
    
        data.booksData.length>0 ? data.booksData.forEach(async(item)=>{
          await dispatch(storeBooks([item]));
        }): await dispatch(storeBooks([data.booksData]));
      }

      setIsLoader(true);
      setIsForwardDisabled(false);
      setProject(location.state.project);
      checkCurrentChunkIndex();
      setIsLoader(false);
      resolve();
    });
  };

  const checkCurrentChunkIndex = async () => {
    try {
      let currentChapter = location.state?.book?.chapters.filter(
        (item) => item.chapterName == location?.state?.chapter
      );
      if (currentChapter.length > 0) {
        setChapterChunks(currentChapter[0].chunks);
        let index = await currentChapter[0].chunks.findIndex(
          (item) => item.chunkName == location?.state?.verse
        );
        setCurrentChunkIndex(index);

        if (currentChapter[0].chunks.length - 1 == index) {
          setIsForwardDisabled(true);
        }
      } else {
        setIsForwardDisabled(true);
      }
    } catch (error) {}
  };

  const goBack = async () => {
    await dispatch(clearCheckFilesName());
    await dispatch(clearFileCount())
    
    if (role == "MTT" || role == "Facilitator") {
      await dispatch(clearTranScriptedText());
    }

    if (isPlayingStatus == true) {
      showNotification("error", "Please stop playing");
    } else if (isRecordingStatus == true) {
      showNotification("error", "Please stop recording");
    } else {
      navigate("/draft");
    }
  };

  const goNext = async () => {
    if (isForwardDisabled == true) {
      showNotification("info", "This is last chunk");
    } 
    else if (chapterChunks.length - 1 !== currentChunkIndex) {

      if(isSafari || isTablet){
        let audio =  getStoredValue();
        audio != undefined && audio.current?.pause();
        dispatch(togglePlaying(false));
        storeValue(undefined)
       }
      setIsLoader(true);
      let nextChunk = chapterChunks[currentChunkIndex + 1];
      let headingName = await extractNumberFromString(nextChunk.chunkName);
      await dispatch(togglePlaying(false))
      await dispatch(clearCheckFilesName());
      await dispatch(clearFileCount())
      await dispatch(clearTranScriptedText());

      nextChunk.chunk=nextChunk.chunk.sort((a, b) => {
        // Extracting file extensions (assuming all files have extensions)
        const extensionA = a.fileName.split('.').pop();
        const extensionB = b.fileName.split('.').pop();        
        // Assigning priorities based on file type
        const priority = { 'mp3': 1, 'txt': 2 };
        // Comparing based on priorities
        return priority[extensionA] - priority[extensionB];
      });  

      navigate("/cross-check", {
        state: {
          chunkName: location.state?.chunkName,
          chunk: nextChunk,
          book: location.state?.book,
          verse: nextChunk.chunkName,
          heading: `${location.state?.chunkName} ${headingName}`,
          chapter: location.state?.chapter,
          project: location.state?.project,
          targetType: "draft",
        },
      });
      setIsLoader(false);
    }
  };

  return (
    <MainLayout>
      {isLoader ? (
        <SpinnerComponent />
      ) : (
        <div className="draft-details">
          <div className="draft-details-wrapper">
            <div className="grid grid-cols-3 border-bottom   ">
              <div className="back-arrow">
                <IoIosArrowBack size={26} onClick={goBack} />
              </div>
              <div className="flex items-center justify-center chunkname">
                <h4>{location.state.heading}</h4>
                <DownDownSelectChunk
                  url={"/cross-check"}
                  size={30}
                  className="pb-2"
                  isloader = {setIsLoader}
                />
              </div>

              <div className="flex items-center justify-end">
                <CrossCheckModal />
                <button className="back-arrow ml-2 remove-btn-css">
                  <IoIosArrowForward
                    size={26}
                    onClick={goNext}
                    color={isForwardDisabled == true ? "gray" : "black"}
                  />
                </button>
              </div>
            </div>

            <div className="pt-2">
            <SourceLanguage chunk={location.state?.chunk} title={"Source"}/>
            </div>

            {Object.keys(project).length ? (
              <>
                {project.projectType.signLanguage == true ? (
                  <CrossCheckVideo />
                ) : null}

                <>
                  {project.projectType.audio == true ? (
                    <CrossCheckAudio />
                  ) : null}
                </>

                <>
                  {project.projectType.text == true ? (
                    <CrossCheckScript />
                  ) : null}
                </>
              </>
            ) : (
              <></>
            )}

            {contextHolder}
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default CheckPage;
