import { Layout } from "antd";
import React from "react";

const FooterComponent = () => {
  const year = new Date().getFullYear();
  return (
    <div className="select-none">
      {" "}
      <Layout className="site-layout ">
        <Layout.Footer
          style={{
            textAlign: "center", 
          }}
        >
          Copyright &copy; {year}. Marisree LLC. All Rights Reserved
        </Layout.Footer>
      </Layout>
    </div>
  );
};

export default FooterComponent;
